import React, { useMemo } from 'react';

import Images from '@/assets/images';

type CircularProgressType = {
  size: number;
  strokeWidth: number;
  percentage: number;
  color: string;
  profilePhoto?: string;
};

const CircularProgress = ({
  size,
  strokeWidth,
  percentage,
  color,
  profilePhoto,
}: CircularProgressType) => {
  const viewBox = `0 0 ${size} ${size}`;

  const circleValues = useMemo(() => {
    const radius = (size - strokeWidth) / 2;
    const circumference = radius * Math.PI * 2;
    const dash = (percentage * circumference) / 100;
    return { radius, circumference, dash };
  }, [size, strokeWidth, percentage]);

  const { radius, circumference, dash } = circleValues;

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        fill="none"
        stroke="#F1F1F1"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        fill="none"
        stroke={color}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeDasharray={[dash, circumference - dash] as any}
        strokeLinecap="round"
        style={{ transition: 'all 0.5s' }}
      />
      <image
        x={'12.6%'}
        y={'12.6%'}
        href={profilePhoto || Images.defaultProfilePic}
        height="150"
        width="150"
        clipPath="circle()"
      />
    </svg>
  );
};

export default CircularProgress;
