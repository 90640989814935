import { gql } from '@apollo/client';

export const DELETE_CONVERSATION = gql`
  mutation ($conversationId: String!) {
    deleteConversationPost(input: { conversationId: $conversationId }) {
      success
      error
    }
  }
`;
