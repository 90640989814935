import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { View, Image, Pressable } from 'react-native';
import PieChart from 'react-native-pie-chart';
import SelectDropdown from 'react-native-select-dropdown';
import { Reoverlay } from 'reoverlay';

import images from '@/assets/images';
import {
  LoadingIndicator,
  PrimaryButton,
  Text,
  useSessionTokenContext,
} from '@/components/web';
import { eventProps } from '@/constants/ANALYTICS_CONST';
import useWebHooks from '@/hooks/useWebHooks';
import { FETCH_USER_STASHLINKS } from '@/queries';
import { FETCH_STASHLINK_SUMMARY } from '@/queries/fetch-stashlink-summary';
import { Stashlink, StashlinkHubAnalyticsData, User } from '@/types';
import {
  COLORS,
  maxWidth,
  numberWithCommas,
  profileHaloColorPicker,
  scroll,
  trackEvent,
} from '@/utils';
import tw from '@tw';
import { WebWrapper } from '@web/components';
import RoundedBorderedImage from '@web/components/RoundedBorderedImage';
import { SuggestionBox } from '@web/components/SuggestionBox';

const AuthOverview = () => {
  const { navigate } = useWebHooks();
  const { userId } = useSessionTokenContext();
  const [artificialLoad, setArtificialLoad] = useState(true);
  const [page, setPage] = useState('stashlink');
  const [fetchUser, { loading, data: userData, refetch }] = useLazyQuery<{
    user: User;
  }>(FETCH_USER_STASHLINKS, {
    variables: { userId },
  });

  const [
    fetchStashLinkSummary,
    { loading: StashLinkSummaryLoading, data: stashLinkSummaryData },
  ] = useLazyQuery<{ stashlinkHubAnalyticsData: StashlinkHubAnalyticsData }>(
    FETCH_STASHLINK_SUMMARY,
    {
      variables: { entityType: 'Stashlink' },
    },
  );

  const stashlinkLengthCheck = (userData?.user?.stashlinks?.length || 0) > 0;
  const haloProperties = profileHaloColorPicker(
    userData?.user?.impactLevel || 0,
    userData?.user?.percentToNextLevel || 0,
    true,
  );
  useEffect(() => {
    if (userId) {
      fetchUser();
      fetchStashLinkSummary();
    } else {
      navigate('/auth');
    }

    const timeout = setTimeout(() => {
      setArtificialLoad(false);
    }, 500);
    // force a refetch to have fresh data
    refetch();
    if (!stashlinkLengthCheck) {
      scroll.lock();
    }
    return () => {
      scroll.enable();
      clearTimeout(timeout);
    };
  }, [userData]);

  const noStashLink = () => (
    <>
      <View style={tw`flex flex-1 items-center px-7 pt-12 relative z-10`}>
        <Text override={tw`text-6xl mb-3`}>👋</Text>
        <Text bold mediumSmallPlus2 centered override={tw`mb-7`}>
          Welcome to the Stashrun fam!
        </Text>
        <Text bold mediumSmallPlus2 centered>
          Stashrun lets you create and share links to fund charities—100% free!
        </Text>
        <Text small centered override={tw`mb-8 mt-6`}>
          Discover and fund the causes you care about by sharing links with
          friends.
        </Text>

        <PrimaryButton
          title="Get Started"
          onPress={() => navigate('/create-stash')}
          style={tw`self-center w-2/3 mb-2.5`}
          fullRadius
        />
        {/* <PrimaryButton
      title="Try it!"
      type="withBorder"
      size="tiny"
      style={tw`self-center w-40`}
      containerStyle={tw`border-primary`}
      titleStyle={tw`text-primary`}
      customRender={
        <Image source={images.link} style={tw`w-2.5 h-2.5 ml-2`} />
      }
      onPress={() => {
        trackEvent('tryItClicked');
        // Reoverlay.showModal('ShareModal');
      }}
      fullRadius
    /> */}
      </View>
      <Image
        source={images.loginIntro}
        style={tw`w-80 h-48 fixed bottom-0 self-center z-0`}
        resizeMode="contain"
      />
    </>
  );

  const stashlinkListView = () => {
    const stashlinkList = userData?.user?.stashlinks || [];
    return (
      <View style={tw`pb-30`}>
        <Text small color={COLORS.trueGray} override={tw`pl-4`}>
          {stashlinkList.length} stashlinks
        </Text>
        <View
          style={tw`border-b-1 border-dividerGray w-9/10 self-center py-2`}
        />
        {stashlinkList.map((stashlink: Stashlink, i: number) => {
          const raisedAmount = stashlink?.dollarsDonated || 0;
          return (
            <View
              style={tw`h-24 w-full border-dividerGray border-b-1 flex-row px-4 py-2`}
              key={i}
            >
              <View style={tw`w-full flex-col`}>
                <View style={tw`h-2/3 flex-col`}>
                  <Text
                    xsmallPlus2
                    numberOfLines={2}
                    override={tw`font-black my-1`}
                  >
                    {stashlink.campaign?.title || ''}
                  </Text>
                  <Text
                    xsmallPlus2
                    numberOfLines={1}
                    color={COLORS.secondaryText}
                  >
                    {stashlink?.campaign?.charity?.name || ''}
                  </Text>
                </View>
                <View style={tw`h-1/3 w-8/10 items-end flex-row`}>
                  {/* <Pressable
                    onPress={() =>
                      window.open(
                        stashlink.url.replace(
                          'https://app.stashrun.com',
                          window.location.origin,
                        ),
                        '_blank',
                        'noreferrer',
                      )
                    }
                  >
                    <Text small bold primary>
                      View
                    </Text>
                  </Pressable> */}
                  <Text xsmallPlus2 color={COLORS.trueGray} bold>
                    {stashlink?.numViews || 0} Views
                  </Text>
                  <Text
                    xsmallPlus2
                    color={COLORS.trueGray}
                    bold
                    override={tw`px-2`}
                  >
                    •
                  </Text>
                  <Text xsmallPlus2 color={COLORS.trueGray} bold>
                    ${raisedAmount.toFixed(0)} Raised
                  </Text>
                  <Text
                    xsmallPlus2
                    color={COLORS.trueGray}
                    bold
                    override={tw`px-2`}
                  >
                    •
                  </Text>
                  <Text xsmallPlus2 color={COLORS.trueGray} bold>
                    {stashlink?.numShares || 0} Shares
                  </Text>
                </View>
              </View>
              <View style={tw`absolute bottom-2 right-0`}>
                <Pressable
                  style={tw`bg-zinc-100 p-2 rounded-full mr-4`}
                  onPress={() => {
                    trackEvent('hubShareModalClicked');
                    Reoverlay.showModal('VideoMessageUploadModal', {
                      url: stashlink.url.replace(
                        'https://app.stashrun.com',
                        window.location.origin,
                      ),
                      campaign: stashlink.campaign,
                      referrerUserId: userId,
                      stashlinkId: stashlink.id,
                      stashlinkMessage: stashlink.thankYouMessage,
                      stashlinkMessageVideo: stashlink.thankYouMessageVideo,
                      haloColor: haloProperties.haloColor,
                      profilePhoto: userData?.user?.profilePhoto,
                      refetch,
                      userName: userData?.user?.fullName,
                    });
                  }}
                >
                  <Image source={images.shareDark} style={tw`w-5 h-5`} />
                </Pressable>
              </View>
            </View>
          );
        })}

        <View
          style={tw.style(
            `fixed h-24 max-w-${maxWidth(
              500,
            )}px w-full bg-white bottomSheetShadow self-center items-center bottom-0 pt-15px rounded-tl-[20px] rounded-tr-[20px]`,
          )}
        >
          <PrimaryButton
            style="w-full"
            onPress={() => navigate('/create-stash')}
            title={'Create New Link'}
            fullRadius
          />
          <Text
            xsmallPlus
            italicizedNotBold
            override={tw`pt-10px text-center w-full text-trueGray`}
          >
            Create and share Stashlinks to fund charities
          </Text>
        </View>
      </View>
    );
  };

  const activity = () => {
    const stashlinkSummaryDataValues =
      stashLinkSummaryData?.stashlinkHubAnalyticsData?.summaryData || [];

    const stashlinkSummaryDate = new Date(
      stashLinkSummaryData?.stashlinkHubAnalyticsData?.startingDate || '',
    ).toLocaleDateString();
    let series = [] as number[];
    const seriesLegends = [] as { name: string; value: number }[];
    stashlinkSummaryDataValues?.forEach((x) => {
      if (x.name.includes('Sources')) {
        if (x.value === 0) {
          series.push(0.01);
        } else {
          series.push(Number(x.value));
        }
        seriesLegends.push({
          name: x.name.replace(' Sources', ''),
          value: x.value,
        });
      }
    });
    const sum = series.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);
    if (sum === 0) {
      // set piechart to all values of 1 for default view
      series = [1, 1, 1, 1, 1, 1];
    }
    const sliceColor = [
      '#5551FF',
      '#00E4D1',
      '#F1F1F1',
      '#1A194F',
      '#999999',
      '#444444',
    ];

    return (
      <View style={tw`flex flex-row flex-wrap`}>
        {stashlinkSummaryDataValues.map((summary, i) => {
          if (i === 0) {
            return (
              <View key={i} style={tw`w-full bg-white`}>
                <View
                  style={tw`h-20 rounded-2xl border border-zinc-300 mx-4 p-2 mb-4 gap-2`}
                >
                  <Text xsmallPlus2>{summary.name}</Text>
                  <Text mediumSmall bold>
                    {summary.value}
                  </Text>
                  <Text xsmall color={COLORS.trueGray}>
                    from <Text xsmall>{stashlinkSummaryDate}</Text>
                  </Text>
                </View>
              </View>
            );
          }

          if (!summary.name.includes('Sources')) {
            return (
              <View key={i} style={tw`w-1/2 bg-white`}>
                <View
                  style={tw.style(
                    `h-20 rounded-2xl border border-zinc-300 mb-4 p-2 gap-2`,
                    i % 2 === 0 ? 'mr-4 ml-2' : 'ml-4 mr-2',
                  )}
                >
                  <Text xsmallPlus2>{summary.name}</Text>
                  <Text mediumSmall bold>
                    {summary.value}
                  </Text>
                  <Text xsmall color={COLORS.trueGray}>
                    from <Text xsmall>{stashlinkSummaryDate}</Text>
                  </Text>
                </View>
              </View>
            );
          }
          return null;
        })}
        <View style={tw`w-full`}>
          <View
            style={tw`rounded-2xl border border-zinc-300 mx-4 flex-row justify-between p-2 mb-6`}
          >
            <View>
              <Text xsmallPlus2>Social Clicks</Text>
              <Text xsmall color={COLORS.trueGray}>
                from <Text xsmall>{stashlinkSummaryDate}</Text>
              </Text>
              {seriesLegends.map((legends, index) => (
                <View key={index} style={tw`flex-row mt-2`}>
                  <View
                    style={tw.style(`w-2.5 h-2.5 rounded-full ml-2 mr-2`, {
                      backgroundColor: sliceColor[index],
                    })}
                  />
                  <Text xsmallPlus>
                    {legends.name === 'Direct' ? 'Other' : legends.name}
                    {'   '}•{'   '}
                    {legends.value} clicks
                  </Text>
                </View>
              ))}
            </View>
            <PieChart
              widthAndHeight={100}
              series={series}
              sliceColor={sliceColor}
              style={tw`p-4 pt-10`}
            />
          </View>
          <SuggestionBox activity />
        </View>
      </View>
    );
  };

  const stashlinkAvailable = () => {
    return (
      <View style={tw`flex flex-1 relative z-10`}>
        <View style={tw`bg-white z-11 w-full`}>
          <View
            style={tw`pl-4 py-3 border-lightGray border-b-2 flex-row w-full`}
          >
            <RoundedBorderedImage
              color={userData?.user?.haloColor || ''}
              type="outer"
              size={80}
              image={userData?.user?.profilePhoto}
              noShadow
            />
            <View style={tw`pl-4`}>
              <Text mediumSmall bold>
                {userData?.user?.fullName || ''}
              </Text>
              <Text small numberOfLines={1} color={COLORS.trueGray}>
                {numberWithCommas(
                  Number(userData?.user?.impactScore?.toFixed(0)) || 0,
                )}{' '}
                Impact
              </Text>
              <Pressable
                style={tw`bg-zinc-100 w-24 rounded py-1 justify-center items-center mt-4`}
                onPress={() => {
                  trackEvent('editProfileButtonClicked');
                  navigate?.('/auth/edit-profile');
                }}
              >
                <Text
                  xsmallPlus2
                  bold
                  override={tw`text-neutral-700 font-black pt-0.6`}
                >
                  Edit Profile
                </Text>
              </Pressable>
            </View>

            <SelectDropdown
              data={['logout'] as any}
              defaultButtonText={'⋯'}
              renderCustomizedButtonChild={() => {
                return <Image source={images.dotMenu} style={tw`h-5 w-6`} />;
              }}
              buttonStyle={tw`h-5 w-6 bg-zinc-100 p-0 rounded-md absolute right-4`}
              buttonTextStyle={tw`font-heavy text-black text-sm p-0 mt-0.8`}
              rowStyle={tw`border-b-transparent`}
              rowTextStyle={tw`font-heavy text-black text-sm p-0 mt-0.8`}
              dropdownStyle={tw`w-20 h-6 absolute left-${
                maxWidth(500) - 95
              }px rounded-md`}
              onSelect={() => {
                Reoverlay.showModal('LogoutModal', { navigate });
              }}
              dropdownOverlayColor={'transparent'}
            />
          </View>
          <View style={tw`flex-row justify-around px-20 pt-6`}>
            <Pressable
              style={tw.style(
                `w-24 items-center pb-1`,
                page === 'stashlink' && `border-b-2 border-purple `,
              )}
              onPress={() => setPage('stashlink')}
            >
              <Text small bold={page === 'stashlink'}>
                Stashlinks
              </Text>
            </Pressable>
            <Pressable
              style={tw.style(
                `w-24 items-center pb-1`,
                page === 'activity' && `border-b-2 border-purple `,
              )}
              onPress={() => setPage('activity')}
            >
              <Text small bold={page === 'activity'}>
                Activity
              </Text>
            </Pressable>
          </View>
        </View>
        <View style={tw`mt-4`} />
        {page === 'stashlink' && stashlinkListView()}
        {page === 'activity' && activity()}
      </View>
    );
  };

  if (loading || StashLinkSummaryLoading || artificialLoad)
    return <LoadingIndicator />;

  return (
    <WebWrapper analytics={{ [eventProps.page]: 'Login intro' }}>
      {stashlinkLengthCheck ? stashlinkAvailable() : noStashLink()}
    </WebWrapper>
  );
};

export default AuthOverview;
