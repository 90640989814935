import React from 'react';

import { Linking, StyleSheet, TouchableOpacity } from 'react-native';

import FlexContainer from '@/components/FlexContainer';
import Text from '@/components/Text';
import { trackEvent, COLORS } from '@/utils';

const styles = StyleSheet.create({
  container: {
    padding: 4,
    paddingHorizontal: 10,
    borderWidth: 2,
    borderRadius: 4,
    textAlign: 'center',
    borderColor: COLORS.purple,
    color: COLORS.purple,
  },
});

type Props = {
  block?: boolean;
  link: string;
  color?: string;
};

const WereHiringButton: React.FC<Props> = ({
  block = false,
  link,
  color = COLORS.purple,
}) => {
  const onPress = async () => {
    const url = link.indexOf('://') === -1 ? `https://${link}` : link;
    trackEvent('hiringButtonClicked', { link });
    return Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      }
    });
  };
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        ...styles.container,
        ...{
          color,
          borderColor: color,
        },
        ...(block ? { width: '100%' } : { width: 120 }),
      }}
    >
      <FlexContainer row justifyContent="center">
        <Text small primary bold override={{ color }}>
          We’re Hiring!
        </Text>
      </FlexContainer>
    </TouchableOpacity>
  );
};

export default WereHiringButton;
