import { gql } from '@apollo/client';

export const SET_PINNED_CAUSES = gql`
  mutation SetPinnedCauses($pinnedCauseIds: [String!]!) {
    setPinnedCauses(input: { pinnedCauseIds: $pinnedCauseIds }) {
      success
      error
    }
  }
`;
