import React, { useMemo } from 'react';

import { View } from 'react-native';

import { avatarImage } from '@/assets/images';
import tw from '@tw';

import RoundedBorderedImage from './RoundedBorderedImage';

type AvatarListProps = {
  size: number;
  count: number;
  noShadow?: boolean;
};

const AvatarList: React.FC<AvatarListProps> = ({
  size = 50,
  count = 10,
  noShadow,
}) => {
  const generateRandomIntegers = (numIntegers: number) => {
    const randomIntegers = [] as number[];

    for (let i = 0; i < numIntegers; i += 1) {
      let newNumber;
      do {
        newNumber = Math.floor(Math.random() * 100) + 1;
      } while (randomIntegers.includes(newNumber));

      randomIntegers.push(newNumber);
    }
    return randomIntegers;
  };

  const ids = useMemo(() => generateRandomIntegers(count), [count]);

  return (
    <div style={tw`flex flex-row`}>
      {ids.map((id, idx) => (
        <View
          key={id}
          style={tw`z-${count - idx} ${idx !== 0 ? `ml-[-30]` : ''}`}
        >
          <RoundedBorderedImage
            image={avatarImage(id)}
            type="inner"
            size={size}
            borderWidth={2}
            borderOffset={8}
            color="white"
            noShadow={noShadow}
            noDefault
          />
        </View>
      ))}
    </div>
  );
};

export default AvatarList;
