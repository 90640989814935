import React from 'react';

import { Image } from 'expo-image';
import { View } from 'react-native';
import ProgressBarAnimated from 'react-native-progress-bar-animated';

import { Text } from '@/components/web';
import { Campaign } from '@/types';
import { COLORS } from '@/utils';
import { addCloudinaryTransformation } from '@/utils/format';
import tw from '@tw';

type CampaignCardProps = {
  campaign: Campaign;
  rotate?: boolean;
  shadow?: boolean;
};

const WebCampaignCard = ({ campaign, rotate, shadow }: CampaignCardProps) => {
  const { imageUrl, currentAmount, charity, goalAmount, title } =
    campaign || {};

  const remainingPercentage =
    (Number(currentAmount || 0) / Number(goalAmount)) * 100;

  return (
    <View
      style={tw.style(
        `w-220px h-240px bg-white rounded-2xl border border-borderGray my-4`,
        shadow && `brandImageShadow`,
        {
          transform: [
            {
              rotate: rotate ? `-5deg` : `0deg`,
            },
          ],
        },
      )}
    >
      <View style={tw`pt-2 px-2`}>
        <Text bold xsmallPlus2 numberOfLines={2}>
          {title || ''}
        </Text>
      </View>

      <View style={tw`px-2 py-1 z-1`}>
        <Text xsmall numberOfLines={1} color={COLORS.alternativeText}>
          {charity?.name || ''}
        </Text>
        <Image
          style={tw.style(
            `absolute h-12 w-12 rounded-full border-2 right-10px -top-0.5`,
            {
              borderColor: charity?.charityColor || 'black',
            },
          )}
          source={{
            uri: addCloudinaryTransformation(
              charity?.imageUrl,
              'c_scale,w_120',
            ),
          }}
        />
      </View>

      <Image
        style={tw.style(`w-full h-140px`)}
        source={{
          uri: addCloudinaryTransformation(imageUrl, 'c_scale,h_140,dpr_2'),
        }}
      />

      <View style={tw`mt-8px justify-center self-center`}>
        <ProgressBarAnimated
          backgroundColor={COLORS.progressBar}
          underlyingColor={COLORS.gray}
          width={180}
          height={6}
          borderWidth={0}
          borderRadius={10}
          value={Math.min(100, remainingPercentage)}
        />
        <View style={tw`self-center mt-4px`}>
          {campaign?.canSupport || remainingPercentage < 100 ? (
            <>
              <Text xsmall centered>
                <Text xsmall centered>
                  ${(currentAmount ? currentAmount / 100 : 0).toFixed(0)}{' '}
                </Text>
                raised of $
                {(goalAmount ? goalAmount / 100 : 0).toLocaleString() || ''}{' '}
                goal
              </Text>
            </>
          ) : (
            <Text xsmall>Completed!</Text>
          )}
        </View>
      </View>
    </View>
  );
};

export default WebCampaignCard;
