import { gql } from '@apollo/client';

export const FETCH_CONVERSATION = gql`
  query ($conversationId: ID!) {
    conversation(id: $conversationId) {
      id
      title
      heroImageUrl
      numVotes
      createdAt
      message
      numComments
      deletedAt
      isAnonymous
      user {
        id
        username
        profilePhoto
        school {
          id
          name
        }
      }
      userVotes {
        id
        voteType
      }
      cause {
        id
        name
        moderators {
          id
          username
          fullName
          profilePhoto
        }
      }
    }
  }
`;
