import React from 'react';

import { Platform, View, Image, Pressable } from 'react-native';
import { Reoverlay } from 'reoverlay';

import backArrow from '@/assets/backArrow.png';
import images from '@/assets/images';
import Stashrun from '@/assets/Stashrun-title.png';
import { Text } from '@/components/web';
import { trackEvent } from '@/utils';
import tw from '@tw';

type WailistModalProps = {
  onBack: () => void;
};

const WaitlistModal: React.FC<WailistModalProps> = ({ onBack }) => {
  return (
    <View
      style={[
        tw.style(
          'left-0 w-full flex z-999 top-0 bg-white h-full justify-center items-center',
          {
            fixed: Platform.OS === 'web',
            absolute: Platform.OS !== 'web',
          },
        ),
      ]}
    >
      <Pressable
        style={[tw.style('top-16 z-10 left-2 absolute')]}
        onPress={onBack}
      >
        <Image
          style={{ width: 33, height: 16, padding: 5 }}
          source={backArrow}
        />
      </Pressable>
      <Pressable
        style={[tw.style('top-2 left-2 absolute')]}
        onPress={() => Reoverlay.showModal('StashrunModal')}
      >
        <Image style={tw.style('w-32 h-6')} source={Stashrun} />
      </Pressable>
      <Pressable
        style={tw`w-25 z-10 h-7 rounded justify-center items-center bg-purple absolute top-2 right-2`}
        onPress={() => {
          trackEvent('whatStashrunButtonClicked', {});
          Reoverlay.showModal('StashrunModal');
        }}
      >
        <Text xsmallPlus2 italicized inverse override={tw`mt-1px`}>
          How It Works
        </Text>
      </Pressable>
      <View style={tw.style('mb-7')}>
        <Text mediumSmallPlus semiboldMinus centered>
          We’re working hard to let you in.{'\n'}
          Please try again later!
        </Text>
      </View>
      <Image
        style={{ width: 300, height: 300, padding: 5, borderRadius: 12 }}
        source={images.waitlist}
      />
    </View>
  );
};

export default WaitlistModal;
