import plugin from 'tailwindcss/plugin';

import { COLORS } from './src/utils/constants';

module.exports = {
  theme: {
    fontFamily: {
      sans: ['Avenir-Regular'],
      blackItalic: ['Avenir-BlackOblique'],
      heavy: ['Avenir-Heavy'],
      black: ['Avenir-Black'],
      medium: ['Avenir-Medium'],
    },
    extend: {
      height: {
        13: 50,
      },
      colors: {
        primary: '#5551FF',
        secondary: '#FF1930',
        ...COLORS,
      },
      borderRadius: {
        default: 10,
      },
      zIndex: {
        full: '999',
      },
      borderWidth: {
        1: 1,
      },
    },
  },
  plugins: [
    plugin(({ addUtilities }) => {
      addUtilities({
        fixed: {
          position: `fixed`,
        },
        bottomSheetShadow: {
          boxShadow: '0px -3px 12px rgba(0, 0, 0, 0.2)',
        },
        campaignCardShadow: {
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.16)',
        },
        brandImageShadow: {
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
        },
      });
    }),
  ],
};
