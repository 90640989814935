import { gql } from '@apollo/client';

export const FETCH_CAMPAIGNS_BY_CHARITY = gql`
  query ($charityId: ID!) {
    campaignListByCharity(charityId: $charityId) {
      id
      title
      description
      location
      imageUrl
      currentAmount
      goalAmount
      studentOrgs
      charity {
        id
        name
        imageUrl
        videoUrl
        heroImageUrl
      }
      cause {
        id
        name
      }
    }
  }
`;
