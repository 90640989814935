import React from 'react';

import { ActivityIndicator } from 'react-native';

import { FlexItem } from '@/components/FlexContainer';
import { COLORS } from '@/utils/constants';

type LoadingProps = {
  color?: string;
};

export default function LoadingIndicator({ color }: LoadingProps) {
  return (
    <FlexItem>
      <ActivityIndicator size="large" color={color || COLORS.purple} />
    </FlexItem>
  );
}
