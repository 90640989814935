import React, { useState, useEffect, useCallback } from 'react';

import { DateTime } from 'luxon';
import { StyleSheet } from 'react-native';

import { FlexContainer, Spacing, Text } from '@/components/web';
import { COLORS } from '@/utils';

type LiveCountdownProps = {
  countdownEndDate: Date;
  canClaim: boolean;
  onFinish: () => void;
  color?: string;
  textColor?: string;
};

export default function LiveCountdown({
  countdownEndDate,
  onFinish,
  color = COLORS.red,
}: LiveCountdownProps) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const updateCounter = useCallback(() => {
    const {
      days: daysDiff = 0,
      hours: hoursDiff = 0,
      minutes: minutesDiff = 0,
      seconds: secondsDiff = 0,
    } = DateTime.fromJSDate(countdownEndDate)
      .diffNow(['days', 'hours', 'minutes', 'seconds'])
      .toObject();

    setDays(daysDiff);
    setHours(hoursDiff);
    setMinutes(minutesDiff);
    setSeconds(Math.round(secondsDiff));

    return daysDiff < 0 || hoursDiff < 0 || minutesDiff < 0 || secondsDiff < 0;
  }, [countdownEndDate, setDays, setHours, setMinutes, setSeconds]);

  useEffect(() => {
    updateCounter();

    const interval = setInterval(() => {
      if (updateCounter()) {
        clearInterval(interval);
        onFinish();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const renderUnit = (name: string, value: number) => {
    return (
      <Spacing horizontal={0.25}>
        <FlexContainer alignItems="center">
          <Spacing top={0.5}>
            <Text
              bold
              override={{
                ...styles.unitName,
                ...{ color },
              }}
            >
              {value < 10 ? `0${value}` : value}
              {name}
            </Text>
          </Spacing>
        </FlexContainer>
      </Spacing>
    );
  };

  return (
    <FlexContainer row justifyContent="center">
      {renderUnit('d', Math.max(days, 0))}
      {renderUnit('h', Math.max(hours, 0))}
      {renderUnit('m', Math.max(minutes, 0))}
      {renderUnit('s', Math.max(seconds, 0))}
    </FlexContainer>
  );
}

const styles = StyleSheet.create({
  unitValueContainer: {
    width: 60,
    height: 60,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 5,
  },
  unitValue: {
    color: COLORS.white,
    fontSize: 26,
    lineHeight: undefined,
  },
  unitName: {
    fontSize: 14,
    lineHeight: 14,
  },
});
