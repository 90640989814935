import { gql } from '@apollo/client';

export const CREATE_CONVERSATION_POST = gql`
  mutation (
    $title: String!
    $causeId: String!
    $message: String
    $isAnonymous: Boolean
    $imageUrl: Upload
  ) {
    createConversationPost(
      input: {
        title: $title
        causeId: $causeId
        message: $message
        isAnonymous: $isAnonymous
        imageUrl: $imageUrl
      }
    ) {
      success
      error
    }
  }
`;
