import { COLORS } from './constants';

const profileHaloColorPicker = (
  level: number,
  percentage: number,
  isCurrentUser: boolean,
) => {
  let impactTextColor = COLORS.white;
  let haloColor = COLORS.impactLightPurple;
  let haloShadow = COLORS.white;
  let impactBackground = `${haloColor}20`;
  let haloPercentage = 0;

  if (level === 0) {
    impactTextColor = COLORS.impactLightPurple;
    haloColor = COLORS.impactLightPurple;
    impactBackground = `${haloColor}20`;
  } else if (level === 1) {
    impactTextColor = COLORS.impactPurple;
    haloColor = COLORS.impactPurple;
    haloShadow = COLORS.black;
    impactBackground = `${haloColor}20`;
  } else if (level === 2) {
    impactTextColor = COLORS.impactDarkPurple;
    haloColor = COLORS.impactDarkPurple;
    haloShadow = COLORS.black;
    impactBackground = `${haloColor}20`;
  } else if (level === 3) {
    impactTextColor = COLORS.impactSkyBlue;
    haloColor = COLORS.impactSkyBlue;
    haloShadow = COLORS.black;
    impactBackground = `${haloColor}20`;
  } else if (level === 4) {
    impactTextColor = COLORS.impactGreen;
    haloColor = COLORS.impactGreen;
    haloShadow = COLORS.black;
    impactBackground = `${haloColor}20`;
  } else if (level === 5) {
    impactTextColor = COLORS.impactYellow;
    haloColor = COLORS.impactYellow;
    haloShadow = COLORS.black;
    impactBackground = `${haloColor}20`;
  } else if (level === 6) {
    impactTextColor = COLORS.impactOrange;
    haloColor = COLORS.impactOrange;
    haloShadow = COLORS.black;
    impactBackground = `${haloColor}20`;
  } else if (level === 7) {
    impactTextColor = COLORS.impactRed;
    haloColor = COLORS.impactRed;
    haloShadow = COLORS.black;
    impactBackground = `${haloColor}20`;
  } else if (level === 8) {
    impactTextColor = COLORS.impactPink;
    haloColor = COLORS.impactPink;
    haloShadow = COLORS.black;
    impactBackground = `${haloColor}20`;
  } else if (level === 9) {
    impactTextColor = COLORS.impactBlack;
    haloColor = COLORS.impactBlack;
    haloShadow = COLORS.white;
    impactBackground = `${haloColor}20`;
  }

  if (isCurrentUser) {
    const numberCheck = Math.round((1 - percentage) * 100) / 100;
    if (numberCheck === 0) {
      haloPercentage = 0.001;
    } else if (numberCheck > 0.98 && numberCheck !== 1) {
      haloPercentage = 0.97;
    } else {
      haloPercentage = numberCheck;
    }
  } else {
    haloPercentage = 1;
  }

  return {
    haloPercentage,
    impactTextColor,
    impactBackground,
    haloColor,
    haloShadow,
    level,
  };
};

export default profileHaloColorPicker;
