import { gql } from '@apollo/client';

export const FETCH_CHARITY = gql`
  query fetchCharity($id: ID!) {
    charity(id: $id) {
      activeSince
      yearFounded
      city
      country
      description
      fullAddress
      id
      imageUrl
      heroImageUrl
      videoUrl
      name
      numCauses
      numEmployees
      numSupporters
      postalCode
      registeredName
      stateProvince
      streetAddress
      registeredNonProfit
      studentOrgs
      supportedCauses {
        charities {
          activeSince
          city
          country
          description
          fullAddress
          id
          imageUrl
          name
          numCauses
          numEmployees
          numSupporters
          postalCode
          registeredName
          stateProvince
          streetAddress
          website
          volunteerUrl
          instagramUsername
          twitterUsername
          facebookUsername
          youtubeUsername
          tiktokUsername
          linkedinUrl
        }
        coinsReceived
        description
        hashTags
        id
        imageUrl
        location
        name
        numCampaigns
        numCharities
        numSupporters
        tagline
        videoUrl
        website
        wikipediaUrl
      }
      website
      username
      location
      volunteerUrl
      instagramUsername
      twitterUsername
      facebookUsername
      youtubeUsername
      tiktokUsername
      linkedinUrl
      charityColor
      instagramData
      charityImages {
        id
        imageUrl
      }
    }
  }
`;
