import React from 'react';

import { View, Platform, Alert } from 'react-native';
import { NavigateFunction } from 'react-router-dom';
import { Reoverlay } from 'reoverlay';

import {
  Text,
  PrimaryButton,
  SlideUpModal,
  useSessionTokenContext,
} from '@/components/web';
import { Campaign, User } from '@/types';
import { getStoreTimesPlayed, maxWidth, trackEvent } from '@/utils';
import tw from '@tw';

type ThankYouProps = {
  user: User;
  navigate?: NavigateFunction;
  campaign?: Campaign;
  thankYouMessage?: string;
  referrerUserId?: string;
};

const ThankYouModal = ({
  navigate = () => false,
  campaign,
  referrerUserId,
}: ThankYouProps) => {
  const { userId } = useSessionTokenContext();
  const timesPlayed =
    Platform.OS === 'web' ? getStoreTimesPlayed(campaign?.slug || '') : 0;

  const calculateMaxHeight = () => {
    if (userId) {
      return 200;
    }
    if (timesPlayed < 3) {
      return 150;
    }
    return 100;
  };

  return (
    <SlideUpModal
      maxHeight={calculateMaxHeight()}
      removeButton
      preventClose
      noPurpleBackground
      showRandomAvatar={!!userId}
    >
      <View style={tw`w-${maxWidth(500)}px pt-2`}>
        {userId && (
          <Text small centered>
            Keep raising funds for{' '}
            <Text small bold>
              #{campaign?.cause?.name}
            </Text>
          </Text>
        )}
        <PrimaryButton
          style="mt-4 w-full self-center"
          title={userId ? 'Choose Another Charity' : 'Join Stashrun’s BETA ⭐️'}
          type={userId ? 'getApp' : ''}
          fullRadius
          onPress={() => {
            trackEvent('chooseOwnCauseClicked');
            Reoverlay.hideModal();
            if (!userId) {
              navigate(
                `/auth?campaignId=${campaign?.id}&imageUrl=${campaign?.imageUrl}`,
              );
            } else {
              navigate(userId ? '/home' : '/auth');
            }
          }}
        />

        {(userId || timesPlayed < 3) && (
          <PrimaryButton
            style="mt-8px w-full"
            title="Play Again"
            fullRadius
            type="playAgain"
            onPress={() => {
              trackEvent('playAgainClicked', {
                campaignId: campaign?.id,
                playedAgainCount: timesPlayed,
              });
              Reoverlay.hideModal();
              if (!campaign?.canSupport) {
                Alert.alert(
                  'Sorry!',
                  'There are no sponsors for this campaign yet!',
                  [
                    {
                      text: 'Ok',
                      onPress: () => {},
                    },
                  ],
                );
              } else {
                navigate(
                  `/coingame/${campaign.id}?referrerUserId=${referrerUserId}`,
                );
              }
            }}
          />
        )}
      </View>
    </SlideUpModal>
  );
};

export default ThankYouModal;
