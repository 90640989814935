import React from 'react';

import ImpactCarousel from '@/components/ImpactCarousel';
import Spacing from '@/components/Spacing';
import Text from '@/components/Text';
import { ImpactItem } from '@/types/index';

type EntityImpactProps = {
  name?: string;
  logoUrl?: string;
  entityType: string;
  entityId: string;
  showLogoUrl?: boolean;
  color?: string;
  brandModalExplainer?: boolean;
  brandDetails?: ImpactItem;
};

const EntityImpact = ({
  name,
  entityType,
  entityId,
  logoUrl,
  color,
  showLogoUrl = false,
  brandModalExplainer = false,
  brandDetails,
}: EntityImpactProps) => {
  if (brandModalExplainer) {
    return (
      <Spacing>
        {!!entityId && (
          <ImpactCarousel
            type={entityType}
            entityId={entityId}
            maxWidth={500}
            logoUrl={showLogoUrl ? logoUrl : undefined}
            color={color}
            brandDetails={brandDetails}
          />
        )}
      </Spacing>
    );
  }
  return (
    <Spacing top={1} bottom={1}>
      {!!name && (
        <Spacing horizontal={1} bottom={1}>
          <Text avenirNext mediumSmallPlus2 semibold lightBlack>
            Our Impact
          </Text>
        </Spacing>
      )}
      <Spacing top={1} bottom={1}>
        {!!entityId && (
          <ImpactCarousel
            type={entityType}
            entityId={entityId}
            maxWidth={500}
            logoUrl={showLogoUrl ? logoUrl : undefined}
            color={color}
          />
        )}
      </Spacing>
    </Spacing>
  );
};

export default EntityImpact;
