import React from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useWebHooks = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const {
    users,
    campaignLink,
    userEmail,
    secondaryReferrer,
    userForgotPasswordCode,
    ...params
  } = useParams();
  const { state: routeState, pathname } = useLocation();
  const {
    referrerUserId,
    isNot404,
    isPasscodeWaitlist,
    dontShowPasscode,
    user,
    thankYouMessageVideo,
    campaign,
  } = routeState || '';

  return {
    navigate,
    users,
    campaignLink,
    referrerUserId,
    secondaryReferrer,
    isNot404,
    isPasscodeWaitlist,
    query,
    params,
    pathname,
    userForgotPasswordCode,
    userEmail,
    dontShowPasscode,
    user,
    thankYouMessageVideo,
    campaign,
  };
};

export default useWebHooks;
