import React from 'react';

import { FontAwesome5 } from '@expo/vector-icons';
import { Image } from 'expo-image';
import { Linking, StyleSheet, TouchableOpacity, Platform } from 'react-native';

import facebookIcon from '@/assets/icons/fb_social_button.png';
import instagramIcon from '@/assets/icons/instagram_social_button.png';
import linkedinIcon from '@/assets/icons/linkedin_social_button.png';
import tiktokIcon from '@/assets/icons/tiktok_social_button.png';
import twitterIcon from '@/assets/icons/twitter_social_button.png';
import youtubeIcon from '@/assets/icons/youtube_social_button.png';
import FlexContainer from '@/components/FlexContainer';
import { trackEvent } from '@/utils';

type Props = {
  type:
    | 'facebook'
    | 'instagram'
    | 'twitter'
    | 'youtube'
    | 'tiktok'
    | 'linkedin';
  username?: string;
  url?: string;
  entity?: string;
  profileIcon?: boolean;
  noir?: boolean;
};

const SocialMediaButton: React.FC<Props> = ({
  type,
  username,
  url,
  profileIcon,
  entity,
  noir,
}) => {
  const socialMediaLink = () => {
    switch (type) {
      case 'facebook':
        return `https://www.facebook.com/${username}`;
      case 'instagram':
        return `https://www.instagram.com/${username}`;
      case 'twitter':
        return `https://www.twitter.com/${username}`;
      case 'youtube':
        return `https://www.youtube.com/@${username}`;
      case 'tiktok':
        return `https://www.tiktok.com/@${username}`;
      case 'linkedin':
        return url || '';
      default:
        return '';
    }
  };

  const socialMediaIcon = () => {
    if (noir) {
      switch (type) {
        case 'facebook':
          return <FontAwesome5 name="facebook-f" size={20} color="black" />;
        case 'instagram':
          return <FontAwesome5 name="instagram" size={20} color="black" />;
        case 'twitter':
          return <FontAwesome5 name="twitter" size={20} color="black" />;
        case 'youtube':
          return <FontAwesome5 name="youtube" size={20} color="black" />;
        case 'tiktok':
          return <FontAwesome5 name="tiktok" size={20} color="black" />;
        case 'linkedin':
          return <FontAwesome5 name="linkedin-in" size={20} color="black" />;
        default:
          return null;
      }
    } else {
      switch (type) {
        case 'facebook':
          return facebookIcon;
        case 'instagram':
          return instagramIcon;
        case 'twitter':
          return twitterIcon;
        case 'youtube':
          return youtubeIcon;
        case 'tiktok':
          return tiktokIcon;
        case 'linkedin':
          return linkedinIcon;
        default:
          return null;
      }
    }
  };

  const onPress = async (link: string) => {
    const url = link.indexOf('://') === -1 ? `https://${link}` : link;

    trackEvent('socialMediaButtonClicked', {
      link,
      type,
      entity,
    });

    return Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      }
    });
  };

  if (noir) {
    return (
      <TouchableOpacity
        onPress={() => onPress(socialMediaLink())}
        style={[styles.container, styles.containerBorder]}
      >
        {socialMediaIcon()}
      </TouchableOpacity>
    );
  }
  return (
    <TouchableOpacity
      onPress={() => onPress(socialMediaLink())}
      style={styles.container}
    >
      <FlexContainer row justifyContent="center">
        <Image
          style={{
            width: profileIcon ? 25 : 30,
            height: profileIcon ? 25 : 30,
          }}
          source={socialMediaIcon()}
        />
      </FlexContainer>
    </TouchableOpacity>
  );
};

export default SocialMediaButton;

const styles = StyleSheet.create({
  container: {
    width: 30,
    height: 30,
    borderRadius: 100,
    marginRight: Platform.OS === 'web' ? 4 : 0,
  },
  containerBorder: {
    borderWidth: 1,
    borderColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
