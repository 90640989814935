import { gql } from '@apollo/client';

export const FETCH_USERS_CAMPAIGNS = gql`
  query FetchUsersCampaigns($userId: ID) {
    campaignsSearch(userId: $userId) {
      id
      title
      location
      imageUrl
      currentAmount
      goalAmount
      endTime
      canSupport
      cause {
        id
        name
        description
        imageUrl
      }
      charity {
        id
        name
        imageUrl
        videoUrl
        heroImageUrl
        studentOrgs
        charityColor
      }
    }
  }
`;
