import React from 'react';

import { Linking, Pressable, View } from 'react-native';
import { Reoverlay } from 'reoverlay';

import { Text } from '@/components/web';
import { getPasscodeDone } from '@/utils/webStorage';
import tw from '@tw';

type Props = {
  title?: string;
  activity?: boolean;
};

const SuggestionBox = ({ title = 'Suggest a Cause', activity }: Props) => {
  const passcodeDone = getPasscodeDone();

  return (
    <View style={tw`px-8 pb-4`}>
      {passcodeDone || activity ? (
        <>
          <Text small centered override={tw`mt-2.5`}>
            Don’t see what you’re looking for? Tell us!
          </Text>
          <Pressable
            onPress={() => Linking.openURL(`https://www.stashrun.com/requests`)}
          >
            <Text small centered bold primary override={tw`mt-2.5`}>
              {title}
            </Text>
          </Pressable>
        </>
      ) : (
        <>
          <Text small centered override={tw`mt-2.5`}>
            Join our beta to support more causes.
          </Text>
          <Pressable onPress={() => Reoverlay.showModal('PasscodeModal')}>
            <Text underlined small centered bold primary override={tw`mt-2.5`}>
              Enter your invite code for early access
            </Text>
          </Pressable>
        </>
      )}
    </View>
  );
};

export { SuggestionBox };
