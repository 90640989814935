import React, { useEffect } from 'react';

import { View, Image, Platform } from 'react-native';
import { Reoverlay } from 'reoverlay';

import images from '@/assets/images';
import { PrimaryButton, Text, SlideUpModal } from '@/components/web';
import { trackEvent } from '@/utils';
import scroll from '@/utils/scroll';
import tw from '@tw';

type Props = {
  redirect: () => void;
};

const MatchDonationModal = ({ redirect }: Props) => {
  useEffect(() => {
    if (Platform.OS === 'web') scroll.lock();
    return () => {
      if (Platform.OS === 'web') scroll.enable();
    };
  }, []);

  const closeModal = () => {
    trackEvent('matchDonationContinued');
    Reoverlay.hideModal();
    redirect();
  };

  return (
    <SlideUpModal maxHeight={440} removeButton>
      <View style={tw`px-12 items-center w-full mt-6`}>
        <View style={tw`my-8 flex flex-col items-center`}>
          <Text mediumSmallPlus2 bold centered override={tw`mb-2`}>
            You’re so philanthropic!
          </Text>
          <Text mediumSmall centered>
            This feature is coming soon, stay tuned
          </Text>
          <PrimaryButton
            style="w-40 my-4"
            titleStyle={tw`w-full text-center`}
            title="Continue"
            onPress={() => closeModal()}
            fullRadius
          />
        </View>

        <Image
          source={images.loginIntro}
          style={tw`w-80 h-46 -bottom-7 self-center z-0`}
          resizeMode="contain"
        />
      </View>
    </SlideUpModal>
  );
};

export default MatchDonationModal;
