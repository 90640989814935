import React, { useEffect, useState } from 'react';

import { Video, ResizeMode } from 'expo-av';
import { Image } from 'expo-image';
import { LinearGradient } from 'expo-linear-gradient';
import { Pressable, View } from 'react-native';
import ProgressBarAnimated from 'react-native-progress-bar-animated';
import { CountUp } from 'use-count-up';

import images from '@/assets/images';
import { Text, PrimaryButton, LoadingIndicator } from '@/components/web';
import useWebHooks from '@/hooks/useWebHooks';
import { COLORS, maxWidth } from '@/utils';
import { deleteVideoPreview, getVideoPreview } from '@/utils/webStorage';
import tw from '@tw';

import { WebWrapper } from './components';
import RoundedBorderedImage from './components/RoundedBorderedImage';

const VideoPreview = () => {
  const {
    campaignCurrentAmount,
    user,
    campaignGoalAmount,
    campaignCanSupport,
    video,
    halo,
    profilePhoto,
  } = getVideoPreview();
  const { navigate } = useWebHooks();
  const remainingPercentage =
    (Number(campaignCurrentAmount || 0) / Number(campaignGoalAmount)) * 100;
  const countUpAmount = Number((campaignCurrentAmount / 100).toFixed(0));

  const [muted, setMute] = useState(true);
  const [videoLoad, setVideoLoad] = useState(true);

  useEffect(() => {
    if (!video) {
      navigate('/home');
    }
  }, []);

  const topBar = () => (
    <View style={tw`w-full absolute top-0 z-200`}>
      <View style={tw`h-10 w-full bg-black justify-center items-center`}>
        <Text inverse bold small>
          🚨 This is a Preview 🚨
        </Text>
      </View>
      <View style={tw`w-full pt-2 flex-row`}>
        <View style={tw`w-1/7`}>
          {video && (
            <RoundedBorderedImage
              color={halo || 'black'}
              type="outer"
              size={30}
              image={profilePhoto}
            />
          )}
        </View>
        <View style={tw`w-5/7`}>
          <Text
            inverse={video}
            centered
            bold
            mediumSmallPlus
            textShadow={video}
          >
            {user}
          </Text>
          <Text inverse={video} mediumSmall bold centered textShadow={video}>
            says thanks!
          </Text>
        </View>
        <View style={tw`w-1/7 items-center`}></View>
      </View>
      {video && (
        <Image
          style={tw`h-7 w-7 absolute top-24 left-3`}
          source={muted ? images.icons.muted : images.icons.notMuted}
        />
      )}
    </View>
  );

  const footer = () => (
    <View
      style={tw.style(
        `fixed max-w-${maxWidth(
          500,
        )}px w-full bg-transparent self-center items-center bottom-0 pt-3 pb-6 rounded-tl-[20px] rounded-tr-[20px] z-500`,
      )}
    >
      <View style={tw`justify-center mt-2`}>
        <View style={tw`px-10px pb-1 text-center`}>
          {campaignCanSupport || remainingPercentage < 100 ? (
            <Text mediumSmall inverse textShadow>
              <Text mediumSmall bold inverse>
                $
                <CountUp isCounting end={countUpAmount} duration={2} />{' '}
              </Text>
              raised of ${(campaignGoalAmount / 100).toLocaleString() || ''}{' '}
              goal
            </Text>
          ) : (
            <Text mediumSmall inverse textShadow>
              Completed!
            </Text>
          )}
        </View>
        <View style={tw`mx-auto`}>
          <ProgressBarAnimated
            backgroundColor={COLORS.progressBar}
            underlyingColor={COLORS.gray}
            barEasing="ease"
            barAnimationDuration={1000 + remainingPercentage * 10}
            width={maxWidth(280)}
            height={10}
            borderWidth={0}
            borderRadius={10}
            value={Math.min(100, remainingPercentage)}
          />
        </View>
      </View>
      <View
        style={tw.style(
          `border-b-2 border-zinc-300 w-6.5/10 my-1 border-transparent`,
        )}
      />

      <LinearGradient
        locations={[0.1, 1]}
        colors={['#5551FF00', COLORS.black]}
        style={tw`h-full w-full absolute bottom-0`}
      />

      <PrimaryButton
        style="w-full"
        title="Go Back"
        fullRadius
        customRender={
          <Image source={images.share} style={tw`w-6 h-6 ml-2.5`} />
        }
        onPress={() => {
          deleteVideoPreview();
          window.close();
        }}
      />

      <View style={tw`h-7 justify-center mt-2`}>
        <Text centered xsmallPlus2 italicized inverse>
          Go back to copy and share your Stashlink
        </Text>
      </View>
    </View>
  );

  return (
    <WebWrapper>
      <View style={tw`bg-white flex-1 overflow-hidden`}>
        <Video
          style={tw`flex-1 h-full w-full absolute z-90`}
          resizeMode={ResizeMode.COVER}
          source={{
            uri: video || '',
          }}
          shouldPlay
          isMuted={muted}
          isLooping
          videoStyle={tw`h-full w-full`}
          onLoad={() => setVideoLoad(false)}
        />

        {topBar()}
        <Pressable
          style={tw`w-full h-full z-300`}
          onPress={() => setMute((prev) => !prev)}
        />
        {footer()}

        {videoLoad && (
          <View
            style={tw`h-full w-full bg-white absolute justify-center items-center z-full`}
          >
            <LoadingIndicator />
          </View>
        )}
      </View>
    </WebWrapper>
  );
};

export default VideoPreview;
