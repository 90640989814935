import React from 'react';

import { View } from 'react-native';
import ProgressBarAnimated from 'react-native-progress-bar-animated';
import { CountUp } from 'use-count-up';

import { Text } from '@/components/web';
import { Campaign } from '@/types';
import { COLORS, maxWidth } from '@/utils';
import tw from '@tw';

type ProgressBarProps = {
  campaign?: Campaign;
  divider?: boolean;
  width?: number;
  borderRadius?: number;
  small?: boolean;
  transparent?: boolean;
};

const ProgressBar = ({
  campaign,
  divider,
  width = 500,
  borderRadius = 10,
  small,
  transparent,
}: ProgressBarProps) => {
  const { currentAmount, goalAmount } = campaign || {};

  const remainingPercentage =
    (Number(currentAmount || 0) / Number(goalAmount)) * 100;

  const countUpAmount = Number(
    (currentAmount ? currentAmount / 100 : 0).toFixed(0),
  );
  return (
    <View>
      {divider && (
        <View style={tw`border-b-1 border-gray w-9/10 my-2 self-center`} />
      )}

      {campaign?.canSupport || remainingPercentage < 100 ? (
        <Text
          mediumSmall={!small}
          xsmallPlus2={small}
          centered
          inverse={transparent}
          textShadow={transparent}
        >
          <Text
            mediumSmall={!small}
            xsmallPlus2={small}
            bold
            centered
            inverse={transparent}
            textShadow={transparent}
          >
            $<CountUp isCounting end={countUpAmount} duration={2} />{' '}
          </Text>
          raised of $
          {(goalAmount ? goalAmount / 100 : 0).toLocaleString() || ''} goal
        </Text>
      ) : (
        <Text
          mediumSmall={!small}
          xsmallPlus2={small}
          centered
          inverse={transparent}
          textShadow={transparent}
        >
          Completed!
        </Text>
      )}

      <View style={tw`mx-auto self-center items-center mt-1`}>
        <ProgressBarAnimated
          backgroundColor={COLORS.progressBar}
          underlyingColor={COLORS.gray}
          barEasing="ease"
          barAnimationDuration={1000 + remainingPercentage * 10}
          width={maxWidth(width)}
          height={10}
          borderWidth={0}
          borderRadius={borderRadius}
          value={Math.min(100, remainingPercentage)}
        />
      </View>
    </View>
  );
};

export default ProgressBar;
