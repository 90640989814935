import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMutation, ApolloError } from '@apollo/client';
import { Ionicons } from '@expo/vector-icons';
import { ImageEditor } from 'expo-image-editor';
import * as ImagePicker from 'expo-image-picker';
import { TextInput, View, Image } from 'react-native';
import { Divider } from 'react-native-elements';

import images from '@/assets/images';
import {
  Input,
  PrimaryButton,
  Text,
  useSessionTokenContext,
} from '@/components/web';
import { eventProps } from '@/constants/ANALYTICS_CONST';
import { SIGN_UP_WITH_EMAIL } from '@/queries';
import { MERGE_SKELETON_USER } from '@/queries/merge-skeleton-user';
import { MergeSkeletonUserData, SignUpWithEmailData } from '@/types';
import {
  addCloudinaryTransformation,
  analytics,
  getStoreUserIdentifier,
  trackEvent,
} from '@/utils';
import tw from '@tw';
import { WebWrapper } from '@web/components';

const AuthSignup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [instagramUsername, setInstagramUsername] = useState('');
  const [twitterUsername, setTwitterUsername] = useState('');
  const [tiktokUsername, setTiktokUsername] = useState('');
  const [youtubeUsername, setYoutubeUsername] = useState('');
  const [description, setDescription] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [step, setStep] = useState(1);

  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [fullNameIsValid, setFullNameIsValid] = useState(false);
  const [previewPassword, setPreviewPassword] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [fullNameErrorMessage, setFullNameErrorMessage] = useState('');
  const [birthdateErrorMessage, setBirthdateErrorMessage] = useState('');
  const { setSessionToken, setUserId } = useSessionTokenContext();
  const [imageUri, setImageUri] = useState<string | undefined>(undefined);
  const [editorVisible, setEditorVisible] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);

  const launchEditor = (uri: string) => {
    // Then set the image uri
    setImageUri(uri);
    // And set the image editor to be visible
    setEditorVisible(true);
  };

  const [mergeSkeletonUser] =
    useMutation<MergeSkeletonUserData>(MERGE_SKELETON_USER);
  const [
    signUpWithEmail,
    { loading: signUpWithEmailLoading, error: signUpWithEmailError },
  ] = useMutation<SignUpWithEmailData>(SIGN_UP_WITH_EMAIL, {
    onCompleted: async (data: SignUpWithEmailData) => {
      if (data.signUpWithEmail?.error) {
        alert(`Something went wrong! ${data.signUpWithEmail?.error}`);
        trackEvent('emailSignupError', {
          error: data.signUpWithEmail?.error,
        });
      } else if (
        data.signUpWithEmail?.success &&
        data.signUpWithEmail?.user?.id &&
        data.signUpWithEmail?.token
      ) {
        trackEvent('emailSignupSuccess');
        const userStoreIdentifier = getStoreUserIdentifier();
        analytics.createIdentity(
          userStoreIdentifier,
          data.signUpWithEmail?.user?.id,
        );
        await mergeSkeletonUser({
          variables: {
            userIdentifier: userStoreIdentifier,
            userId: data.signUpWithEmail?.user?.id,
          },
        });
        await setUserId(data.signUpWithEmail?.user?.id);
        await setSessionToken(data.signUpWithEmail?.token);
      }
    },
    onError: (error: ApolloError) => {
      trackEvent('emailSignupError', {
        error,
      });
      alert(error.message);
    },
  });

  const validateEmail = useCallback(
    (input: string) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValid = re.test(String(input).toLowerCase());
      setEmailIsValid(isValid);
      return isValid;
    },
    [setEmailIsValid],
  );

  const validateFullName = useCallback(
    (fullName: string) => {
      const isValid = fullName?.length >= 1;
      setFullNameIsValid(isValid);
      return isValid;
    },
    [setFullNameIsValid],
  );

  const validatePassword = useCallback(
    (input: string) => {
      const isValid = input?.length >= 8 && input?.length <= 32;
      setPasswordIsValid(isValid);
      return isValid;
    },
    [setPasswordIsValid],
  );

  const validateBirthdate = useCallback(
    (month: string, day: string, year: string) => {
      if (
        month.length < 1 ||
        day.length < 1 ||
        year.length < 4 ||
        Number(parseInt(year, 10)) < 1900 ||
        new Date(`${year}-${month}-${day}`).toString() === 'Invalid Date'
      ) {
        setBirthdateErrorMessage('Please enter a valid date');
        return false;
      }
      setBirthdateErrorMessage('');
      return true;
    },
    [setBirthdateErrorMessage],
  );

  const onChangePassword = useCallback(
    (password: string) => {
      setPassword(password);
      if (!validatePassword(password)) {
        setPasswordErrorMessage('Password needs to be within 8-72 characters');
      } else {
        setPasswordErrorMessage('');
      }
    },
    [setPassword, setPasswordErrorMessage],
  );

  const onChangeEmail = useCallback(
    (email: string) => {
      setEmail(email);
      if (!validateEmail(email)) {
        setEmailErrorMessage('Please enter a valid email');
      } else {
        setEmailErrorMessage('');
      }
    },
    [setEmail, setEmailErrorMessage],
  );

  const onChangeFullName = useCallback(
    (fullName: string) => {
      setFullName(fullName);
      if (!validateFullName(fullName)) {
        setFullNameErrorMessage('Please enter a valid full name');
      } else {
        setFullNameErrorMessage('');
      }
    },
    [setFullName, setFullNameErrorMessage],
  );

  const onChangeMonth = useCallback(
    (month: string) => {
      setMonth(!Number.isNaN(parseInt(month, 10)) ? month : '');
    },
    [setMonth],
  );

  const onChangeDay = useCallback(
    (day: string) => {
      setDay(!Number.isNaN(parseInt(day, 10)) ? day : '');
    },
    [setDay],
  );

  const onChangeYear = useCallback(
    (year: string) => {
      setYear(parseInt(year, 10) ? String(parseInt(year, 10)) : '');
    },
    [setYear],
  );

  function dataURLtoFile(dataurl: string | null, filename: string) {
    if (!dataurl) return null;
    const arr = dataurl.split(',');
    const mime = arr[0]?.match(/:(.*?);/)?.[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleLogin = async () => {
    if (
      passwordIsValid &&
      fullNameIsValid &&
      emailIsValid &&
      profileImageUrl?.length > 0
    ) {
      const image = dataURLtoFile(profileImageUrl, 'avatar.jpeg');
      await signUpWithEmail({
        variables: {
          input: {
            email,
            fullName,
            password,
            birthdate: `${year}-${month}-${day}`,
            profileImageUrl: image,
            ...(!!youtubeUsername?.length && { youtubeUsername }),
            ...(!!twitterUsername?.length && { twitterUsername }),
            ...(!!tiktokUsername?.length && { tiktokUsername }),
            ...(!!instagramUsername?.length && { instagramUsername }),
            description,
          },
        },
      });
    } else {
      alert('Please go back and fill out all required fields.');
      onChangeEmail(email);
      onChangeFullName(fullName);
      onChangePassword(password);
    }
  };

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      allowsMultipleSelection: false,
      aspect: [4, 4],
      quality: 0.85,
    });

    if (!result.canceled) {
      if (imageUri) {
        setImageUri(undefined);
        setTimeout(() => {
          launchEditor(result.assets[0].uri);
        });
      } else {
        launchEditor(result.assets[0].uri);
      }
    }
  };

  let title;
  let subtitle;
  switch (step) {
    case 1:
      title = 'Sign up with email';
      subtitle = 'Enter your email';
      break;
    case 2:
      title = 'Create password';
      subtitle = 'Must be between 8-32 characters';
      break;
    case 3:
      title = 'When’s your birthday?';
      subtitle = 'Step 1 / 5';
      break;
    case 4:
      title = 'Add your name';
      subtitle = 'Step 2 / 5';
      break;
    case 5:
      title = 'Add your social handles';
      subtitle = 'Step 3 / 5';
      break;
    case 6:
      title = 'Add profile photo';
      subtitle = 'Step 4 / 5';
      break;
    case 7:
      title = 'Make sure everything looks right';
      subtitle = 'Step 5 / 5';
      break;
    default:
      title = 'Create an account';
      subtitle = '';
  }

  const isStepValid = useMemo(() => {
    let valid = false;
    switch (step) {
      case 1:
        valid = emailIsValid;
        break;
      case 2:
        valid = passwordIsValid;
        break;
      case 3:
        valid =
          new Date(`${year}-${month}-${day}`).toString() !== 'Invalid Date';
        break;
      case 4:
        valid = fullNameIsValid;
        break;
      case 5:
        valid = true;
        break;
      case 6:
        valid = true;
        break;
      case 7:
        valid = true;
        break;
      default:
        break;
    }
    return valid;
  }, [
    step,
    emailIsValid,
    passwordIsValid,
    fullNameIsValid,
    profileImageUrl,
    month,
    day,
    year,
  ]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <View style={tw`px-6`}>
            <Input
              style={tw`mb-2 w-full`}
              inputStyle={tw`border rounded px-2 mt-1`}
              autoCorrect={false}
              label="Email address"
              placeholder="bob@icloud.com"
              labelSize="mediumSmall"
              autocapitalize="none"
              keyboardType="email-address"
              value={email}
              onChange={onChangeEmail}
              errorMessage={emailErrorMessage}
            />
          </View>
        );
      case 2:
        return (
          <>
            <View style={tw`px-6 w-full flex flex-row relative`}>
              <Input
                style={tw`flex-grow`}
                inputStyle={tw`border rounded px-2 mt-1`}
                secureTextEntry={!previewPassword}
                autoCorrect={false}
                label="Create a password"
                labelSize="mediumSmall"
                autocapitalize="none"
                errorMessage={passwordErrorMessage}
                value={password}
                onChange={onChangePassword}
              />
              <div style={tw`relative top-8 ml-4`}>
                <Ionicons
                  style={tw`cursor-pointer`}
                  name={previewPassword ? 'eye' : 'eye-off'}
                  size={24}
                  color="black"
                  onClick={() => setPreviewPassword(!previewPassword)}
                />
              </div>
            </View>
          </>
        );
      case 3:
        return (
          <View style={tw`px-6`}>
            <div>
              <Text small>Birthday</Text>
            </div>
            <div style={tw`w-full flex flex-row relative`}>
              <TextInput
                style={tw`border rounded px-2 mt-1 w-[60px] h-[48px] mr-[10px]`}
                autoCapitalize="none"
                keyboardType="number-pad"
                placeholder="MM"
                value={month}
                maxLength={2}
                onChangeText={onChangeMonth}
                onBlur={() => {
                  const paddedMonth = month.padStart(2, '0');
                  setMonth(paddedMonth);
                }}
              />
              <TextInput
                style={tw`border rounded px-2 mt-1 w-[60px] h-[48px] mr-[10px]`}
                autoCapitalize="none"
                keyboardType="number-pad"
                placeholder="DD"
                value={day}
                maxLength={2}
                onChangeText={onChangeDay}
                onBlur={() => {
                  const paddedDay = day.padStart(2, '0');
                  setDay(paddedDay);
                }}
              />
              <TextInput
                style={tw`border rounded px-2 mt-1 w-[90px] h-[48px]`}
                autoCapitalize="none"
                keyboardType="number-pad"
                placeholder="YYYY"
                value={year}
                maxLength={4}
                onChangeText={onChangeYear}
                onBlur={() => {
                  if (parseInt(year, 10) > new Date().getFullYear()) {
                    setYear(new Date().getFullYear().toString());
                    return;
                  }
                  setYear(year);
                }}
              />
            </div>
            <div style={tw`text-red mt-2`}>{birthdateErrorMessage}</div>
            <div style={tw`mt-4`}>
              <Text xsmallPlus2 italicizedNotBold>
                Your birthday won’t be shown publicly
              </Text>
            </div>
          </View>
        );
      case 4:
        return (
          <View style={tw`px-6`}>
            <Input
              style={tw`mb-2 w-full`}
              inputStyle={tw`border rounded px-2 mt-1`}
              autoCorrect={false}
              label="Full name"
              placeholder="Bob Stasherson"
              labelSize="mediumSmall"
              autocapitalize="words"
              value={fullName}
              onChange={onChangeFullName}
              errorMessage={fullNameErrorMessage}
            />
          </View>
        );
      case 5:
        return (
          <View style={tw`px-6`}>
            <Input
              style={tw`mb-4 w-full`}
              inputStyle={tw`border rounded px-2 mt-1`}
              autoCorrect={false}
              label="Instagram (optional)"
              labelSize="mediumSmall"
              autocapitalize="none"
              keyboardType="default"
              onFocusInput={() => setHideFooter(true)}
              onBlurInput={() => setHideFooter(false)}
              value={instagramUsername}
              onChange={(text) => setInstagramUsername(text.replace('@', ''))}
            />
            <Input
              style={tw`mb-4 w-full`}
              inputStyle={tw`border rounded px-2 mt-1`}
              autoCorrect={false}
              label="Twitter (optional)"
              labelSize="mediumSmall"
              autocapitalize="none"
              keyboardType="default"
              onFocusInput={() => setHideFooter(true)}
              onBlurInput={() => setHideFooter(false)}
              value={twitterUsername}
              onChange={(text) => setTwitterUsername(text.replace('@', ''))}
            />
            <Input
              style={tw`mb-4 w-full`}
              inputStyle={tw`border rounded px-2 mt-1`}
              autoCorrect={false}
              label="TikTok (optional)"
              labelSize="mediumSmall"
              autocapitalize="none"
              keyboardType="default"
              onFocusInput={() => setHideFooter(true)}
              onBlurInput={() => setHideFooter(false)}
              value={tiktokUsername}
              onChange={(text) => setTiktokUsername(text.replace('@', ''))}
            />
            <Input
              style={tw`mb-4 w-full`}
              inputStyle={tw`border rounded px-2 mt-1`}
              autoCorrect={false}
              label="YouTube (optional)"
              labelSize="mediumSmall"
              autocapitalize="none"
              onFocusInput={() => setHideFooter(true)}
              onBlurInput={() => setHideFooter(false)}
              keyboardType="default"
              value={youtubeUsername}
              onChange={(text) => setYoutubeUsername(text.replace('@', ''))}
            />
          </View>
        );
      case 6:
        return (
          <View style={tw`px-6`}>
            <View
              style={tw`w-full flex flex-row justify-center relative mt-[120px]`}
            >
              <Image
                source={profileImageUrl || images.profilePhotoUpload}
                style={tw`w-[200px] h-[200px]`}
              />
            </View>
            <ImageEditor
              visible={editorVisible}
              onCloseEditor={() => setEditorVisible(false)}
              imageUri={imageUri}
              fixedCropAspectRatio={1}
              lockAspectRatio
              minimumCropDimensions={{
                width: 100,
                height: 100,
              }}
              onEditingComplete={(result) => {
                setProfileImageUrl(result.uri);
                setStep(step + 1);
              }}
              mode="full"
            />
            {profileImageUrl?.length ? (
              <View style={tw`flex flex-row justify-center mt-4`}>
                <PrimaryButton
                  style="w-full"
                  containerStyle={tw`rounded-none bg-purple`}
                  title="Remove Photo"
                  onPress={() => {
                    setProfileImageUrl('');
                    setImageUri(undefined);
                  }}
                />
              </View>
            ) : null}
          </View>
        );
      case 7:
        return (
          <>
            <View
              style={tw.style(
                `h-30 w-30 rounded-full border-4 items-center justify-center self-center top-5 bg-white`,
                { borderColor: '#C1C0EB' },
              )}
            >
              <Image
                style={tw.style(`h-28 w-28 rounded-full`)}
                source={{
                  uri: addCloudinaryTransformation(
                    profileImageUrl || images.defaultProfilePic,
                    'c_scale,w_100,dpr_2',
                  ),
                }}
                defaultSource={images.defaultProfilePic}
              />
            </View>
            <View style={tw`mt-8 mb-4`}>
              <Text mediumSmallPlus bold centered>
                {fullName || 'Test'}
              </Text>
            </View>
            <View
              style={tw`mb-4 flex flex-row w-full justify-center bg-[#C1C0EB] py-[6px]`}
            >
              <Text small italicized inverse>
                <Text small bold inverse>
                  100
                </Text>{' '}
                Impact
              </Text>
            </View>
            <View style={tw`px-6 w-full flex flex-row justify-center`}>
              <textarea
                style={tw`w-full h-[48px] border rounded px-2 pt-2 mt-1`}
                placeholder="Start typing here to add a short bio"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onFocus={() => setHideFooter(true)}
                onBlur={() => setHideFooter(false)}
              />
            </View>
            <View
              style={tw`flex flex-row w-full justify-center items-center mt-6`}
            >
              {[
                instagramUsername,
                twitterUsername,
                tiktokUsername,
                youtubeUsername,
              ]
                .filter((username) => username.length > 0)
                .map((u, i) => {
                  let logo;
                  switch (i) {
                    case 0:
                      logo = images.logoInstagram;
                      break;
                    case 1:
                      logo = images.logoTwitter;
                      break;
                    case 2:
                      logo = images.logoTiktok;
                      break;
                    case 3:
                      logo = images.logoYoutube;
                      break;
                    default:
                      logo = null;
                  }
                  return (
                    <View
                      key={`${u}-${i}`}
                      style={tw`h-[30px] w-[30px] rounded-full mx-1`}
                    >
                      <Image
                        source={logo}
                        style={tw`h-[30px] w-[30px] rounded-full`}
                      />
                    </View>
                  );
                })}
            </View>
          </>
        );
      default:
        return <></>;
    }
  };

  const handleNext = () => {
    if (step === 3 && !validateBirthdate(month, day, year)) {
      return;
    }
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    if (signUpWithEmailError) {
      alert(signUpWithEmailError.message);
    }
  }, [signUpWithEmailError]);

  return (
    <WebWrapper withBack analytics={{ [eventProps.page]: 'Login' }}>
      <View style={tw`my-6`}>
        <Text centered mediumSmallPlus bold>
          {title}
        </Text>
        <Text centered small>
          {subtitle}
        </Text>
      </View>
      <View style={tw`px-16 mb-6`}>
        <Divider />
      </View>
      <View style={tw``}>{renderStep()}</View>
      {!hideFooter ? (
        <View
          style={tw.style(
            `fixed w-full bg-white bottomSheetShadow self-center items-center bottom-0 pt-4 pb-6 rounded-tl-[20px] rounded-tr-[20px]`,
          )}
        >
          {step < 7 ? (
            <>
              <PrimaryButton
                style="w-full"
                containerStyle={tw`rounded-none bg-purple mb-2`}
                title={
                  step === 6 && !profileImageUrl?.length ? 'Add Photo' : 'Next'
                }
                onPress={
                  step === 6 && !profileImageUrl?.length
                    ? pickImage
                    : handleNext
                }
                disabled={!isStepValid}
              />
              {step > 1 && (
                <PrimaryButton
                  style="w-full"
                  containerStyle={tw`rounded-none bg-white text-black`}
                  titleStyle={tw`text-black`}
                  title="Go Back"
                  onPress={handleBack}
                />
              )}
            </>
          ) : (
            <>
              <PrimaryButton
                style="w-full"
                title="Continue"
                containerStyle={tw`rounded-none bg-purple`}
                loading={signUpWithEmailLoading}
                onPress={handleLogin}
              />
              <Text xsmall centered override={tw`opacity-50 mt-1.5 px-2`}>
                By pressing ‘Continue’ you agree to our TOS and Privacy Policy.
              </Text>
              <PrimaryButton
                style="w-full"
                containerStyle={tw`rounded-none bg-white text-black`}
                titleStyle={tw`text-black`}
                title="Go Back"
                onPress={handleBack}
              />
            </>
          )}
        </View>
      ) : null}
    </WebWrapper>
  );
};

export default AuthSignup;
