import React from 'react';

import { StyleSheet, View } from 'react-native';
import QRCode from 'react-native-qrcode-svg';

import images from '@/assets/images';
import { Text } from '@/components/web';
import { Campaign } from '@/types';
import { COLORS } from '@/utils';
import tw from '@tw';
import { WebWrapper } from '@web/components';

import RoundedBorderedImage from './RoundedBorderedImage';

type BrandMatchProps = {
  campaign?: Campaign;
  userId?: string;
};

const CampaignWall = ({ campaign, userId }: BrandMatchProps) => {
  const campaignURL = userId?.length
    ? `
      ${window.location.origin}/users/${userId}/${campaign?.slug}`
    : `${window.location.origin}/campaign/${campaign?.slug}`;

  return (
    <WebWrapper analytics={{ page: 'Campaign wall' }} style={styles.container}>
      <View style={tw`items-center`}>
        <RoundedBorderedImage
          color={COLORS.purple}
          type="outer"
          size={75}
          image={images.stashrun}
          className={`self-center py-5`}
          noShadow
        />
        <Text bold centered>
          Scan to continue
        </Text>
        <View style={tw`pt-2`}>
          <Text centered mediumSmall>
            Supporting causes on Stashrun only works
          </Text>
          <Text centered mediumSmall>
            on mobile for now. Scan this QR to continue:
          </Text>
        </View>

        <View
          style={tw.style(`m-6 pt-4`, { transform: [{ rotate: '45deg' }] })}
        >
          <QRCode value={campaignURL} size={160} quietZone={10} />
        </View>
      </View>
    </WebWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerText: {
    paddingVertical: 12,
    paddingHorizontal: 22,
    backgroundColor: 'rgba(255,255,255,0.8)',
    position: 'relative',
    zIndex: 100,
  },
  bgImage: {
    height: '100%',
    width: '100%',
    bottom: 0,
    position: 'absolute',
    zIndex: 1,
  },
  contentWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 32,
    paddingTop: 8,
    position: 'relative',
    zIndex: 10,
  },
  qrContainer: {
    position: 'relative',
    width: '100%',
    height: '65%',
    marginTop: 30,
  },
  qrCode: {
    width: 170,
    height: 170,
    position: 'absolute',
    top: -30,
    right: 0,
    zIndex: 2,
    borderRadius: 10,
    overflow: 'hidden',
  },
  qrImage: {
    width: '100%',
    height: '100%',
    marginLeft: -20,
  },
  listItemWrapper: {
    marginTop: 12,
    marginHorizontal: 10,
  },
  listItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 16,
  },
  listItemBullet: {
    width: 6,
    height: 6,
    borderRadius: 10,
    backgroundColor: COLORS.black,
    marginRight: 8,
    marginTop: 8,
  },
  formWrapper: {
    marginTop: 28,
    width: '100%',
  },
  input: {
    borderRadius: 10,
    height: 50,
    backgroundColor: COLORS.white,
    borderWidth: 2,
    borderColor: COLORS.purple,
    paddingHorizontal: 12,
    marginBottom: 15,
    fontFamily: 'AvenirNext-Regular',
    fontSize: 16,
  },
  overlay: {
    opacity: 0.9,
    zIndex: 2,
    backgroundColor: COLORS.purple,
  },
});

export default CampaignWall;
