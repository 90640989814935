import React from 'react';

import { View, TouchableOpacity } from 'react-native';
import { SvgXml } from 'react-native-svg';

import infoIcon from '@/assets/icons/info_icon';
import FlexContainer, { FlexItem } from '@/components/FlexContainer';
import Spacing from '@/components/Spacing';
import Text from '@/components/Text';
import { ImpactItem } from '@/types';
import { numberWithCommas } from '@/utils/format';

import { styles } from './styles';

type UserImpactCardProps = {
  item: ImpactItem;
  coinsPerDrop?: number;
  dollarsEarned?: number;
  invitesSent?: number;
  impactScore?: number;
  haloProperties: {
    haloPercentage: number;
    impactTextColor: string;
    impactBackground: string;
    haloColor: string;
    haloShadow: string;
  };
  setLeaderboardExplainerModalOpen: (...args: any[]) => any;
};

const UserImpactCard = ({
  item,
  coinsPerDrop,
  dollarsEarned,
  invitesSent,
  impactScore,
  haloProperties,
  setLeaderboardExplainerModalOpen,
}: UserImpactCardProps) => {
  const fontSize = 19;
  if (item.type !== 'User') return null;

  return (
    <View style={styles.impactContainer}>
      <Spacing>
        <View
          style={{
            ...styles.impact,
            backgroundColor: haloProperties.impactBackground,
            borderColor: haloProperties.haloColor,
          }}
        >
          <Text
            medium
            inverse
            override={{
              fontSize: 14,
              color: haloProperties.impactTextColor,
              paddingBottom: 10,
            }}
          >
            Total Impact Score{'\n'}
          </Text>
          <TouchableOpacity
            style={styles.infoIconWhite}
            onPress={() => {
              setLeaderboardExplainerModalOpen(true);
            }}
          >
            <SvgXml
              width={20}
              height={20}
              fill={haloProperties.impactTextColor}
              xml={infoIcon}
            />
          </TouchableOpacity>
          <Spacing top={-5} bottom={-0.5}>
            <FlexContainer justifyContent="center">
              <Text
                large
                inverse
                bold
                centered
                override={{
                  fontSize: 35,
                  color: haloProperties.impactTextColor,
                }}
              >
                {numberWithCommas(Math.round(Number(impactScore || 0)))} ⭐
              </Text>
            </FlexContainer>
          </Spacing>
          <View
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <FlexContainer row justifyContent="space-between">
              <FlexItem justifyContent="center" alignItems="center">
                <Text
                  medium
                  inverse
                  bold
                  override={{ fontSize, color: haloProperties.impactTextColor }}
                >
                  {coinsPerDrop?.toFixed(1) || 0}
                </Text>
                <Text
                  small
                  inverse
                  override={{ color: haloProperties.impactTextColor }}
                >
                  Coins/drop
                </Text>
              </FlexItem>
              <FlexItem justifyContent="center" alignItems="center">
                <Text
                  medium
                  inverse
                  bold
                  override={{ fontSize, color: haloProperties.impactTextColor }}
                >
                  {invitesSent || 0}
                </Text>
                <Text
                  small
                  inverse
                  override={{ color: haloProperties.impactTextColor }}
                >
                  Influence
                </Text>
              </FlexItem>
              <FlexItem justifyContent="center" alignItems="center">
                <Text
                  medium
                  inverse
                  bold
                  override={{ fontSize, color: haloProperties.impactTextColor }}
                >
                  ${Math.round(dollarsEarned || 0)}
                </Text>
                <Text
                  small
                  inverse
                  override={{ color: haloProperties.impactTextColor }}
                >
                  Donated
                </Text>
              </FlexItem>
            </FlexContainer>
          </View>
          {/* <Spacing top={4}>
            <View style={{ position: 'absolute', right: 0, bottom: 0 }}>
              <Text italicized small inverse>
                {`Swipe to see more 👉 `}
              </Text>
            </View>
          </Spacing> */}
        </View>
      </Spacing>
    </View>
  );
};

export default UserImpactCard;
