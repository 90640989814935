import { gql } from '@apollo/client';

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $email: String!
    $forgotPasswordCode: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    resetPassword(
      input: {
        email: $email
        forgotPasswordCode: $forgotPasswordCode
        password: $password
        passwordConfirmation: $passwordConfirmation
      }
    ) {
      success
      error
    }
  }
`;
