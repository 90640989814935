/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef } from 'react';

import { useQuery } from '@apollo/client';
import { BlurView } from 'expo-blur';
import { StyleSheet, View, Animated, Pressable } from 'react-native';
import { NavigateFunction } from 'react-router-dom';
import { Reoverlay } from 'reoverlay';

import { PrimaryButton, Text } from '@/components/web';
import { FETCH_HEAD_TO_HEAD } from '@/queries/fetch-head-to-head';
import { HeadToHeadBattle } from '@/types';
import { scroll, COLORS, maxWidth, trackEvent } from '@/utils';
import tw from '@tw';
import RoundedBorderedImage from '@web/components/RoundedBorderedImage';

type Props = {
  navigate?: NavigateFunction;
  slideDown?: boolean;
};

const HeadToHeadBattleModal = ({
  slideDown,
  navigate = () => false,
}: Props) => {
  const slide = useRef(new Animated.Value(0)).current;

  const { data } = useQuery<{
    currentHeadToHeadBattle: HeadToHeadBattle;
    nextHeadToHeadBattle: HeadToHeadBattle;
  }>(FETCH_HEAD_TO_HEAD, {
    fetchPolicy: 'cache-first',
  });

  const closeModal = () => {
    Animated.timing(slide, {
      toValue: 0,
      duration: 200,
      useNativeDriver: false,
    }).start(() => {
      Reoverlay.hideModal();
    });
  };

  useEffect(() => {
    scroll.lock();
    return () => {
      scroll.enable();
    };
  }, []);

  useEffect(() => {
    Animated.timing(slide, {
      toValue: 1,
      duration: 200,
      useNativeDriver: false,
    }).start();
    if (slideDown) {
      closeModal();
    }
  }, [slideDown]);

  const stashlink1 = data?.currentHeadToHeadBattle?.player1Stashlink;
  const stashlink2 = data?.currentHeadToHeadBattle?.player2Stashlink;

  return (
    <View
      style={tw.style(
        'left-0 w-full items-center flex z-999',
        `h-[150px] bottom-0 fixed`,
      )}
    >
      <BlurView intensity={10} style={tw.style(`w-full h-full`)}>
        <Pressable
          onPress={() => {}}
          style={tw.style(`w-full h-full absolute z-2 bg-transparent`)}
        />
      </BlurView>

      <Animated.View
        style={[
          styles.contentWrapper,
          {
            transform: [
              {
                translateY: slide.interpolate({
                  inputRange: [0, 1],
                  outputRange: [600, 0],
                }),
              },
            ],
            maxHeight: 150,
            overflow: 'visible',
          },
        ]}
      >
        <View style={tw`self-center w-[90%]`}>
          <View style={tw`w-full z-200 absolute -top-10`}>
            <View style={tw`w-full flex flex-row justify-between`}>
              <View style={tw`w-[45%] flex flex-col items-center relative`}>
                <RoundedBorderedImage
                  image={stashlink1?.user?.profilePhoto}
                  size={75}
                  type="inner"
                  color="white"
                />
                <Text override={{ fontSize: 13 }}>
                  {stashlink1?.user?.fullName || ''}
                </Text>

                <PrimaryButton
                  style="mt-1"
                  containerStyle={tw`px-12`}
                  title="Play"
                  fullRadius
                  size="small"
                  onPress={() => {
                    trackEvent('headToHeadBattleStashlinkClicked', {
                      campaignId: stashlink1?.campaign?.slug,
                    });
                    closeModal();
                    navigate(
                      `/users/${stashlink1?.user?.id}/${stashlink1?.campaign?.slug}`,
                    );
                  }}
                />
              </View>
              <View style={tw`w-[45%] flex flex-col items-center`}>
                <RoundedBorderedImage
                  image={stashlink2?.user?.profilePhoto}
                  size={75}
                  type="inner"
                  color="white"
                />

                <Text override={{ fontSize: 13 }}>
                  {stashlink2?.user?.fullName || ''}
                </Text>

                <PrimaryButton
                  style="mt-1"
                  containerStyle={tw`px-12`}
                  title="Play"
                  size="small"
                  fullRadius
                  onPress={() => {
                    trackEvent('headToHeadBattleStashlinkClicked', {
                      campaignId: stashlink2?.campaign?.slug,
                    });
                    closeModal();
                    navigate(
                      `/users/${stashlink2?.user?.id}/${stashlink2?.campaign?.slug}`,
                    );
                  }}
                />
              </View>
            </View>
            <View style={tw`mt-2`}>
              <Text centered xsmallPlus>
                Tap to Stash for {stashlink1?.user?.fullName || ''} or{' '}
                {stashlink2?.user?.fullName || ''}
              </Text>
            </View>
          </View>
        </View>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  contentWrapper: {
    height: '100%',
    width: maxWidth(500),
    backgroundColor: COLORS.white,
    borderTopRightRadius: 35,
    borderTopLeftRadius: 35,
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    zIndex: 10,
    shadowColor: 'black',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 16,
    shadowOpacity: 0.6,
  },
});

export default HeadToHeadBattleModal;
