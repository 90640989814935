import { gql } from '@apollo/client';

export const CREATE_FLAG = gql`
  mutation CreateFlag(
    $resourceType: String!
    $resourceId: String!
    $flagType: String!
    $notes: String
  ) {
    createFlag(
      input: {
        resourceType: $resourceType
        resourceId: $resourceId
        flagType: $flagType
        notes: $notes
      }
    ) {
      success
      error
    }
  }
`;
