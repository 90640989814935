import { gql } from '@apollo/client';

export const DELETE_VOTE = gql`
  mutation DeleteVote(
    $resourceType: String!
    $resourceId: String!
    $voteType: String!
  ) {
    deleteVote(
      input: {
        resourceType: $resourceType
        resourceId: $resourceId
        voteType: $voteType
      }
    ) {
      success
      error
    }
  }
`;
