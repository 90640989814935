import { gql } from '@apollo/client';

export const AUTHENTICATE_WITH_APPLE = gql`
  mutation AuthenticateWithApple(
    $idToken: String!
    $subjectId: String!
    $nonce: String!
    $authorizationCode: String!
    $email: String
    $fullName: String
    $coinDropId: Int
    $coinsCollected: Int
    $silverCoinsCollected: Int
    $goldCoinsCollected: Int
    $redCoinsCollected: Int
    $purpleCoinsCollected: Int
    $referralCode: String
  ) {
    authenticateWithApple(
      input: {
        idToken: $idToken
        subjectId: $subjectId
        nonce: $nonce
        authorizationCode: $authorizationCode
        email: $email
        fullName: $fullName
        coinDropId: $coinDropId
        coinsCollected: $coinsCollected
        silverCoinsCollected: $silverCoinsCollected
        goldCoinsCollected: $goldCoinsCollected
        redCoinsCollected: $redCoinsCollected
        purpleCoinsCollected: $purpleCoinsCollected
        referralCode: $referralCode
      }
    ) {
      success
      error
      token
      user {
        id
        email
        fbName
      }
      newUser
      coinDrop {
        id
        collectedValueUsd
      }
    }
  }
`;
