import { gql } from '@apollo/client';

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      success
      error
      user {
        email
        id
        username
        fullName
        website
        description
        instagramUsername
        twitterUsername
        facebookUsername
        youtubeUsername
        tiktokUsername
        linkedinUrl
      }
    }
  }
`;
