import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { Image, TouchableOpacity } from 'react-native';

import backArrow from '@/assets/backArrow.png';
import { useSessionTokenContext } from '@/components/SessionTokenProvider';
import Spacing from '@/components/Spacing';

type BackButtonProps = {
  onPress?: () => void;
  sourceImage?: string;
  returnTo?: string;
};

export default function BackButton({
  onPress,
  sourceImage,
  returnTo = 'Home',
}: BackButtonProps) {
  const navigation = useNavigation();
  const { sessionToken } = useSessionTokenContext();

  return (
    <TouchableOpacity
      onPress={() => {
        if (onPress) {
          onPress();
          return;
        }

        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.reset({
            index: 0,
            routes: [{ name: sessionToken ? returnTo : 'Welcome' }],
          });
        }
      }}
    >
      <Spacing top={2} bottom={1}>
        <Image
          style={{ width: 33, height: 16, padding: 5 }}
          source={sourceImage ?? backArrow}
        />
      </Spacing>
    </TouchableOpacity>
  );
}
