import React, { useCallback, useState } from 'react';

import { useMutation, ApolloError } from '@apollo/client';
import { View, Image } from 'react-native';

import images from '@/assets/images';
import { Input, PrimaryButton, Text } from '@/components/web';
import { eventProps } from '@/constants/ANALYTICS_CONST';
import { INITIATE_FORGOT_PASSWORD } from '@/queries';
import { InitiateForgotPasswordData } from '@/types';
import { trackEvent } from '@/utils';
import tw from '@tw';
import { WebWrapper } from '@web/components';

const ForgotPasswordPage = () => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [initiateForgotPassword, { loading: initiateForgotPasswordLoading }] =
    useMutation<InitiateForgotPasswordData>(INITIATE_FORGOT_PASSWORD, {
      variables: { email: emailOrUsername, web: true },
      onCompleted: (data: InitiateForgotPasswordData) => {
        if (data.initiateForgotPassword?.error) {
          setErrorMessage(data.initiateForgotPassword?.error);
          trackEvent('forgotPasswordError', {
            error: data.initiateForgotPassword?.error,
          });
          alert(data.initiateForgotPassword?.error);
        } else if (data.initiateForgotPassword?.success) {
          trackEvent('forgotPasswordInitiated');
          alert('You will receive an email shortly with further instructions');
        }
      },
      onError: (error: ApolloError) => {
        trackEvent('forgotPasswordError', {
          error,
        });
        setErrorMessage(error.message);
      },
    });

  const onChangeEmailOrUsername = useCallback(
    (email: string) => {
      setEmailOrUsername(email);
    },
    [setEmailOrUsername],
  );

  return (
    <WebWrapper
      withBack
      analytics={{ [eventProps.page]: 'Forgot Password Web' }}
    >
      <View style={tw`flex flex-1 flex-col items-center p-6 pb-32`}>
        <Text bold mediumSmallPlus2 centered>
          Forgot Password
        </Text>
        <View
          style={tw`w-28 h-28 rounded-full border-4 border-primary p-1 mt-5`}
        >
          <Image
            source={images.stashrun}
            style={tw`w-full h-full rounded-full`}
          />
        </View>

        <Input
          style={tw`mb-2 w-full mt-9`}
          inputStyle={tw`border rounded px-2 mt-1`}
          autoCorrect={false}
          label="Email (or Username)"
          labelSize="mediumSmall"
          autocapitalize="none"
          returnKeyType="next"
          value={emailOrUsername}
          errorMessage={errorMessage}
          onChange={onChangeEmailOrUsername}
        />
      </View>
      <View
        style={tw.style(
          `fixed w-full bg-white bottomSheetShadow self-center items-center bottom-0 pt-4 pb-7 rounded-tl-[20px] rounded-tr-[20px]`,
        )}
      >
        <PrimaryButton
          fullRadius
          style="w-full"
          title="Forgot Password"
          loading={initiateForgotPasswordLoading}
          onPress={async () => {
            if (emailOrUsername.length > 0) {
              await initiateForgotPassword({
                variables: {
                  email: emailOrUsername,
                },
              });
            } else {
              setErrorMessage('Please enter a email or username');
            }
          }}
        />
      </View>
    </WebWrapper>
  );
};

export default ForgotPasswordPage;
