import { gql } from '@apollo/client';

export const INITIATE_FORGOT_PASSWORD = gql`
  mutation InitiateForgotPassword($email: String!, $web: Boolean) {
    initiateForgotPassword(input: { email: $email, web: $web }) {
      success
      error
    }
  }
`;
