import { gql } from '@apollo/client';

export const GET_REFERRAL_TEXT = gql`
  mutation GetReferralText(
    $invitedPhoneNumber: String!
    $invitedUserName: String!
    $campaignId: String
    $brandId: String
  ) {
    getReferralText(
      input: {
        invitedPhoneNumber: $invitedPhoneNumber
        invitedUserName: $invitedUserName
        campaignId: $campaignId
        brandId: $brandId
      }
    ) {
      success
      error
      message
    }
  }
`;
