import { gql } from '@apollo/client';

export const MARK_ALL_READ_NOTIFICATIONS = gql`
  mutation MarkAllNotificationsAsRead {
    markAllNotificationsAsRead(input: {}) {
      success
      error
    }
  }
`;
