import React, { useCallback, useEffect, useState } from 'react';

import { useMutation, ApolloError } from '@apollo/client';
import { View } from 'react-native';

import { Text, Input, PrimaryButton } from '@/components/web';
import useWebHooks from '@/hooks/useWebHooks';
import { RESET_PASSWORD } from '@/queries';
import { ResetPasswordData } from '@/types';
import { trackEvent } from '@/utils';
import tw from '@tw';

import { WebWrapper } from '../components';

const ResetPasswordPage = () => {
  const { navigate, userEmail, userForgotPasswordCode } = useWebHooks();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [resetPassword, { loading: resetPasswordLoading }] =
    useMutation<ResetPasswordData>(RESET_PASSWORD, {
      variables: { email: userEmail },
      onCompleted: (data: ResetPasswordData) => {
        if (data.resetPassword?.error) {
          setErrorMessage(data.resetPassword?.error);
          trackEvent('resetPasswordError', {
            error: data.resetPassword?.error,
          });
        } else if (data.resetPassword?.success) {
          trackEvent('resetPasswordSuccess');
          alert(
            'Success!!\n\nYou will now be redirected back to the login page to re-enter your credentials.',
          );
          navigate('/auth/', {
            state: { dontShowPasscode: true },
            replace: true,
          });
        }
      },
      onError: (error: ApolloError) => {
        trackEvent('resetPasswordError', { error });
        setErrorMessage(error.message);
      },
    });

  const validatePassword = useCallback(
    (input: string) => {
      const isValid = input?.length >= 8 && input?.length <= 72;
      setPasswordIsValid(isValid);
      return isValid;
    },
    [setPasswordIsValid],
  );

  const onChangePassword = useCallback(
    (password: string) => {
      setPassword(password);
      if (!validatePassword(password)) {
        setErrorMessage('Password needs to be within 8-72 characters');
      } else {
        setErrorMessage('');
      }
    },
    [setPassword, setErrorMessage],
  );

  const onChangePasswordConfirmation = useCallback(
    (passwordConfirmation: string) => {
      setPasswordConfirmation(passwordConfirmation);
    },
    [setPasswordConfirmation],
  );

  useEffect(() => {}, []);

  return (
    <WebWrapper>
      <View style={tw`items-center justify-center h-full`}>
        <Text mediumSmall centered override={tw`px-9`}>
          Enter your new password below. Your new password must be between 8-72
          characters long.
        </Text>

        <Input
          box
          secureTextEntry
          autoCorrect={false}
          label="New password"
          labelSize="mediumSmall"
          autocapitalize="none"
          keyboardType="default"
          validator={validatePassword}
          errorMessage={errorMessage}
          value={password}
          onChange={onChangePassword}
          style={tw`mt-4 mb-2 w-8.5/10`}
        />

        <Input
          box
          secureTextEntry
          autoCorrect={false}
          label="Re-enter password"
          labelSize="mediumSmall"
          autocapitalize="none"
          keyboardType="default"
          value={passwordConfirmation}
          onChange={onChangePasswordConfirmation}
          style={tw`mb-4 w-8.5/10`}
        />

        <PrimaryButton
          loading={resetPasswordLoading}
          title="Update password"
          style={'self-center w-full'}
          onPress={async () => {
            if (passwordIsValid && password === passwordConfirmation) {
              await resetPassword({
                variables: {
                  forgotPasswordCode: userForgotPasswordCode,
                  email: userEmail,
                  password,
                  passwordConfirmation,
                },
              });
            } else {
              alert(
                'Please enter a valid password and make sure you re-enter the exact same one',
              );
            }
          }}
        />
      </View>
    </WebWrapper>
  );
};

export default ResetPasswordPage;
