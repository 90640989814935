import { gql } from '@apollo/client';

export const FOLLOW_ENTITY = gql`
  mutation FollowEntity(
    $entityType: String!
    $entityId: String!
    $isFollow: Boolean!
  ) {
    followEntity(
      input: {
        entityType: $entityType
        entityId: $entityId
        isFollow: $isFollow
      }
    ) {
      success
      error
    }
  }
`;
