import { useQuery } from '@apollo/client';

import { FETCH_LEADERBOARD } from '@/queries/fetch-leaderboard';
import {} from 'react';
import { LeaderboardData } from '@/types';

export const useLeaderboard = (topN = 1) => {
  const { data, loading } = useQuery<{
    leaderboard: LeaderboardData;
  }>(FETCH_LEADERBOARD, {
    variables: {
      topN,
    },
    fetchPolicy: 'network-only',
  });

  return { loading, data };
};
