import { GROWTHBOOK_API_HOST, GROWTHBOOK_CLIENT_KEY } from '@env';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GrowthBook } from '@growthbook/growthbook-react';

import { analytics } from '@/utils/mixpanel';

export type AppFeatures = {
  'test-feature': boolean;
  'campaign-wall': boolean;
  'disable-campaign-funding': boolean;
};

export const growthbook = new GrowthBook({
  apiHost: GROWTHBOOK_API_HOST || 'https://cdn.growthbook.io',
  clientKey: GROWTHBOOK_CLIENT_KEY || 'sdk-9v0HcX5r8qq9dbFP', // 'sdk-ZGhTxVNsMdABZWhD', // dev
  backgroundSync: true,
  trackingCallback: (experiment, result) => {
    analytics.track('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

export const isFeatureEnabled = (id: keyof AppFeatures & string): boolean =>
  growthbook.isOn(id);
