import React from 'react';

import { Image } from 'react-native';

import darkStashCoinIcon from '@/assets/coin-drop-icon-dark.png';
import purpleStashCoinIcon from '@/assets/coin-drop-icon-purple.png';
import stashIcon from '@/assets/coin-drop-icon-white.png';
import stashrunDropIconPurpleBackground from '@/assets/stashrunCoinDropPurple.png';

type StashrunDropIconProps = {
  size: number;
  purple?: boolean;
  dark?: boolean;
  levelUp?: boolean;
};

const getIcon = ({
  purple,
  dark,
  levelUp,
}: {
  purple?: boolean;
  dark?: boolean;
  levelUp?: boolean;
}) => {
  if (purple) return purpleStashCoinIcon;
  if (dark) return darkStashCoinIcon;
  if (levelUp) return stashrunDropIconPurpleBackground;
  return stashIcon;
};

export default function StashrunDropIcon({
  size,
  purple,
  dark,
  levelUp,
}: StashrunDropIconProps) {
  return (
    <Image
      style={{ width: size, height: size }}
      source={getIcon({ purple, dark, levelUp })}
    />
  );
}
