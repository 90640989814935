import { gql } from '@apollo/client';

export const FETCH_USER_STATUS = gql`
  query UserStatuses($userId: ID!) {
    userStatuses(userId: $userId) {
      id
      user {
        id
        username
      }
      statusText
      createdAt
    }
  }
`;
