/* eslint-disable operator-assignment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable global-require */
import React, { Component } from 'react';

import { Audio } from 'expo-av';
import {
  Animated,
  Image,
  Platform,
  Dimensions,
  GestureResponderEvent,
  Pressable,
} from 'react-native';
import {
  PanGestureHandlerGestureEvent,
  PanGestureHandlerProperties,
  State,
  TapGestureHandler,
  TapGestureHandlerStateChangeEvent,
} from 'react-native-gesture-handler';

// Using bronze image for the silver coin asset now since we wanted to replace silver asset but
// api is all referencing silver
import Images from '@/assets/images';
import { randomIntFromInterval } from '@/utils';

let TAP_COUNTER_FOR_ALTERNATING_SOUNDS = 0;

type DraggableCoinProps = {
  rotate?: number;
  boxStyle?: any;
  onRelease?: (...args: any) => void;
  onStart?: (...args: any) => void;
  animation?: any;
  noSound?: boolean;
  color?: 'silver' | 'gold' | 'red' | 'purple';
};

type DraggableCoinState = {
  hidden: boolean;
  clicked: boolean;
  top: number;
  left: number;
  sound1: {
    playAsync: () => void;
    unloadAsync: () => void;
  } | null;
  sound2: {
    playAsync: () => void;
    unloadAsync: () => void;
  } | null;
};

export class DraggableCoin extends Component<
  PanGestureHandlerProperties & DraggableCoinProps,
  DraggableCoinState
> {
  _translateX: Animated.Value = new Animated.Value(0);

  _translateY: Animated.Value = new Animated.Value(0);

  _lastOffset: { [position: string]: number } = { x: 0, y: 0 };

  _onGestureEvent?: (...args: any) => void;

  _coinId: number = randomIntFromInterval(-100000, 100000);

  _coinAngle: number = randomIntFromInterval(-180, 180);

  state: DraggableCoinState = {
    hidden: false,
    clicked: false,
    top: 0,
    left: 0,
    sound1: null,
    sound2: null,
  };

  constructor(props: PanGestureHandlerProperties & DraggableCoinProps) {
    super(props);
    if (props.rotate) this._coinAngle = props.rotate;
    this._translateX = new Animated.Value(0);
    this._translateY = new Animated.Value(0);
    this._lastOffset = { x: 0, y: 0 };
    this._onGestureEvent = Animated.event(
      [
        {
          nativeEvent: {
            translationX: this._translateX,
            translationY: this._translateY,
          },
        },
      ],
      { useNativeDriver: true },
    );

    this._checkRelease = this._checkRelease.bind(this);
  }

  async componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    await Audio.Sound.createAsync(require('../../assets/coinSound1.mp3')).then(
      ({ sound }) => {
        this.setState({ sound1: sound });
      },
    );

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    await Audio.Sound.createAsync(require('../../assets/coinSound2.mp3')).then(
      ({ sound }) => {
        this.setState({ sound2: sound });
      },
    );
  }

  componentWillUnmount() {
    const { sound1, sound2 } = this.state;

    if (sound1) {
      sound1.unloadAsync();
    }

    if (sound2) {
      sound2.unloadAsync();
    }
  }

  _checkRelease = (event: any, override?: boolean) => {
    const { onRelease } = this.props;
    const windowWidth = Dimensions.get('window').width;
    const x =
      Platform.OS === 'web'
        ? event.nativeEvent.pageX
        : event.nativeEvent.absoluteX;
    const y =
      Platform.OS === 'web'
        ? event.nativeEvent.pageY
        : event.nativeEvent.absoluteY;

    if (
      override ||
      (event.nativeEvent.oldState === State.ACTIVE &&
        event.nativeEvent.state === State.END)
    ) {
      if (x > windowWidth - 200 && y < 150) {
        if (onRelease) {
          onRelease();
        }
        this.setState({ hidden: true });
      }
    }
  };

  animateSendToBalance = () => {
    const { noSound } = this.props;
    const { sound1, sound2 } = this.state;
    if (!noSound) {
      if (TAP_COUNTER_FOR_ALTERNATING_SOUNDS % 2 === 0 && sound2) {
        sound2.playAsync();
      } else if (sound1) {
        sound1.playAsync();
      }

      TAP_COUNTER_FOR_ALTERNATING_SOUNDS =
        TAP_COUNTER_FOR_ALTERNATING_SOUNDS + 1;
    }

    Animated.parallel([
      Animated.timing(this._translateX, {
        toValue: 1,
        duration: 1000,
        delay: 10,
        useNativeDriver: true,
      }),
      Animated.timing(this._translateY, {
        toValue: 1,
        duration: 1000,
        delay: 10,
        useNativeDriver: true,
      }),
    ]).start();
  };

  _onHandlerStateChange = (event: PanGestureHandlerGestureEvent) => {
    if ((event.nativeEvent as any).oldState === State.ACTIVE) {
      this._lastOffset.x = this._lastOffset?.x + event.nativeEvent.translationX;
      this._lastOffset.y = this._lastOffset?.y + event.nativeEvent.translationY;
      this._translateX?.setOffset(this._lastOffset.x);
      this._translateX?.setValue(0);
      this._translateY?.setOffset(this._lastOffset.y);
      this._translateY?.setValue(0);
      this._checkRelease(event);
    }
  };

  render() {
    const { animation, color } = this.props;
    const { hidden, top, left } = this.state;
    const windowWidth = Dimensions.get('window').width;

    let coinImage = Images.coins.goldCoin;
    switch (color) {
      case 'silver':
        coinImage = Images.coins.copperCoin;
        break;
      case 'red':
        coinImage = Images.coins.redCoin;
        break;
      case 'purple':
        coinImage = Images.coins.purpleCoin;
        break;
      case 'gold':
      default:
        coinImage = Images.coins.goldCoin;
        break;
    }

    const animatedStyle = {
      opacity: this._translateX.interpolate({
        inputRange: [0, 0.25, 0.5, 0.75, 0.9, 1],
        outputRange: [1, 0.8, 0.8, 0.8, 0.8, 0],
      }),
      transform: [
        {
          translateX: this._translateX.interpolate({
            inputRange: [0, 1],
            outputRange: [0, windowWidth - 45 - left],
          }),
        },
        {
          translateY: this._translateY.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 100 - top],
          }),
        },
        {
          scale: this._translateX.interpolate({
            inputRange: [0, 0.1, 1],
            outputRange: [1, 1.4, 0.2],
          }),
        },
      ],
      zIndex: 100,
      width: 60,
      height: 60,
      padding: 2.5,
    };

    if (hidden) {
      return null;
    }

    return Platform.OS === 'web' ? (
      <Pressable
        key={`coin-${this._coinId}-${Date.now}`}
        style={{ width: 50, height: 50, zIndex: 1000 }}
        hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
        onPressIn={(event: GestureResponderEvent) => {
          const { onStart, onRelease } = this.props;
          if (!this.state.clicked) {
            if (onStart) {
              onStart();
            }

            animation?.stop();

            this.setState({
              clicked: true,
              top: event.nativeEvent.pageY,
              left: event.nativeEvent.pageX,
            });
            this.animateSendToBalance();

            if (onRelease) {
              onRelease();
            }
          }
        }}
      >
        <Animated.View style={animatedStyle}>
          <Image
            style={{
              width: 50,
              height: 50,
              transform: [{ rotate: `${this._coinAngle}deg` }],
            }}
            source={coinImage}
          />
        </Animated.View>
      </Pressable>
    ) : (
      <TapGestureHandler
        onHandlerStateChange={(event: TapGestureHandlerStateChangeEvent) => {
          const { onStart, onRelease } = this.props;
          if (!this.state.clicked) {
            if (onStart) {
              onStart();
            }

            animation?.stop();

            this.setState({
              clicked: true,
              top: event.nativeEvent.absoluteY,
              left: event.nativeEvent.absoluteX,
            });
            this.animateSendToBalance();

            if (onRelease) {
              onRelease();
            }
          }
        }}
      >
        <Animated.View style={animatedStyle}>
          <Image
            style={{
              width: 50,
              height: 50,
              transform: [{ rotate: `${this._coinAngle}deg` }],
            }}
            source={coinImage}
          />
        </Animated.View>
      </TapGestureHandler>
    );
  }
}
