import React, { useEffect, useState } from 'react';

import Clipboard from '@react-native-clipboard/clipboard';
import { Video, ResizeMode } from 'expo-av';
import { Image } from 'expo-image';
import * as ImagePicker from 'expo-image-picker';
import { Pressable, View } from 'react-native';
import BouncyCheckbox from 'react-native-bouncy-checkbox';
import ProgressBarAnimated from 'react-native-progress-bar-animated';
import { Reoverlay } from 'reoverlay';

import images from '@/assets/images';
import {
  Text,
  SlideUpModal,
  PrimaryButton,
  Input,
  LoadingIndicator,
} from '@/components/web';
import { useUserCTX } from '@/contexts';
import useStashLinkHooks from '@/hooks/useStashLink';
import { Campaign } from '@/types';
import { COLORS, errorModal, maxWidth, scroll, trackEvent } from '@/utils';
import { AbortHandler } from '@/utils/customApolloUploadProgress';
import { setVideoPreview } from '@/utils/webStorage';
import tw from '@tw';

type VideoMessageUploadModalProps = {
  stashlinkId?: string;
  stashlinkMessageVideo?: string;
  stashlinkMessage?: string;
  refetch?: () => void;
  campaign?: Campaign;
  userName?: string;
  url: string;
  haloColor?: string;
  profilePhoto?: string;
};
const VideoMessageUploadModal = ({
  stashlinkId,
  stashlinkMessageVideo,
  stashlinkMessage,
  refetch,
  campaign,
  userName,
  haloColor,
  profilePhoto,
  url,
}: VideoMessageUploadModalProps) => {
  const { userData } = useUserCTX();
  const [thankyouVideo, setThankyouVideo] = useState('');
  const [thankYouMessage, setThankYouMessage] = useState('');
  const [checkTOSVid, setTOSVid] = useState(false);
  const [checkTOSmes, setTOSMes] = useState(false);
  const [deleteVideo, setDeleteVideo] = useState(false);

  const [copyTitle, changeCopyTitle] = useState('Copy Link');
  const [screen, setScreen] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [videoLoad, setVideoLoad] = useState(true);
  const [progressBar, setProgressBar] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [abortUpload, setAbortUpload] = useState<AbortHandler>();
  const video = React.useRef<Video>(null);

  const { updateStashlink, updateStashlinkLoading } = useStashLinkHooks();

  const uploadCancelled = () => {
    if (abortUpload) {
      abortUpload();
      errorModal('The upload has been canceled.');
      setAbortUpload(undefined);
      setThankyouVideo('');
    }
  };

  useEffect(() => {
    if (stashlinkMessageVideo) {
      setThankyouVideo(stashlinkMessageVideo);
      setVideoUrl(stashlinkMessageVideo);
      setTOSVid(true);
    }
    if (stashlinkMessage) {
      setThankYouMessage(stashlinkMessage);
      setTOSMes(true);
    }
    scroll.lockInput();
    return () => {
      refetch?.();
      scroll.unlockInput();
      uploadCancelled();
    };
  }, []);

  useEffect(() => {
    if (deleteVideo) {
      deleteVideoFromStashlink();
      trackEvent('thankYouVideoDeleted');
    }
  }, [deleteVideo]);

  const dataURLtoFile = (dataurl: string | null, filename: string) => {
    if (!dataurl) return null;
    const arr = dataurl.split(',');
    const mime = arr[0]?.match(/:(.*?);/)?.[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Videos,
      allowsEditing: true,
      aspect: [9, 16],
      quality: 0.85,
      videoMaxDuration: 30,
      videoQuality: ImagePicker.UIImagePickerControllerQualityType.Medium,
      selectionLimit: 1,
    });

    if (!result.canceled) {
      const checkVideo = dataURLtoFile(
        result.assets[0].uri,
        'thank-you-video.mp4',
      );

      if (checkVideo?.size && checkVideo?.size > 40000000) {
        Reoverlay.showModal('ErrorModal', {
          errorMessage:
            'Video size is too large! Try uploading something smaller.',
        });
      } else if (videoDuration > 16) {
        Reoverlay.showModal('ErrorModal', {
          errorMessage:
            'Video duration is too long! Try keeping it within 15 seconds.',
        });
      } else {
        setDeleteVideo(false);
        setThankyouVideo(result.assets[0].uri);
        updateThankYouVideoMessage(result.assets[0].uri);
        setVideoLoad(true);
      }
    }
  };

  const handleBack = () => {
    setScreen('');
    uploadCancelled();
  };

  const updateThankYouVideoMessage = async (file?: string) => {
    const videoFile = file || thankyouVideo;
    if (stashlinkId) {
      const response = await updateStashlink({
        variables: {
          stashlinkId,
          thankYouMessageVideo: dataURLtoFile(videoFile, 'thank-you-video.mp4'),
        },
        context: {
          fetchOptions: {
            useUpload: true,
            onProgress: (ev: ProgressEvent) => {
              setProgressBar(ev.loaded / ev.total);
            },
            onAbortPossible: (abortHandler: any) => {
              setAbortUpload(() => abortHandler);
            },
          },
        },
      });

      if (!response.data?.updateStashlink.success) {
        errorModal(response.data?.updateStashlink.error);
        changeCopyTitle('Copy Link');
      } else {
        trackEvent('thankYouVideoUploaded');
        setVideoUrl(
          response.data?.updateStashlink?.stashlink?.thankYouMessageVideo,
        );
        setAbortUpload(undefined);
      }
    }
  };
  const updateThankYouMessage = async () => {
    if (stashlinkId) {
      const response = await updateStashlink({
        variables: {
          stashlinkId,
          thankYouMessage,
        },
      });

      if (!response.data?.updateStashlink.success) {
        Reoverlay.showModal('ErrorModal', {
          errorMessage: response.data?.updateStashlink.error,
        });
        changeCopyTitle('Copy Link');
      } else {
        trackEvent('thankYouMessageAdded');
      }
    }
  };

  const deleteVideoFromStashlink = async () => {
    await updateStashlink({
      variables: {
        stashlinkId,
        thankYouMessageVideo: null,
      },
    });
  };

  const titleProperty =
    {
      '': 'Personalize your link and share',
      video: 'Inspire with a video',
      message: 'Inspire with a message',
    }[screen] || '';

  const titleDescription =
    {
      '': 'Include a short video or thank you message to inspire more support. ❤️',
      video: `Express why this cause matters to you, and why your friends need to share. Say thanks! 🙏`,
      message:
        'Express why this cause matters to you, and why your friends need to share. Say thanks! 🙏',
    }[screen] || '';

  const renderTitle = () => (
    <>
      <Text centered bold>
        {titleProperty}
      </Text>
      <Text centered small override={tw`px-10 py-2`}>
        {titleDescription}
      </Text>
      <View style={tw`border-b-1 border-zinc-300 w-8/10 mb-4 self-center`} />
    </>
  );
  const renderTOS = () => (
    <>
      <Text xsmallPlus2>
        The content of my {screen === 'video' ? 'video' : 'message'} adheres to
        the{' '}
        <Pressable
          onPress={() =>
            window.open(
              `https://www.stashrun.com/terms`,
              '_blank',
              'noreferrer',
            )
          }
        >
          <Text xsmallPlus2 primary>
            TOS
          </Text>
        </Pressable>{' '}
        and{' '}
        <Pressable
          onPress={() =>
            window.open(
              `https://www.stashrun.com/community-guidelines`,
              '_blank',
              'noreferrer',
            )
          }
        >
          <Text xsmallPlus2 primary>
            Community Guidelines
          </Text>
        </Pressable>
      </Text>
    </>
  );

  const videoUploadScreen = () => (
    <>
      {renderTitle()}

      {thankyouVideo ? (
        <View
          style={tw.style(
            `w-[108.93px] h-[190px] self-center rounded-md`,
            updateStashlinkLoading && 'border border-dashed',
          )}
        >
          {updateStashlinkLoading ? (
            <>
              <LoadingIndicator />
              <View
                style={tw`bottom-[30%] self-center justify-center items-center`}
              >
                <Text xsmall centered>
                  Uploading...
                </Text>
                <ProgressBarAnimated
                  backgroundColor={COLORS.borderGray}
                  underlyingColor={'#F1F1F1'}
                  width={100}
                  height={10}
                  borderWidth={0}
                  borderRadius={10}
                  value={progressBar * 100}
                />
              </View>
              <Pressable
                onPress={async () => {
                  setThankyouVideo('');
                  setDeleteVideo(true);
                  setTOSVid(false);
                  trackEvent('thankYouVideoDeleted');
                  uploadCancelled();
                }}
                style={tw`w-[16px] h-[16px] absolute top-1 right-1`}
              >
                <Image source={images.videoClose} style={tw`w-full h-full`} />
              </Pressable>
            </>
          ) : (
            <>
              <Video
                ref={video}
                style={tw`w-full h-full rounded-md`}
                source={{ uri: thankyouVideo }}
                resizeMode={ResizeMode.COVER}
                shouldPlay
                isMuted
                videoStyle={tw`h-full w-full`}
                onReadyForDisplay={(videoData: any) => {
                  setVideoDuration(videoData.srcElement.duration);
                }}
                onLoad={() => {
                  // hack to load thumbnail
                  video?.current?.stopAsync();
                  setVideoLoad(false);
                }}
              />
              {videoLoad && (
                <View
                  style={tw`h-full w-full absolute justify-center items-center`}
                >
                  <LoadingIndicator />
                </View>
              )}

              {!videoLoad && (
                <>
                  <Pressable
                    onPress={async () => {
                      setThankyouVideo('');
                      setDeleteVideo(true);
                      setTOSVid(false);
                      trackEvent('thankYouVideoDeleted');
                      uploadCancelled();
                    }}
                    style={tw`w-[16px] h-[16px] absolute top-1 right-1`}
                  >
                    <Image
                      source={images.videoClose}
                      style={tw`w-full h-full`}
                    />
                  </Pressable>
                  <Pressable
                    onPress={() => {
                      setVideoPreview({
                        user: userName || '',
                        campaignCurrentAmount: campaign?.currentAmount || 0,
                        campaignGoalAmount: campaign?.goalAmount || 0,
                        campaignCauseName: campaign?.cause?.name || '',
                        campaignCanSupport: campaign?.canSupport || false,
                        video: videoUrl,
                        haloColor: haloColor || '',
                        profilePhoto: profilePhoto || '',
                      });
                      window.open(
                        `${window.location.origin}/video-preview`,
                        '_blank',
                        'noreferrer',
                      );
                    }}
                    style={tw`absolute bottom-1 self-center flex-row items-center`}
                  >
                    <Text xsmallPlus2 bold inverse textShadow>
                      Preview
                    </Text>
                    <Image
                      source={images.openTab}
                      style={tw`ml-1 w-[10px] h-[10px]`}
                    />
                  </Pressable>
                </>
              )}
            </>
          )}
        </View>
      ) : (
        <>
          <Pressable
            onPress={async () => {
              pickImage();
            }}
            style={tw`w-[108.93px] h-[190px] self-center mb-2`}
          >
            <Image source={images.videoPlaceholder} style={tw`h-full w-full`} />
          </Pressable>
          <Text xsmallPlus2 centered>
            Your video should be up to 15 seconds
          </Text>
          <Text xsmallPlus2 centered>
            and shot vertically or square
          </Text>
        </>
      )}

      {thankyouVideo && !videoLoad && !updateStashlinkLoading && (
        <View style={tw`flex-row px-10 pt-6`}>
          <BouncyCheckbox
            size={20}
            isChecked={checkTOSVid}
            iconStyle={tw`border-2 border-zinc-500 rounded-sm`}
            innerIconStyle={tw`rounded-sm`}
            fillColor="black"
            unfillColor="#FFFFFF"
            onPress={() => setTOSVid((prev) => !prev)}
          />
          {renderTOS()}
        </View>
      )}

      {checkTOSVid &&
        thankyouVideo &&
        !videoLoad &&
        !updateStashlinkLoading && (
          <>
            <PrimaryButton
              style="w-full mt-4 self-center"
              title={copyTitle}
              fullRadius
              onPress={async () => {
                changeCopyTitle('Copied!');
                Clipboard.setString(url);
                Reoverlay.showModal('AlertModal', {
                  alert: 'Link copied to clipboard',
                  afterClose: () => {
                    Reoverlay.hideModal();
                  },
                });
              }}
              customRender={
                copyTitle === 'Copy Link' ? (
                  <Image source={images.copyIcon} style={tw`w-5 h-5 ml-2.5`} />
                ) : null
              }
            />
            <Text xsmallPlus2 override={tw`pt-8px text-center w-full`}>
              Post to your socials or DM friends
            </Text>
          </>
        )}
    </>
  );

  const defaultScreen = () => (
    <>
      {renderTitle()}

      <PrimaryButton
        style="w-full self-center mb-4"
        title="Upload Video"
        type="getApp"
        onPress={() => {
          setScreen('video');
        }}
        customRender={
          <Image source={images.cameraIcon} style={tw`w-7 h-4 ml-2.5`} />
        }
      />
      <Text centered xsmallPlus2>
        Up to 15 seconds, shot vertically or square
      </Text>
      <View
        style={tw`w-8/10 my-2 self-center flex-row justify-between items-center`}
      >
        <View style={tw`border-b-1 border-zinc-300 w-2/5`} />
        <Text centered bold mediumSmall>
          OR
        </Text>
        <View style={tw`border-b-1 border-zinc-300 w-2/5`} />
      </View>

      <PrimaryButton
        style="w-full self-center mb-20"
        title="Write a Message"
        type="withBorder"
        onPress={() => {
          setScreen('message');
        }}
      />

      <PrimaryButton
        style="w-full self-center"
        title={copyTitle}
        fullRadius
        onPress={async () => {
          changeCopyTitle('Copied!');
          Clipboard.setString(url);
          Reoverlay.showModal('AlertModal', {
            alert: 'Link copied to clipboard',
            afterClose: () => {
              Reoverlay.hideModal();
            },
          });
        }}
        customRender={
          copyTitle === 'Copy Link' ? (
            <Image source={images.copyIcon} style={tw`w-5 h-5 ml-2.5`} />
          ) : null
        }
      />
      <Text xsmallPlus2 override={tw`pt-8px text-center w-full`}>
        Post to your socials or DM friends
      </Text>
    </>
  );

  const messageScreen = () => (
    <>
      {renderTitle()}

      <Input
        style={tw`w-8/10 self-center mb-1`}
        inputStyle={tw`border-transparentborder50 border rounded-md px-2 mt-1 h-34`}
        multiline
        placeholder="Thanks everyone for supporting this cause with me, you are truly awesome humans! Don’t forget to share this campaign with a friend, to help us reach the funding goal."
        value={thankYouMessage}
        maxLength={512}
        onChange={(text) => {
          if (text.length === 0) {
            setTOSMes(false);
          }
          setThankYouMessage(text);
        }}
        autoFocus
      />

      {thankYouMessage.length !== 0 && (
        <View style={tw`flex-row px-10`}>
          <BouncyCheckbox
            size={20}
            isChecked={checkTOSmes}
            iconStyle={tw`border-2 border-zinc-500 rounded-sm`}
            innerIconStyle={tw`rounded-sm`}
            fillColor="black"
            unfillColor="#FFFFFF"
            onPress={() => setTOSMes((prev) => !prev)}
          />
          {renderTOS()}
        </View>
      )}

      {checkTOSmes &&
        thankYouMessage.length > 0 &&
        thankYouMessage.length < 513 && (
          <>
            <PrimaryButton
              style="w-full mt-4 self-center"
              title={copyTitle}
              fullRadius
              onPress={async () => {
                changeCopyTitle('Copied!');
                updateThankYouMessage();
                Clipboard.setString(url);
                Reoverlay.showModal('AlertModal', {
                  alert: 'Link copied to clipboard',
                  afterClose: () => {
                    Reoverlay.hideModal();
                  },
                });
              }}
              customRender={
                copyTitle === 'Copy Link' ? (
                  <Image source={images.copyIcon} style={tw`w-5 h-5 ml-2.5`} />
                ) : null
              }
            />
            <Text xsmallPlus2 override={tw`pt-8px text-center w-full`}>
              Post to your socials or DM friends
            </Text>
          </>
        )}
    </>
  );

  const renderScreen = () => {
    if (screen === 'video') {
      return videoUploadScreen();
    }
    if (screen === 'message') {
      return messageScreen();
    }
    return defaultScreen();
  };

  return (
    <SlideUpModal
      maxHeight={500}
      haloColor={userData?.user?.haloColor}
      userImage={userData?.user?.profilePhoto}
      preventClose
      removeButton={!!screen}
      keepBackground
      backbuttonFunction={screen ? handleBack : undefined}
      customClose={refetch && refetch}
    >
      <View style={tw`w-${maxWidth(500)}px pt-14 `}>{renderScreen()}</View>
    </SlideUpModal>
  );
};

export default VideoMessageUploadModal;
