import { gql } from '@apollo/client';

export const FETCH_ENDING_SOON_CAMPAIGNS = gql`
  query {
    endingSoonCampaigns {
      id
      title
      description
      location
      imageUrl
      currentAmount
      goalAmount
      endTime
      studentOrgs
      charity {
        id
        name
        imageUrl
      }
      cause {
        id
        imageUrl
        description
        location
        name
        numCampaigns
        numCharities
        numSupporters
        tagline
        videoUrl
        website
        wikipediaUrl
      }
    }
  }
`;
