import React from 'react';

import { Image } from 'react-native';

import blackStashIcon from '@/assets/black-regular-stash-coin.png';
import menuStashIcon from '@/assets/icons/tab-icons/stashrun_icon.png';
import menuStashIconActive from '@/assets/icons/tab-icons/stashrun_icon_active.png';
import purpleStashIcon from '@/assets/purple-regular-stash-coin.png';
import stashIcon from '@/assets/regular-stash-coin.png';

type StashrunCoinIconProps = {
  size: number;
  black?: boolean;
  purple?: boolean;
  menuActive?: boolean;
  menu?: boolean;
};

const getIcon = ({
  black,
  purple,
  menu,
  menuActive,
}: {
  black?: boolean;
  purple?: boolean;
  menu?: boolean;
  menuActive?: boolean;
}) => {
  if (black) return blackStashIcon;
  if (purple) return purpleStashIcon;
  if (menu) return menuStashIcon;
  if (menuActive) return menuStashIconActive;

  return stashIcon;
};

export default function StashrunCoinIcon({
  size,
  black,
  purple,
  menu,
  menuActive,
}: StashrunCoinIconProps) {
  return (
    <Image
      style={{
        width: size,
        height: size,
        resizeMode: menu || menuActive ? 'contain' : 'cover',
      }}
      source={getIcon({ black, purple, menu, menuActive })}
    />
  );
}
