import React, { useEffect } from 'react';

import { BlurView } from 'expo-blur';
import { Platform, Pressable, View } from 'react-native';
import { Reoverlay } from 'reoverlay';

import { Text } from '@/components/web';
import scroll from '@/utils/scroll';
import tw from '@tw';

type ErrorProps = {
  errorMessage?: string;
  errorButton?: string;
  errorFunction?: () => void;
};

const ErrorModal = ({
  errorMessage = 'test',
  errorButton = 'done',
  errorFunction,
}: ErrorProps) => {
  useEffect(() => {
    scroll.lock();
    return () => {
      scroll.enable();
    };
  }, []);

  return (
    <View
      style={tw.style(
        'left-0 w-full items-center flex z-999 top-0 h-full justify-center items-center',
        {
          fixed: Platform.OS === 'web',
          absolute: Platform.OS !== 'web',
        },
      )}
    >
      <BlurView intensity={10} style={tw.style(`w-full h-full`)}>
        <Pressable
          onPress={() => {
            Reoverlay.hideModal();
          }}
          style={tw.style(`w-full h-full absolute z-2 bg-trueGray opacity-80`)}
        />
      </BlurView>

      <View
        style={tw`max-h-44 w-80 p-4 pb-14 bottomSheetShadow z-10 bg-white absolute rounded-lg`}
      >
        <Text mediumSmall>{errorMessage}</Text>
        <Pressable
          style={tw`px-2 h-8 bg-white rounded-lg justify-center items-center border border-borderGray right-2 bottom-2 absolute`}
          onPress={() => {
            if (errorFunction) {
              errorFunction();
            }
            Reoverlay.hideModal();
          }}
        >
          <Text mediumSmall>{errorButton}</Text>
        </Pressable>
      </View>
    </View>
  );
};

export default ErrorModal;
