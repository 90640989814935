import { gql } from '@apollo/client';

export const FETCH_FLAGGED_CONVO = gql`
  query ($id: ID!) {
    flag(id: $id) {
      id
      resourceType
      resourceId
      userId
      flagType
      resolvedAt
      associatedNotification {
        id
      }
      resource {
        __typename
        ... on Conversation {
          id
          title
          heroImageUrl
          isAnonymous
          message
          user {
            id
            username
            profilePhoto
          }
        }
        ... on ConversationComment {
          id
          message
          isAnonymous
          conversation {
            id
          }
          user {
            id
            username
            profilePhoto
          }
        }
      }
    }
  }
`;
