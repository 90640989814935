import React from 'react';

import { View, StyleSheet } from 'react-native';

import BackButton from '@/components/BackButton';
import CoinDropBalance from '@/components/CoinDropBalance';
import { useSessionTokenContext } from '@/components/SessionTokenProvider';

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 16,
  },
  childrenContainer: {
    position: 'absolute',
    alignSelf: 'center',
  },
});

type Props = {
  showCoinDropBalance?: boolean;
  children: React.ReactNode;
};

const EntityScreenHeader = ({ showCoinDropBalance, children }: Props) => {
  const { sessionToken, userId } = useSessionTokenContext();
  return (
    <>
      <View style={styles.headerContainer}>
        <BackButton />
        {!!userId && !!sessionToken && showCoinDropBalance && (
          <CoinDropBalance alignSelf={'center'} />
        )}
      </View>
      <View style={styles.childrenContainer}>{children}</View>
    </>
  );
};

export default EntityScreenHeader;
