import { useMemo } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { COLLECT_COINS, FETCH_PENDING_COIN_DROPS } from '@/queries';

import { useSessionTokenContext } from '../components/SessionTokenProvider';

export const usePendingCoinDrops = () => {
  const { userId } = useSessionTokenContext();
  const { loading, data, error, refetch } = useQuery<{
    user: { pendingCoinDrops: number };
  }>(FETCH_PENDING_COIN_DROPS, {
    variables: { userId },
    fetchPolicy: 'network-only',
  });
  const pendingCoinDrops = useMemo(() => {
    return data?.user?.pendingCoinDrops || 0;
  }, [data?.user?.pendingCoinDrops]);

  return {
    loading,
    pendingCoinDrops,
    error,
    refetch,
  };
};

export const useLazyPendingCoinDrops = () => {
  const { userId } = useSessionTokenContext();
  const [
    fetchPendingCoinDrops,
    { loading: loadingPendingCoinDrops, data, error: pendingCoinDropsError },
  ] = useLazyQuery<{
    user: { pendingCoinDrops: number };
  }>(FETCH_PENDING_COIN_DROPS, {
    variables: { userId },
    fetchPolicy: 'network-only',
  });
  const pendingCoinDrops = useMemo(() => {
    return data?.user?.pendingCoinDrops || 0;
  }, [data?.user?.pendingCoinDrops]);

  return {
    fetchPendingCoinDrops,
    loadingPendingCoinDrops,
    pendingCoinDrops,
    pendingCoinDropsError,
  };
};

export const useCollectCoins = () => {
  const [
    collectCoins,
    {
      data: collectCoinsData,
      loading: collectCoinsLoading,
      error: collectCoinsError,
    },
  ] = useMutation<
    any,
    {
      coinDropId: number;
      coinsCollected: number;
      silverCoinsCollected: number;
      goldCoinsCollected: number;
      redCoinsCollected: number;
      purpleCoinsCollected: number;
      secret?: string;
      referrerUserId?: string;
      secondaryReferrerUserId?: string;
    }
  >(COLLECT_COINS, {
    fetchPolicy: 'network-only',
  });

  return {
    collectCoins,
    collectCoinsData,
    collectCoinsLoading,
    collectCoinsError,
  };
};
