import { gql } from '@apollo/client';

export const USER_ENTITY_FOLLOWING = gql`
  query UserEntityFollowing($entityType: String!, $userId: String!) {
    userEntityFollowing(entityType: $entityType, userId: $userId) {
      brand {
        brandColor
        coinBalance
        coinsGiven
        description
        headerLogoUrl
        heroImageUrl
        heroTagline
        id
        imageUrl
        location
        name
        numCampaigns
        numCauses
        numEmployees
        numSupporters
        registeredName
        registeredNonProfit
        supportedCauses {
          charities {
            id
          }
          coinsReceived
          description
          hashTags
          heroImageUrl
          heroTagline
          id
          imageUrl
          location
          name
          numCampaigns
          numCharities
          numSupporters
          tagline
          videoUrl
          website
          wikipediaUrl
        }
        totalCoinDrops
        instagramUsername
        twitterUsername
        facebookUsername
        youtubeUsername
        tiktokUsername
        linkedinUrl
        username
        videoUrl
        website
        yearFounded
      }
      campaign {
        cause {
          charities {
            id
          }
          coinsReceived
          description
          hashTags
          heroImageUrl
          heroTagline
          id
          imageUrl
          location
          name
          numCampaigns
          numCharities
          numSupporters
          tagline
          videoUrl
          website
          wikipediaUrl
        }
        charity {
          activeSince
          city
          coinBalance
          coinsGiven
          country
          description
          fullAddress
          headerLogoUrl
          heroImageUrl
          heroTagline
          id
          imageUrl
          location
          name
          numCampaigns
          numCauses
          numEmployees
          numSupporters
          postalCode
          registeredName
          stateProvince
          streetAddress
          supportedCauses {
            id
          }
          totalCoinDrops
          videoUrl
          website
          yearFounded
        }
        coinsReceived
        currentAmount
        description
        endTime
        goalAmount
        id
        imageUrl
        location
        numSupporters
        title
        totalCoinDrops
      }
      cause {
        charities {
          activeSince
          city
          coinBalance
          coinsGiven
          country
          description
          fullAddress
          headerLogoUrl
          heroImageUrl
          heroTagline
          id
          imageUrl
          location
          name
          numCampaigns
          numCauses
          numEmployees
          numSupporters
          postalCode
          registeredName
          stateProvince
          streetAddress
          supportedCauses {
            id
          }
          totalCoinDrops
          videoUrl
          website
          yearFounded
        }
        coinsReceived
        description
        hashTags
        heroImageUrl
        heroTagline
        id
        imageUrl
        location
        name
        numCampaigns
        numCharities
        numSupporters
        tagline
        videoUrl
        website
        wikipediaUrl
      }
      charity {
        activeSince
        city
        coinBalance
        coinsGiven
        country
        description
        fullAddress
        headerLogoUrl
        heroImageUrl
        heroTagline
        id
        imageUrl
        location
        name
        numCampaigns
        numCauses
        numEmployees
        numSupporters
        postalCode
        registeredName
        stateProvince
        streetAddress
        supportedCauses {
          charities {
            id
          }
          coinsReceived
          description
          hashTags
          heroImageUrl
          heroTagline
          id
          imageUrl
          location
          name
          numCampaigns
          numCharities
          numSupporters
          tagline
          videoUrl
          website
          wikipediaUrl
        }
        totalCoinDrops
        videoUrl
        website
        yearFounded
      }
      success
    }
  }
`;
