import React, { useEffect } from 'react';

import { BlurView } from 'expo-blur';
import { Image } from 'expo-image';
import { Dimensions, View, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';

import Images from '@/assets/images';
import { Text, Spacing, PrimaryButton } from '@/components/web';
import { Brand } from '@/types';
import { maxWidth, preloadImages, trackEvent, COLORS } from '@/utils';
import tw from '@tw';

type CoinDropExplainerModalProps = {
  brand?: Brand;
  brandImage?: string;
  isCoinDropExplainerModalOpen: boolean;
  setCoinDropExplainerModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const deviceWidth = maxWidth(500);
const deviceHeight = Dimensions.get('window').height;

const CoinDropExplainerModal = ({
  brand,
  isCoinDropExplainerModalOpen,
  setCoinDropExplainerModalOpen,
}: CoinDropExplainerModalProps) => {
  useEffect(() => {
    preloadImages([Images.coinDrop.coinValues]);
  }, []);

  useEffect(() => {
    if (brand?.name && isCoinDropExplainerModalOpen) {
      trackEvent('coinDropExplainModalViewed', {
        brand: brand?.name,
      });
    }
  }, [brand?.name]);

  if (!brand) {
    return null;
  }

  const modalContent = (
    <View style={[styles.modalContent, styles.contentContainer]}>
      <Spacing horizontal={2}>
        <Image
          style={styles.imageContainer}
          source={Images.coinDrop.coinValues}
        />
      </Spacing>
      <View style={tw`flex flex-row justify-center items-center w-full mb-5`}>
        <PrimaryButton
          title="Let’s Goooo!"
          style={tw`max-w-none w-[80%]`}
          onPress={() => {
            trackEvent('coinDropExplainModalComplete', {
              brand: brand?.name,
            });
            setCoinDropExplainerModalOpen(false);
          }}
          fullRadius
        />
      </View>
    </View>
  );

  return (
    <Modal
      isVisible={isCoinDropExplainerModalOpen}
      deviceWidth={Dimensions.get('window').width}
      deviceHeight={deviceHeight}
      backdropOpacity={0.9}
      customBackdrop={
        <BlurView intensity={10} style={tw.style(`w-full h-full`)}>
          <View
            style={tw.style(`w-full h-full absolute bg-trueGray opacity-80`)}
          />
        </BlurView>
      }
    >
      <View style={styles.modalContainer}>
        <View style={styles.headerWrapper}>
          <View style={styles.headerTextWrapper}>
            <Spacing top={2.5} bottom={2.5}>
              <Text mediumSmall centered bold>
                How Coin Drops Work
              </Text>
            </Spacing>
            <Spacing bottom={2.5}>
              <Text xsmallPlus2 centered>
                Tap coins until time runs out.
              </Text>
            </Spacing>
            <Spacing bottom={2.5}>
              <Text xsmallPlus2 centered override={{ lineHeight: '150%' }}>
                Every coin you tap is{' '}
                <Text primary xsmallPlus2 underlined>
                  real money
                </Text>
                {'\n'}
                donated directly to this charity by{'\n'}
                <Text xsmallPlus2 bold>
                  {brand?.name || 'a company'}
                </Text>
              </Text>
            </Spacing>
          </View>
        </View>
        {modalContent}
      </View>
    </Modal>
  );
};

export default CoinDropExplainerModal;

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    width: '100%',
    maxWidth: deviceWidth,
    alignSelf: 'center',
    backgroundColor: COLORS.purple,
    opacity: 0.9,
  },
  modalContainer: {
    backgroundColor: COLORS.white,
    alignSelf: 'center',
    width: '100%',
    maxWidth: deviceWidth * 0.8,
    overflow: 'hidden',
    borderRadius: 25,
    position: 'relative',
    alignItems: 'center',
    maxHeight: '65%',
    shadowColor: 'black',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowRadius: 24,
    shadowOpacity: 0.25,
  },
  headerWrapper: {
    position: 'relative',
    width: '80%',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#D9D9D9',
  },
  headerTextWrapper: {
    width: '100%',
    alignSelf: 'center',
    paddingHorizontal: 10,
    lineHeight: '150%',
  },
  modalContent: {
    flex: 1,
    width: '100%',
    paddingHorizontal: 10,
  },
  contentContainer: {
    paddingHorizontal: 10,
    justifyContent: 'center',
  },
  imageContainer: {
    width: '100%',
    height: 140,
    resizeMode: 'contain',
  },
});
