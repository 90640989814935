import React from 'react';

import { Image } from 'expo-image';
import { View, Pressable } from 'react-native';

import { addCloudinaryTransformation } from '@/utils';
import tw from '@tw';

type RoundedBorderedImageProps = {
  color: string;
  // If you want the border to be the `color` then use `outer`, if you want the color to be inside of image, use `inner`
  type: 'inner' | 'outer';
  size: number;
  borderOffset?: number;
  borderWidth?: number;
  className?: string;
  image?: string;
  onPress?: () => void;
  noShadow?: boolean;
  noDefault?: boolean;
  onLoadEnd?: () => void;
};

const RoundedBorderedImage: React.FC<RoundedBorderedImageProps> = ({
  color,
  image,
  type,
  size, // in units fo pixels
  borderWidth = 2, // in units of 4 * pixels
  borderOffset = 8, // in units of pixels
  className = '',
  onPress,
  noShadow,
  onLoadEnd,
}) => {
  const innerBorderOffset = size - borderOffset + 3;

  return (
    <Pressable style={tw`${className}`} onPress={onPress}>
      <View
        style={tw.style(
          `mx-auto h-${size + borderOffset}px w-${
            size + borderOffset
          }px rounded-full border-${borderWidth} items-center justify-center`,
          !noShadow && 'bottomSheetShadow',
          {
            borderColor: type === 'outer' ? color : 'transparent',
            backgroundColor: type === 'outer' ? 'white' : 'tranparent',
          },
        )}
      >
        <Image
          style={tw.style(`h-${size}px w-${size}px rounded-full mx-auto`)}
          source={{
            uri: addCloudinaryTransformation(image, 'c_scale,w_100,dpr_2'),
          }}
          onLoadEnd={() => onLoadEnd && onLoadEnd()}
        />
        {type === 'inner' && (
          <View
            style={tw.style(
              `w-[${innerBorderOffset}px] h-[${innerBorderOffset}px] rounded-full absolute border-${borderWidth}`,
              { backgroundColor: 'transparent', borderColor: color },
              {
                top: '50%',
                left: '50%',
                transform: [
                  { translateX: (-1 * innerBorderOffset) / 2 },
                  { translateY: (-1 * innerBorderOffset) / 2 },
                ],
              },
            )}
          />
        )}
      </View>
    </Pressable>
  );
};

export default RoundedBorderedImage;
