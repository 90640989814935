import React, { useEffect } from 'react';

import { LoadingIndicator, useSessionTokenContext } from '@/components/web';
import useTiktok from '@/hooks/useTiktok';
import useWebHooks from '@/hooks/useWebHooks';

import { WebWrapper } from './components';

const TiktokRedirect = () => {
  const { fetchTiktokUser } = useTiktok();
  const { setSessionToken, setUserId } = useSessionTokenContext();
  const { navigate, query } = useWebHooks();
  const code = query.get('code');

  useEffect(() => {
    if (code) {
      fetchTiktokUser({
        variables: {
          code,
        },
        onCompleted: (data) => {
          if (data?.connectToTiktok?.success) {
            setUserId(data?.connectToTiktok?.user.id);
            setSessionToken(data?.connectToTiktok?.token);
            navigate('/home');
          }
        },
      });
    } else {
      navigate('/home');
    }
  }, []);

  return (
    <WebWrapper>
      <LoadingIndicator />
    </WebWrapper>
  );
};

export default TiktokRedirect;
