import { gql } from '@apollo/client';

export const FETCH_USER_BALANCE = gql`
  query FetchUserBalance($userId: ID!) {
    user(id: $userId) {
      id
      totalBalance
    }
  }
`;
