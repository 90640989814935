import React, { useState, useEffect } from 'react';

import {
  StyleSheet,
  View,
  Animated,
  ViewStyle,
  TouchableOpacity,
  Platform,
  TouchableWithoutFeedback,
  Image,
  Dimensions,
} from 'react-native';
import Modal from 'react-native-modal/dist/modal';

import coinDropImage from '@/assets/modal-images/coindropmodal.png';
import Spacing from '@/components/Spacing';
import StashrunDropIcon from '@/components/StashrunDropIcon';
import Text from '@/components/Text';
import { usePendingCoinDrops } from '@/hooks/useCoinDrops';
import { COLORS } from '@/utils';

type Props = {
  dark?: boolean;
  alignSelf?: ViewStyle['alignSelf'];
  noNavigation?: boolean;
  transparent?: boolean;
  levelUp?: boolean;
  coinIncrease?: boolean;
  setCoinIncrease?: React.Dispatch<React.SetStateAction<boolean>>;
};

const deviceWidth = Dimensions.get('window').width;
const deviceHeight = Dimensions.get('window').height;

export default function CoinDropBalance({
  dark,
  alignSelf = 'flex-end',
  transparent,
  levelUp,
  coinIncrease,
  setCoinIncrease,
}: Props) {
  const { pendingCoinDrops, refetch } = usePendingCoinDrops();
  const [balanceIconAnimatedValue] = useState(new Animated.Value(0));
  const [pendingCoins, setPendingCoins] = React.useState(0);
  const [coinDropModalExplainer, setCoinDropModalExplainer] =
    React.useState(false);

  const animateBalanceIncrease = async () => {
    Animated.sequence([
      Animated.timing(balanceIconAnimatedValue, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }),
      Animated.delay(100),
      Animated.timing(balanceIconAnimatedValue, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start(() => {
      if (setCoinIncrease) {
        setCoinIncrease(false);
      }
    });
  };

  const redirect = () => {
    setCoinDropModalExplainer(true);
    // if (!noNavigation) {
    //   navigation.navigate('Discover');
    // }
    // below code is giving tons of error in topbar
    // navigation.dispatch(
    //   CommonActions.reset({
    //     index: 0,
    //     routes: [
    //       {
    //         name: 'Home',
    //         state: {
    //           index: 0,
    //           routes: [
    //             {
    //               name: 'TabView',
    //               state: { index: 0, routes: [{ name: 'Discover' }] },
    //             },
    //           ],
    //         },
    //       },
    //     ],
    //   }),
    // );
  };

  useEffect(() => {
    setPendingCoins(pendingCoinDrops);
    if (coinIncrease) {
      refetch();
      animateBalanceIncrease();
    }
  }, [pendingCoins, pendingCoinDrops, coinIncrease]);

  useEffect(() => {
    if (pendingCoins > 0) {
      animateBalanceIncrease();
    }
  }, [pendingCoins]);

  // coin drop explainer modal
  const coinDropModal = () => {
    return (
      <Modal
        isVisible={coinDropModalExplainer}
        deviceWidth={deviceWidth as any}
        deviceHeight={deviceHeight}
        backdropOpacity={0.9}
        customBackdrop={
          <TouchableWithoutFeedback
            onPress={() => setCoinDropModalExplainer(false)}
          >
            <View style={styles.modalBackground} />
          </TouchableWithoutFeedback>
        }
        onSwipeComplete={() => setCoinDropModalExplainer(false)}
        swipeDirection={['down']}
        propagateSwipe
        style={{ justifyContent: 'flex-end', margin: 0 }}
      >
        <View style={styles.modalContainer}>
          <View
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: -1,
              width: '50%',
              alignItems: 'center',
            }}
          >
            <Image
              style={{
                width: '100%',
                height: '100%',
                aspectRatio: 355 / 346,
                borderTopRightRadius: 45,
              }}
              source={coinDropImage}
            />
          </View>
          <Spacing bottom={4}>
            <View style={styles.modalNotch} />
          </Spacing>
          <View
            style={{
              width: '100%',
            }}
          >
            <Spacing left={4}>
              <Text bold mediumLarge override={{ maxWidth: '50%' }}>
                Coin drops make our
              </Text>
              <Text bold mediumLarge override={{ maxWidth: '70%' }}>
                world go round
              </Text>
            </Spacing>
          </View>
          <Spacing horizontal={4} top={2}>
            <Text mediumSmallPlus>
              You don’t need money to support charities on Stashrun. Instead you
              take action using Coin Drops– mini games from brands who sponsor
              the donations. Here’s how:
            </Text>
            <Spacing top={2}>
              <View style={{ flexDirection: 'row' }}>
                <Text
                  medium
                  bold
                  color={COLORS.purple}
                  override={{
                    textDecorationLine: 'underline',
                    paddingRight: 8,
                  }}
                >
                  Catch drops
                </Text>
                <Text medium>✊</Text>
              </View>
            </Spacing>
            <Spacing top={1}>
              <Text mediumSmallPlus>
                Open Stashrun daily to get a free drop. One’s not enough? Switch
                on notifications and we’ll alert you when another drop is
                happening. Be on the Drops Page in time to catch it!
              </Text>
            </Spacing>
            <Spacing top={2}>
              <View style={{ flexDirection: 'row' }}>
                <Text
                  medium
                  bold
                  color={COLORS.purple}
                  override={{
                    textDecorationLine: 'underline',
                    paddingRight: 8,
                  }}
                >
                  Get drops from friends
                </Text>
                <Text medium>🤝</Text>
              </View>
            </Spacing>
            <Spacing top={1}>
              <Text mediumSmallPlus>
                Each time you Take Action and stash for a campaign, a friend can
                too. Check your inbox to redeem drops your friends have sent
                you.
              </Text>
            </Spacing>
            <Spacing top={2}>
              <View style={{ flexDirection: 'row' }}>
                <Text
                  medium
                  bold
                  color={COLORS.purple}
                  override={{
                    textDecorationLine: 'underline',
                    paddingRight: 8,
                  }}
                >
                  Level up
                </Text>
                <Text medium>🙌️</Text>
              </View>
            </Spacing>
            <Spacing top={1}>
              <Text mediumSmallPlus>
                When friends upvote your posts, your impact score goes up too.
                You’ll get free drops for each new level you unlock.
              </Text>
            </Spacing>
          </Spacing>
        </View>
      </Modal>
    );
  };

  return (
    <View style={{ alignSelf }}>
      <TouchableOpacity
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        onPress={() => redirect()}
      >
        <View
          style={[
            styles.stashBalanceContainer,
            dark ? styles.darkBackground : null,
            transparent ? styles.transparentBackground : null,
          ]}
        >
          <View style={styles.textOffset}>
            <Text
              override={{ color: dark ? COLORS.white : COLORS.purple }}
              medium
              bold
            >
              {pendingCoinDrops}
            </Text>
          </View>
        </View>
        <Animated.View
          style={{
            ...styles.stashCoinIconContainer,
            transform: [
              {
                scale: coinIncrease
                  ? balanceIconAnimatedValue.interpolate({
                      inputRange: [0, 0.5, 1],
                      outputRange: [1, 1.3, 1],
                    })
                  : balanceIconAnimatedValue.interpolate({
                      inputRange: [0, 0.25, 0.65, 1],
                      outputRange: [1, 1.05, 1.1, 1.05],
                    }),
              },
            ],
          }}
        >
          {levelUp ? (
            <StashrunDropIcon levelUp size={32} />
          ) : (
            <StashrunDropIcon purple size={32} />
          )}
        </Animated.View>
      </TouchableOpacity>
      {coinDropModal()}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    alignSelf: 'flex-end',
  },
  stashBalanceContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    top: 1,
  },
  darkBackground: {
    backgroundColor: COLORS.muted,
  },
  transparentBackground: {
    backgroundColor: 'transparent',
  },
  stashCoinIconContainer: {
    // position: 'absolute',
    // left: Platform.OS === 'android' ? 0 : -15,
    paddingRight: Platform.OS === 'android' ? 0 : 10,
  },
  textOffset: {
    marginTop: 2,
    marginHorizontal: 4,
  },
  modalContainer: {
    height: '90%',
    width: '100%',
    backgroundColor: COLORS.white,
    borderTopLeftRadius: 45,
    borderTopRightRadius: 45,
    shadowColor: COLORS.muted,
    shadowRadius: 4,
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: -8 },
    paddingTop: 15,
    alignItems: 'center',
  },
  modalBackground: {
    flex: 1,
    backgroundColor: COLORS.purple,
    opacity: 0.9,
    zIndex: -1,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  modalNotch: {
    borderTopWidth: 5,
    borderRadius: 25,
    width: 140,
    borderColor: '#E9E7EB',
    zIndex: 40,
  },
});
