import { gql } from '@apollo/client';

export const UPDATE_STASHLINK = gql`
  mutation UpdateStashlink(
    $stashlinkId: String!
    $thankYouMessage: String
    $thankYouMessageVideo: Upload
  ) {
    updateStashlink(
      input: {
        stashlinkId: $stashlinkId
        thankYouMessage: $thankYouMessage
        thankYouMessageVideo: $thankYouMessageVideo
      }
    ) {
      success
      error
      stashlink {
        url
        thankYouMessage
        thankYouMessageVideo
      }
    }
  }
`;
