import React from 'react';

import { View, Text, Pressable, ActivityIndicator } from 'react-native';
import Animated, {
  FadeInLeft,
  FadeInRight,
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from 'react-native-reanimated';

import tw from '@tw';

type ButtonProps = {
  title?: string;
  titleStyle?: string | Record<string, any>;
  style?: string | Record<string, any>;
  containerStyle?: string | Record<string, any>;
  fullRadius?: boolean;
  loading?: boolean;
  reverse?: boolean;
  disabled?: boolean;
  size?: 'normal' | 'small' | 'tiny';
  type?: '' | 'playAgain' | 'getApp' | 'share' | 'withBorder';
  onPress?: () => void;
  customRender?: React.ReactNode;
};

const PrimaryButton = ({
  title,
  titleStyle,
  size = 'normal',
  style,
  containerStyle,
  fullRadius,
  loading,
  reverse,
  disabled,
  type = '',
  onPress = () => false,
  customRender,
}: ButtonProps) => {
  const pressed = useSharedValue(1);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: withTiming(pressed.value, {
            duration: 250,
            easing: Easing.out(Easing.circle),
          }),
        },
      ],
    } as any;
  });

  return (
    <Pressable
      disabled={disabled || loading}
      onPress={onPress}
      style={tw.style(
        'max-w-[300px]',
        {
          'w-8/10 self-center':
            type === 'share' || type === 'playAgain' || type === 'getApp',
        },
        style,
      )}
      onPressIn={() => {
        pressed.value = 1.05;
      }}
      onPressOut={() => {
        pressed.value = 1;
      }}
    >
      <Animated.View
        style={[
          animatedStyle,
          tw.style(
            'flex w-full items-center justify-center px-3 bg-secondary flex-row',
            {
              'h-11 rounded-default': size === 'normal',
              'h-10 rounded-lg': size === 'small',
              'h-8 rounded': size === 'tiny',
              'flex-row-reverse': Boolean(reverse),
              'rounded-full': Boolean(fullRadius),
              'opacity-80': Boolean(disabled),
              'bg-white border border-transparentborder50':
                type === 'playAgain',
              'bg-black': type === 'getApp',
              'bg-white border': type === 'withBorder',
            },
            containerStyle,
          ),
        ]}
      >
        {loading && (
          <Animated.View
            entering={FadeInRight.duration(350)}
            exiting={FadeInLeft.duration(250)}
          >
            <ActivityIndicator size="small" color={'#fff'} />
          </Animated.View>
        )}
        {title && loading ? <View style={tw`w-2`} /> : null}
        {title && (
          <Text
            style={tw.style(
              'text-white',
              {
                'text-base font-black mt-1px': size === 'normal',
                'text-xs font-black mt-2px': size === 'small',
                'text-xs font-black mt-1px': size === 'tiny',
                'text-black text-base': type === 'playAgain',
                'text-black': type === 'withBorder',
                'text-base': type === 'getApp',
              },
              titleStyle,
            )}
          >
            {title}
          </Text>
        )}
        {customRender}
      </Animated.View>
    </Pressable>
  );
};

export { PrimaryButton };
