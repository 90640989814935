import { gql } from '@apollo/client';

export const GET_STASHLINK = gql`
  query stashlinkByUserAndCampaign(
    $campaignId: ID
    $campaignSlug: String
    $userId: ID!
  ) {
    stashlinkByUserAndCampaign(
      userId: $userId
      campaignId: $campaignId
      campaignSlug: $campaignSlug
    ) {
      thankYouMessage
      thankYouMessageVideo
      url
      id
      user {
        id
        fullName
        profilePhoto
        haloColor
      }
    }
  }
`;
