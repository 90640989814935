import { gql } from '@apollo/client';

export const FETCH_USERS_CAUSES = gql`
  query FetchUsersCauses($userId: ID) {
    causes(userId: $userId) {
      id
      name
      description
      imageUrl
      coinsReceived
      dollarsReceived
      numCampaigns
      numSupporters
      numCharities
      numComments
      moderators {
        id
        username
        fullName
        profilePhoto
      }
    }
  }
`;
