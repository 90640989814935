import { gql } from '@apollo/client';

export const RESOLVE_FLAG = gql`
  mutation ResolveFlag($flagId: String!, $notificationId: String!) {
    resolveFlag(input: { flagId: $flagId, notificationId: $notificationId }) {
      success
      error
    }
  }
`;
