import React from 'react';

import { View } from 'react-native';

type FlexContainerProps = {
  children: NonNullable<React.ReactNode>;
  row?: boolean;
  justifyContent?: 'center' | 'space-between' | 'flex-end' | 'space-around';
  alignItems?: 'center' | 'flex-start';
  width?: number | string;
};

export default function FlexContainer(props: FlexContainerProps) {
  const { alignItems, children, row, justifyContent, width } = props;

  return (
    <View
      style={{
        alignItems,
        flexDirection: row ? 'row' : 'column',
        justifyContent,
        width: width || 'auto',
      }}
    >
      {children}
    </View>
  );
}

type FlexItemProps = {
  children: NonNullable<React.ReactNode>;
  flex?: number;
  justifyContent?: 'center' | 'space-between' | 'flex-end';
  alignItems?: 'center' | 'flex-end' | 'flex-start';
  alignSelf?: 'center' | 'flex-end' | 'flex-start';
};

export function FlexItem(props: FlexItemProps) {
  const {
    flex = 1,
    justifyContent = 'center',
    alignItems,
    alignSelf,
    children,
  } = props;

  return (
    <View style={{ flex, justifyContent, alignItems, alignSelf }}>
      {children}
    </View>
  );
}
