import { gql } from '@apollo/client';

export const CHECK_PHONE_VERIFICATION = gql`
  mutation ($verificationSid: String!, $code: String!) {
    checkVerification(
      input: { verificationSid: $verificationSid, code: $code }
    ) {
      success
      error
    }
  }
`;
