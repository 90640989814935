import React from 'react';

import { Image } from 'expo-image';
import { LinearGradient } from 'expo-linear-gradient';
import { View, Pressable } from 'react-native';

import { Text } from '@/components/web';
import useWebHooks from '@/hooks/useWebHooks';
import { Cause } from '@/types';
import { COLORS, addCloudinaryTransformation, numberWithCommas } from '@/utils';
import tw from '@tw';

const CauseCard = ({
  imageUrl,
  dollarsReceived = 0,
  name = '',
  index,
}: Cause) => {
  const { navigate } = useWebHooks();

  if (index === 0) {
    return (
      <Pressable
        style={tw`w-full aspect-video relative border flex items-center justify-center border-white`}
        onPress={() => {
          navigate(`/create-stash/${name}`);
        }}
      >
        <View
          style={tw`bg-black absolute z-20 rounded-sm px-2 justify-center items-center h-[26px]`}
        >
          <Text bold mediumSmallPlus color={COLORS.white} numberOfLines={1}>
            #{name}
          </Text>
        </View>
        <Image
          source={{
            uri: addCloudinaryTransformation(imageUrl, 'c_scale,h_500'),
          }}
          style={tw`w-full h-full absolute z-0`}
        />
        <LinearGradient
          colors={['#000', 'transparent']}
          start={{ x: 0.5, y: 1.0 }}
          end={{ x: 0.5, y: 0.0 }}
          style={tw`w-full h-1/2 absolute z-10 left-0 bottom-0`}
        />
        <Text
          bold
          mediumSmall
          color={COLORS.white}
          textShadow
          override={tw`absolute bottom-2.5 z-20`}
        >
          ${numberWithCommas(Math.round(Number(dollarsReceived)))} donated
        </Text>
      </Pressable>
    );
  }
  return (
    <Pressable
      style={tw`w-1/2 aspect-square relative border flex items-center justify-center border-white`}
      onPress={() => {
        navigate(`/create-stash/${name}`);
      }}
    >
      <View
        style={tw`bg-black absolute z-20 rounded-sm px-2 justify-center items-center h-[26px]`}
      >
        <Text bold mediumSmallPlus color={COLORS.white} numberOfLines={1}>
          #{name}
        </Text>
      </View>
      <Image
        source={{
          uri: addCloudinaryTransformation(imageUrl, 'c_scale,h_500'),
        }}
        style={tw`w-full h-full absolute z-0`}
      />
      <View
        style={tw`bg-neutral-800 bg-opacity-20 absolute h-full w-full z-10`}
      />
    </Pressable>
  );
};

export { CauseCard };
