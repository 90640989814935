import React, { useEffect, useState } from 'react';

import { View } from 'react-native';

import {
  LoadingIndicator,
  Text,
  useSessionTokenContext,
} from '@/components/web';
import { eventProps } from '@/constants/ANALYTICS_CONST';
import { useCauses } from '@/hooks/useCauses';
import useUser from '@/hooks/useUser';
import useWebHooks from '@/hooks/useWebHooks';
import { Cause } from '@/types';
import { errorModal } from '@/utils';
import { getFirstTimeCause, getPasscodeDone } from '@/utils/webStorage';
import tw from '@tw';
import { CauseCard, WebWrapper } from '@web/components';
import { SuggestionBox } from '@web/components/SuggestionBox';

const Causes = () => {
  const [causes, setCauses] = useState<Cause[]>();
  const [loading, setLoading] = useState(false);
  const [artificialLoading, setArtificialLoading] = useState(true);
  const {
    fetchCauses,
    causes: allCauses,
    causesLoading: allCausesLoading,
  } = useCauses();
  const { fetchUserCauses, userCause, userCauseLoading } = useUser();
  const { navigate } = useWebHooks();
  const passcodeDone = getPasscodeDone();
  const { userId } = useSessionTokenContext();
  const firstTimeCause = getFirstTimeCause();

  useEffect(() => {
    // handles if user have passcode or not
    if (passcodeDone) {
      fetchCauses({ variables: { stashlinkEnabled: true } });
    } else if (userId) {
      fetchUserCauses({ variables: { userId } });
    } else {
      errorModal(
        'You need to play a Stashlink to fund a charity, before you can make your own. Here’s one to get started.',
        'Tap to Play',
        () => {
          navigate(
            '/users/4b8fa503-b070-4251-a678-b94660e283c8/plant-trees-to-reforest-and-replenish-our-landscape',
          );
        },
      );
    }
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setArtificialLoading(false);
    }, 400);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (userCauseLoading || allCausesLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [userCauseLoading, allCausesLoading]);

  useEffect(() => {
    if (allCauses) {
      setCauses(allCauses);
    }
    if (userCause && !passcodeDone) {
      setCauses(userCause);
    }
  }, [allCauses, loading, userCause]);

  // check if user have causes or first time user
  useEffect(() => {
    if (causes?.length === 0 && !userCauseLoading && !allCausesLoading) {
      if (firstTimeCause) {
        navigate(`/create-stash/${firstTimeCause}`, {
          replace: true,
        });
      } else {
        errorModal(
          'You need to play a Stashlink to fund a charity, before you can make your own. Here’s one to get started.',
          'Tap to Play',
          () => {
            navigate(
              '/users/4b8fa503-b070-4251-a678-b94660e283c8/plant-trees-to-reforest-and-replenish-our-landscape',
            );
          },
        );
      }
    }
    if (causes?.length === 1 && !userCauseLoading && !allCausesLoading) {
      navigate(`/create-stash/${causes?.[0]?.name}`, {
        replace: true,
      });
    }
  }, [causes]);

  if (artificialLoading) {
    return <LoadingIndicator />;
  }

  return (
    <WebWrapper
      withBack
      analytics={{ [eventProps.page]: 'Create a Stashlink' }}
    >
      <View style={tw`flex flex-col px-8 py-2 items-center`}>
        <Text mediumSmallPlus2 bold centered>
          Create and share links to fund charities
        </Text>
      </View>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <View style={tw`flex flex-row flex-wrap`}>
            {causes?.map((item, index) => (
              <CauseCard key={item.id} {...item} index={index} />
            ))}
          </View>
          <SuggestionBox title="Suggest a Cause" />
        </>
      )}
    </WebWrapper>
  );
};

export default Causes;
