import { gql } from '@apollo/client';

export const SHARE_STASHLINK = gql`
  mutation shareStashlink(
    $userId: String!
    $campaignId: String!
    $secondaryReferrerUserId: String
  ) {
    shareStashlink(
      input: {
        userId: $userId
        campaignId: $campaignId
        secondaryReferrerUserId: $secondaryReferrerUserId
      }
    ) {
      success
      error
    }
  }
`;
