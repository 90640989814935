import { gql } from '@apollo/client';

export const FETCH_USER_FEATURED_URL = gql`
  query FetchUser($userId: ID!) {
    user(id: $userId) {
      featuredUrl
      featuredUrlLinkPreviewJson
    }
  }
`;
