export const debounce = (func: (arg: any) => void, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (args: any) => {
    const later = () => {
      clearTimeout(timeout);
      func(args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
