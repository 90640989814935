import React, { useEffect, useState } from 'react';

import { TextInput, View } from 'react-native';
import { Reoverlay } from 'reoverlay';

import images from '@/assets/images';
import { PrimaryButton, SlideUpModal, Text } from '@/components/web';
import { COLORS, trackEvent } from '@/utils';
import scroll from '@/utils/scroll';
import { setPasscodeDone } from '@/utils/webStorage';
import tw from '@tw';

const PasscodeModal = () => {
  const [passcode, setPasscode] = useState('');
  const [wrongPasscode, setWrongPasscode] = useState(false);

  const onSubmit = async () => {
    if (passcode) {
      onSubmitPasscode(passcode);
    }
  };

  const onSubmitPasscode = (passcode: string) => {
    if (passcode === '7SR5') {
      trackEvent('passcodeSubmitted');
      setPasscodeDone();
      Reoverlay.hideModal();
      window.location.reload();
    } else {
      setWrongPasscode(true);
      trackEvent('passcodeWrong');
    }
  };
  useEffect(() => {
    scroll.lock();
    return () => {
      scroll.enable();
    };
  }, []);

  return (
    <SlideUpModal
      maxHeight={280}
      preventClose
      haloColor={COLORS.purple}
      userImage={images.stashrun}
      keepBackground
    >
      <View style={tw`px-10 items-center w-full mt-6`}>
        <View style={tw`w-full mt-24px px-16px z-100`}>
          <Text mediumSmallPlus bold centered override={tw`mb-8px`}>
            Get Early Access
          </Text>
          <Text xsmallPlus2 centered override={tw`mb-8px`}>
            Stashrun is in private BETA. Enter your secret code below to support
            more causes and charities.
          </Text>

          <TextInput
            autoCorrect={false}
            placeholder="Secret Code"
            placeholderTextColor={COLORS.darkLightGray}
            value={passcode}
            onChangeText={(text) => setPasscode(text.toLocaleUpperCase())}
            keyboardType="email-address"
            autoCapitalize="none"
            secureTextEntry
            style={tw`rounded-10px h-44px bg-white border-2 border-black px-12px text-base mt-2`}
          />
          {wrongPasscode && (
            <Text small bold override={tw`text-red mt-2`}>
              Invalid passcode
            </Text>
          )}
          <PrimaryButton
            style="w-full self-center flex mt-4"
            fullRadius
            onPress={onSubmit}
            title="Continue"
          />
        </View>
      </View>
    </SlideUpModal>
  );
};

export default PasscodeModal;
