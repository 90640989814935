import { ExpoMixpanelAnalytics } from '../webMixPanel';

// Using a placeholder token here so that it doesn't log to panel during development
const MIXPANEL_PROJECT_TOKEN = __DEV__
  ? 'b15e92d187e3c9011151dd248555507d'
  : '08a9b1277e0cbd82ce9b76080a774ed9';

/*
Reference: https://mixpanel.com/help/reference/http


analytics.identify("13793");

analytics.register({ email: "bob@bob.com" }); // super props sent on every request and persisted in AsyncStorage

analytics.track("Signed Up", { "Referred By": "Friend" });

analytics.people_set({ "$first_name": "Joe", "$last_name": "Doe", "$email": "joe.doe@example.com", "$created": "2013-04-01T13:20:00", "$phone": "4805551212", "Address": "1313 Mockingbird Lane", "Birthday": "1948-01-01" });

analytics.people_set_once({ "First login date": "2013-04-01T13:20:00" });

analytics.people_unset([ "Days Overdue" ]);

analytics.people_increment({ "Coins Gathered": 12 });

analytics.people_append({ "Power Ups": "Bubble Lead" });

analytics.people_union({ "Items purchased": ["socks", "shirts"] });

analytics.people_delete_user();

analytics.reset();
*/

export const analytics = new ExpoMixpanelAnalytics(MIXPANEL_PROJECT_TOKEN);
