import { useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';

import { FETCH_CAUSE, FETCH_CAUSES } from '@/queries';
import { Cause } from '@/types';

export const useCauses = () => {
  const [fetchCauses, { data: causesData, loading: causesLoading }] =
    useLazyQuery<{
      causes: Cause[];
    }>(FETCH_CAUSES);

  const causes = useMemo(() => {
    if (!causesData?.causes) return [];
    const causes = [...(causesData?.causes || [])] as Cause[];
    return causes;
  }, [!!causesData]);

  const [fetchCause, { data: causeData, loading: causeLoading }] =
    useLazyQuery<{
      cause: Cause;
    }>(FETCH_CAUSE);

  const singleCause = useMemo(() => {
    if (!causeData?.cause) return [];
    const causes = [causeData?.cause] as Cause[];
    return causes;
  }, [!!causeData]);

  return {
    fetchCauses,
    causes,
    causesLoading,
    fetchCause,
    singleCause,
    causeLoading,
  };
};
