import { gql } from '@apollo/client';

export const FETCH_USERS_BRANDS = gql`
  query FetchUsersBrands($userId: ID) {
    brands(userId: $userId) {
      id
      name
      imageUrl
      username
      description
      amountGiven
      brandColor
    }
  }
`;
