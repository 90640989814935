export const eventProps = {
  link: 'link',
  referralCode: 'referralCode',
  numberOfFriends: 'Number of Friends',
  type: 'type',
  campaignTitle: 'Campaign Title',
  campaignName: 'Campaign Name',
  onboarding: 'Onboarding',
  causeName: 'Cause Name',
  brand: 'Brand Name',
  error: 'Error',
  status: 'Status',
  title: 'Title',
  navigationProps: 'navigationProps',
  navigationPath: 'navigationPath',
  videoUrl: 'videoUrl',
  entityId: 'entityId',
  index: 'index',
  nonProfit: 'Non-profit Name',
  resourceId: 'resourceId',
  url: 'url',
  secondsSpent: 'seconds spent',
  referrerUserId: 'referrerUserId',
  page: 'page',
  coinDropId: 'coinDropId',
  coinsCollected: 'coinsCollected',
  campaignId: 'campaignId',
  entity: 'Entity',
  userProfile: 'User profile',
  charity: 'Charity name',
  balance: 'Balance',
  stashrunUserId: 'Stashrun userId',
  anonymousUserId: 'Anonymous userId',
  playedAgainCount: 'Played again clicked',
  silverCoinsCollected: 'Silver coin',
  goldCoinsCollected: 'Gold coin',
  redCoinsCollected: 'Red coin',
  purpleCoinsCollected: 'Purple coin',
};

export const eventNames = {
  campaignProfileVisited: 'Campaign Profile',
  campaignCompletedButtonClicked: 'Completed campaign button clicked',
  fundButtonClicked: 'Fund button clicked',
  earlyAccessButtonClicked: 'Early Access button clicked',
  campaignCardClicked: 'Campaign card clicked',
  brandModalViewed: 'Brand modal view',
  brandMatchContinueButtonClicked: 'Brand match continue button clicked',
  findedSponsor: 'Brand finded',
  coinDropExplainModalViewed: 'Coin drop explainer modal view',
  coinDropExplainModalComplete: 'Coin drop explainer modal completed',
  coinDropExplainModalExit: 'Coin drop explainer modal exited',
  coinDropComplete: 'Coin Drop Completed',
  coinDropStart: 'Coin drop started',
  coinDropError: 'Coin drop error',
  coinDropGameViewed: 'Coin game',
  spinnerComplete: 'Spin wheel Completed',
  spinnerStart: 'Spin wheel started',
  spinnerError: 'Spin wheel error',
  spinnerGameViewed: 'Spin wheel',
  chooseGameViewed: 'Choose game view',
  chooseGameClicked: 'Choose game clicked',
  whatStashrunButtonClicked: 'What’s Stashrun button clicked',
  appUpgradeForce: 'Forced app upgrade',
  friendDownloadAppSms: 'Friend invited download app in SMS',
  contentCopied: 'Content copied',
  contentShareSms: 'Content shared to SMS',
  contentShareInstagram: 'Content shared to Instagram',
  contentShareWhatsapp: 'Content shared to Whatsapp',
  contentShareFB: 'Content shared to Facebook',
  contentShareSnapchat: 'Content shared to Snapchat',
  contentShareTwitter: 'Content shared to Twitter',
  contentShareReddit: 'Content shared to Reddit',
  thankYouContentCopied: 'Content copied - Thank you modal',
  thankYouContentShareSms: 'Content shared to SMS - Thank you modal',
  thankYouContentShareInstagram:
    'Content shared to Instagram - Thank you modal',
  thankYouContentShareWhatsapp: 'Content shared to Whatsapp - Thank you modal',
  thankYouContentShareFB: 'Content shared to Facebook Thank you modal',
  thankYouContentShareSnapchat: 'Content shared to Snapchat - Thank you modal',
  thankYouContentShareTwitter: 'Content shared to Twitter - Thank you modal ',
  thankYouContentShareReddit: 'Content shared to Reddit - Thank you modal',
  hubContentCopied: 'Content copied - hub',
  hubContentShareSms: 'Content shared to SMS - hub',
  hubContentShareInstagram: 'Content shared to Instagram - hub',
  hubContentShareWhatsapp: 'Content shared to Whatsapp - hub',
  hubContentShareFB: 'Content shared to Facebook hub',
  hubContentShareSnapchat: 'Content shared to Snapchat - hub',
  hubContentShareTwitter: 'Content shared to Twitter - hub',
  hubContentShareReddit: 'Content shared to Reddit - hub',
  disclosuresButtonClicked: 'Disclosures clicked',
  referralLinked: 'Referral Code Linked',
  contactsPermissionGranted: 'Contacts Permission Granted',
  contactsPermissionDenied: 'Contacts Permission Denied',
  contactsPermissionRequest: 'Contacts Permission Requested',
  contactsPermissionReset: 'Reset contacts permissions',
  contactsPermissionDismiss: 'Dismiss contacts reset alert',
  shopButtonClicked: 'Shop button clicked',
  hiringButtonClicked: 'We’re hiring button clicked',
  campaignClicked: 'Campaign Clicked',
  causeLearnMoreButtonClicked: 'Cause clicked via Learn More',
  causeClicked: 'Cause clicked',
  learnAboutSponsor: 'Learn more about the sponsor',
  existingUserOnboarding: 'Existing user going through onboarding',
  dismissTrackingNotification: 'Dismiss Allow Tracking notification',
  allowTrackingNotification: 'Go to Settings for Allow Tracking',
  fbLoginRequest: 'Facebook login requested',
  fbLoginSuccess: 'Facebook login successful',
  fbLoginSkipp: 'Facebook login skipped',
  fbLoginError: 'Facebook login error',
  trackingPermissionDenied: 'Tracking permission denied',
  takeActionButton: 'Take action button pressed',
  videoPlayed: 'Video played',
  videoStopped: 'Video stopped',
  impactCardSwiped: 'Impact card swiped',
  followButtonClicked: 'Follow button clicked',
  unfollowButtonClicked: 'Unfollow button clicked',
  supportersButtonClicked: 'Supporters clicked',
  deleteAccount: 'Delete Account',
  logout: 'Logout',
  webLogout: 'Web Logout',
  logoutCancel: 'Cancel Logout',
  brandProfileViewed: 'Brand profile view',
  claimDailyDrop: 'Claim Daily Drop',
  linkPreviewButtonClicked: 'Link Preview clicked during creation',
  conversationPostDeleted: 'Conversation Post deleted',
  commentDeleted: 'Comment deleted',
  commentFlagged: 'Comment flagged',
  conversationPostFlagged: 'Conversation Post flagged',
  notificationUrlClicked: 'Notification URL clicked',
  supportButtonClicked: 'Support button clicked',
  contentShare: 'Content Shared',
  forgotPasswordError: 'Forgot password flow error',
  forgotPasswordInitiated: 'Forgot password flow initiated',
  inviteFriendSkipped: 'Invite a Friend Skipped',
  emailLoginError: 'Login with email error',
  emailLoginSuccess: 'Login with email success',
  resetPasswordError: 'Reset password error',
  resetPasswordSuccess: 'Reset password success',
  inviteButtonClicked: 'Invite button clicked',
  inviteButtonCanceled: 'Invite button canceled',
  inviteButtonConfirmed: 'Invite button confirmed',
  referralSent: 'Referral sent',
  emailSignupError: 'Sign up with email error',
  emailSignupSuccess: 'Sign up with email success',
  levelUpDropClaimed: 'Claim level_up Drop',
  userWebsiteButtonClicked: 'User website clicked',
  deleteAccountInitial: 'Initialized account deletion',
  deleteAccountFirstConfirm: 'First confirmation account deletion',
  deleteAccountFinalConfirm: 'Final confirmation account deletion',
  socialMediaButtonClicked: 'Social Media button clicked',
  volunteerButtonClicked: 'Volunteer button clicked',
  websiteButtonClicked: 'Website button clicked',
  congratsCopyWebsiteLink: 'Congrats copy website link',
  userProfileViewed: 'User Profile view',
  notFoundViewed: '404 Page view',
  campaignProfileViewed: 'Campaign Profile view',
  profileModalClicked: 'Profile modal clicked',
  profileImpactClicked: 'Impact score explainer clicked',
  profileFeaturedUrlClicked: 'Profile featured url clicked',
  charityModalClicked: 'Charity modal clicked',
  playAgainClicked: 'Play again clicked',
  chooseOwnCauseClicked: 'Choose my own cause button clicked',
  shareModalClicked: 'Share modal clicked',
  thankYouShareModalClicked: 'Share modal clicked - Thank you modal',
  hubShareModalClicked: 'Share modal clicked - hub',
  getStashlinkClicked: 'Get stashlink clicked',
  tryItClicked: 'Try it clicked',
  profileButtonClicked: 'Profile button clicked',
  loginIntroViewed: 'Login intro view',
  loginButtonClicked: 'Login button clicked',
  continueWithEmailButtonClicked: 'Continue with Email button clicked',
  editProfileButtonClicked: 'Edit profile button clicked',
  updateProfileclicked: 'Update profile clicked',
  matchDonationSkipped: 'Match this donation skipped',
  matchDonationClicked: 'Match this donation button clicked',
  matchDonationContinued: 'Match this donation modal closed',
  passcodeSubmitted: 'Passcode has been submitted by an user',
  passcodeWrong: 'Wrong Passcode submitted',
  passcodeSkipped: 'Passcode has been skipped an redirected to waitlist',
  thankYouMessageViewed: 'Thank you message views',
  thankYouVideoUploaded: 'A thankyou video has been uploaded',
  thankYouVideoDeleted: 'A thankyou video has been deleted',
  thankYouMessageAdded: 'A thankyou message has been added',
  thankYouVideoPreview: 'A thankyou video has been previewed',
  leaderboardViewed: 'Leaderboard viewed',
  headToHeadBattleStashlinkClicked: 'H2H Stashlink clicked',
  headToHeadBattleViewed: 'H2H viewed',
};
