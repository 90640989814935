import React, { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Image } from 'expo-image';
import { View } from 'react-native';
import Carousel from 'react-native-snap-carousel';
import {
  TabView,
  TabBar,
  SceneRendererProps,
  NavigationState,
  Route,
} from 'react-native-tab-view';

import Check from '@/assets/icons/check.png';
import Padlock from '@/assets/icons/padlock.png';
import { LoadingIndicator, Text } from '@/components/web';
import { PROFILE_EXPLAINER_CONST } from '@/constants/PROFILE_EXPLAINER_CONST';
import useWebHooks from '@/hooks/useWebHooks';
import levels from '@/pages/Leaderboard/levels';
import { FETCH_USER } from '@/queries';
import { User } from '@/types';
import {
  COLORS,
  profileHaloColorPicker,
  numberWithCommas,
  maxWidth,
} from '@/utils';
import tw from '@tw';
import { CircularProgress, WebWrapper } from '@web/components';

const ProfileExplainerModal = () => {
  const [index, setIndex] = useState(0);
  const { navigate, users } = useWebHooks();
  const [routes] = useState([
    { key: 'first', title: 'Halo' },
    { key: 'second', title: 'Impact Score' },
  ]);

  const { loading: userLoading, data: userData } = useQuery<{
    user: User;
  }>(FETCH_USER, {
    variables: { userId: users },
  });
  const { user } = userData || {};

  const haloProperties = profileHaloColorPicker(
    user?.impactLevel || 0,
    user?.percentToNextLevel || 0,
    true,
  );

  if (!user) {
    navigate('/');
  }

  const CarouselItems = ({
    item,
    index,
  }: {
    item: { colorName: string };
    index: number;
  }) => (
    <View key={index} style={tw`mb-10px`}>
      <View
        style={tw`h-40px w-40px rounded-full justify-center items-center bg-contain bg-${item.colorName}`}
      >
        {index <= haloProperties.level ? (
          <Image
            style={tw.style(`h-20px w-20px`, { resizeMode: 'contain' })}
            source={Check}
          />
        ) : (
          <Image
            style={tw.style(`h-20px w-20px`, { resizeMode: 'contain' })}
            source={Padlock}
          />
        )}
      </View>
    </View>
  );

  const YourHalo = () => {
    return (
      <View style={tw`h-full w-full pt-50px`}>
        <View style={tw`self-center`}>
          <CircularProgress
            size={200}
            strokeWidth={16}
            percentage={haloProperties.haloPercentage * 100}
            color={haloProperties.haloColor}
            profilePhoto={user?.profilePhoto}
          />
        </View>
        <Text medium centered bold override={tw`my-12px`}>
          {numberWithCommas(Math.round(Number(user?.impactScore || 0)))} ⭐
        </Text>
        <View style={tw`justify-center items-center`}>
          <Carousel
            layout="default"
            data={levels}
            renderItem={CarouselItems}
            sliderWidth={maxWidth(360)}
            itemWidth={52}
            firstItem={haloProperties.level}
            scrollEnabled={false}
          />

          {haloProperties.level === 14 ? (
            <Text mediumSmall bold>
              Max impact influencer
            </Text>
          ) : (
            <>
              <Text mediumSmall bold>
                +
                {numberWithCommas(
                  Math.round(Number(user?.remainingPointsToNextLevel || 0)),
                )}{' '}
                points
              </Text>
              <Text mediumSmall color={COLORS.secondaryBlack} italicized>
                to next Halo
              </Text>
            </>
          )}

          <View style={tw`px-34px pb-20px mt-24px`}>
            <Text mediumSmall>
              Halos are your Karate Black Belt for
              <Text mediumSmall bold>
                {' '}
                Impact
              </Text>
              - each color represents a level for others to see.
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const ImpactScore = () => (
    <View style={tw`h-full w-full`}>
      <Text mediumLarge centered override={tw`mt-48px`}>
        ✋ x 🔥 x 💸
      </Text>
      <Text centered medium bold>
        Impact Score
      </Text>
      <View style={tw`mx-32px pb-20px`}>
        {PROFILE_EXPLAINER_CONST.map((_item, i) => (
          <View key={i}>
            <Text medium primary bold override={tw`mt-16px`}>
              {_item.title}
            </Text>
            <Text
              mediumSmall
              color={COLORS.secondaryBlack}
              italicized
              override={tw`mt-5px`}
            >
              {_item.subTitle}
            </Text>
            <Text mediumSmall override={tw`mt-5px`}>
              {_item.content}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );

  // prevents react native snap carousel from re-rendering and causing flickers.
  const noStateyourHalo = useMemo(
    () => <YourHalo />,
    [user?.profilePhoto || ''],
  );

  const renderScene = ({ route }: { route: any }) => {
    switch (route.key) {
      case 'first':
        return noStateyourHalo;
      case 'second':
        return <ImpactScore />;
      default:
        return null;
    }
  };

  const renderTabBar = (
    props: SceneRendererProps & {
      navigationState: NavigationState<Route>;
    },
  ) => {
    return (
      <TabBar
        {...props}
        bounces
        tabStyle={tw`h-45px z-1`}
        pressColor={COLORS.white}
        indicatorStyle={tw`p-15px rounded-25px bg-primary`}
        renderLabel={({ focused, route }) => (
          <Text
            mediumSmall
            bold
            color={focused ? COLORS.white : COLORS.secondaryBlack}
          >
            {route.title || ''}
          </Text>
        )}
        style={tw`absolute rounded-25px w-37/50 h-30px flex justify-center self-center bg-lightGray`}
      />
    );
  };

  if (userLoading) return <LoadingIndicator />;

  return (
    <WebWrapper>
      <View style={tw`pt-20px`}>
        <TabView
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={setIndex}
          renderTabBar={renderTabBar}
          style={tw`bg-white rounded-35px`}
        />
      </View>
    </WebWrapper>
  );
};

export default ProfileExplainerModal;
