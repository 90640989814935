import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import * as d3Shape from 'd3-shape';
import { Audio } from 'expo-av';
import { View, Image } from 'react-native';
import Animated, {
  Easing,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import Svg, { G, Path, Text as SText } from 'react-native-svg';

import Images from '@/assets/images';
import { maxWidth } from '@/utils';
import tw from '@tw';

type PropsT = {
  rewards: string[];
  innerRadius?: number;
  spaceBetween?: number;
  duration?: number;
  winner?: number;
  textColor?: string;
  colors?: string[];
  onWheelStop?: (winner: number) => void;
};
type PathT = {
  path: string | null;
  color: string;
  value: string;
  centroid: [number, number];
};

const width = maxWidth(500);

const WheelSpin = forwardRef(function WheelSpin(props: PropsT, ref) {
  const {
    rewards,
    innerRadius = 50,
    duration = 2800,
    textColor = '#000',
    colors = ['#fff'],
    spaceBetween = 0.02,
    onWheelStop = () => false,
    winner,
  } = props;
  const wheelRef = useRef({
    agle: 0,
    circle: 360,
    gameScreen: width * 0.8,
    numberOfSegments: rewards.length,
    get winner() {
      return winner || Math.floor(Math.random() * this.numberOfSegments);
    },
    get angleBySegment() {
      return this.circle / this.numberOfSegments;
    },
    get angleOffset() {
      return this.angleBySegment / 2;
    },
  }).current;
  const enabled = useRef(true);
  const [paths, setPaths] = useState<PathT[]>([]);
  const [sound, setSound] = useState<Audio.Sound | null>(null);
  const animatedAngle = useSharedValue(0);

  useEffect(() => {
    const data = Array.from({
      length: wheelRef.numberOfSegments,
    }).fill(1) as number[];
    const arcs = d3Shape.pie()(data) as unknown as d3Shape.DefaultArcObject[];
    const wheelPaths: PathT[] = arcs.map((arc, index) => {
      const instance = d3Shape
        .arc()
        .padAngle(spaceBetween)
        .outerRadius(wheelRef.gameScreen / 2)
        .innerRadius(innerRadius);
      return {
        path: instance(arc),
        color: colors[index % colors.length],
        value: rewards[index],
        centroid: instance.centroid(arc),
      };
    });
    setPaths(wheelPaths);
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    Audio.Sound.createAsync(require('../assets/wheel-spin.mp3')).then(
      ({ sound }) => {
        setSound(sound);
      },
    );
    return () => {
      sound?.unloadAsync();
    };
  }, []);

  const handleSpin = () => {
    if (!enabled.current) return;
    sound?.stopAsync();
    sound?.playAsync();
    const winnerDeg =
      wheelRef.winner * (wheelRef.circle / wheelRef.numberOfSegments);
    enabled.current = false;
    animatedAngle.value = withTiming(
      winnerDeg + 360 * Math.ceil(duration / 1000),
      {
        duration,
        easing: Easing.elastic(0.7),
      },
      () => {
        if (!enabled.current) {
          const segment = wheelRef.circle / wheelRef.numberOfSegments;
          const winner =
            (animatedAngle.value - 360 * Math.ceil(duration / 1000)) / segment;
          runOnJS(onWheelStop)(Math.floor(winner % wheelRef.numberOfSegments));
        }
      },
    );
  };

  const handleHoldingSpin = () => {
    animatedAngle.value = withTiming(-360, {
      duration: 10000,
      easing: Easing.linear,
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      spin: handleSpin,
      holdSpin: handleHoldingSpin,
    }),
    [sound],
  );

  const wheelAnimation = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotate: `${-1 * animatedAngle.value}deg`,
        },
      ],
    } as any;
  });

  const renderText = (x: number, y: number, value: string, _: number) => {
    return (
      <SText
        x={x}
        y={y - 20}
        fill={textColor}
        textAnchor="middle"
        fontSize={22}
        dy={22}
        fontFamily="Avenir-Heavy"
        fontWeight={500}
      >
        {value}
      </SText>
    );
  };

  return (
    <View style={tw`flex items-center justify-center relative`}>
      <Image
        style={tw`w-16 h-12 absolute -top-3 z-20`}
        source={Images.wheelGameKnob}
        resizeMode="contain"
      />
      <Animated.View
        style={[wheelAnimation, tw`bg-[#d9d9d9] rounded-full  p-1`]}
      >
        <Svg
          width={wheelRef.gameScreen}
          height={wheelRef.gameScreen}
          viewBox={`0 0 ${wheelRef.gameScreen} ${wheelRef.gameScreen}`}
          style={{
            transform: [{ rotate: `-${wheelRef.angleOffset}deg` }],
          }}
        >
          <G y={wheelRef.gameScreen / 2} x={wheelRef.gameScreen / 2}>
            {paths.map((arc, i) => {
              const [x, y] = arc.centroid;
              return (
                <G key={`arc-${i}`}>
                  <Path d={String(arc.path)} strokeWidth={2} fill={arc.color} />
                  <G
                    rotation={
                      (i * wheelRef.circle) / wheelRef.numberOfSegments +
                      wheelRef.angleOffset
                    }
                    originX={x}
                    originY={y}
                  >
                    {renderText(x, y, arc.value.toString(), i)}
                  </G>
                </G>
              );
            })}
          </G>
        </Svg>
      </Animated.View>
    </View>
  );
});

export default WheelSpin;
