import { gql } from '@apollo/client';

export const FETCH_CAUSE = gql`
  query FetchCause($causeId: ID!) {
    cause(id: $causeId) {
      id
      name
      description
      imageUrl
      coinsReceived
      numCampaigns
      numSupporters
      numCharities
      hashTags
      videoUrl
      wikipediaUrl
      charities {
        id
        name
        imageUrl
      }
      tagline
      website
      location
      heroTagline
      heroImageUrl
    }
  }
`;
