import React from 'react';

import { Dimensions, Platform, StyleSheet, Text as RNText } from 'react-native';

import { COLORS } from '@/utils';

const { height } = Dimensions.get('window');

// 428, 926 for iPhone 12 Pro Max
// 375, 812 for iPhone XS

const calculateHeight = () => {
  if (Platform.OS === 'web') {
    return 1;
  }
  if (height > 200) {
    if (height < 700) {
      return height / 700;
    }
    if (height < 812) {
      return height / 812;
    }
    return 1;
  }
  return 1;
};

export const RATIO = calculateHeight();

export type TextProps = {
  children: NonNullable<React.ReactNode>;
  numberOfLines?: number;
  color?: string;
  avenirNext?: boolean;
  centered?: boolean;
  primaryBg?: boolean;
  right?: boolean;
  title?: boolean;
  inverse?: boolean;
  italicized?: boolean;
  underlined?: boolean;
  italicizedNotBold?: boolean;
  primary?: boolean;
  semiboldMinus?: boolean;
  semibold?: boolean;
  semiboldPlus?: boolean;
  bold?: boolean;
  xsmall?: boolean;
  xsmallPlus?: boolean;
  xsmallPlus2?: boolean;
  small?: boolean;
  mediumSmall?: boolean;
  mediumSmallPlus?: boolean;
  mediumSmallPlus2?: boolean;
  medium?: boolean;
  medium2?: boolean;
  mediumLarge?: boolean;
  large?: boolean;
  xlarge?: boolean;
  xxlarge?: boolean;
  xxxlarge?: boolean;
  muted?: boolean;
  lightBlack?: boolean;
  darkGray?: boolean;
  textShadow?: boolean;
  override?: any;
  textProps?: any;
};

export default function Text(props: TextProps) {
  const {
    numberOfLines,
    color,
    avenirNext,
    semiboldMinus,
    semibold,
    semiboldPlus,
    bold,
    centered,
    children,
    title,
    inverse,
    italicized,
    italicizedNotBold,
    underlined,
    primary,
    xsmall,
    xsmallPlus,
    xsmallPlus2,
    small,
    mediumSmall,
    mediumSmallPlus,
    mediumSmallPlus2,
    medium,
    medium2,
    mediumLarge,
    large,
    xlarge,
    xxlarge,
    xxxlarge,
    muted,
    lightBlack,
    textShadow,
    override,
    textProps,
    right,
    darkGray,
    primaryBg,
  } = props;

  return (
    <RNText
      allowFontScaling={false}
      numberOfLines={numberOfLines}
      style={[
        styles.default,
        avenirNext && styles.avenirNext,
        title && styles.title,
        primaryBg && styles.primaryBg,
        semiboldMinus && styles.semiboldMinus,
        semibold && styles.semibold,
        semiboldPlus && styles.semiboldPlus,
        (bold || title) && styles.bold,
        centered && styles.centered,
        right && styles.right,
        italicized && styles.italicized,
        italicizedNotBold && styles.italicizedNotBold,
        underlined && styles.underlined,
        primary && styles.primary,
        inverse && styles.inverse,
        xsmall && styles.xsmall,
        xsmallPlus && styles.xsmallPlus,
        xsmallPlus2 && styles.xsmallPlus2,
        small && styles.small,
        medium2 && styles.medium2,
        mediumSmall && styles.mediumSmall,
        mediumSmallPlus && styles.mediumSmallPlus,
        mediumSmallPlus2 && styles.mediumSmallPlus2,
        medium && styles.medium,
        mediumLarge && styles.mediumLarge,
        large && styles.large,
        xlarge && styles.xlarge,
        xxlarge && styles.xxlarge,
        xxxlarge && styles.xxxlarge,
        muted && styles.muted,
        lightBlack && styles.lightBlack,
        textShadow && styles.textShadow,
        darkGray && styles.darkGray,
        override,
        color && { color: color || COLORS.primaryText },
      ]}
      {...textProps}
    >
      {children}
    </RNText>
  );
}

const styles = StyleSheet.create({
  default: {
    fontFamily: 'Avenir-Regular',
    fontSize: 20 * RATIO,
    lineHeight: 30 * RATIO,
    letterSpacing: Platform.OS === 'web' ? 0 : 0.2,
  },
  avenirNext: {
    fontFamily: 'AvenirNext-Regular',
    fontSize: 20 * RATIO,
    lineHeight: 30 * RATIO,
    letterSpacing: 0.2,
  },
  title: {
    fontSize: 26 * RATIO,
    lineHeight: 35 * RATIO,
  },
  centered: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  italicized: {
    fontFamily: 'Avenir-BlackOblique',
    fontStyle: Platform.OS === 'web' ? 'normal' : 'italic',
  },
  italicizedNotBold: {
    fontFamily: 'Avenir-Oblique',
  },
  underlined: {
    textDecorationLine: 'underline',
  },
  primary: {
    color: COLORS.purple,
  },
  primaryBg: {
    backgroundColor: COLORS.purple,
    paddingHorizontal: 2,
    paddingVertical: 4,
  },
  semiboldMinus: {
    fontWeight: '500',
  },
  semibold: {
    fontWeight: '600',
  },
  semiboldPlus: {
    fontWeight: '700',
  },
  bold: {
    fontFamily: Platform.OS === 'web' ? 'Avenir-Heavy' : 'Avenir-Regular',
    fontWeight: Platform.OS === 'web' ? 'normal' : 'bold',
  },
  inverse: {
    color: COLORS.white,
  },
  xsmall: {
    fontSize: 9 * RATIO,
    lineHeight: 14 * RATIO,
  },
  xsmallPlus: {
    fontSize: 11 * RATIO,
    lineHeight: 14 * RATIO,
  },
  xsmallPlus2: {
    fontSize: 12 * RATIO,
    lineHeight: 14 * RATIO,
  },
  small: {
    fontSize: 14 * RATIO,
    lineHeight: 19 * RATIO,
  },
  mediumSmall: {
    fontSize: 16 * RATIO,
    lineHeight: 22 * RATIO,
  },
  mediumSmallPlus: {
    fontSize: 18 * RATIO,
    lineHeight: 24 * RATIO,
  },
  mediumSmallPlus2: {
    fontSize: 20 * RATIO,
    lineHeight: 31 * RATIO,
  },
  medium: {
    fontSize: 24 * RATIO,
    lineHeight: 36 * RATIO,
  },
  medium2: {
    fontSize: 28 * RATIO,
    lineHeight: 40 * RATIO,
  },
  mediumLarge: {
    fontSize: 35 * RATIO,
    lineHeight: 50 * RATIO,
  },
  large: {
    fontSize: 60 * RATIO,
    lineHeight: 80 * RATIO,
  },
  xlarge: {
    fontSize: 100 * RATIO,
    lineHeight: 120 * RATIO,
  },
  xxlarge: {
    fontSize: 72 * RATIO,
    lineHeight: 80 * RATIO,
  },
  xxxlarge: {
    fontSize: 90 * RATIO,
    lineHeight: 100 * RATIO,
  },
  muted: {
    color: COLORS.muted,
  },
  lightBlack: {
    color: COLORS.lightBlack,
  },
  darkGray: {
    color: COLORS.darkGray,
  },
  textShadow: {
    textShadowOffset: { width: 1, height: 2 },
    textShadowColor: 'rgba(0, 0, 0, 0.8)',
    textShadowRadius: 0,
  },
});
