import React from 'react';

import { StyleSheet, View } from 'react-native';

import { CountdownTimer } from '@/components/CoinGame/CountdownTimer';
import { Spacing, Text } from '@/components/web';
import tw from '@tw';

type GameContextAndCountdownProps = {
  onboardingFinished: boolean;
  displayResults: boolean;
  isCoindrop: boolean;
  totalDurationMs: number;
  setCoinGameFinished: (finished: boolean) => void;
  username?: string;
  causeName?: string;
};

export default function GameContextAndCountdown({
  onboardingFinished,
  displayResults,
  totalDurationMs,
  setCoinGameFinished,
  username,
  isCoindrop,
  causeName,
}: GameContextAndCountdownProps) {
  return (
    <>
      <View style={styles.contextContainer}>
        {!onboardingFinished ? (
          <>
            <Spacing top={6}>
              {isCoindrop ? (
                <Text centered mediumSmallPlus2 bold>
                  Tap to start!
                </Text>
              ) : (
                <Text centered mediumSmallPlus2 bold>
                  'Hold and release to spin!'
                </Text>
              )}

              {isCoindrop && (
                <>
                  <Text centered small>
                    Start stashing 💰 for #{causeName}
                  </Text>
                  <View
                    style={tw`border-b-1 border-zinc-300 w-7/10 pt-4 self-center`}
                  />
                </>
              )}
            </Spacing>
            <Spacing top={1}>
              {!isCoindrop && (
                <Text centered small>
                  Every cent you stash is donated to{' '}
                  <Text centered small bold>
                    #{causeName}
                  </Text>
                </Text>
              )}
            </Spacing>
          </>
        ) : (
          <>
            <Spacing top={8}>
              {onboardingFinished && !displayResults && (
                <CountdownTimer
                  isStarted
                  totalSeconds={totalDurationMs / 1000}
                  onFinish={() => setCoinGameFinished(true)}
                />
              )}
              <Text centered small>
                Keep tapping! Every coin is donated!
              </Text>
            </Spacing>
          </>
        )}
      </View>
      {/* Social Media Handle */}
      {username && (
        <View style={styles.socialHandleContainer}>
          <Text centered xsmallPlus2>
            Sponsored by
          </Text>
          <Text centered mediumSmallPlus bold>
            {username}
          </Text>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  contextContainer: {
    display: 'flex',
    flex: 1,
    zIndex: -100,
    width: '100%',
  },
  countdownTimerContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  socialHandleContainer: {
    position: 'absolute',
    bottom: '2%',
    left: 0,
    right: 0,
  },
  socialHandleText: {
    fontWeight: '600',
    fontFamily: 'Avenir Next',
  },
  boldText: {
    fontWeight: '800',
  },
});
