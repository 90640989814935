import { gql } from '@apollo/client';

export const CREATE_CONVERSATION_COMMENT = gql`
  mutation (
    $message: String
    $conversationId: String!
    $isAnonymous: Boolean
    $imageUrl: Upload
    $repliedToMessageId: String
  ) {
    createConversationComment(
      input: {
        message: $message
        conversationId: $conversationId
        isAnonymous: $isAnonymous
        imageUrl: $imageUrl
        repliedToMessageId: $repliedToMessageId
      }
    ) {
      success
      error
    }
  }
`;
