import React, { memo, useCallback, useEffect, useState } from 'react';

import { View } from 'react-native';
import ConfettiCannon from 'react-native-confetti-cannon';
import { Reoverlay } from 'reoverlay';

import { PrimaryButton, Spacing, Text } from '@/components/web';
import useWebHooks from '@/hooks/useWebHooks';
import { Charity, HeadToHeadBattle, Stashlink, User } from '@/types';
import { COLORS, numberWithCommas, trackEvent } from '@/utils';
import { CONFETTI_COLORS } from '@/utils/constants';
import tw from '@tw';

import LiveCountdown from '../LiveCountdown';
import RoundedBorderedImage from '../RoundedBorderedImage';

type HeadToHeadProps = {
  currentHeadToHeadBattle: HeadToHeadBattle | undefined;
  nextHeadToHeadBattle: HeadToHeadBattle | undefined;
  recentlyFinishedHeadToHeadBattle: HeadToHeadBattle | undefined;
  refetch: () => void;
};

const HeadToHead: React.FC<HeadToHeadProps> = ({
  currentHeadToHeadBattle,
  nextHeadToHeadBattle,
  recentlyFinishedHeadToHeadBattle,
  refetch,
}) => {
  const [confettiDone, setConfettiDone] = useState(false);
  const { navigate } = useWebHooks();
  const inProgress = !!currentHeadToHeadBattle;
  const previousBattle = recentlyFinishedHeadToHeadBattle;
  const nextBattle = nextHeadToHeadBattle;

  const stashlink1 = currentHeadToHeadBattle?.player1Stashlink;
  const stashlink2 = currentHeadToHeadBattle?.player2Stashlink;

  useEffect(() => {
    trackEvent('headToHeadBattleViewed');
  }, []);

  const onPressProfileImage = useCallback(
    (user: User) => () => {
      if (!user) return;
      trackEvent('profileModalClicked', {
        userProfile: user?.id,
      });
      Reoverlay.showModal('ProfileModal', {
        user,
        haloProperties: {
          haloColor: user?.haloColor,
        },
      });
    },
    [],
  );

  const onPressCharityImage = useCallback(
    (charity?: Charity, disclosuresUrl?: string) => () => {
      if (!charity) return;
      trackEvent('charityModalClicked', {
        charity: charity?.name,
      });
      Reoverlay.showModal('BrandModal', {
        charity,
        disclosuresUrl,
      });
    },
    [],
  );

  const PlayerColumn = React.memo(
    ({
      finished,
      isWinner,
      stashlink,
      charity,
      score,
    }: {
      finished?: boolean;
      isWinner?: boolean;
      stashlink?: Stashlink;
      charity?: Charity;
      score: number;
    }) => {
      if (!stashlink) return null;

      return (
        <View style={tw`w-[45%] flex flex-col items-center relative`}>
          {finished && isWinner ? (
            <View style={tw`mb-4`}>
              <Text
                bold
                centered
                override={{
                  fontSize: 24,
                  fontWeight: 'bold',
                  color: '#FFC02E',
                }}
              >
                1st!
              </Text>
            </View>
          ) : null}
          {finished && !isWinner ? (
            <View style={tw`mb-4`}>
              <Text
                bold
                centered
                override={{
                  fontSize: 24,
                  fontWeight: 'bold',
                  color: '#777777',
                }}
              >
                2nd
              </Text>
            </View>
          ) : null}

          <Text
            override={{ color: '#444444', fontSize: 14, fontWeight: '600' }}
          >
            {stashlink?.user?.fullName || ''}
          </Text>
          <View>
            {finished && isWinner ? (
              <View style={tw`absolute -left-3 z-10`}>
                <Text
                  bold
                  centered
                  override={{
                    fontSize: 36,
                    fontWeight: 'bold',
                    color: '#FFC02E',
                    rotate: '-45deg',
                  }}
                >
                  👑
                </Text>
              </View>
            ) : null}
            <RoundedBorderedImage
              image={stashlink?.user?.profilePhoto}
              onPress={onPressProfileImage(stashlink?.user)}
              noShadow
              size={75}
              type="outer"
              borderWidth={4}
              borderOffset={16}
              color={stashlink?.user?.haloColor || 'white'}
            />
          </View>
          {score !== undefined && (
            <View style={tw`mt-2`}>
              <Text
                mediumSmall
                centered
                bold
                override={{
                  color: '#222222',
                  fontSize: 20,
                  fontWeight: '700',
                }}
              >
                ${numberWithCommas(Math.round(score))}
              </Text>
              <Text small centered override={{ color: '#444444' }}>
                Raised
              </Text>
              <View style={tw`my-[0.5]`}>
                <Text
                  centered
                  italicizedNotBold
                  override={{
                    color: '#595959',
                    fontSize: 10,
                    fontWeight: 'normal',
                  }}
                >
                  for
                </Text>
              </View>
              <View style={tw`mt-1 mb-2`}>
                <RoundedBorderedImage
                  size={50}
                  type="outer"
                  color={charity?.charityColor || 'black'}
                  borderWidth={2}
                  borderOffset={8}
                  image={charity?.imageUrl}
                  onPress={onPressCharityImage(
                    charity,
                    stashlink?.campaign?.disclosuresUrl,
                  )}
                  noShadow
                />
                <Spacing top={1}>
                  <Text centered small>
                    {charity?.name || ''}
                  </Text>
                </Spacing>
              </View>
            </View>
          )}
          {!finished ? (
            <PrimaryButton
              style="mt-1"
              containerStyle={tw`px-12`}
              title="Play"
              fullRadius
              size="small"
              onPress={() => {
                trackEvent('headToHeadBattleStashlinkClicked', {
                  campaignId: stashlink?.campaign?.slug,
                });
                navigate(
                  `/users/${stashlink?.user?.id}/${stashlink?.campaign?.slug}`,
                );
              }}
            />
          ) : null}
        </View>
      );
    },
  );

  const HowItWorks = memo(
    ({
      showContact = true,
      nextMatchTime,
    }: {
      showContact: boolean;
      nextMatchTime?: string;
    }) => {
      return (
        <View
          style={tw`rounded-[32px] bg-[#F1F1F1] px-8 py-6 m-auto flex flex-col justify-center items-center`}
        >
          {nextMatchTime ? (
            <View style={tw`mb-6`}>
              <Text
                mediumSmall
                override={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Next match in
              </Text>
              <LiveCountdown
                color={COLORS.red}
                countdownEndDate={new Date(nextMatchTime)}
                canClaim
                onFinish={refetch}
              />
            </View>
          ) : null}

          <View>
            <Text small centered bold>
              How it works
            </Text>
          </View>
          <View style={tw`mt-4`}>
            <Text small centered>
              Help your favorite celebs and influencers win Head to Head
              fundraising challenges.
            </Text>
          </View>
          <View style={tw`mt-6`}>
            <Text small centered>
              Choose a team to play and share for, helping them reach the
              donation goal, or raise the most money before time runs out.
            </Text>
          </View>
          <View style={tw`mt-6`}>
            <Text small centered>
              Let’s see who has the most impactful fanbase!
            </Text>
          </View>
          {showContact ? (
            <View style={tw`mt-6`}>
              <Text small centered>
                Want to see someone in particular? Tell us what celebs or
                influencers you want to see go head to head! 🤺
              </Text>
            </View>
          ) : null}
        </View>
      );
    },
  );

  if (inProgress) {
    const isFinished = currentHeadToHeadBattle?.status === 1;

    return (
      <View
        style={{
          flexDirection: 'column',
          display: 'flex',
          width: '100%',
          position: 'relative',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            height: '100%',
          }}
        >
          <View style={tw`mt-4`}>
            <View
              style={tw`w-full relative py-1 flex flex-row justify-center items-center`}
            >
              <Text
                override={{
                  fontSize: 26,
                  letterSpacing: '-0.8px',
                  fontWeight: 'bold',
                }}
              >
                $
                {numberWithCommas(
                  Math.round(currentHeadToHeadBattle?.goal || 0),
                )}{' '}
                Goal
              </Text>
            </View>
            <View
              style={tw`w-full relative py-1 flex flex-row justify-center items-center`}
            >
              {isFinished ? (
                <Spacing top={0.5} horizontal={0.25}>
                  <Text
                    override={{
                      fontWeight: '700',
                      fontStyle: 'italic',
                      fontSize: 12,
                      lineHeight: 20,
                    }}
                  >
                    Times up!
                  </Text>
                </Spacing>
              ) : (
                <>
                  <Spacing top={0.5} horizontal={0.25}>
                    <Text override={{ fontSize: 14, lineHeight: 20 }}>
                      Ends in
                    </Text>
                  </Spacing>
                  <LiveCountdown
                    color={COLORS.red}
                    countdownEndDate={
                      new Date(currentHeadToHeadBattle?.endTime || '')
                    }
                    canClaim
                    onFinish={refetch}
                  />
                </>
              )}
            </View>
          </View>
          <View style={tw`self-center w-[90%] mt-4`}>
            <View style={tw`w-full relative mb-4`}>
              <View style={tw`w-full flex flex-row justify-between`}>
                <PlayerColumn
                  finished={isFinished}
                  isWinner={
                    currentHeadToHeadBattle?.player1Score >
                    currentHeadToHeadBattle?.player2Score
                  }
                  stashlink={stashlink1}
                  charity={stashlink1?.campaign?.charity}
                  score={currentHeadToHeadBattle?.player1Score}
                />
                <View style={tw`w-[5px] h-[110%] bg-[#F1F1F1]`} />
                <PlayerColumn
                  finished={isFinished}
                  isWinner={
                    currentHeadToHeadBattle?.player2Score >
                    currentHeadToHeadBattle?.player1Score
                  }
                  stashlink={stashlink2}
                  charity={stashlink2?.campaign?.charity}
                  score={currentHeadToHeadBattle?.player2Score}
                />
              </View>
            </View>
            <HowItWorks showContact={false} />
          </View>
          {isFinished && !confettiDone ? (
            <View
              style={tw`-z-50 absolute scale-110 w-full h-full -top-2 left-0 pointer-events-none`}
            >
              <ConfettiCannon
                count={150}
                fadeOut
                fallSpeed={1500}
                colors={CONFETTI_COLORS}
                origin={{
                  x: 0,
                  y: 0,
                }}
                explosionSpeed={1000}
                onAnimationEnd={() => setConfettiDone(true)}
              />
              <ConfettiCannon
                count={150}
                fadeOut
                fallSpeed={1500}
                colors={CONFETTI_COLORS}
                origin={{
                  x: 500,
                  y: 0,
                }}
                explosionSpeed={1000}
              />
            </View>
          ) : null}
        </View>
      </View>
    );
  }

  if (previousBattle) {
    return (
      <View
        style={{
          flexDirection: 'column',
          display: 'flex',
          width: '100%',
          position: 'relative',
          // height: 'calc(100vh - 200px)', // Had to use direct styles because tailwind was not working
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            height: '100%',
          }}
        >
          <View style={tw`mt-4`}>
            <View
              style={tw`w-full relative py-1 flex flex-row justify-center items-center`}
            >
              <Text
                override={{
                  color: '#222222',
                  fontSize: 26,
                  fontWeight: 'bold',
                }}
              >
                ${numberWithCommas(Math.round(previousBattle?.goal || 0))} Goal
              </Text>
            </View>
            <View
              style={tw`w-full relative py-1 flex flex-row justify-center items-center`}
            >
              <Spacing top={0.5} horizontal={0.25}>
                <Text override={{ fontSize: 14, lineHeight: 20 }}>
                  Times up!
                </Text>
              </Spacing>
            </View>
          </View>
          <View style={tw`self-center w-[90%] mt-4`}>
            <View style={tw`w-full relative mb-4`}>
              <View style={tw`w-full flex flex-row justify-between`}>
                <PlayerColumn
                  finished
                  isWinner={
                    previousBattle?.player1Score > previousBattle?.player2Score
                  }
                  stashlink={stashlink1}
                  charity={stashlink1?.campaign?.charity}
                  score={previousBattle?.player1Score}
                />
                <View style={tw`w-[5px] h-[110%] bg-[#F1F1F1]`} />
                <PlayerColumn
                  finished
                  isWinner={
                    previousBattle?.player2Score > previousBattle?.player1Score
                  }
                  stashlink={stashlink2}
                  charity={stashlink2?.campaign?.charity}
                  score={previousBattle?.player2Score}
                />
              </View>
            </View>
            <HowItWorks
              showContact={false}
              nextMatchTime={nextBattle?.startTime}
            />
          </View>
        </View>
      </View>
    );
  }

  return (
    <View
      style={tw`m-auto flex flex-col justify-center items-center mt-[64px] px-8`}
    >
      <View style={tw`mb-[70px]`}>
        <Text override={{ fontSize: 120 }}>🤺</Text>
      </View>
      <HowItWorks showContact nextMatchTime={nextBattle?.startTime} />
    </View>
  );
};

export default HeadToHead;
