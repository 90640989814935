import { gql } from '@apollo/client';

export const MERGE_SKELETON_USER = gql`
  mutation MergeSkeletonUser($userIdentifier: String!, $userId: String!) {
    mergeSkeletonUser(
      input: { userIdentifier: $userIdentifier, userId: $userId }
    ) {
      success
      error
    }
  }
`;
