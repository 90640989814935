import React from 'react';

import { SlideUpModal } from '@/components/web';
import { Campaign } from '@/types';
import WaitlistForm from '@web/CoinGame/WaitlistForm';

type Props = {
  campaign: Campaign;
  isModal?: boolean;
};

const WaitlistFormModal = ({ campaign }: Props) => {
  return (
    <SlideUpModal maxHeight={400}>
      <WaitlistForm campaign={campaign} isModal />
    </SlideUpModal>
  );
};

export default WaitlistFormModal;
