import { gql } from '@apollo/client';

export const FETCH_FEED_POSTS = gql`
  query {
    feedPosts {
      id
      type
      metadata
      creatorType
      creatorId
    }
  }
`;
