import { gql } from '@apollo/client';

export const FOLLOWING_ENTITY = gql`
  query ($entityType: String!, $entityId: String!, $userId: String) {
    followingEntity(
      entityType: $entityType
      entityId: $entityId
      userId: $userId
    ) {
      error
      following
      success
    }
  }
`;
