import { gql } from '@apollo/client';

export const FETCH_LEADERBOARD = gql`
  query FetchLeaderboard($topN: Int!) {
    leaderboard {
      id
      schoolImpactScore {
        school {
          id
          name
          username
          imageUrl
        }
        score
      }
      allTimeUserImpactScore: userImpactScore(
        topN: $topN
        timePeriod: "all-time"
      ) {
        user {
          id
          username
          fullName
          coinsPerDrop
          dollarsEarned
          dollarsRaised
          totalShares
          invitesSent
          isPublicProfile
          profilePhoto
          impactScore
          haloColor
          description
          tiktokUsername
          instagramUsername
          twitterUsername
          youtubeUsername
          linkedinUrl
          facebookUsername
        }
        score
      }
      userImpactScore(topN: $topN, timePeriod: "month") {
        user {
          id
          username
          fullName
          coinsPerDrop
          dollarsEarned
          dollarsRaised
          dollarsRaisedCurrentMonth
          totalShares
          totalSharesCurrentMonth
          invitesSent
          isPublicProfile
          profilePhoto
          impactScore
          impactScoreCurrentMonth
          haloColor
          description
          tiktokUsername
          instagramUsername
          twitterUsername
          youtubeUsername
          linkedinUrl
          facebookUsername
        }
        score
      }
      userCoinsPerDrop(topN: $topN, timePeriod: "month") {
        user {
          id
          username
          isPublicProfile
          profilePhoto
          impactScore
        }
        score
      }
      userDollarsEarned(topN: $topN, timePeriod: "month") {
        user {
          id
          username
          isPublicProfile
          profilePhoto
          impactScore
        }
        score
      }
      userInvitesSent(topN: $topN, timePeriod: "month") {
        user {
          id
          username
          isPublicProfile
          profilePhoto
          impactScore
        }
        score
      }
    }
  }
`;
