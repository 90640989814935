import { gql } from '@apollo/client';

export const FETCH_USER_NOTIFICATIONS = gql`
  query FetchUser($userId: ID!) {
    user(id: $userId) {
      id
      notifications {
        claimedAt
        createdAt
        id
        imageUrl
        message
        numCoinDrops
        readAt
        title
        type
        url
        metadata
        user {
          id
          username
          fullName
        }
      }
    }
  }
`;
