import { gql } from '@apollo/client';

export const FETCH_FEED_SETTINGS = gql`
  query FeedSettings {
    feedSettings {
      error
      success
      feedSettingLabels {
        heading
        content {
          key
          label
          link
          type
          value
          switches {
            key
            label
            type
            value
          }
        }
      }
      switchesValues {
        key
        value
      }
    }
  }
`;

export const UPDATE_FEED_SETTINGS = gql`
  mutation ($input: UpdateFeedSettingsInput!) {
    updateFeedSettings(input: $input) {
      error
      success
      user {
        feedSettingLabels {
          heading
          content {
            key
            label
            link
            type
            value
            switches {
              key
              label
              link
              type
              value
            }
          }
        }
        switchesValues {
          key
          value
        }
      }
    }
  }
`;
