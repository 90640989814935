import { gql } from '@apollo/client';

export const FETCH_IMPACT_DATA = gql`
  query ImpactData($entityType: String!, $entityId: String!) {
    impactData(entityType: $entityType, entityId: $entityId) {
      numSupporters
      numCauses
      numCampaigns
      numBrands
      coinsGiven
      dollarsGiven
      coinsReceived
      id
      entityType

      impactCards {
        type
        complete
        numCampaignSupporters
        color
        textColor
        campaignTitle
        campaignImage
        campaignCauseName
      }
    }
  }
`;
