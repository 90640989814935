import { gql } from '@apollo/client';

export const CREATE_NEW_PHONE_VERIFICATION = gql`
  mutation ($phoneNumber: String!) {
    createNewVerification(input: { phoneNumber: $phoneNumber }) {
      success
      error
      verificationSid
    }
  }
`;
