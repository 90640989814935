import { gql } from '@apollo/client';

export const FETCH_STASHLINK_SUMMARY = gql`
  query (
    $entityType: String
    $entityId: String
    $startTime: String
    $endTime: String
  ) {
    stashlinkHubAnalyticsData(
      entityType: $entityType
      entityId: $entityId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      summaryData {
        name
        value
        percentChange
      }
      startingDate
      endingDate
    }
  }
`;
