import React, { useState, useEffect } from 'react';

import { StyleSheet, View, Image, Animated, Platform } from 'react-native';

import preCoinDropArrow from '@/assets/pre-coin-drop-arrow.png';
import { DraggableCoin } from '@/components/CoinGame/DraggableCoin';
import { getScreenSize, maxWidth } from '@/utils';

type OnboardingAnimationsProps = {
  onStart: () => void;
  onboardingFinished: boolean;
  offsetTop?: number;
};

const PULSE_DELAY_MS = 500;
const PULSE_TIMING_MS = 3000;

export default function OnboardingAnimations({
  onStart,
  onboardingFinished,
  offsetTop = 0,
}: OnboardingAnimationsProps) {
  const [introAnimatedValue] = useState(new Animated.Value(0));
  const [introArrowAnimatedValue] = useState(new Animated.Value(0));
  const [pulseAnimatedValue] = useState(new Animated.Value(0));
  const [animationFinished, setAnimationFinished] = useState<boolean>(false);

  const animateIntroCoin = () => {
    Animated.sequence([
      Animated.timing(introAnimatedValue, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }),
      Animated.timing(introArrowAnimatedValue, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }),
      Animated.loop(
        Animated.sequence([
          Animated.delay(PULSE_DELAY_MS),
          Animated.timing(pulseAnimatedValue, {
            toValue: 1,
            duration: PULSE_TIMING_MS,
            useNativeDriver: true,
          }),
          Animated.delay(PULSE_DELAY_MS),
          Animated.timing(pulseAnimatedValue, {
            toValue: 0,
            duration: PULSE_TIMING_MS,
            useNativeDriver: true,
          }),
        ]),
      ),
    ]).start();
  };

  const { height, width } = getScreenSize();
  const windowWidth = Platform.OS === 'web' ? maxWidth(500) : width;
  const windowHeight = height >= 200 ? height : 600;

  useEffect(() => {
    animateIntroCoin();
    const animationFinishedTimeout = setTimeout(() => {
      setAnimationFinished(true);
    }, 1000);

    return () => {
      clearTimeout(animationFinishedTimeout);
    };
  }, []);

  return (
    <>
      {!onboardingFinished && (
        <View
          style={[
            styles.arrowRoot,
            {
              top: 145 + offsetTop,
            },
          ]}
        >
          <Animated.View
            style={{
              opacity: introArrowAnimatedValue,
            }}
          >
            <Image style={styles.onboardingArrow} source={preCoinDropArrow} />
          </Animated.View>
        </View>
      )}
      <Animated.View
        style={[
          styles.coinRoot,
          animationFinished && styles.coinRoot_clickable,
          {
            transform: [
              {
                translateX: introAnimatedValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [(windowWidth / 2 - 110) * -1, 0],
                }),
              },
              {
                translateY: introAnimatedValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [(300 - windowHeight * 0.62) * -1, 0],
                }),
              },
              {
                scale: pulseAnimatedValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, 1.4],
                }),
              },
            ],
            top: 175 + offsetTop,
          },
        ]}
      >
        <DraggableCoin
          onStart={onStart}
          rotate={35}
          boxStyle={{ top: -25, left: -25, zIndex: -15 }}
        />
      </Animated.View>
    </>
  );
}

const styles = StyleSheet.create({
  arrowRoot: {
    position: 'absolute',
    right: 100,
    zIndex: 1000,
  },
  onboardingArrow: {
    top: -10,
    height: 100,
    width: 100,
    resizeMode: 'contain',
    transform: [{ rotate: '-15deg' }],
  },
  coinRoot: {
    position: 'absolute',
    right: 50,
    zIndex: -10,
  },
  coinRoot_clickable: {
    zIndex: 1000,
  },
});
