import { gql } from '@apollo/client';

export const COLLECT_COINS = gql`
  mutation CollectCoins(
    $coinDropId: ID!
    $coinsCollected: Int!
    $silverCoinsCollected: Int!
    $goldCoinsCollected: Int!
    $redCoinsCollected: Int!
    $purpleCoinsCollected: Int!
    $secret: String
    $referrerUserId: String
    $secondaryReferrerUserId: String
  ) {
    collectCoins(
      input: {
        coinDropId: $coinDropId
        coinsCollected: $coinsCollected
        silverCoinsCollected: $silverCoinsCollected
        goldCoinsCollected: $goldCoinsCollected
        redCoinsCollected: $redCoinsCollected
        purpleCoinsCollected: $purpleCoinsCollected
        secret: $secret
        referrerUserId: $referrerUserId
        secondaryReferrerUserId: $secondaryReferrerUserId
      }
    ) {
      success
      error
      coinDrop {
        id
        coinsCollected
        silverCoinsCollected
        goldCoinsCollected
        redCoinsCollected
        purpleCoinsCollected
        collectedValueUsd
        maxCoins
        secret
        brand {
          name
          imageUrl
        }
        campaign {
          title
        }
        user {
          id
          email
        }
      }
    }
  }
`;
