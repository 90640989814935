import { gql } from '@apollo/client';

export const CREATE_STASHLINK = gql`
  mutation CreateStashlink(
    $userId: String!
    $campaignId: String!
    $thankYouMessage: String
  ) {
    createStashlink(
      input: {
        userId: $userId
        campaignId: $campaignId
        thankYouMessage: $thankYouMessage
      }
    ) {
      success
      error
      stashlink {
        id
        url
        thankYouMessage
      }
    }
  }
`;
