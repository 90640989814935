import React, { createContext, useContext, useState, useEffect } from 'react';

import { trackEvent, analytics } from '@/utils';
import SecureStore from '@/utils/secureStore';

const defaultState = {
  loading: true,
  sessionToken: undefined,
  userId: undefined,
  referralCode: undefined,
  // tslint:disable: no-empty
  setSessionToken() {},
  setUserId() {},
  setReferralCode() {},
  // tslint:enable: no-empty
};

type SessionTokenContextType = {
  loading: boolean;
  sessionToken: undefined | null | string;
  userId?: undefined | null | string;
  referralCode?: undefined | null | string;
  setSessionToken: (id: string | null) => void;
  setUserId: (userId: string | null) => void;
  setReferralCode: (referralCode: string | null) => void;
};

const SessionTokenContext =
  createContext<SessionTokenContextType>(defaultState);

export const SESSION_TOKEN_KEY = 'SESSION_TOKEN';
export const USER_ID_KEY = 'USER_ID';
export const REFERRAL_CODE_KEY = 'REFERRAL_CODE';

type SessionTokenProviderProps = {
  children: NonNullable<React.ReactNode>;
};

export function useSessionTokenContext() {
  return useContext(SessionTokenContext);
}

export function SessionTokenProvider({ children }: SessionTokenProviderProps) {
  const [sessionToken, setSessionTokenRaw] =
    useState<SessionTokenContextType['sessionToken']>(undefined);
  const [userId, setUserIdRaw] =
    useState<SessionTokenContextType['userId']>(undefined);
  const [referralCode, setReferralCodeRaw] =
    useState<SessionTokenContextType['referralCode']>(undefined);

  async function setSessionToken(id: string | null) {
    setSessionTokenRaw(id);

    if (id) {
      await SecureStore.setItemAsync(SESSION_TOKEN_KEY, id);
    } else {
      await SecureStore.deleteItemAsync(SESSION_TOKEN_KEY);
    }
  }

  async function setUserId(rawUserId: string | null) {
    setUserIdRaw(rawUserId);

    if (rawUserId) {
      await SecureStore.setItemAsync(USER_ID_KEY, rawUserId);
      analytics.identify(rawUserId);
    } else {
      await SecureStore.deleteItemAsync(USER_ID_KEY);
    }
  }

  async function setReferralCode(rawReferralCode: string | null) {
    setReferralCodeRaw(rawReferralCode);

    if (rawReferralCode) {
      await SecureStore.setItemAsync(REFERRAL_CODE_KEY, rawReferralCode);
      trackEvent('referralLinked', {
        referralCode: rawReferralCode,
      });
    } else {
      await SecureStore.deleteItemAsync(REFERRAL_CODE_KEY);
    }
  }

  useEffect(() => {
    /*
      Authentication Flow:

      Check if session token exists, if it does, try to hit auth check endpoint on server
        Endpoint should:
          1. Verify token is still valid (i.e. it should be on the User object in DB).
            1a. If it is not associated with a user, this means that we invalidated it ourselves (InvalidSessionTokenError)
          2. Extract out user id from the decrypted token
      If no session token exists, they are in logged out state

      If they want to log out, we will just clear the session token on client and on server.
    */
    // tslint:disable: no-floating-promises
    const refetchVariables = async () => {
      await SecureStore.getItemAsync(SESSION_TOKEN_KEY)
        .then((sessionTokenValue) => {
          setSessionTokenRaw(sessionTokenValue || null);
        })
        // catch here in case of edge case where user sessionToken is broken
        .catch(() => SecureStore.deleteItemAsync(SESSION_TOKEN_KEY));
      await SecureStore.getItemAsync(USER_ID_KEY)
        .then((userIdValue) => {
          setUserIdRaw(userIdValue || undefined);
        })
        .catch(() => SecureStore.deleteItemAsync(USER_ID_KEY));
      await SecureStore.getItemAsync(REFERRAL_CODE_KEY)
        .then((referralCodeValue) => {
          setReferralCodeRaw(referralCodeValue || undefined);
        })
        .catch(() => SecureStore.deleteItemAsync(REFERRAL_CODE_KEY));
    };
    refetchVariables();
    // tslint:enable: no-floating-promises
  }, []);

  return (
    <SessionTokenContext.Provider
      value={{
        loading: sessionToken === undefined,
        sessionToken,
        userId,
        referralCode,
        setSessionToken,
        setUserId,
        setReferralCode,
      }}
    >
      {children}
    </SessionTokenContext.Provider>
  );
}
