import { gql } from '@apollo/client';

export const CLAIM_COIN_DROP_GIVEAWAY = gql`
  mutation ClaimCoinDropGiveaway($coinDropGiveawayId: String!) {
    claimCoinDropGiveaway(input: { coinDropGiveawayId: $coinDropGiveawayId }) {
      success
      error
    }
  }
`;
