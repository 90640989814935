import { gql } from '@apollo/client';

export const CREATE_WAITLIST = gql`
  mutation CreateWaitlist($email: String!, $accessAllowed: Boolean!) {
    createWaitlist(input: { email: $email, accessAllowed: $accessAllowed }) {
      success
      error
    }
  }
`;
