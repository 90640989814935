import { gql } from '@apollo/client';

export const FETCH_CAMPAIGNS = gql`
  query FetchCampaigns($causeName: String, $stashlinkEnabled: Boolean) {
    campaignsSearch(
      causeName: $causeName
      stashlinkEnabled: $stashlinkEnabled
    ) {
      id
      title
      description
      location
      imageUrl
      currentAmount
      goalAmount
      endTime
      studentOrgs
      canSupport
      cause {
        id
        name
        description
        imageUrl
      }
      charity {
        id
        name
        imageUrl
        videoUrl
        heroImageUrl
        studentOrgs
        charityColor
      }
    }
  }
`;
