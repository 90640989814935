/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Feather } from '@expo/vector-icons';
import { Image } from 'expo-image';
import _ from 'lodash';
import { View, ScrollView, Pressable } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import { NavigateFunction } from 'react-router-dom';
import { Reoverlay } from 'reoverlay';

import images from '@/assets/images';
import {
  LoadingIndicator,
  PrimaryButton,
  SlideUpModal,
  SocialMediaButton,
  Text,
  useSessionTokenContext,
} from '@/components/web';
import levels from '@/pages/Leaderboard/levels';
import { FETCH_USERS_CAMPAIGNS, FETCH_USER_FEATURED_URL } from '@/queries';
import { Campaign, User } from '@/types';
import {
  COLORS,
  abbreviateNumber,
  maxWidth,
  numberWithCommas,
  removeBeginningHttps,
  removeUnwantedString,
  scroll,
  trackEvent,
} from '@/utils';
import tw from '@tw';

type ProfileModalProps = {
  user: User;
  haloProperties: {
    haloColor: string;
    level: number;
    haloPercentage: number;
  };
  editable?: boolean;
  navigate?: NavigateFunction;
  showImpactPage?: boolean;
};

const ProfileModal = ({
  user,
  haloProperties,
  editable,
  navigate,
  showImpactPage,
}: ProfileModalProps) => {
  const [showHaloExplainer, setHaloExplainer] = useState(false);
  const { userId: currentUserId } = useSessionTokenContext();

  useEffect(() => {
    if (showImpactPage) {
      setHaloExplainer(true);
    }
  }, []);

  const {
    id,
    fullName,
    impactScore,
    description,
    profilePhoto,
    impactLevel,
    haloColor,
    tiktokUsername,
    instagramUsername,
    twitterUsername,
    youtubeUsername,
    linkedinUrl,
    facebookUsername,
  } = user;
  const { loading: featureUrlLoading, data: featuredUrlData } = useQuery<{
    user: User;
  }>(FETCH_USER_FEATURED_URL, {
    variables: { userId: id },
  });

  const { loading: campaignsLoading, data: campaignsData } = useQuery<{
    campaignsSearch: Campaign[];
  }>(FETCH_USERS_CAMPAIGNS, {
    variables: { userId: id },
    fetchPolicy: 'network-only',
  });

  const campaignCauses = _.uniqBy(
    campaignsData?.campaignsSearch?.map((c) => c.cause!) || [],
    'name',
  );

  const displayedCause =
    user.pinnedCauses?.length > 0 ? user.pinnedCauses : campaignCauses;

  const urlSite = removeBeginningHttps(
    featuredUrlData?.user?.featuredUrl || '',
    false,
  );
  const urlPreviewData = featuredUrlData?.user?.featuredUrlLinkPreviewJson
    ? JSON.parse(featuredUrlData?.user?.featuredUrlLinkPreviewJson)
    : '';

  const CarouselItems = ({
    item,
    index,
  }: {
    item: { colorName: string };
    index: number;
  }) => (
    <View key={index} style={tw`w-55px justify-center items-center`}>
      <View
        style={tw`h-40px w-40px rounded-full justify-center items-center bg-contain bg-${item.colorName}`}
      >
        {index <= haloProperties.level ? (
          <Image
            style={tw.style(`h-22px w-22px`, { resizeMode: 'contain' })}
            source={
              index === haloProperties.level
                ? images.icons.star
                : images.icons.checkMark
            }
          />
        ) : (
          <Image
            style={tw.style(`h-22px w-22px`, { resizeMode: 'contain' })}
            source={images.icons.padLock}
          />
        )}
      </View>
    </View>
  );

  useEffect(() => {
    scroll.lock();
    return () => {
      scroll.enable();
    };
  }, []);

  if (featureUrlLoading || campaignsLoading) {
    return (
      <View
        style={tw`fixed top-0 left-0 w-full h-full items-center justify-center`}
      >
        <LoadingIndicator />
      </View>
    );
  }

  const haloExplainer = () => (
    <View>
      <Carousel
        enabled={false}
        data={levels}
        renderItem={CarouselItems}
        defaultIndex={impactLevel}
        width={55}
        height={40}
        style={{
          height: 40,
          width: maxWidth(500),
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 10,
        }}
        loop={false}
      />

      {haloProperties.level === 14 ? (
        <Text mediumSmall bold centered>
          Max
        </Text>
      ) : (
        <>
          <Text mediumSmall bold centered>
            +
            {numberWithCommas(
              Math.round(Number(user?.remainingPointsToNextLevel || 0)),
            )}{' '}
            points
          </Text>
          <Text mediumSmall color={COLORS.secondaryBlack} italicized centered>
            to next Halo
          </Text>
        </>
      )}
      <View style={tw`border-b-1 border-dividerGray w-4/5 self-center my-2`} />
      <View style={tw`px-10 mt-4`}>
        <Text mediumSmall centered>
          Your halo is like your Karate Black Belt for
          <Text mediumSmall bold>
            {' '}
            Impact.
          </Text>
        </Text>
        <Text mediumSmall centered override={tw`mt-2`}>
          Increase your impact score by{' '}
          <Text mediumSmall bold>
            sharing links
          </Text>{' '}
          and{' '}
          <Text mediumSmall bold>
            stashing donations.
          </Text>
        </Text>
        <Text mediumSmall centered override={tw`mt-2`}>
          You get credit when your friends stash and share your links too.
        </Text>
      </View>
    </View>
  );

  const profileContent = () => (
    <View style={tw`w-full px-4`}>
      <View style={tw`px-16px pb-4`}>
        <Text mediumSmall centered>
          {description || ''}
        </Text>
      </View>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={tw`max-h-50px`}
        style={tw`self-center`}
      >
        {tiktokUsername ? (
          <SocialMediaButton
            noir
            type="tiktok"
            username={removeUnwantedString(tiktokUsername)}
          />
        ) : null}
        {instagramUsername ? (
          <SocialMediaButton
            noir
            type="instagram"
            username={removeUnwantedString(instagramUsername)}
          />
        ) : null}
        {twitterUsername ? (
          <SocialMediaButton
            noir
            type="twitter"
            username={removeUnwantedString(twitterUsername)}
          />
        ) : null}
        {youtubeUsername ? (
          <SocialMediaButton
            noir
            type="youtube"
            username={removeUnwantedString(youtubeUsername)}
          />
        ) : null}
        {linkedinUrl ? (
          <SocialMediaButton noir type="linkedin" url={linkedinUrl} />
        ) : null}
        {facebookUsername ? (
          <SocialMediaButton
            noir
            type="facebook"
            username={removeUnwantedString(facebookUsername)}
          />
        ) : null}
      </ScrollView>
      {displayedCause?.length > 0 && (
        <Text small bold>
          Supporting
        </Text>
      )}
      <ScrollView
        horizontal
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          marginTop: 8,
        }}
      >
        {[
          ...displayedCause,
          ...(displayedCause.length < 4 ? [{}, {}, {}, {}] : []),
        ]?.map((c, i) =>
          c.name ? (
            <View
              key={i}
              style={tw`bg-tertiaryPurple px-8px py-4px mr-10px rounded-full`}
            >
              <Text primary small bold>
                {`#${c.name}`}
              </Text>
            </View>
          ) : (
            <View
              key={i}
              style={tw`bg-transparent min-w-[200px] px-8px py-4px mr-10px rounded-full`}
            >
              <Text
                numberOfLines={1}
                override={{ color: 'transparent', outerWidth: '100px' }}
              >
                {'                '}
              </Text>
            </View>
          ),
        )}
      </ScrollView>
      {urlSite ? (
        <View style={tw`mt-24px pb-20px`}>
          <Text small bold>
            Shared by {fullName}
          </Text>
          <Pressable
            style={tw`mt-8px w-full border-gray border-2 h-64px rounded-10px flex-row`}
            onPress={() => {
              trackEvent('profileFeaturedUrlClicked', {
                url: urlSite,
              });
              window.open(`//${urlSite}`, '_blank', 'noreferrer');
            }}
          >
            <Image
              style={tw` w-[60px] rounded-md overflow-hidden m-1.5 mr-0`}
              source={{
                uri:
                  urlPreviewData?.images?.[0]?.src || urlPreviewData?.favicon,
              }}
              defaultSource={images.brokenImage}
            />
            <View style={tw`flex-col p-2 flex-1`}>
              <Text xsmallPlus2 numberOfLines={1} override={tw`pb-2px`}>
                {urlPreviewData.title}
              </Text>
              <Text xsmallPlus numberOfLines={2}>
                {urlPreviewData.description.startsWith(
                  'Oh no! It looks like JavaScript is not enabled',
                )
                  ? 'No preview available'
                  : urlPreviewData.description}
              </Text>
            </View>
          </Pressable>
        </View>
      ) : null}
      {editable ? (
        <>
          <PrimaryButton
            title="Logout"
            style={tw`self-center w-3/4 mb-4`}
            containerStyle={tw`bg-white`}
            titleStyle={tw`text-black`}
            onPress={() => {
              Reoverlay.showModal('LogoutModal', { navigate });
            }}
          />
        </>
      ) : null}
    </View>
  );

  return (
    <SlideUpModal
      maxHeight={500}
      removeButton={showHaloExplainer}
      haloColor={haloColor}
      userImage={profilePhoto}
      keepBackground
      bigHead
    >
      {showHaloExplainer && (
        <Pressable
          style={tw`absolute left-4 top-4 z-200`}
          onPress={() => {
            if (currentUserId === user.id) {
              setHaloExplainer(false);
            }
          }}
        >
          <Image source={images.fillBack} style={tw`w-7 h-7 mr-2.5`} />
        </Pressable>
      )}
      <View
        style={tw`pt-16 bg-white relative rounded-t-[35px] overflow-hidden`}
      >
        <Text mediumSmallPlus2 numberOfLines={1} bold centered>
          {fullName || ''}
        </Text>

        <Pressable
          style={tw.style(
            `my-2 items-center justify-center h-[30px] w-full flex-row`,
            {
              backgroundColor: haloProperties?.haloColor,
            },
          )}
          onPress={() => {
            if (currentUserId === user.id) {
              trackEvent('profileImpactClicked', {
                userProfile: id,
              });
              setHaloExplainer(!showHaloExplainer);
            }
          }}
        >
          <Text
            small
            numberOfLines={1}
            inverse
            italicized
            override={tw`mr-5px`}
          >
            {abbreviateNumber(impactScore, 0)} Impact
          </Text>
          {!showHaloExplainer && currentUserId === user.id ? (
            <Feather name="info" size={20} color={COLORS.white} />
          ) : null}
        </Pressable>
        {showHaloExplainer ? haloExplainer() : profileContent()}
      </View>
    </SlideUpModal>
  );
};

export default ProfileModal;
