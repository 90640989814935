import { gql } from '@apollo/client';

export const FETCH_BRANDS = gql`
  query FetchBrands {
    brands {
      brandColor
      coinBalance
      coinsGiven
      description
      headerLogoUrl
      heroImageUrl
      videoUrl
      heroTagline
      id
      imageUrl
      location
      name
      numCampaigns
      numCauses
      numEmployees
      numSupporters
      registeredName
      registeredNonProfit
      supportedCauses {
        charities {
          activeSince
          city
          country
          description
          fullAddress
          id
          imageUrl
          name
          numCauses
          numEmployees
          numSupporters
          postalCode
          registeredName
          stateProvince
          streetAddress
          location
          website
          volunteerUrl
          instagramUsername
          twitterUsername
          facebookUsername
          youtubeUsername
          tiktokUsername
          linkedinUrl
        }
        coinsReceived
        description
        hashTags
        heroImageUrl
        heroTagline
        id
        imageUrl
        location
        name
        numCampaigns
        numCharities
        numSupporters
        tagline
        videoUrl
        website
        wikipediaUrl
      }
      totalCoinDrops
      username
      website
      yearFounded
      instagramUsername
      twitterUsername
      facebookUsername
      youtubeUsername
      tiktokUsername
      linkedinUrl
    }
  }
`;
