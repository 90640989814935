import React from 'react';

import { Linking, TouchableOpacity, View } from 'react-native';

import Text from '@/components/Text';
import { trackEvent } from '@/utils';
import tw from '@tw';

type Props = {
  block?: boolean;
  link: string;
  webStyle?: boolean;
};

const DisclosuresButton = ({ block = false, link, webStyle }: Props) => {
  const onPress = async () => {
    const url = link.indexOf('://') === -1 ? `https://${link}` : link;

    trackEvent('disclosuresButtonClicked', { link });

    return Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      }
    });
  };
  return (
    <TouchableOpacity
      onPress={onPress}
      style={tw.style(
        `p-1 rounded-4px text-center bg-transparentPurple`,
        block ? `w-full` : `w-120px`,
        webStyle && `bg-white border-darkGray border-2 self-center mb-4`,
      )}
    >
      <View style={tw`flex-row justify-center`}>
        <Text
          small
          bold
          italicized={webStyle}
          override={tw.style(webStyle ? `text-darkGray` : `text-purple`)}
        >
          Disclosures
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default DisclosuresButton;
