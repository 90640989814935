/* eslint-disable no-console */
import { useLazyQuery, useMutation } from '@apollo/client';

import { FETCH_LOGIN_TIKTOK_URL, FETCH_LOGIN_TIKTOK_USER } from '@/queries';

const useTiktok = () => {
  const [fetchTiktokLink, { data: tiktokUrlData, loading: tiktokUrlLoading }] =
    useLazyQuery<{ authTiktok: { redirectTo: string } }>(
      FETCH_LOGIN_TIKTOK_URL,
      {
        fetchPolicy: 'network-only',
        onCompleted: (data) => console.log(data),
        onError: (err) => console.log(err),
      },
    );

  const [
    fetchTiktokUser,
    { data: fetchTiktokUserData, loading: fetchTiktokUserLoading },
  ] = useMutation<{
    connectToTiktok: {
      token: string;
      success: boolean;
      error: string;
      user: {
        id: string;
      };
    };
  }>(FETCH_LOGIN_TIKTOK_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => console.log(data),
    onError: (err) => console.log(err),
  });

  return {
    fetchTiktokLink,
    tiktokUrlData,
    tiktokUrlLoading,
    fetchTiktokUser,
    fetchTiktokUserData,
    fetchTiktokUserLoading,
  };
};

export default useTiktok;
