import React from 'react';

import { Linking, StyleSheet, TouchableOpacity } from 'react-native';

import Text from '@/components/Text';
import { trackEvent, COLORS } from '@/utils';

const styles = StyleSheet.create({
  container: {
    padding: 4,
    paddingTop: 5,
    paddingHorizontal: 10,
    borderWidth: 2,
    borderRadius: 4,
    textAlign: 'center',
    borderColor: COLORS.purple,
    color: COLORS.purple,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
});

type Props = {
  block?: boolean;
  link: string;
  entity?: string;
};

const VolunteerButton: React.FC<Props> = ({ block = false, link, entity }) => {
  const onPress = async () => {
    const url = link.indexOf('://') === -1 ? `https://${link}` : link;

    trackEvent('volunteerButtonClicked', {
      link,
      entity,
    });

    return Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      }
    });
  };
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        ...styles.container,
        ...(block ? { width: '100%' } : { width: 110 }),
      }}
    >
      <Text small primary bold>
        Volunteer!
      </Text>
    </TouchableOpacity>
  );
};

export default VolunteerButton;
