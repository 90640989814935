import React from 'react';

import { View } from 'react-native';

const UNIT = 8;

type SpacingProps = {
  children: NonNullable<React.ReactNode>;
  all?: number;
  vertical?: number;
  horizontal?: number;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};

export default function Spacing(props: SpacingProps) {
  const { children, all, top, bottom, left, right, vertical, horizontal } =
    props;

  return (
    <View
      style={{
        marginTop: (all || vertical || top || 0) * UNIT,
        marginBottom: (all || vertical || bottom || 0) * UNIT,
        marginLeft: (all || horizontal || left || 0) * UNIT,
        marginRight: (all || horizontal || right || 0) * UNIT,
      }}
    >
      {children}
    </View>
  );
}
