import React, { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Pressable, View } from 'react-native';
import {
  NavigationState,
  Route,
  SceneRendererProps,
  TabBar,
  TabView,
} from 'react-native-tab-view';
import { Reoverlay } from 'reoverlay';

import { LoadingIndicator, Text } from '@/components/web';
import { useLeaderboard } from '@/hooks/useLeaderboard';
import { FETCH_HEAD_TO_HEAD } from '@/queries/fetch-head-to-head';
import { HeadToHeadBattle, User, UserScorePair } from '@/types';
import { COLORS, abbreviateNumber, trackEvent } from '@/utils';
import tw from '@tw';
import { WebWrapper } from '@web/components';
import HeadToHead from '@web/components/HeadToHead';
import RoundedBorderedImage from '@web/components/RoundedBorderedImage';

const Leaderboard = () => {
  const { loading, data } = useLeaderboard(10);

  const { data: headToHeadData, refetch } = useQuery<{
    currentHeadToHeadBattle: HeadToHeadBattle;
    nextHeadToHeadBattle: HeadToHeadBattle;
    recentlyFinishedHeadToHeadBattle: HeadToHeadBattle;
  }>(FETCH_HEAD_TO_HEAD, {
    fetchPolicy: 'network-only',
  });

  const {
    currentHeadToHeadBattle,
    nextHeadToHeadBattle,
    recentlyFinishedHeadToHeadBattle,
  } = headToHeadData || {};

  useEffect(() => {
    trackEvent('leaderboardViewed');
  }, []);

  const onPressRow = useCallback(
    (user: User) => () => {
      trackEvent('profileModalClicked', {
        userProfile: user?.id,
      });
      Reoverlay.showModal('ProfileModal', {
        user,
        haloProperties: {
          haloColor: user?.haloColor,
        },
      });
    },
    [],
  );

  const LeaderboardList = React.memo(
    ({
      userScorePairs,
      allTime,
    }: {
      userScorePairs?: UserScorePair[];
      allTime: boolean;
    }) => {
      return (
        <View>
          {userScorePairs?.map((userScorePair, index) => {
            const { user } = userScorePair;

            if (
              ((allTime ? user?.impactScore : user?.impactScoreCurrentMonth) ||
                0) <= 0
            ) {
              return null;
            }

            return (
              <View
                key={index}
                style={tw`flex-row py-4 px-6 items-center justify-between w-full m-auto border-b-1 border-b-zinc-400`}
              >
                <Pressable
                  style={tw`flex-row items-center w-full`}
                  onPress={onPressRow(user)}
                >
                  <View style={tw`mr-4`}>
                    <RoundedBorderedImage
                      color={user?.haloColor || 'transparent'}
                      type="outer"
                      image={user.profilePhoto}
                      size={48}
                      onPress={onPressRow(user)}
                    />
                  </View>
                  <View>
                    <Text xsmallPlus2={index >= 3} mediumSmall={index < 3} bold>
                      #{index + 1}. {user?.fullName || user?.username}
                    </Text>
                    <View
                      style={tw`flex flex-row items-center space-x-4 w-full`}
                    >
                      <View style={tw`mr-2`}>
                        <Text xsmallPlus2>
                          $
                          {abbreviateNumber(
                            (allTime
                              ? user?.dollarsRaised
                              : user?.dollarsRaisedCurrentMonth) || 0,
                            2,
                          )}{' '}
                          raised
                        </Text>
                      </View>
                      <View style={tw`mr-2`}>
                        <Text xsmallPlus2>•</Text>
                      </View>
                      <View style={tw`mr-2`}>
                        <Text xsmallPlus2>
                          {abbreviateNumber(
                            (allTime
                              ? user?.totalShares
                              : user?.totalSharesCurrentMonth) || 0,
                            0,
                          )}{' '}
                          shares
                        </Text>
                      </View>
                      <View style={tw`mr-2`}>
                        <Text xsmallPlus2>•</Text>
                      </View>
                      <View>
                        <Text xsmallPlus2>
                          {abbreviateNumber(
                            (allTime
                              ? user?.impactScore
                              : user?.impactScoreCurrentMonth) || 0,
                            0,
                          )}{' '}
                          Impact
                        </Text>
                      </View>
                    </View>
                  </View>
                </Pressable>
              </View>
            );
          })}
        </View>
      );
    },
  );

  const renderScene = ({ route }: { route: any }) => {
    switch (route.key) {
      case 'first':
        return (
          <HeadToHead
            currentHeadToHeadBattle={currentHeadToHeadBattle}
            nextHeadToHeadBattle={nextHeadToHeadBattle}
            recentlyFinishedHeadToHeadBattle={recentlyFinishedHeadToHeadBattle}
            refetch={refetch}
          />
        );
      case 'second':
        return (
          <LeaderboardList
            allTime
            userScorePairs={data?.leaderboard?.allTimeUserImpactScore}
          />
        );
      case 'third':
        return (
          <LeaderboardList
            allTime={false}
            userScorePairs={data?.leaderboard?.userImpactScore}
          />
        );
      default:
        return null;
    }
  };

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'first', title: '  Head to Head  ' },
    { key: 'second', title: '  Leaderboard  ' },
  ]);

  const renderTabBar = (
    props: SceneRendererProps & {
      navigationState: NavigationState<Route>;
    },
  ) => {
    return (
      <TabBar
        {...props}
        bounces
        tabStyle={{
          zIndex: 1,
          height: 45,
          backgroundColor: 'transparent',
        }}
        pressColor={COLORS.white}
        style={tw`bg-white border-b-0 shadow-none w-3/4 m-auto`}
        indicatorStyle={{
          padding: 15,
          borderRadius: 25,
          backgroundColor: 'transparent',
        }}
        renderLabel={({ focused, route }) => (
          <View style={tw`flex flex-col items-center justify-center`}>
            <Text
              override={{
                top: '-35%',
                fontSize: 14,
                color: focused ? COLORS.black : '#777777',
                fontWeight: focused ? '900' : '800',
                backgroundColor: 'transparent',
                borderBottomWidth: focused ? 2 : 0,
                borderBottomColor: COLORS.purple,
                height: '28px',
              }}
              numberOfLines={1}
            >
              {route.title || ''}
            </Text>
          </View>
        )}
      />
    );
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <WebWrapper>
      <TabView
        navigationState={{ index, routes }}
        swipeEnabled={false}
        renderScene={renderScene}
        onIndexChange={setIndex}
        renderTabBar={renderTabBar}
        style={tw`bg-white mt-2`}
      />
    </WebWrapper>
  );
};

export default Leaderboard;
