import React, { useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Entypo } from '@expo/vector-icons';
import { Image } from 'expo-image';
import moment from 'moment';
import {
  Animated,
  Pressable,
  ScrollView,
  View,
  StyleSheet,
} from 'react-native';
import { Reoverlay } from 'reoverlay';

import {
  EntityDescription,
  EntityHeader,
  Text,
  useSessionTokenContext,
} from '@/components/web';
import { eventProps } from '@/constants/ANALYTICS_CONST';
import useStashLinkHooks from '@/hooks/useStashLink';
import useWebHooks from '@/hooks/useWebHooks';
import { FETCH_CAMPAIGN_PROFILE, FETCH_USER } from '@/queries';
import { User } from '@/types';
import {
  addCloudinaryTransformation,
  COLORS,
  errorModal,
  getStoreTimesPlayed,
  isMobileDevice,
  maxWidth,
  profileHaloColorPicker,
  trackEvent,
} from '@/utils';
import { isFeatureEnabled } from '@/utils/growthbook';
import { checkSourceTrack } from '@/utils/webSourceTrack';
import { setFirstTimeCause } from '@/utils/webStorage';
import tw from '@tw';
import WaitlistForm from '@web/CoinGame/WaitlistForm';
import { CampaignWall, ProgressBar, WebWrapper } from '@web/components';

import CampaignProfileFooter from './components/CampaignProfileFooter';
import RoundedBorderedImage from './components/RoundedBorderedImage';
import splashLogo from '../src/assets/splash_logo_3.png';

type CampaignProfileProps = {
  referrer?: boolean;
};

const PULSE_DELAY_MS = 500;
const PULSE_TIMING_MS = 2000;

const CampaignProfile = ({ referrer }: CampaignProfileProps) => {
  const { navigate, campaignLink, users, query, secondaryReferrer } =
    useWebHooks();

  const [showWaitlist, setShowWaitlist] = useState(false);
  const [showWall, setShowWall] = useState(false);
  const [dontHideModal, setDontHideModal] = useState(false);
  const [splashDone, setSplashDone] = useState(false);
  const [pulseAnimatedValue] = useState(new Animated.Value(0));
  const loadingProgress = React.useRef(new Animated.Value(0)).current;

  const createStashStep = query.get('type') === 'create-stash';
  const utm = query.get('utm_source');

  const {
    viewStashLink,
    getStashLink,
    getStashLinkData,
    createStashLink,
    createStashLinkData,
  } = useStashLinkHooks();
  const { userId: userIdSession } = useSessionTokenContext();

  const { loading: campaignLoading, data: campaignData } = useQuery(
    FETCH_CAMPAIGN_PROFILE,
    {
      variables: { campaignId: campaignLink },
    },
  );

  const [fetchUser, { loading: userLoading, data: userData }] = useLazyQuery<{
    user: User;
  }>(FETCH_USER, {
    variables: { userId: createStashStep ? userIdSession : users },
  });

  const [
    fetchSecondaryUser,
    { loading: secondaryUserLoading, data: secondaryUserData },
  ] = useLazyQuery<{
    user: User;
  }>(FETCH_USER, {
    variables: { userId: secondaryReferrer },
  });

  const haloProperties = useMemo(() => {
    const circleProperties = profileHaloColorPicker(
      userData?.user?.impactLevel || 0,
      userData?.user?.percentToNextLevel || 0,
      true,
    );
    return { ...circleProperties };
  }, [userData?.user?.impactLevel]);

  const timesPlayed =
    getStoreTimesPlayed(campaignData?.campaign?.slug || '') || 0;

  useEffect(() => {
    if (users || createStashStep) fetchUser();
    if (secondaryReferrer?.length) fetchSecondaryUser();
    const timeNow = moment().format();
    trackEvent('campaignProfileViewed', {
      campaignId: campaignLink,
    });

    return () => {
      trackEvent('campaignProfileVisited', {
        campaignId: campaignLink,
        secondsSpent: moment().diff(moment(timeNow), 'seconds'),
      });
      if (dontHideModal) {
        Reoverlay.hideAll();
      }
    };
  }, []);

  const animatePulse = () => {
    Animated.loop(
      Animated.sequence([
        Animated.delay(PULSE_DELAY_MS),
        Animated.timing(pulseAnimatedValue, {
          toValue: 1,
          duration: PULSE_TIMING_MS,
          useNativeDriver: true,
        }),
        Animated.delay(PULSE_DELAY_MS),
        Animated.timing(pulseAnimatedValue, {
          toValue: 0,
          duration: PULSE_TIMING_MS,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  };

  useEffect(() => {
    if (!isMobileDevice()) {
      setShowWall(true);
    }
    animatePulse();
  }, []);

  useEffect(() => {
    getStashLink({
      variables: {
        userId: createStashStep ? userIdSession : users,
        campaignId: campaignData?.campaign?.id,
      },
    });

    if (!userIdSession && campaignData) {
      if (timesPlayed > 0) {
        setFirstTimeCause(campaignData.campaign?.cause?.name || '');
      }
    }
  }, [campaignData]);

  useEffect(() => {
    if (
      getStashLinkData?.stashlinkByUserAndCampaign?.id &&
      campaignData &&
      users &&
      !createStashStep
    ) {
      // check user agent for in app web browsers and local storage for social clicks
      const source = checkSourceTrack(
        getStashLinkData?.stashlinkByUserAndCampaign.id,
        utm || '',
      );

      viewStashLink({
        variables: {
          userId: users,
          campaignId: campaignData?.campaign?.id,
          ...(source && {
            source,
          }),
        },
      });
    }
  }, [getStashLinkData, campaignData]);

  useEffect(() => {
    if (createStashLinkData?.createStashlink) {
      if (createStashLinkData?.createStashlink.success) {
        setDontHideModal(true);
        navigate('/home');
        Reoverlay.showModal('VideoMessageUploadModal', {
          url: createStashLinkData?.createStashlink.stashlink.url,
          campaign,
          referrerUserId: userIdSession,
          stashlinkId: createStashLinkData?.createStashlink.stashlink.id,
          stashlinkMessage:
            createStashLinkData?.createStashlink.stashlink.thankYouMessage,
          haloColor: haloProperties.haloColor,
          profilePhoto: userData?.user?.profilePhoto,
          userName: userData?.user?.fullName,
        });
      } else {
        errorModal('Failed to create stashlink, please try again');
      }
    }
  }, [createStashLinkData]);

  const onFooterButtonPress = async () => {
    if (createStashStep) {
      trackEvent('getStashlinkClicked', {
        campaignId: campaignData?.campaign?.id,
      });
      if (getStashLinkData?.stashlinkByUserAndCampaign) {
        Reoverlay.showModal('VideoMessageUploadModal', {
          url: getStashLinkData?.stashlinkByUserAndCampaign.url,
          campaign,
          referrerUserId: userIdSession,
          stashlinkId: getStashLinkData?.stashlinkByUserAndCampaign.id,
          stashlinkMessage:
            getStashLinkData?.stashlinkByUserAndCampaign.thankYouMessage,
          stashlinkMessageVideo:
            getStashLinkData?.stashlinkByUserAndCampaign.thankYouMessageVideo,
          haloColor: haloProperties.haloColor,
          profilePhoto: userData?.user?.profilePhoto,
          userName: userData?.user?.fullName,
        });
      } else {
        await createStashLink({
          variables: {
            userId: userIdSession,
            campaignId: campaignData?.campaign?.id,
          },
        });
      }

      return;
    }
    if (isCompleted) {
      trackEvent('campaignCompletedButtonClicked', {
        campaignName: campaign.title,
        campaignId: campaignData?.campaign?.id,
        referrerUserId: users,
      });
      setShowWaitlist(true);
      return;
    }
    trackEvent('fundButtonClicked', {
      campaignName: campaign.title,
      campaignId: campaignData?.campaign?.id,
    });
    if (!campaign.canSupport) {
      alert('Sorry! There are no sponsors for this campaign yet!');
    }

    // if (isMobileDevice()) {
    if (timesPlayed > 2) {
      navigate(`/auth`);
    } else if (isFeatureEnabled('disable-campaign-funding')) {
      Reoverlay.showModal('WaitlistModal', {
        onBack: () => {
          Reoverlay.hideModal();
        },
      });
    } else {
      const queryParams = new URLSearchParams();
      if (users) queryParams.set('referrerUserId', users);
      if (secondaryReferrer)
        queryParams.set('secondaryReferrer', secondaryReferrer);
      const queryString = queryParams.toString();

      navigate(`/coingame/${campaign.id}?${queryString}`);
    }
  };

  const splashImageScale = {
    transform: [
      {
        scale: loadingProgress.interpolate({
          inputRange: [1, 15, 100],
          outputRange: [1, 0.5, 30],
        }),
      },
      {
        translateY: loadingProgress.interpolate({
          inputRange: [1, 100],
          outputRange: [1, 30],
        }),
      },
    ],
  };

  const splashOpacity = {
    opacity: loadingProgress.interpolate({
      inputRange: [0, 25, 50, 100],
      outputRange: [1, 1, 0.8, 0],
      extrapolate: 'clamp',
    }),
  };

  const splashScreen = (
    <Animated.View
      style={[
        StyleSheet.absoluteFill,
        {
          backgroundColor: '#222222',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        },
        splashOpacity,
      ]}
    >
      <Animated.View
        style={[
          {
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          },
          {
            transform: [
              {
                scale: pulseAnimatedValue.interpolate({
                  inputRange: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                  outputRange: [1, 0.97, 0.95, 0.97, 1, 1.03, 1.05, 1],
                }),
              },
            ],
          },
        ]}
      >
        <Animated.Image
          style={[
            {
              height: '40%',
              width: 140,
              resizeMode: 'contain',
            },
            splashImageScale,
            splashOpacity,
          ]}
          source={splashLogo}
        />
      </Animated.View>
    </Animated.View>
  );

  useEffect(() => {
    if (
      !campaignLoading &&
      !userLoading &&
      (!secondaryReferrer?.length || !secondaryUserLoading)
    ) {
      Animated.timing(loadingProgress, {
        duration: 900,
        toValue: 100,
        useNativeDriver: true,
        delay: 300,
      }).start(() => setSplashDone(true));
    }
  }, [campaignLoading, userLoading, secondaryReferrer, secondaryUserLoading]);

  if (
    !splashDone ||
    campaignLoading ||
    userLoading ||
    (secondaryReferrer?.length && secondaryUserLoading)
  ) {
    return splashScreen;
  }

  const campaign = campaignData?.campaign || {};
  const {
    description: campaignDescription,
    title,
    imageUrl,
    videoUrl,
    location,
    charity,
    studentOrgs,
    heroTagline,
    currentAmount,
    goalAmount,
    cause,
    disclosuresUrl,
  } = campaign || {};

  const { name: charityName, location: charityLocation } = charity || {};
  const remainingPercentage =
    (Number(currentAmount || 0) / Number(goalAmount)) * 100;
  const isCompleted = remainingPercentage >= 100;
  const showFooter = createStashStep || campaign.canSupport || isCompleted;
  const hideReferrer = !referrer || !users || showFooter;

  if (showWaitlist) {
    return (
      <WebWrapper
        analytics={{
          [eventProps.page]: 'Campaign',
          [eventProps.campaignId]: campaignLink,
        }}
      >
        <WaitlistForm campaign={campaign} />
      </WebWrapper>
    );
  }
  if (showWall && isFeatureEnabled('campaign-wall'))
    return <CampaignWall campaign={campaignData?.campaign} userId={users} />;
  return (
    <WebWrapper
      analytics={{
        [eventProps.page]: 'Campaign',
        [eventProps.campaignId]: campaignLink,
      }}
      withBack={createStashStep}
    >
      <ScrollView
        style={tw`bg-white self-center w-full max-w-${maxWidth(500)} pb-${
          showFooter ? '250px' : '100px'
        } flex flex-1`}
        showsVerticalScrollIndicator={false}
      >
        <View style={tw`px-10px`}>
          {hideReferrer ? null : (
            <Pressable
              style={tw`w-full h-60px mt-8px items-center justify-between rounded-full bg-tertiaryPurple flex-row`}
              onPress={() => {
                trackEvent('profileModalClicked', {
                  userProfile: userData?.user?.id,
                });
                Reoverlay.showModal('ProfileModal', {
                  user: userData?.user,
                  haloProperties,
                });
              }}
            >
              <View style={tw`flex-row w-8/10 items-center pl-5px`}>
                <RoundedBorderedImage
                  color={haloProperties.haloColor}
                  type="outer"
                  size={44}
                  image={userData?.user?.profilePhoto}
                />
                <View style={tw`ml-10px w-17/20`}>
                  <Text small primary>
                    Stand with{' '}
                    <Text small primary bold>
                      {userData?.user?.fullName || ''}{' '}
                    </Text>
                    to support{' '}
                    <Text small primary bold>
                      #{cause.name}
                    </Text>
                  </Text>
                </View>
              </View>
              <Entypo
                name="dots-three-horizontal"
                size={28}
                color={COLORS.purple}
                style={tw`pr-12px`}
              />
            </Pressable>
          )}

          <Text
            mediumSmallPlus2
            bold
            override={tw.style(
              'text-xl leading-6 mt-2 font-black',
              !users && 'mt-6',
            )}
          >
            {title || ''}
          </Text>
        </View>
        {createStashStep && (
          <ProgressBar campaign={campaign} divider borderRadius={0} />
        )}

        <EntityHeader
          heroImageUrl={imageUrl}
          videoUrl={videoUrl}
          heroTagline={heroTagline}
          location={location}
          instagramData={charity?.charityImages}
          studentOrgs={studentOrgs}
          showlocation={false}
        />

        <View style={tw`pl-4`}>
          <View style={tw`py-2 pr-28`}>
            <Text
              mediumSmallPlus2
              bold
              numberOfLines={1}
              override={tw`text-xl leading-5 pb-5px`}
            >
              {charityName || ''}
            </Text>
            <Text
              xsmallPlus2
              bold
              numberOfLines={1}
              color={COLORS.secondaryText}
            >
              {charityLocation || ''}
            </Text>
          </View>

          <Pressable
            style={tw.style(
              `w-22 h-22 absolute right-0 top-[-13] z-1 mr-16px border-2 items-center justify-center rounded-full bg-white`,
              {
                borderColor: charity?.charityColor || 'black',
              },
            )}
            onPress={() => {
              trackEvent('charityModalClicked', {
                charity: charity?.name,
              });
              Reoverlay.showModal('BrandModal', {
                charity,
                disclosuresUrl,
              });
            }}
          >
            <Image
              style={tw.style(`h-20 w-20 rounded-full`)}
              source={{
                uri: addCloudinaryTransformation(
                  charity?.imageUrl,
                  'c_scale,w_90,dpr_3',
                ),
              }}
            />
          </Pressable>

          <View style={tw`w-9.2/10`}>
            <EntityDescription
              noTitle
              description={campaignDescription}
              website={charity?.website}
              color={charity?.charityColor || 'black'}
              volunteerUrl={charity?.volunteerUrl}
              facebookUsername={charity?.facebookUsername}
              twitterUsername={charity?.twitterUsername}
              instagramUsername={charity?.instagramUsername}
              youtubeUsername={charity?.youtubeUsername}
              tiktokUsername={charity?.tiktokUsername}
              linkedinUrl={charity?.linkedInUrl}
              entity="Charity"
            />
          </View>
        </View>
      </ScrollView>
      {showFooter && (
        <CampaignProfileFooter
          userData={userData}
          secondaryUserData={secondaryUserData ?? undefined}
          campaignData={campaignData}
          onFooterButtonPress={onFooterButtonPress}
          createStashStep={createStashStep}
          stashLinkExist={!!getStashLinkData?.stashlinkByUserAndCampaign}
        />
      )}
    </WebWrapper>
  );
};
export default CampaignProfile;
