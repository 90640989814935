import React, { useState } from 'react';

import Clipboard from '@react-native-clipboard/clipboard';
import { Linking, StyleSheet, TouchableOpacity } from 'react-native';

import Text from '@/components/Text';
import { trackEvent } from '@/utils';

const styles = StyleSheet.create({
  container: {
    padding: 4,
    paddingTop: 5,
    paddingHorizontal: 10,
    borderWidth: 2,
    borderRadius: 4,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
});

type Props = {
  block?: boolean;
  link: string;
  entity?: string;
};

const WebsiteButton: React.FC<Props> = ({ block = false, link, entity }) => {
  const [clipboardButton, setClipboardbutton] = useState('Copy Website Link');
  const isBrand = entity === 'Brand';

  const onPress = async () => {
    if (isBrand) {
      Clipboard.setString(link || '');
      setClipboardbutton('Copied!');
    } else {
      const url = link.indexOf('://') === -1 ? `https://${link}` : link;
      trackEvent('websiteButtonClicked', {
        link,
        entity,
      });

      Linking.canOpenURL(url).then((supported) => {
        if (supported) {
          Linking.openURL(url);
        }
      });
    }
  };

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        ...styles.container,
        ...(block || isBrand ? { width: '100%' } : { width: 90 }),
      }}
    >
      <Text small bold>
        {isBrand ? clipboardButton : 'Website'}
      </Text>
    </TouchableOpacity>
  );
};

export default WebsiteButton;
