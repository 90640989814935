// Code from
// https://github.com/peacechen/react-native-make-it-rain

import React from 'react';

import { View, Text } from 'react-native';

import MakeItRainConfetti from './MakeItRainConfetti';

function MakeItRain(props: any) {
  const itemComponent = props.itemComponent || (
    <View style={props.itemDimensions} />
  );

  switch (props.flavor) {
    case 'arrive':
      return <MakeItRainConfetti {...props} itemComponent={itemComponent} />;
    default:
      return <Text>Unsupported flavor</Text>;
  }
}

MakeItRain.defaultProps = {
  numItems: 100,
  itemDimensions: { width: 20, height: 10 },
  itemColors: ['#00e4b2', '#09aec5', '#107ed5'],
  itemTintStrength: 1.0,
  flavor: 'arrive',
  fallSpeed: 50,
  flipSpeed: 3,
  horizSpeed: 50,
  continuous: true,
};

export default MakeItRain;
