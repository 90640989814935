import { gql } from '@apollo/client';

export const FETCH_NOTIFICATION = gql`
  query FetchNotification($identifier: String!) {
    notification(identifier: $identifier) {
      claimedAt
      createdAt
      id
      imageUrl
      message
      numCoinDrops
      readAt
      title
      type
      url
      metadata
      user {
        id
        username
        fullName
      }
    }
  }
`;
