import React, { useEffect, useMemo, useState } from 'react';

import { AntDesign } from '@expo/vector-icons';
import { View, Image, Pressable } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { Text, LoadingIndicator, HorizontalCarousel } from '@/components/web';
import { eventProps } from '@/constants/ANALYTICS_CONST';
import { useCampaigns } from '@/hooks/useCampaigns';
import useWebHooks from '@/hooks/useWebHooks';
import { Campaign } from '@/types';
import { addCloudinaryTransformation, getScreenSize, maxWidth } from '@/utils';
import { getPasscodeDone } from '@/utils/webStorage';
import tw from '@tw';
import { CampaignCard, WebWrapper } from '@web/components';
import { SuggestionBox } from '@web/components/SuggestionBox';

const width = maxWidth(500);
const { height } = getScreenSize();

const CauseCampaigns = () => {
  const { params } = useWebHooks();
  const { fetchCampaigns, campaignsData, campaignsLoading } = useCampaigns();
  const opacityAnimate = useSharedValue(0);
  const [showScrolldown, setScrolldown] = useState(false);

  const passcodeDone = getPasscodeDone();

  const campaigns = useMemo(() => {
    if (!campaignsData?.campaignsSearch) return [];
    const causes = [
      ...(campaignsData?.campaignsSearch
        .slice()
        .sort((campaign1: Campaign, campaign2: Campaign) => {
          if (!campaign1.endTime) return -1;
          if (!campaign2.endTime) return 1;
          return campaign1.endTime > campaign2.endTime ? 1 : 1;
        }) || []),
    ] as Campaign[];
    return causes;
  }, [!!campaignsData]);

  useEffect(() => {
    fetchCampaigns({
      variables: {
        stashlinkEnabled: true,
        causeName: params.cause,
      },
    });

    const timeout = setTimeout(() => {
      if (passcodeDone) {
        setScrolldown(true);
      }

      opacityAnimate.value = withTiming(0.9, {
        duration: 800,
      });
    }, 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const scrollDownOpacityAnimatedStyle = useAnimatedStyle(
    () =>
      ({
        opacity: opacityAnimate.value,
      } as any),
  );

  const backgroundImage = campaigns[0]?.cause?.imageUrl;
  return (
    <WebWrapper
      withBack
      analytics={{ [eventProps.page]: 'Create a Stashlink - Campaigns' }}
    >
      <Image
        style={tw`flex-1 h-full w-full absolute opacity-10`}
        source={{
          uri: addCloudinaryTransformation(
            backgroundImage,
            `h_${Math.ceil(height)},w_${Math.ceil(width)},c_fill`,
          ),
        }}
      />
      <View style={tw`flex flex-col px-8 pt-6 pb-2 mb-2 items-center`}>
        <Text centered mediumSmallPlus2 bold>
          #{params.cause}
        </Text>
        <Text centered mediumSmall>
          Choose a campaign to support
        </Text>
        <View
          style={tw`border-b-1 border-dividerGray w-9/10 my-2 self-center`}
        />
      </View>

      {campaignsLoading ? (
        <LoadingIndicator />
      ) : (
        <View style={tw`flex flex-col`}>
          <HorizontalCarousel
            width={width * 0.7}
            height={width}
            items={campaigns}
            renderItem={(item) => (
              <CampaignCard campaign={item} shadow key={item.id} />
            )}
          />
          <SuggestionBox />
          {showScrolldown && (
            <Animated.View
              style={[
                tw`fixed self-center rounded-xl p-2 bg-borderGray bottom-10`,
                scrollDownOpacityAnimatedStyle,
              ]}
            >
              <Pressable
                onPress={() => {
                  window.scrollTo(0, document.body.scrollHeight);
                  opacityAnimate.value = withTiming(
                    0,
                    {
                      duration: 800,
                    },
                    () => setScrolldown(false),
                  );
                }}
                style={tw`items-center`}
              >
                <Text small centered inverse bold>
                  Can't find a charity to support?
                </Text>
                <AntDesign name="caretdown" size={18} color="white" />
              </Pressable>
            </Animated.View>
          )}
        </View>
      )}
    </WebWrapper>
  );
};

export default CauseCampaigns;
