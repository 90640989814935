import { getUserAgentSync } from 'react-native-device-info';

import { getSourceTrack, setSourceTrack } from './webStorage';

export const checkSourceTrack = (stashlinkId: string, utm: string) => {
  const userAgent = getUserAgentSync();
  const getSource = getSourceTrack(stashlinkId) || '';

  if (userAgent.includes('Instagram')) {
    if (!getSource.includes('instagram')) {
      setSourceTrack(stashlinkId, `${getSource} instagram`);
      return 'instagram';
    }
    return null;
  }
  if (userAgent.includes('LinkedInApp')) {
    if (!getSource.includes('linkedin')) {
      setSourceTrack(stashlinkId, `${getSource} linkedin`);
      return 'linkedin';
    }
    return null;
  }
  if (
    userAgent.includes('FBAN') ||
    userAgent.includes('FBAV') ||
    userAgent.includes('FB_IAB')
  ) {
    if (!getSource.includes('meta')) {
      setSourceTrack(stashlinkId, `${getSource} meta`);
      return 'meta';
    }
    return null;
  }
  if (userAgent.includes('ByteLocale')) {
    if (!getSource.includes('tiktok')) {
      setSourceTrack(stashlinkId, `${getSource} tiktok`);
      return 'tiktok';
    }
    return null;
  }

  if (utm) {
    if (!getSource.includes('instagram') && utm === 'instagram') {
      setSourceTrack(stashlinkId, `${getSource} instagram`);
      return 'instagram';
    }
    if (!getSource.includes('linkedin') && utm === 'linkedin') {
      setSourceTrack(stashlinkId, `${getSource} linkedin`);
      return 'linkedin';
    }
    if (!getSource.includes('meta') && utm === 'meta') {
      setSourceTrack(stashlinkId, `${getSource} meta`);
      return 'meta';
    }
    if (!getSource.includes('tiktok') && utm === 'tiktok') {
      setSourceTrack(stashlinkId, `${getSource} tiktok`);
      return 'tiktok';
    }
    if (!getSource.includes('twitter') && utm === 'twitter') {
      setSourceTrack(stashlinkId, `${getSource} twitter`);
      return 'twitter';
    }
  }

  if (!getSource.includes('direct')) {
    setSourceTrack(stashlinkId, `${getSource} direct`);
    return 'direct';
  }
  return null;
};
