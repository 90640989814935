import React, { useEffect, useState } from 'react';

import { Video, ResizeMode } from 'expo-av';
import { Image } from 'expo-image';
import { LinearGradient } from 'expo-linear-gradient';
import { Animated, View, Pressable } from 'react-native';
import ConfettiCannon from 'react-native-confetti-cannon';
import { Reoverlay } from 'reoverlay';

import images from '@/assets/images';
import {
  Text,
  PrimaryButton,
  FadeInView,
  useSessionTokenContext,
  LoadingIndicator,
} from '@/components/web';
import { useCollectCoins } from '@/hooks/useCoinDrops';
import useStashLinkHooks from '@/hooks/useStashLink';
import useWebHooks from '@/hooks/useWebHooks';
import { CoinDrop } from '@/types';
import {
  addCloudinaryTransformation,
  trackEvent,
  getScreenSize,
  getStoreTimesPlayed,
  scroll,
  maxWidth,
  preloadImages,
} from '@/utils';
import { COLORS, CONFETTI_COLORS } from '@/utils/constants';
import { setFirstTimeCause, setStoreTimesPlayed } from '@/utils/webStorage';
import tw from '@tw';
import { ProgressBar, WebWrapper } from '@web/components';
import RoundedBorderedImage from '@web/components/RoundedBorderedImage';

type GameFinishedProps = {
  coinDrop: CoinDrop;
  coinsObtained: number;
  silverCoinsObtained: number;
  goldCoinsObtained: number;
  redCoinsObtained: number;
  purpleCoinsObtained: number;
  timeupAnimatedValue: Animated.Value;
  balance: number;
  displayResults: boolean;
  isCoindrop: boolean;
  isOnboarding: boolean;
  secret?: string;
  referrerUserId?: string;
  tapToContinueAnimation: () => void;
  profilePhoto?: string;
  secondaryReferrerUserId?: string;
};

const { height: deviceHeight } = getScreenSize();
const deviceWidth = maxWidth(500);

export default function GameFinished({
  balance = 0,
  coinDrop,
  coinsObtained,
  silverCoinsObtained,
  goldCoinsObtained,
  redCoinsObtained,
  purpleCoinsObtained,
  secret = '',
  referrerUserId = '',
  isCoindrop,
  profilePhoto,
  secondaryReferrerUserId,
}: GameFinishedProps) {
  const [displayConfetti, setDisplayConfetti] = useState(true);
  const [muted, setMute] = useState(true);
  const [videoLoad, setVideoLoad] = useState(true);

  const video = React.useRef<Video>(null);
  const { id: coinDropId, brand, campaign } = coinDrop;
  const { userId: currentUserId } = useSessionTokenContext();

  const { navigate } = useWebHooks();
  const timesPlayed = getStoreTimesPlayed(campaign?.slug || '');
  const { collectCoins } = useCollectCoins();
  const { getStashLink, getStashLinkData, getStashlinkLoading } =
    useStashLinkHooks();

  useEffect(() => {
    if (campaign) {
      getStashLink({
        variables: {
          userId: referrerUserId,
          campaignId: campaign?.id,
        },
      });
    }
  }, [campaign]);

  const onPressVideo = () => {
    if (muted) {
      setMute(false);
      video.current?.playAsync();
    } else {
      setMute(true);
      video.current?.pauseAsync();
    }
  };

  useEffect(() => {
    // tracking to make sure theres no dups
    video.current?.stopAsync();
    preloadImages([stashlinkUser?.profilePhoto]);

    if (!getStashlinkLoading && getStashLinkData) {
      if (getStashLinkData?.stashlinkByUserAndCampaign?.thankYouMessageVideo) {
        trackEvent('thankYouMessageViewed', { type: 'video' });
      } else if (
        getStashLinkData?.stashlinkByUserAndCampaign?.thankYouMessage
      ) {
        trackEvent('thankYouMessageViewed', { type: 'message' });
      } else {
        trackEvent('thankYouMessageViewed', { type: 'default' });
      }
    }
  }, [getStashLinkData, getStashlinkLoading]);

  useEffect(() => {
    scroll.enable();
    const timeout = setTimeout(() => {
      setTimeout(() => {
        setDisplayConfetti(false);
      }, 650);
    }, 2500);

    setStoreTimesPlayed(campaign?.slug || '', timesPlayed + 1);
    if (!currentUserId) {
      setFirstTimeCause(campaign?.cause?.name || '');
    }
    collectCoins({
      variables: {
        coinDropId: Number(coinDropId || '0'),
        coinsCollected: coinsObtained,
        silverCoinsCollected: isCoindrop ? silverCoinsObtained : balance * 100,
        goldCoinsCollected: goldCoinsObtained,
        redCoinsCollected: redCoinsObtained,
        purpleCoinsCollected: purpleCoinsObtained,
        secret,
        referrerUserId,
        secondaryReferrerUserId,
      },
    });

    trackEvent(
      isCoindrop ? 'coinDropComplete' : 'spinnerComplete',
      isCoindrop
        ? {
            referrerUserId,
            coinDropId: Number(coinDropId || '0'),
            coinsCollected: coinsObtained,
            balance: balance.toFixed(2),
            silverCoinsCollected: isCoindrop
              ? silverCoinsObtained
              : balance * 100,
            goldCoinsCollected: goldCoinsObtained,
            redCoinsCollected: redCoinsObtained,
            purpleCoinsCollected: purpleCoinsObtained,
          }
        : {
            referrerUserId,
            balance: balance.toFixed(2),
          },
    );

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const redirect = () => {
    return referrerUserId?.length
      ? navigate(`/users/${referrerUserId}/${campaign?.slug}`)
      : navigate(`/campaign/${campaign?.slug}`);
  };

  const thankYouMessage =
    getStashLinkData?.stashlinkByUserAndCampaign?.thankYouMessage;

  const thankYouMessageVideo =
    getStashLinkData?.stashlinkByUserAndCampaign?.thankYouMessageVideo;

  const stashlinkUser = getStashLinkData?.stashlinkByUserAndCampaign?.user;

  const topBar = (video = false) => (
    <View style={tw`w-full absolute top-0 z-200`}>
      {video ? (
        <>
          <LinearGradient
            locations={[0.1, 1]}
            colors={[COLORS.black, '#5551FF00']}
            style={tw`h-full w-full absolute top-0`}
          />
          <View style={tw`w-full pt-2 flex-row`}>
            <View style={tw`w-1/7`}>
              <RoundedBorderedImage
                color={stashlinkUser?.haloColor || 'black'}
                type="outer"
                size={40}
                image={stashlinkUser?.profilePhoto}
              />
            </View>
            <View style={tw`w-5/7`}>
              <Text inverse bold small centered>
                🎉 ${balance.toFixed(2)} donation complete! #{coinDropId || ''}
              </Text>
            </View>
            <View style={tw`w-1/7 items-center`}></View>
          </View>
          <Image
            style={tw`h-7 w-7 absolute top-3 left-3`}
            source={muted ? images.icons.muted : images.icons.notMuted}
          />
        </>
      ) : (
        <>
          <View style={tw`h-10 w-full bg-black justify-center items-center`}>
            <Text inverse bold small>
              🎉 ${balance.toFixed(2)} donation complete! #{coinDropId || ''}
            </Text>
          </View>
          <View style={tw`w-full pt-4 flex-row`}>
            <View style={tw`w-1/7`}></View>
            <View style={tw`w-5/7`}>
              <Text
                inverse={video}
                centered
                bold
                mediumSmallPlus2
                textShadow={video}
              >
                {stashlinkUser?.fullName}{' '}
              </Text>
              <Text
                inverse={video}
                mediumSmall
                bold
                centered
                textShadow={video}
              >
                says thanks!
              </Text>
            </View>
            <View style={tw`w-1/7 items-center`}></View>
          </View>
        </>
      )}
    </View>
  );

  const footer = (transparent = false) => (
    <View
      style={tw.style(
        `fixed max-w-${maxWidth(
          500,
        )}px w-full bg-transparent self-center items-center bottom-0 pt-3 pb-2 rounded-tl-[20px] rounded-tr-[20px] z-500`,
        !transparent && 'bg-white bottomSheetShadow',
      )}
    >
      <View style={tw`justify-center mt-2`}>
        <ProgressBar
          campaign={campaign}
          transparent={transparent}
          width={240}
        />
      </View>
      <View
        style={tw.style(
          `border-b-2 border-zinc-300 w-6.5/10 my-3`,
          transparent && 'border-transparent my-1',
        )}
      />
      {transparent && (
        <LinearGradient
          locations={[0.1, 1]}
          colors={['#5551FF00', COLORS.black]}
          style={tw`h-full w-full absolute bottom-0`}
        />
      )}

      <PrimaryButton
        style="w-7/10 self-center"
        title="Share To Raise More"
        fullRadius
        customRender={
          <Image source={images.share} style={tw`w-6 h-6 ml-2.5`} />
        }
        onPress={() => {
          trackEvent('thankYouShareModalClicked');
          const noOriginalReferrerLink = `${window.location.origin}/campaign/${campaign?.slug}`;
          let newSecondaryReferrer = '';
          if (currentUserId?.length) {
            // If user is logged in (and not the original stashlink user), use them as the new secondary
            if (currentUserId !== stashlinkUser?.id) {
              newSecondaryReferrer = currentUserId;
            }
          } else if (secondaryReferrerUserId?.length) {
            // if they are not logged in, use the original secondary referrer or nothing at all.
            newSecondaryReferrer = secondaryReferrerUserId;
          }
          Reoverlay.showModal('ShareModal', {
            url: stashlinkUser?.id
              ? `${window.location.origin}/referrer/${stashlinkUser?.id}/${campaign?.slug}/${newSecondaryReferrer}`
              : noOriginalReferrerLink,
            campaign,
            referrerUserId: stashlinkUser?.id,
            secondaryReferrerUserId: currentUserId,
            haloColor: brand?.brandColor,
            profilePhoto,
            redirect,
          });
        }}
      />

      <Pressable
        style={tw`w-40 h-7 justify-center mt-2`}
        onPress={() => {
          trackEvent('matchDonationSkipped');
          redirect();
        }}
      >
        <Text centered xsmallPlus2 italicizedNotBold inverse={transparent}>
          Skip and Continue
        </Text>
      </Pressable>
    </View>
  );

  const defaultThankyou = () => (
    <FadeInView style={tw`flex-1`}>
      <View style={tw`bg-white flex-1 overflow-hidden`}>
        <Image
          style={tw`flex-1 h-full w-full absolute z-90 opacity-5`}
          source={{
            uri: addCloudinaryTransformation(
              campaign?.imageUrl,
              `h_${Math.ceil(deviceHeight)},w_${Math.ceil(deviceWidth)},c_fill`,
            ),
          }}
        />
        <View style={tw`z-200 h-full w-full mt-10 pb-22 absolute`}>
          <Image
            style={tw.style(`w-60px h-60px self-center`)}
            source={images.icons.congrats}
          />
          <Image
            style={tw.style(`w-250px h-45px self-center`, {
              contentFit: 'center',
            })}
            source={images.donationDone}
          />

          <Text italicizedNotBold small centered>
            #{coinDropId || ''}
          </Text>

          <View style={tw`py-2 px-6`}>
            <Text centered mediumSmallPlus>
              You stashed{' '}
              <Text bold underlined mediumSmallPlus>
                ${balance.toFixed(2)}{' '}
              </Text>
              from{' '}
              <Text centered bold mediumSmallPlus>
                {brand?.name || ''}{' '}
              </Text>
              for{' '}
              <Text centered bold mediumSmallPlus>
                #{campaign?.cause?.name}
              </Text>
            </Text>
          </View>

          {footer()}
        </View>
      </View>
      {getStashlinkLoading && (
        <View
          style={tw`h-full w-full bg-white absolute justify-center items-center z-full`}
        >
          <LoadingIndicator />
        </View>
      )}
    </FadeInView>
  );

  const messageThankYou = () => (
    <FadeInView style={tw`flex-1`}>
      <View style={tw`bg-white flex-1 overflow-hidden`}>
        <Image
          style={tw`flex-1 h-full w-full absolute z-90 opacity-5`}
          source={{
            uri: addCloudinaryTransformation(
              campaign?.imageUrl,
              `h_${Math.ceil(deviceHeight)},w_${Math.ceil(deviceWidth)},c_fill`,
            ),
          }}
        />
        <View style={tw`z-200 absolute w-full`}>
          {topBar()}
          <View style={tw`self-center pt-28 w-full`}>
            <View
              style={tw`border-b-1 border-zinc-300 w-[270px] mb-4 self-center`}
            />
            <RoundedBorderedImage
              color={stashlinkUser?.haloColor || 'black'}
              type="outer"
              size={75}
              image={stashlinkUser?.profilePhoto}
              noShadow
            />

            {thankYouMessage && (
              <View
                style={tw.style(
                  `border-l-2 my-2 pl-2 mt-4 w-[300px] self-center`,
                  {
                    borderColor: stashlinkUser?.haloColor || 'black',
                  },
                )}
              >
                <Text small italicizedNotBold>
                  {thankYouMessage}
                </Text>
              </View>
            )}
          </View>

          {footer()}
        </View>
      </View>
      {getStashlinkLoading && (
        <View
          style={tw`h-full w-full bg-white absolute justify-center items-center z-full`}
        >
          <LoadingIndicator />
        </View>
      )}
    </FadeInView>
  );

  const videoThankYou = () => (
    <View style={tw`bg-white flex-1 overflow-hidden`}>
      <Video
        ref={video}
        style={tw`flex-1 h-full w-full absolute z-90`}
        resizeMode={ResizeMode.COVER}
        source={{
          uri: thankYouMessageVideo || '',
        }}
        isLooping
        shouldPlay
        isMuted={muted}
        videoStyle={tw`h-full w-full`}
        onLoad={() => {
          setVideoLoad(false);
        }}
      />
      {muted && (
        <View
          style={tw`absolute justify-center items-center z-300 h-full w-full`}
        >
          <Image source={images.videoPlay} style={tw`h-50px w-50px`} />
        </View>
      )}
      {topBar(true)}
      <Pressable style={tw`w-full h-full z-300`} onPress={onPressVideo} />
      {footer(true)}

      {videoLoad && (
        <View
          style={tw`h-full w-full bg-white absolute justify-center items-center z-full`}
        >
          <LoadingIndicator />
        </View>
      )}
    </View>
  );

  const renderScreen = () => {
    if (thankYouMessageVideo) {
      return videoThankYou();
    }
    if (thankYouMessage) {
      return messageThankYou();
    }
    return defaultThankyou();
  };

  return (
    <WebWrapper>
      {displayConfetti && (
        <Pressable
          style={tw`fixed scale-110 w-full h-full z-full -top-2 left-0`}
          onPress={onPressVideo}
        >
          <ConfettiCannon
            count={150}
            fadeOut
            fallSpeed={3000}
            colors={CONFETTI_COLORS}
            origin={{ x: 0, y: 0 }}
            explosionSpeed={0}
          />
        </Pressable>
      )}
      {renderScreen()}
    </WebWrapper>
  );
}
