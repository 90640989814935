/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import { useLogin } from 'react-facebook';
import { Dimensions, View, Image, Alert } from 'react-native';
import { Reoverlay } from 'reoverlay';

import images from '@/assets/images';
import {
  LoadingIndicator,
  PrimaryButton,
  Text,
  useSessionTokenContext,
} from '@/components/web';
import { eventProps } from '@/constants/ANALYTICS_CONST';
import useWebHooks from '@/hooks/useWebHooks';
import { CONNECT_TO_FACEBOOK } from '@/queries';
import { MERGE_SKELETON_USER } from '@/queries/merge-skeleton-user';
import { ConnectToFacebookData, MergeSkeletonUserData } from '@/types';
import {
  addCloudinaryTransformation,
  analytics,
  getStoreUserIdentifier,
  maxWidth,
  scroll,
  trackEvent,
} from '@/utils';
import tw from '@tw';
import { WebWrapper } from '@web/components';

const screenWidth = maxWidth(500);
const { height } = Dimensions.get('window');

const AuthIntro = () => {
  const { navigate, query } = useWebHooks();

  const [loading, setLoading] = useState(false);
  const campaignImage = query.get('imageUrl');
  const { login: loginFB, isLoading: fbLoading } = useLogin();
  const { setSessionToken, setUserId } = useSessionTokenContext();

  const [mergeSkeletonUser] =
    useMutation<MergeSkeletonUserData>(MERGE_SKELETON_USER);

  const [connectToFacebook] = useMutation<ConnectToFacebookData>(
    CONNECT_TO_FACEBOOK,
    {
      onCompleted: async (data) => {
        if (
          data?.connectToFacebook?.success &&
          data?.connectToFacebook?.token
        ) {
          if (data?.connectToFacebook?.user?.id) {
            if (data?.connectToFacebook?.newUser) {
              const userStoreIdentifier = getStoreUserIdentifier();
              analytics.createIdentity(
                userStoreIdentifier,
                data?.connectToFacebook?.user.id,
              );
              await mergeSkeletonUser({
                variables: {
                  userIdentifier: userStoreIdentifier,
                  userId: data?.connectToFacebook?.user.id,
                },
              });
            }
            await setUserId(data?.connectToFacebook?.user.id);
          }
          await setSessionToken(data?.connectToFacebook?.token);
          trackEvent('fbLoginSuccess');
          Reoverlay.hideAll();
        }
        if (!data?.connectToFacebook?.success) {
          trackEvent('fbLoginError');
          let errorMessage =
            'Something went wrong with logging in. Please try again later.';
          if (data?.connectToFacebook?.error?.length) {
            errorMessage = data?.connectToFacebook?.error;
          }
          Alert.alert('Error', errorMessage, [
            {
              text: 'OK',
            },
          ]);
        }
        setLoading(false);
      },
    },
  );

  async function handleLoginFB() {
    trackEvent('fbLoginRequest');
    try {
      const response = await loginFB({
        scope: 'email',
      });
      if (response.status === 'connected') {
        const data = response.authResponse as any;
        connectToFacebook({
          variables: {
            fbToken: data.accessToken,
            fbId: data.userID,
            fbAppId: '127837652546700',
            ...(data?.data_access_expiration_time && {
              fbExpirationDate: new Date(
                data?.data_access_expiration_time,
              ).toISOString(),
            }),
          },
        });
      }
    } catch (error: any) {
      alert(error.message);
    }
  }

  useEffect(() => {
    // Fix in-app browser issue
    if (window.location.hash) {
      const fbHash = queryString.parse(window.location.hash) as any;
      if (fbHash?.access_token) {
        setLoading(true);
        fetch(
          `https://graph.facebook.com/me?access_token=${fbHash?.access_token}`,
        )
          .then((res) => res.json())
          .then((res) => {
            if (res?.id) {
              connectToFacebook({
                variables: {
                  fbToken: fbHash.access_token,
                  fbId: res.id,
                  fbAppId: '127837652546700',
                  ...(fbHash?.data_access_expiration_time && {
                    fbExpirationDate: new Date(
                      fbHash.data_access_expiration_time * 1000,
                    ).toISOString(),
                  }),
                },
              });
            } else setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    }
    trackEvent('loginIntroViewed', {
      campaignId: query.get('campaignId'),
    });

    scroll.lock();
    return () => {
      scroll.enable();
      Reoverlay.hideAll();
    };
  }, []);

  if (loading || fbLoading) {
    return <LoadingIndicator />;
  }

  return (
    <WebWrapper analytics={{ [eventProps.page]: 'Login intro' }}>
      {campaignImage && (
        <Image
          style={tw.style('fixed w-full h-full bottom-0 opacity-5 z-0')}
          source={{
            uri: addCloudinaryTransformation(
              campaignImage,
              `h_${Math.ceil(height)},w_${Math.ceil(screenWidth)},c_fill`,
            ),
          }}
        />
      )}
      <Image
        source={images.stashrunLogoRing}
        style={tw`w-20 h-20 self-center mt-4`}
      />
      <View style={tw`flex flex-1 items-center p-7 pt-4 relative z-10`}>
        <Text bold mediumSmallPlus2 centered override={tw`mb-2 leading-none`}>
          Fund the causes you’re {'\n'} passionate about
        </Text>
        <Text small>
          <Text bold small>
            Login
          </Text>{' '}
          or{' '}
          <Text bold small>
            Create an account
          </Text>{' '}
          to make your
        </Text>
        <Text small>own links for charities you choose.</Text>
        <View style={tw`border-b-1 border-zinc-300 w-9/10 my-2 self-center`} />
        <View
          style={tw`px-8 flex-col mt-4 flex gap-3 justify-center align-middle`}
        >
          <PrimaryButton
            title="Continue with Meta"
            type="withBorder"
            onPress={handleLoginFB}
            reverse
            customRender={
              <Image
                source={images.meta}
                style={tw`w-7 h-7 absolute left-2.5`}
                resizeMode="contain"
              />
            }
          />
          <PrimaryButton
            title="Continue with Email"
            type="withBorder"
            onPress={() => {
              trackEvent('continueWithEmailButtonClicked');
              navigate('/auth/signup');
            }}
            reverse
            customRender={
              <Image
                source={images.email}
                style={tw`w-6 h-6 absolute left-3`}
                resizeMode="contain"
              />
            }
          />
          <Text xsmall centered override={tw`opacity-50 -mt-2 px-2 mb-1`}>
            By pressing ‘Continue’ you agree to our TOS and Privacy Policy.
          </Text>
          <PrimaryButton
            title="Login"
            type="withBorder"
            onPress={() => {
              trackEvent('loginButtonClicked');
              navigate('/auth/login');
            }}
          />
        </View>
      </View>
    </WebWrapper>
  );
};

export default AuthIntro;
