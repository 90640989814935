import { Buffer } from 'buffer';
import Constants from 'expo-constants';

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Device from 'expo-device';
import { Platform, Dimensions } from 'react-native';

import { getStoreUserIdentifier, isMobileDevice } from '@/utils';

import { getUserIdContext } from './webStorage';

const MIXPANEL_API_URL = 'https://api.mixpanel.com';

export class ExpoMixpanelAnalytics {
  ready = false;

  token: string;

  storageKey: string;

  userId?: string | null;

  clientId?: string;

  platform?: string;

  model?: string;

  queue: any[] = [];

  constants: { [key: string]: string | number | void } = {};

  superProps: any = {};

  brand?: string;

  constructor(token: string, storageKey = 'mixpanel:super:props') {
    this.storageKey = storageKey;

    this.token = token;
    this.userId = null;
    this.clientId = Constants.deviceId;
    this.constants = {
      app_build_number: Constants.manifest?.revisionId,
      app_id: Constants.manifest?.slug,
      app_name: Constants.manifest?.name,
      app_version_string: Constants.manifest?.version,
      device_name: Constants.deviceName,
      expo_app_ownership: Constants.appOwnership || undefined,
      os_version: Platform.Version,
    };

    Constants.getWebViewUserAgentAsync().then((userAgent) => {
      const { width, height } = Dimensions.get('window');
      Object.assign(this.constants, {
        screen_height: height,
        screen_size: `${width}x${height}`,
        screen_width: width,
        user_agent: userAgent,
      });

      this.brand = Device.brand || undefined;
      this.platform = Platform.OS;
      this.model = Device.modelName || undefined;

      AsyncStorage.getItem(this.storageKey, (_, result) => {
        if (result) {
          this.superProps = JSON.parse(result) || {};
        }

        this.ready = true;
        this._flush();
      });
    });
  }

  register(props: any) {
    this.superProps = props;
    AsyncStorage.setItem(this.storageKey, JSON.stringify(props));
  }

  track(name: string, props?: any) {
    this.queue.push({
      name,
      props,
    });
    this._flush();
  }

  identify(userId?: string) {
    this.userId = userId;
  }

  reset() {
    this.identify(this.clientId);
    AsyncStorage.setItem(this.storageKey, JSON.stringify({}));
  }

  people_set(props: any) {
    this._people('set', props);
  }

  people_set_once(props: any) {
    this._people('set_once', props);
  }

  people_unset(props: any) {
    this._people('unset', props);
  }

  people_increment(props: any) {
    this._people('add', props);
  }

  people_append(props: any) {
    this._people('append', props);
  }

  people_union(props: any) {
    this._people('union', props);
  }

  people_delete_user() {
    this._people('delete', '');
  }

  createIdentity(distinctId: string, anonId: string) {
    const options = {
      method: 'POST',
      headers: {
        accept: 'text/plain',
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        data: `{\n      "event": "$identify",\n      "properties": {\n          "$identified_id": "${distinctId}",\n          "$anon_id": "${anonId}",\n          "token": "${this.token}"\n      }\n}\n`,
      }),
    };

    return fetch(`${MIXPANEL_API_URL}/track#create-identity`, options);
  }

  // ===========================================================================================

  _flush() {
    if (this.ready) {
      while (this.queue.length) {
        const event = this.queue.pop();
        this._pushEvent(event).then(() => {
          event.sent = true;
        });
      }
    }
  }

  _people(operation: string, props: string) {
    if (this.userId) {
      const data = {
        $token: this.token,
        $distinct_id: this.userId,
      } as any;
      data[`$${operation}`] = props;

      this._pushProfile(data as any);
    }
  }

  _pushEvent(event: { name: any; props: any }) {
    const data = {
      event: event.name,
      properties: {
        ...this.constants,
        ...(event.props || {}),
        ...this.superProps,
      },
    };

    const userContextId = getUserIdContext();
    const userStoreIdentifier = getStoreUserIdentifier();
    data.properties.distinct_id = userContextId || userStoreIdentifier;
    data.properties.token = this.token;
    data.properties.client_id = this.clientId;
    if (this.platform) {
      data.properties.platform = this.platform;
    }
    if (this.model) {
      data.properties.model = this.model;
    }
    data.properties.isDesktop = !isMobileDevice();

    const buffer = Buffer.from(JSON.stringify(data)).toString('base64');

    return fetch(`${MIXPANEL_API_URL}/track/?data=${buffer}`);
  }

  _pushProfile(data: string) {
    const newData = Buffer.from(JSON.stringify(data)).toString('base64');
    return fetch(`${MIXPANEL_API_URL}/engage/?data=${newData}`);
  }
}
