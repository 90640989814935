import { gql } from '@apollo/client';

export const CREATE_USER_STATUS = gql`
  mutation CreateNewUserStatus($statusText: String!) {
    createNewUserStatus(input: { statusText: $statusText }) {
      success
      error
    }
  }
`;
