/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef, useEffect, forwardRef } from 'react';

import { useMutation } from '@apollo/client';
import { ImageEditor } from 'expo-image-editor';
import * as ImagePicker from 'expo-image-picker';
import _, { range } from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { View, Pressable, Image, ScrollView } from 'react-native';

import images from '@/assets/images';
import { Input, InputProps, PrimaryButton, Text } from '@/components/web';
import { useUserCTX } from '@/contexts';
import useUser from '@/hooks/useUser';
import { SET_PINNED_CAUSES, UPDATE_USER_PROFILE } from '@/queries';
import { Cause, SetPinnedCause } from '@/types';
import { COLORS, maxWidth, trackEvent } from '@/utils';
import tw from '@tw';
import { WebWrapper } from '@web/components';

type ProfileFields = {
  fullName: string | undefined;
  description: string | undefined;
  email: string | undefined;
  password: string | undefined;
  passwordConfirmation: string | undefined;
  instagramUsername: string | undefined;
  twitterUsername: string | undefined;
  facebookUsername: string | undefined;
  youtubeUsername: string | undefined;
  tiktokUsername: string | undefined;
  featuredUrl: string | undefined;
  birthdate: string | undefined;
};

type PinnedCause = {
  name: string;
  id: string;
};

const EditProfile = () => {
  const [updateProfile, { loading: isUpdatingProfile }] =
    useMutation(UPDATE_USER_PROFILE);
  const [setPinnedCause, { loading: SetPinnedCausesLoading }] =
    useMutation<SetPinnedCause>(SET_PINNED_CAUSES);
  const [imageUri, setImageUri] = useState<string | undefined>(undefined);
  const [editorVisible, setEditorVisible] = useState(false);

  const launchEditor = (uri: string) => {
    // Then set the image uri
    setImageUri(uri);
    // And set the image editor to be visible
    setEditorVisible(true);
  };

  const { userData, userRefetch } = useUserCTX();
  const { fetchUserCampaign, campaignsData } = useUser();

  const initialProfile = useRef<ProfileFields>({
    fullName: undefined,
    email: undefined,
    password: undefined,
    passwordConfirmation: undefined,
    description: undefined,
    instagramUsername: undefined,
    twitterUsername: undefined,
    facebookUsername: undefined,
    youtubeUsername: undefined,
    tiktokUsername: undefined,
    featuredUrl: undefined,
    birthdate: undefined,
  });
  const [profileFields, setProfileFields] = useState<ProfileFields>(
    initialProfile.current,
  );

  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [areFieldsValid, setFieldsValid] = useState({
    fullName: true,
    email: true,
    password: true,
    passwordConfirmation: true,
    description: true,
    instagramUsername: true,
    twitterUsername: true,
    facebookUsername: true,
    youtubeUsername: true,
    tiktokUsername: true,
    featuredUrl: true,
    birthdate: true,
  });

  const [masterCampaignCauses, setMasterCampaignCauses] = useState<Cause[]>();
  const [savedPinnedCauses, setSavedPinnedCauses] = useState<PinnedCause[]>();

  useEffect(() => {
    fetchUserCampaign({ variables: { userId: userData?.user?.id } });
  }, []);

  useEffect(() => {
    if (campaignsData) {
      const campaignCauses = _.uniqBy(
        campaignsData?.campaignsSearch?.map((c) => c.cause!) || [],
        'name',
      );
      setMasterCampaignCauses(campaignCauses);
    }
  }, [campaignsData]);

  useEffect(() => {
    if (userData) {
      const currentProfile = {
        fullName: userData?.user?.fullName,
        description: userData?.user?.description,
        email: userData?.user?.email,
        password: undefined,
        passwordConfirmation: undefined,
        instagramUsername: userData?.user?.instagramUsername,
        twitterUsername: userData?.user?.twitterUsername,
        facebookUsername: userData?.user?.facebookUsername,
        youtubeUsername: userData?.user?.youtubeUsername,
        tiktokUsername: userData?.user?.tiktokUsername,
        featuredUrl: userData?.user?.featuredUrl,
        birthdate: userData?.user?.birthdate,
      };
      initialProfile.current = currentProfile;
      setProfileFields(currentProfile);
      setSavedPinnedCauses(userData?.user?.pinnedCauses as PinnedCause[]);
      if (!userData?.user?.birthdate) {
        setFieldsValid((prev) => ({ ...prev, birthdate: false }));
      }
    }
  }, [userData]);

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.85,
    });

    if (!result.canceled) {
      if (imageUri) {
        setImageUri(undefined);
        setTimeout(() => {
          launchEditor(result.assets[0].uri);
        });
      } else {
        launchEditor(result.assets[0].uri);
      }
    }
  };

  const handleChange = ({
    key,
    value,
  }: {
    key: keyof ProfileFields;
    value: string | boolean;
  }) => {
    setProfileFields((prevState: ProfileFields) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const validateEmail = (input: string) => {
    if (!input) {
      setFieldsValid((prev) => ({ ...prev, email: true }));
      return true;
    }

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = emailRegex.test(String(input).toLowerCase());
    setFieldsValid((prev) => ({ ...prev, email: isValid }));
    return isValid;
  };

  const validateFullName = (input: string) => {
    return setFieldsValid((prev) => ({
      ...prev,
      fullName: input?.length >= 1,
    }));
  };

  const validatePassword = (input: string) => {
    if (!input || input?.length === 0) {
      setFieldsValid((prev) => ({ ...prev, password: true }));
      return true;
    }
    const isValid = input?.length >= 8 && input?.length <= 72;
    setFieldsValid((prev) => ({ ...prev, password: isValid }));
    return isValid;
  };

  const validatePasswordConfirmation = (input: string) => {
    if (!input || input?.length === 0) {
      setFieldsValid((prev) => ({ ...prev, passwordConfirmation: true }));
      return true;
    }
    const isValid = input === profileFields?.password;
    setFieldsValid((prev) => ({ ...prev, passwordConfirmation: isValid }));
    return isValid;
  };

  const validateFeatureUrl = (input: string) => {
    if (!input) {
      setFieldsValid((prev) => ({ ...prev, featuredUrl: true }));
      return true;
    }
    const websiteRegex =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w-]+)+[\w\-_~:/?#[\]@!&',;=.]+$/gim;
    const valid = !!input.toLowerCase().match(websiteRegex);
    setFieldsValid((prev) => ({ ...prev, featuredUrl: valid }));
    return valid;
  };

  const normalizeUsername = (input: string) => {
    // eslint-disable-next-line no-useless-escape
    return input.toLowerCase().replace(/[^a-zA-Z0-9_\.\-]/g, '');
  };

  function dataURLtoFile(dataurl: string | null, filename: string) {
    if (!dataurl) return null;
    const arr = dataurl.split(',');
    const mime = arr[0]?.match(/:(.*?);/)?.[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const handleSave = async () => {
    let validator = true;
    Object.values(areFieldsValid).forEach((item) => {
      if (!item) {
        validator = false;
      }
    });
    if (validator) {
      try {
        trackEvent('updateProfileclicked');
        const response = await updateProfile({
          variables: {
            input: {
              ...(profileImageUrl
                ? {
                    profileImageUrl: dataURLtoFile(
                      profileImageUrl,
                      'avatar.jpeg',
                    ),
                  }
                : {}),
              ...profileFields,
            },
          },
        });
        const pinnedCauseIds = savedPinnedCauses?.map((c: any) => c.id);
        await setPinnedCause({
          variables: { pinnedCauseIds },
        });
        if (response?.data?.updateProfile?.success) {
          window.history.back();
          userRefetch();
        } else {
          alert(response?.data?.updateProfile?.error);
        }
      } catch (error) {
        alert(error);
      }
    } else {
      alert('Please check that all fields have been filled out properly');
    }
  };

  const INPUTS: Array<
    { key: string; normalize?: boolean } & Partial<InputProps>
  > = [
    {
      key: 'fullName',
      label: 'Your name',
      placeholder: 'your name here',
      errorMessage: 'do not leave it empty, please',
      validator: validateFullName,
      isValid: areFieldsValid.fullName,
      value: profileFields.fullName,
    },
    {
      key: 'description',
      label: 'Bio',
      placeholder: 'your bio here',
      maxLength: 140,
      value: profileFields.description,
      multiline: true,
      inputStyle: tw`border rounded px-2 mt-1 h-20 border-dividerGray`,
    },
    {
      key: 'featuredUrl',
      label: 'Featured link',
      placeholder: 'https://example.com',
      errorMessage: 'please input a valid link',
      validator: validateFeatureUrl,
      isValid: areFieldsValid.featuredUrl,
      value: profileFields.featuredUrl?.toLowerCase(),
    },
    {
      key: 'email',
      label: 'Email',
      placeholder: 'stasher@stashtest.com',
      errorMessage: 'should be a valid email',
      keyboardType: 'email-address',
      validator: validateEmail,
      isValid: areFieldsValid.email,
      value: profileFields.email,
    },
    {
      key: 'instagramUsername',
      label: 'Instagram',
      placeholder: '@instagram_username',
      value:
        profileFields.instagramUsername &&
        `@${profileFields.instagramUsername?.toLowerCase()}`,
      normalize: true,
    },
    {
      key: 'twitterUsername',
      label: 'Twitter',
      placeholder: '@twitter_username',
      value:
        profileFields.twitterUsername &&
        `@${profileFields.twitterUsername?.toLowerCase()}`,
      normalize: true,
    },
    {
      key: 'facebookUsername',
      label: 'Facebook',
      placeholder: '@facebook.username',
      value:
        profileFields.facebookUsername &&
        `@${profileFields.facebookUsername?.toLowerCase()}`,
      normalize: true,
    },
    {
      key: 'youtubeUsername',
      label: 'Youtube',
      placeholder: '@youtube-username',
      value:
        profileFields.youtubeUsername &&
        `@${profileFields.youtubeUsername?.toLowerCase()}`,
      normalize: true,
    },
    {
      key: 'tiktokUsername',
      label: 'Tiktok',
      placeholder: '@tiktok_username',
      value:
        profileFields.tiktokUsername &&
        `@${profileFields.tiktokUsername?.toLowerCase()}`,
      normalize: true,
    },
    {
      key: 'password',
      label: 'New password',
      placeholder: 'new password',
      value: profileFields.password,
      validator: validatePassword,
      isValid: areFieldsValid.password,
      errorMessage: 'password needs to be between 8 and 72 characters long',
      secureTextEntry: true,
    },
    {
      key: 'passwordConfirmation',
      label: 'Re-enter password',
      placeholder: 're-enter password',
      value: profileFields.passwordConfirmation,
      validator: validatePasswordConfirmation,
      isValid: areFieldsValid.passwordConfirmation,
      errorMessage: 'passwords does not match',
      secureTextEntry: true,
    },
  ];

  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <Pressable
      style={tw.style(
        `border border-dividerGray rounded px-2 mt-1 w-auto h-36px w-${
          maxWidth(500) - 50
        }px items-center justify-center flex-row`,
        !profileFields?.birthdate && 'border-red',
      )}
      onPress={onClick}
      ref={ref}
    >
      <Text small override={tw`font-medium w-full pt-0.5`}>
        {value}
      </Text>
      <Image source={images.calendar} style={tw`h-5 w-5 justify-end`} />
    </Pressable>
  ));

  const currentYear = new Date();
  const years = range(currentYear.getFullYear(), 1920);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const changeDate = (date: any) => {
    if (date) {
      setFieldsValid((prev) => ({ ...prev, birthdate: true }));
      setProfileFields((prev) => ({ ...prev, birthdate: date }));
    }
  };

  return (
    <WebWrapper withBack>
      <View style={tw`flex flex-1 flex-col items-center p-6 pb-32`}>
        <View style={tw`w-28 h-28 rounded-full border-4 border-primary p-1`}>
          <Image
            source={
              profileImageUrl ||
              userData?.user?.profilePhoto ||
              images.defaultProfilePic
            }
            style={tw`w-full h-full rounded-full`}
          />
        </View>
        <Pressable
          onPress={pickImage}
          style={tw`border-2 border-primary rounded py-1.5 px-4 mt-4 mb-2`}
        >
          <Text bold xsmallPlus2 color={COLORS.purple}>
            Change profile photo
          </Text>
        </Pressable>
        <View style={tw`mb-10`}>
          <Text xsmallPlus2 centered>
            For an optimized profile,{'\n'}we suggest an image dimensions of
            600px x 600px.
          </Text>
        </View>
        <ImageEditor
          visible={editorVisible}
          onCloseEditor={() => setEditorVisible(false)}
          imageUri={imageUri}
          fixedCropAspectRatio={1}
          lockAspectRatio
          minimumCropDimensions={{
            width: 100,
            height: 100,
          }}
          onEditingComplete={(result) => {
            setProfileImageUrl(result.uri);
          }}
          mode="full"
        />
        {INPUTS.map((item, index) => {
          if (index === 3) {
            return (
              <View style={tw`mb-6 z-full`} key={'birthday-field'}>
                <Text mediumSmall override={tw`text-left`}>
                  Birthday
                </Text>
                <DatePicker
                  selected={
                    profileFields.birthdate &&
                    new Date(profileFields?.birthdate)
                  }
                  maxDate={currentYear}
                  onChange={(date: any) => changeDate(date)}
                  customInput={<ExampleCustomInput />}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }: any) => (
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {'<'}
                      </button>

                      <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) => {
                          changeMonth(months.indexOf(value));
                        }}
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {'>'}
                      </button>
                    </div>
                  )}
                />
              </View>
            );
          }
          return (
            <Input
              style={tw`mb-2 w-full`}
              inputStyle={tw`border border-dividerGray rounded px-2 mt-1`}
              autocapitalize="none"
              autoCorrect={false}
              labelSize="mediumSmall"
              {...item}
              key={item.key}
              secureTextEntry={item.secureTextEntry}
              onChange={(text) =>
                handleChange({
                  key: item.key as keyof ProfileFields,
                  value: item.normalize ? normalizeUsername(text) : text,
                })
              }
            />
          );
        })}

        <Text mediumSmall override={tw`mb-2 w-full`}>
          Edit pinned causes
        </Text>

        <ScrollView
          horizontal
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            maxWidth: maxWidth(500),
            paddingLeft: 20,
            marginTop: 8,
          }}
        >
          {masterCampaignCauses?.map((c, i) => {
            const isActive = savedPinnedCauses?.some(
              (filterName: { id?: string }) => filterName.id === c.id,
            );
            return (
              <Pressable
                key={i}
                style={tw.style(
                  `bg-gray px-8px py-4px mr-10px rounded-full`,
                  isActive && 'bg-tertiaryPurple',
                )}
                onPress={() => {
                  if (!isActive) {
                    setSavedPinnedCauses((oldArray: any) => {
                      return [...oldArray, { id: c.id, name: c.name }];
                    });
                  } else {
                    setSavedPinnedCauses((oldArray: any) => {
                      const spliceArray = oldArray;
                      spliceArray?.forEach(
                        (splicingElement: any, i: number) => {
                          if (splicingElement.id === c.id) {
                            spliceArray.splice(i, 1);
                          }
                        },
                      );
                      return [...spliceArray];
                    });
                  }
                }}
              >
                <Text
                  small
                  bold
                  color={isActive ? COLORS.purple : COLORS.alternativeText}
                >
                  #{c.name || ''}
                </Text>
              </Pressable>
            );
          })}
        </ScrollView>
      </View>
      <View
        style={tw.style(
          `fixed w-full bg-white bottomSheetShadow self-center items-center bottom-0 pt-4 pb-7 rounded-tl-[20px] rounded-tr-[20px]`,
        )}
      >
        <PrimaryButton
          fullRadius
          style="w-full"
          title="Save and Continue"
          loading={isUpdatingProfile || SetPinnedCausesLoading}
          onPress={handleSave}
        />
      </View>
    </WebWrapper>
  );
};

export default EditProfile;
