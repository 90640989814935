export const abbreviateNumber = (num: number | undefined, fixed: number) => {
  if (num === 0 || !num) {
    return '0';
  } // terminate early
  const fixedNumber = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  const b = num.toPrecision(2).split('e'); // get power
  const k =
    b.length === 1 ? 0 : Math.floor(Math.min(Number(b[1].slice(1)), 14) / 3); // floor at decimals, ceiling at trillions
  const c =
    k < 1
      ? Number(num.toFixed(0 + fixedNumber))
      : Number((num / 10 ** (k * 3)).toFixed(1 + fixedNumber)); // divide by power
  const d = c < 0 ? c : Math.abs(c); // enforce -0 is 0
  const e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
  return e;
};

export const padLeadingZeros = (num: number, size: number): string => {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
};

export const numberWithCommas = (x: number) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberShortened = (x: number) => {
  const moreThan10000 = x > 10000 ? `${(x / 1000).toFixed(1)}K` : x;
  return x > 1000000 ? `${(x / 1000000).toFixed(1)}M` : moreThan10000;
};

/* options needs to be of format 'w_300,h_100' for example */
export const addCloudinaryTransformation = (
  imageUrl?: string,
  options?: string,
) => {
  if (!imageUrl || !imageUrl?.includes('cloudinary')) {
    return imageUrl || '';
  }

  const splitImageUrl = imageUrl.split('/upload/', 2);

  return `${splitImageUrl[0]}/upload/${options}/${splitImageUrl[1]}`;
};

export const nFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};
