/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { createUploadLink } from 'apollo-upload-client';
import { useFonts } from 'expo-font';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FacebookProvider } from 'react-facebook';
import { Animated, View, Image, Text, StyleSheet } from 'react-native';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ModalContainer, Reoverlay } from 'reoverlay';
import { v4 as uuid } from 'uuid';

import images from '@/assets/images';
import { SessionTokenProvider, useSessionTokenContext } from '@/components/web';
import { UserProvider } from '@/contexts';
import {
  analytics,
  getStoreUserIdentifier,
  isMobileDevice,
  setStoreUserIdentifier,
} from '@/utils';
import { customFetch } from '@/utils/customApolloUploadProgress';
import { growthbook, isFeatureEnabled } from '@/utils/growthbook';
import tw from '@tw';
import AuthOverview from '@web/Auth/AuthOverview';
import ResetPasswordPage from '@web/Auth/ResetPasswordPage';
import {
  Auth,
  CampaignProfile,
  CoinGame,
  NotFound,
  CreateStashlink,
  Leaderboard,
} from '@web/index';
import {
  BrandModal,
  ProfileModal,
  StashrunModal,
  WebShareModal,
  WaitlistFormModal,
  MatchDonationModal,
  LogoutModal,
  PasscodeModal,
  ThankYouModal,
  ErrorModal,
  VideoMessageUploadModal,
  AlertModal,
  HeadToHeadBattleModal,
} from '@web/modals';
import WaitlistModal from '@web/modals/WaitlistModal';
import ProfileExplainer from '@web/ProfileExplainer';
import TiktokRedirect from '@web/TiktokRedirect';
import TiktokTestLink from '@web/TiktokTestLink';
import VideoPreview from '@web/VideoPreview';

import splashLogo from './src/assets/splash_logo_3.png';

Reoverlay.config([
  {
    name: 'StashrunModal',
    component: StashrunModal,
  },
  {
    name: 'BrandModal',
    component: BrandModal,
  },
  {
    name: 'ProfileModal',
    component: ProfileModal,
  },
  {
    name: 'ShareModal',
    component: WebShareModal,
  },
  {
    name: 'WaitlistFormModal',
    component: WaitlistFormModal,
  },
  {
    name: 'MatchDonationModal',
    component: MatchDonationModal,
  },
  {
    name: 'LogoutModal',
    component: LogoutModal,
  },
  {
    name: 'HeadToHeadBattleModal',
    component: HeadToHeadBattleModal,
  },
  {
    name: 'WaitlistModal',
    component: WaitlistModal,
  },
  { name: 'PasscodeModal', component: PasscodeModal },
  { name: 'ThankYouModal', component: ThankYouModal },
  { name: 'VideoMessageUploadModal', component: VideoMessageUploadModal },
  { name: 'ErrorModal', component: ErrorModal },
  { name: 'AlertModal', component: AlertModal },
]);

function getApolloClient(sessionToken?: string) {
  const defaultClientOptions = {
    link: createUploadLink({
      uri: `https://api.stashrun.com/graphql`,
      headers: {
        ...(sessionToken && { Authorization: `Bearer ${sessionToken}` }),
      },
      fetch: customFetch,
    }) as any,
    cache: new InMemoryCache(),
  };

  return new ApolloClient({
    ...defaultClientOptions,
  });
}

function App() {
  const { sessionToken, userId } = useSessionTokenContext();
  const [isLandscape, setIsLandscape] = useState(false);
  const [splashDone, setSplashDone] = useState(
    !!localStorage?.getItem('splash'),
  );
  const loadingProgress = React.useRef(new Animated.Value(0)).current;

  const [loaded] = useFonts({
    'Avenir-Regular': require('./src/assets/fonts/Avenir-Regular.ttf'),
    'Avenir-BlackOblique': require('./src/assets/fonts/Avenir-BlackOblique.ttf'),
    'Avenir-Oblique': require('./src/assets/fonts/Avenir-Oblique.ttf'),
    'Avenir-Heavy': require('./src/assets/fonts/Avenir-Heavy.ttf'),
    'Avenir-Black': require('./src/assets/fonts/Avenir-Black.ttf'),
    'Avenir-Medium': require('./src/assets/fonts/Avenir-Medium.ttf'),
    'Inter-Regular': require('./src/assets/fonts/Inter-Regular-01.ttf'),
    'Inter-Black': require('./src/assets/fonts/Inter-Black-02.ttf'),
    'Inter-Bold': require('./src/assets/fonts/Inter-Bold-15.ttf'),
    'Inter-ExtraBold': require('./src/assets/fonts/Inter-ExtraBold-17.ttf'),
    'Inter-Light': require('./src/assets/fonts/Inter-Light-07.ttf'),
    'Inter-Medium': require('./src/assets/fonts/Inter-Medium-11.ttf'),
    'Inter-SemiBold': require('./src/assets/fonts/Inter-SemiBold-13.ttf'),
    'Inter-Thin': require('./src/assets/fonts/Inter-Thin-05.ttf'),
  });

  const checkLandscapeMode = () => {
    if (
      isMobileDevice() &&
      window.screen.availWidth >= window.screen.availHeight
    ) {
      setIsLandscape(true);
    } else {
      setIsLandscape(false);
    }
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
    body, div, img {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      font-family: sans-serif;
    }`;
    document.head.append(style);
    checkLandscapeMode();
    window.addEventListener('resize', checkLandscapeMode);
    return () => {
      window.removeEventListener('resize', checkLandscapeMode);
    };
  }, []);

  // assign identifier and anon id for mixpanel
  useEffect(() => {
    if (userId) {
      analytics.identify(userId);
    } else {
      const userStoreIdentifier = getStoreUserIdentifier();
      if (!userStoreIdentifier) {
        const identifier = uuid();
        setStoreUserIdentifier(identifier);
      }
    }
  }, []);

  useEffect(() => {
    growthbook.setAttributes({
      id: userId,
      loggedIn: !!userId,
    });
  }, [userId]);

  useEffect(() => {
    if (!localStorage?.getItem('splash')) {
      Animated.timing(loadingProgress, {
        duration: 900,
        toValue: 100,
        useNativeDriver: true,
        delay: 300,
      }).start(() => setSplashDone(true));
      localStorage?.setItem('splash', 'true');
    }
  }, []);

  useEffect(() => {
    // Load features asynchronously when the app renders
    const initGrowthbook = async () => {
      await growthbook.loadFeatures();
    };
    initGrowthbook();
  }, []);

  const enabled = isFeatureEnabled('test-feature');

  if (!loaded) {
    return null;
  }

  const splashImageScale = {
    transform: [
      {
        scale: loadingProgress.interpolate({
          inputRange: [1, 15, 100],
          outputRange: [1, 0.5, 30],
        }),
      },
      {
        translateY: loadingProgress.interpolate({
          inputRange: [1, 100],
          outputRange: [1, 30],
        }),
      },
    ],
  };

  const splashOpacity = {
    opacity: loadingProgress.interpolate({
      inputRange: [0, 25, 50, 100],
      outputRange: [1, 1, 0.8, 0],
      extrapolate: 'clamp',
    }),
  };

  const splashScreen = (
    <Animated.View
      style={[
        StyleSheet.absoluteFill,
        {
          backgroundColor: '#222222',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        },
        splashOpacity,
      ]}
    >
      <Animated.Image
        style={[
          {
            height: '40%',
            width: 140,
            resizeMode: 'contain',
          },
          splashImageScale,
          splashOpacity,
        ]}
        source={splashLogo}
      />
    </Animated.View>
  );

  // If it is a route that has a splash screen, don't show the splash screen
  if (
    !splashDone &&
    !window.location.pathname.startsWith('/users/') &&
    !window.location.pathname.startsWith('/referrer/') &&
    !window.location.pathname.startsWith('/campaign/')
  ) {
    return splashScreen;
  }

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <ApolloProvider client={getApolloClient(sessionToken || undefined)}>
        <FacebookProvider appId="127837652546700">
          <UserProvider>
            <Router basename="/">
              <View style={{ flex: 1 }}>
                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Routes>
                  <Route
                    path="/users/:users/:campaignLink/:secondaryReferrer?"
                    element={<CampaignProfile />}
                  />
                  <Route
                    path="/referrer/:users/:campaignLink/:secondaryReferrer?"
                    element={<CampaignProfile referrer />}
                  />
                  <Route
                    path="/coingame/:campaignLink"
                    element={<CoinGame />}
                  />
                  <Route
                    path="/halo-explainer/:users"
                    element={<ProfileExplainer />}
                  />
                  <Route path="/create-stash/*" element={<CreateStashlink />} />
                  <Route
                    path="/campaign/:campaignLink"
                    element={<CampaignProfile />}
                  />
                  <Route path="/leaderboard" element={<Leaderboard />} />
                  <Route path="/auth/*" element={<Auth />} />
                  <Route path="/home" element={<AuthOverview />} />
                  <Route path="/tiktok/callback" element={<TiktokRedirect />} />
                  <Route path="/test/test" element={<TiktokTestLink />} />
                  <Route
                    path="/resetPassword/:userEmail/:userForgotPasswordCode"
                    element={<ResetPasswordPage />}
                  />
                  <Route path="/video-preview" element={<VideoPreview />} />

                  {enabled && (
                    <Route
                      path="/growthbook/test"
                      element={
                        <View>
                          <Text>Growthbook enabled</Text>
                        </View>
                      }
                    />
                  )}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </View>
            </Router>
            {isLandscape && (
              <View
                style={tw`fixed top-0 left-0 w-full h-full bg-primary flex items-center justify-center`}
              >
                <Image
                  source={images.icons.rotatePhone}
                  style={tw`w-24 h-24`}
                />
                <Text style={tw`font-blackItalic text-white text-xl`}>
                  Please rotate your phone
                </Text>
              </View>
            )}
            <ModalContainer />
          </UserProvider>
        </FacebookProvider>
      </ApolloProvider>
    </GrowthBookProvider>
  );
}

export default function AppWithSession() {
  return (
    <SessionTokenProvider>
      <App />
    </SessionTokenProvider>
  );
}
