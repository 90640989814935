import React from 'react';

import { Image } from 'expo-image';
import {
  StyleSheet,
  View,
  ViewStyle,
  TouchableOpacity,
  Pressable,
} from 'react-native';
import { Reoverlay } from 'reoverlay';

import images from '@/assets/images';
import StashrunTitleImg from '@/assets/Stashrun-title.png';
import { Text } from '@/components/web';
import { useUserCTX } from '@/contexts';
import useWebHooks from '@/hooks/useWebHooks';
import {
  maxWidth,
  trackEvent,
  COLORS,
  profileHaloColorPicker,
  addCloudinaryTransformation,
  abbreviateNumber,
} from '@/utils';
import tw from '@tw';

type PropsT = {
  children: React.ReactNode;
  analytics?: Record<string, any>;
  style?: ViewStyle;
  hiddenHeader?: boolean;
  withBack?: boolean;
};

const WebWrapper = ({
  children,
  style,
  hiddenHeader = false,
  analytics: analyticsProps = {},
  withBack,
}: PropsT) => {
  const { navigate } = useWebHooks();
  const { userData } = useUserCTX();
  const addBack = withBack && window.history.length > 0;
  const haloProperties = profileHaloColorPicker(
    userData?.user?.impactLevel || 0,
    userData?.user?.percentToNextLevel || 0,
    true,
  );

  const handleTopRightButton = () => {
    if (window.location.pathname.includes('/coingame')) {
      return null;
    }

    if (userData) {
      return (
        <TouchableOpacity
          style={tw`relative justify-center`}
          onPress={() => {
            if (window.location.pathname === '/home') {
              trackEvent('profileButtonClicked', analyticsProps);
              Reoverlay.showModal('ProfileModal', {
                user: userData?.user,
                haloProperties,
                editable: true,
                navigate,
                showImpactPage: true,
              });
            } else {
              navigate('/home');
            }
          }}
        >
          <View
            style={tw.style(
              'absolute rounded-2xl py-1 pl-2.5 pr-7 right-full -mr-5',
              {
                backgroundColor: haloProperties.haloColor,
              },
            )}
          >
            <Text small bold inverse numberOfLines={1} override={tw`mt-0.5`}>
              {abbreviateNumber(userData.user?.impactScore || 0, 0)} Impact
            </Text>
          </View>
          <View
            style={tw.style(
              'border-2 rounded-full p-0.5 relative z-10 bg-white',
              {
                borderColor: haloProperties.haloColor,
              },
            )}
          >
            <Image
              source={
                userData?.user?.profilePhoto
                  ? addCloudinaryTransformation(
                      userData.user.profilePhoto,
                      'c_scale,w_32,dpr_3',
                    )
                  : images.defaultProfilePic
              }
              contentFit="cover"
              style={tw`w-7 h-7 rounded-full`}
            />
          </View>
        </TouchableOpacity>
      );
    }

    if (
      window.location.pathname === '/auth' ||
      window.location.pathname === '/leaderboard'
    ) {
      return (
        <TouchableOpacity
          style={tw`w-25 h-7 rounded justify-center items-center bg-purple`}
          onPress={() => {
            trackEvent('whatStashrunButtonClicked', analyticsProps);
            Reoverlay.showModal('StashrunModal');
          }}
        >
          <Text xsmallPlus2 italicized inverse override={tw`mt-1px`}>
            How It Works
          </Text>
        </TouchableOpacity>
      );
    }

    return (
      <TouchableOpacity
        style={tw`w-25 h-7 rounded justify-center items-center bg-purple`}
        onPress={() => {
          trackEvent('whatStashrunButtonClicked', analyticsProps);
          Reoverlay.showModal('StashrunModal');
        }}
      >
        <Text xsmallPlus2 italicized inverse override={tw`mt-1px`}>
          How It Works
        </Text>
      </TouchableOpacity>
    );

    // return (
    //   <TouchableOpacity
    //     style={tw`py-2 rounded flex flex-row justify-center items-center bg-black px-4 space-x-4`}
    //     onPress={() => {
    //       navigate('/leaderboard');
    //     }}
    //   >
    //     <Ionicons name="trophy" size={20} color={'#fff'} />
    //     <Text xsmallPlus2 italicized inverse override={tw`ml-2`}>
    //       Leaderboard
    //     </Text>
    //   </TouchableOpacity>
    // );
  };

  return (
    <View style={[styles.container]}>
      <View style={styles.centerContainer}>
        {!hiddenHeader && (
          <View style={tw.style('relative', styles.header)}>
            <View style={tw`flex flex-row items-center`}>
              {addBack && (
                <Pressable
                  onPress={() => {
                    if (addBack) window.history.back();
                  }}
                >
                  <Image source={images.fillBack} style={tw`w-7 h-7 mr-2.5`} />
                </Pressable>
              )}
              <Pressable
                onPress={() => {
                  if (window.location.pathname === '/auth') {
                    Reoverlay.showModal('StashrunModal');
                  } else {
                    navigate('/auth');
                  }
                }}
              >
                <Image
                  style={tw.style('w-32 h-6', addBack && 'w-20 h-5')}
                  contentFit="contain"
                  source={StashrunTitleImg}
                />
              </Pressable>
            </View>

            {handleTopRightButton()}
          </View>
        )}
        <View style={[styles.content, style]}>{children}</View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.tertiaryText,
  },
  centerContainer: {
    backgroundColor: COLORS.white,
    alignSelf: 'center',
    width: '100%',
    height: '100%',
    maxWidth: maxWidth(500),
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flex: 1,
    position: 'relative',
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 10,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.tertiaryText,
    height: 50,
    width: '100%',
    maxWidth: maxWidth(500),
    backgroundColor: COLORS.white,
    zIndex: 100,
  },
  logo: {
    height: 24,
    width: 132,
  },
});

export default WebWrapper;
