import React, { useEffect } from 'react';

import { View } from 'react-native';

import {
  DetailsTable,
  DisclosuresButton,
  EntityDescription,
  SlideUpModal,
  Text,
} from '@/components/web';
import { Charity } from '@/types';
import { COLORS, scroll } from '@/utils';
import tw from '@tw';
import RoundedBorderedImage from '@web/components/RoundedBorderedImage';

type BrandModalProps = {
  charity: Charity;
  disclosuresUrl?: string;
};

const BrandModal = ({ charity, disclosuresUrl }: BrandModalProps) => {
  const {
    name,
    activeSince,
    location,
    registeredName,
    imageUrl,
    charityColor,
    description,
    website,
    volunteerUrl,
    facebookUsername,
    twitterUsername,
    youtubeUsername,
    instagramUsername,
    tiktokUsername,
    linkedinUrl,
  } = charity || {};

  const charityDetails = [
    { label: 'Registered name', value: registeredName || name },
    { label: 'Location', value: location || '?' },
    { label: 'Founded in', value: activeSince || '?' },
  ];

  useEffect(() => {
    scroll.lock();
    return () => {
      scroll.enable();
    };
  }, []);

  return (
    <SlideUpModal>
      <View style={tw`px-20px pt-40px items-center`}>
        <RoundedBorderedImage
          color={charityColor || 'black'}
          type="outer"
          size={120}
          image={imageUrl}
          noShadow
        />
        <View style={tw`py-8px items-center mt-1 w-full`}>
          <Text mediumSmallPlus bold numberOfLines={1}>
            {name || ''}
          </Text>
          <Text small numberOfLines={1} color={COLORS.secondaryText}>
            {location || ''}
          </Text>
        </View>
        <EntityDescription
          description={description}
          website={website}
          color={charityColor || 'black'}
          volunteerUrl={volunteerUrl}
          facebookUsername={facebookUsername}
          twitterUsername={twitterUsername}
          instagramUsername={instagramUsername}
          youtubeUsername={youtubeUsername}
          tiktokUsername={tiktokUsername}
          linkedinUrl={linkedinUrl}
          entity="Charity"
        />
      </View>
      <View style={tw`px-5 pb-5`}>
        <DetailsTable
          details={charityDetails}
          supportedCauses={charity?.supportedCauses}
        />
      </View>
      {disclosuresUrl && (
        <View style={tw`pb-3`}>
          <DisclosuresButton link={disclosuresUrl} webStyle />
        </View>
      )}
    </SlideUpModal>
  );
};

export default BrandModal;
