import React, { createContext, useContext, useEffect } from 'react';

import { useSessionTokenContext } from '@/components/web';
import { useLogout } from '@/hooks/useLogout';
import useUser from '@/hooks/useUser';
import { User } from '@/types';

const defaultState = {
  userId: undefined,
  userData: undefined,
  userRefetch: () => false,
};

type UserContextType = {
  userId?: undefined | null | string;
  userData?: { user?: User };
  userRefetch: () => void;
};

const UserContext = createContext<UserContextType>(defaultState);

export const SESSION_TOKEN_KEY = 'SESSION_TOKEN';
export const USER_ID_KEY = 'USER_ID';
export const REFERRAL_CODE_KEY = 'REFERRAL_CODE';

type UserProviderProps = {
  children: NonNullable<React.ReactNode>;
};

export function useUserCTX() {
  return useContext(UserContext);
}

export function UserProvider({ children }: UserProviderProps) {
  const { userData, fetchUser, userRefetch, fetchAuthCheck, authData } =
    useUser();
  const { userId } = useSessionTokenContext();

  const [logoutMutation] = useLogout(() => {
    window.location.reload();
  });

  useEffect(() => {
    if (userId) {
      fetchAuthCheck();
      fetchUser({ variables: { userId } });
    }
  }, [userId]);

  useEffect(() => {
    if (authData?.auth?.success === false) {
      logoutMutation();
    }
  }, [authData]);

  return (
    <UserContext.Provider
      value={{
        userId,
        userData,
        userRefetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
