import React, { useEffect } from 'react';

import { View } from 'react-native';
import Animated, {
  useSharedValue,
  withTiming,
  Easing,
  useAnimatedStyle,
  withSpring,
  withRepeat,
  withDelay,
  useDerivedValue,
  interpolate,
  SharedValue,
} from 'react-native-reanimated';

import tw from '@tw';

export default function LottieLoadingDots() {
  const progress = useSharedValue(0);
  useEffect(() => {
    const pulse = withDelay(
      500,
      withTiming(1, { duration: 800, easing: Easing.linear }),
    );
    progress.value = withRepeat(pulse, -1, false);
  }, []);

  const dot1 = useDerivedValue(() =>
    interpolate(
      progress.value,
      [0, 0.2, 0.4, 0.6, 0.8, 1],
      [0, 0.5, 1, 0.5, 0, 0],
    ),
  );
  const dot2 = useDerivedValue(() =>
    interpolate(
      progress.value,
      [0, 0.2, 0.4, 0.6, 0.8, 1],
      [0, 0, 0.5, 1, 0.5, 0],
    ),
  );
  const dot3 = useDerivedValue(() =>
    interpolate(
      progress.value,
      [0, 0.2, 0.4, 0.6, 0.8, 1],
      [0, 0, 0, 0.5, 1, 0.5, 0],
    ),
  );

  const animatedStyle = (derivedDot: SharedValue<number>) =>
    useAnimatedStyle(
      () =>
        ({
          transform: [{ translateY: withSpring(derivedDot.value * -15) }],
        } as any),
    );
  const dotsArray = [dot1, dot2, dot3];

  return (
    <View
      style={tw`items-center justify-around flex-row w-160px h-80px px-12px bg-purple rounded-full`}
    >
      {dotsArray.map((dot, i) => (
        <Animated.View
          key={i}
          style={[tw`h-30px w-30px bg-white rounded-full`, animatedStyle(dot)]}
        />
      ))}
    </View>
  );
}
