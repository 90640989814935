import { useApolloClient, useMutation } from '@apollo/client';
import { Platform } from 'react-native';

import { LOGOUT } from '@/queries';
import { trackEvent } from '@/utils';

import { useSessionTokenContext } from '../components/SessionTokenProvider';

export const useLogout = (afterLogoutAction?: (...args: any[]) => any) => {
  const client = useApolloClient();
  const { setSessionToken, setUserId, sessionToken } = useSessionTokenContext();
  return useMutation(LOGOUT, {
    onCompleted: async () => {
      if (Platform.OS === 'web') {
        trackEvent('webLogout');
      } else {
        trackEvent('logout');
      }
      client?.resetStore();
      if (sessionToken) {
        await setSessionToken(null);
        await setUserId(null);

        // Only run afterLogoutAction if they were previously logged in
        if (afterLogoutAction) {
          afterLogoutAction();
        }
      }
    },
  });
};
