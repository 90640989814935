import React, { useState, useCallback, useEffect } from 'react';

import { useFormspark } from '@formspark/use-formspark';
import { Image } from 'expo-image';
import { StyleSheet, View } from 'react-native';

import stashrunLogo from '@/assets/stashrun-logo-ring.png';
import { Text, Input, Spacing, PrimaryButton } from '@/components/web';
import { eventProps } from '@/constants/ANALYTICS_CONST';
import useWebHooks from '@/hooks/useWebHooks';
import { COLORS, getStoreUserIdentifier, trackEvent } from '@/utils';
import tw from '@tw';
import { WebWrapper } from '@web/components';

import {
  FORMSPARK_FORM_ID,
  FORMSPARK_FORM_ID_PASSCODE_WAITLIST,
} from './CoinGame/WaitlistForm';

const NotFound = () => {
  const { isNot404, pathname, isPasscodeWaitlist } = useWebHooks();

  const [submit, submitting] = useFormspark({
    formId: isPasscodeWaitlist
      ? FORMSPARK_FORM_ID_PASSCODE_WAITLIST
      : FORMSPARK_FORM_ID,
  });
  const [email, setEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);

  useEffect(() => {
    trackEvent('notFoundViewed');
  }, []);

  const validateEmail = useCallback(
    (input: string) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValid = re.test(String(input).toLowerCase());
      setEmailIsValid(isValid);
      return isValid;
    },
    [setEmailIsValid],
  );

  const onChangeEmail = useCallback(
    (email: string) => {
      setEmail(email);
      validateEmail(email);
    },
    [setEmail],
  );

  const onSubmit = async () => {
    if (!emailIsValid) {
      alert('Please enter a valid email');
      return;
    }
    if (isPasscodeWaitlist) {
      const allStorageKey = Object.keys(localStorage);
      const allStorageItems = allStorageKey
        .map((key) => {
          if (key.includes('numTimesPlayed-')) {
            return {
              campaignId: key.replace('numTimesPlayed-', ''),
              numTimesPlayed: localStorage.getItem(key),
            };
          }
          return null;
        })
        .filter((i) => i);

      const userStoreIdentifier = getStoreUserIdentifier();

      trackEvent('earlyAccessButtonClicked', {
        page: 'passcode waitlist page',
      });
      await submit({
        email,
        data: allStorageItems,
        anonymousUserId: userStoreIdentifier,
      });
    } else {
      trackEvent('earlyAccessButtonClicked', {
        page: '404 page',
      });
      await submit({ email });
    }

    alert('You did it! We’ll be in touch. ✨');
    setEmail('');
  };

  const show404 = !isNot404 && pathname !== '/';

  return (
    <WebWrapper analytics={{ [eventProps.page]: '404 page' }}>
      {/* 404 TextBox */}
      {show404 ? (
        <View style={styles.notFoundTextWrapper}>
          <Text small bold centered primary>
            404
          </Text>
          <Text small bold centered primary>
            Oops, something went wrong.
          </Text>
        </View>
      ) : null}
      <View style={styles.contentWrapper}>
        <Spacing vertical={2}>
          {/* Logo */}
          <View style={tw`w-full flex flex-row justify-center items-center`}>
            <Image source={stashrunLogo} style={styles.stashrunLogo} />
          </View>
          <Text mediumSmallPlus2 inverse bold centered>
            Fund causes you care about with{'\n'}a little help from your friends
          </Text>
          {/* Description */}
          <Text
            mediumSmallPlus
            centered
            inverse
            override={{ marginTop: 30, marginBottom: 30 }}
          >
            Join the waitlist for early access
          </Text>
          {/* Form */}
          <View style={tw`w-full flex flex-1 relative`}>
            <Input
              box
              style={{ marginBottom: 0 }}
              inputContainerStyle={{
                borderRadius: 8,
                overflow: 'hidden',
              }}
              inputStyle={{
                height: '100%',
                minHeight: 44,
              }}
              autoCorrect={false}
              autocapitalize="none"
              keyboardType="email-address"
              placeholder="johnsmith123@stanford.edu"
              value={email}
              onChange={onChangeEmail}
            />
            <PrimaryButton
              style="mt-2 max-w-none"
              title="Get Early Access"
              onPress={onSubmit}
              loading={submitting}
            />
          </View>
          <Text
            small
            centered
            inverse
            override={{ marginTop: 12, marginHorizontal: 28 }}
          >
            Student? Use your .edu address to skip to the front of the line.
          </Text>
        </Spacing>
      </View>
    </WebWrapper>
  );
};

const styles = StyleSheet.create({
  stashrunHeaderWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  stashrunButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.purple,
    width: 140,
    height: 30,
    borderRadius: 5,
  },
  notFoundTextWrapper: {
    paddingVertical: 12,
    backgroundColor: COLORS.tertiaryPurple,
  },
  contentWrapper: {
    flex: 1,
    backgroundColor: COLORS.purple,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 32,
    paddingTop: 4,
  },
  stashrunLogo: {
    width: 200,
    height: 120,
    marginHorizontal: 'auto',
    resizeMode: 'contain',
    marginBottom: 24,
  },
  listItemWrapper: {
    marginTop: 20,
    marginHorizontal: 20,
  },
  listItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 16,
  },
  listItemBullet: {
    width: 6,
    height: 6,
    borderRadius: 10,
    backgroundColor: COLORS.white,
    marginRight: 8,
    marginTop: 8,
  },
  formWrapper: {
    marginTop: 28,
    width: '100%',
  },
});

export default NotFound;
