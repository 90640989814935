import React from 'react';

import {
  TextInput,
  StyleSheet,
  View,
  TextInputProps,
  KeyboardTypeOptions,
  ReturnKeyTypeOptions,
  Platform,
  NativeSyntheticEvent,
  TextInputFocusEventData,
} from 'react-native';

import FlexContainer from '@/components/FlexContainer';
import Text from '@/components/Text';
import { COLORS } from '@/utils';

export type InputProps = {
  label?: string;
  autoCorrect?: boolean;
  returnKeyType?: ReturnKeyTypeOptions;
  labelSize?: 'small' | 'mediumSmall' | 'default';
  multiline?: boolean;
  value?: string | undefined;
  validator?: (text: string) => void;
  errorMessage?: string;
  placeholder?: string;
  maxLength?: number;
  onChange: (text: string) => void;
  isValid?: boolean;
  box?: boolean;
  autocapitalize?: TextInputProps['autoCapitalize'];
  keyboardType?: KeyboardTypeOptions;
  secureTextEntry?: boolean;
  style?: any;
  inputContainerStyle?: any;
  inputStyle?: any;
  autoFocus?: boolean;
  showErrors?: boolean;
  onFocusInput?:
    | ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void)
    | undefined;
  onBlurInput?: (() => void) | undefined;
};

const Input = ({
  label,
  returnKeyType = 'default',
  autoCorrect = true,
  labelSize = 'default',
  multiline,
  value,
  errorMessage,
  placeholder,
  maxLength,
  onChange,
  validator,
  isValid,
  onFocusInput,
  onBlurInput,
  box,
  autocapitalize,
  keyboardType,
  secureTextEntry = false,
  style,
  inputContainerStyle,
  inputStyle,
  autoFocus,
  showErrors,
}: InputProps) => {
  const onBlur = (text: string) => {
    validator?.(text);
  };

  return (
    <View style={[styles.container, style]}>
      {label !== undefined && (
        <Text
          small={labelSize === 'small'}
          medium={labelSize === 'default'}
          mediumSmall={labelSize === 'mediumSmall'}
        >
          {label}
        </Text>
      )}
      <View
        style={[
          box ? styles.inputContainerBox : styles.inputContainer,
          inputContainerStyle,
        ]}
      >
        <FlexContainer>
          <TextInput
            secureTextEntry={secureTextEntry}
            autoCorrect={autoCorrect}
            style={[styles.input, inputStyle]}
            placeholder={placeholder ?? ''}
            placeholderTextColor={COLORS.darkLightGray}
            multiline={!!multiline}
            value={value}
            maxLength={maxLength}
            onChangeText={onChange}
            onFocus={onFocusInput}
            onBlur={(e) => {
              onBlurInput?.();
              onBlur(e.nativeEvent.text);
            }}
            autoCapitalize={autocapitalize}
            keyboardType={keyboardType}
            returnKeyType={returnKeyType}
            autoFocus={autoFocus}
          />
        </FlexContainer>
        {!!maxLength && (
          <Text xsmall right muted>
            {value?.length ?? 0}/{maxLength}
          </Text>
        )}
      </View>
      {showErrors && (
        <View style={styles.errorContainer}>
          {!!errorMessage && !isValid && (
            <Text small override={styles.error}>
              {errorMessage}
            </Text>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 24,
  },
  inputContainer: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#FFF',
    borderBottomColor: COLORS.white,
  },
  inputContainerBox: {
    backgroundColor: COLORS.tertiaryPurple,
    minHeight: 30,
    paddingHorizontal: 8,
  },
  input: {
    paddingVertical: 8,
    fontSize: 14,
    fontFamily: 'Avenir-Medium',
  },
  errorContainer: {
    paddingHorizontal: Platform.OS === 'web' ? 0 : 12,
    minHeight: 16,
  },
  error: {
    color: '#ff0000',
  },
});
export default Input;
