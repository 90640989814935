import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';

const secureStore = {
  async setItemAsync(key: string, value: string) {
    const isAvailable = await SecureStore.isAvailableAsync();

    if (isAvailable) {
      return SecureStore.setItemAsync(key, value);
    }

    return AsyncStorage.setItem(key, value);
  },

  async getItemAsync(key: string) {
    const isAvailable = await SecureStore.isAvailableAsync();

    if (isAvailable) {
      return SecureStore.getItemAsync(key);
    }

    return AsyncStorage.getItem(key);
  },

  async deleteItemAsync(key: string) {
    const isAvailable = await SecureStore.isAvailableAsync();

    if (isAvailable) {
      return SecureStore.deleteItemAsync(key);
    }

    return AsyncStorage.removeItem(key);
  },
};

export default secureStore;
