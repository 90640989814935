import { gql } from '@apollo/client';

export const FETCH_USER = gql`
  query FetchUser($userId: ID!) {
    user(id: $userId) {
      brandsSupported
      campaignsSupported
      causesSupported
      charitiesSupported
      coinsDonated
      description
      email
      fbExpirationDate
      fbId
      fbName
      fbRefreshDate
      fbToken
      finishedOnBoarding
      fullName
      id
      joinedAt
      haloColor
      pendingCoinDrops
      profilePhoto
      totalBalance
      username
      numInvites
      website
      impactScore
      impactLevel
      hasLevelUpReward
      coinsPerDrop
      dollarsEarned
      invitesSent
      schoolId
      isPublicProfile
      instagramUsername
      twitterUsername
      facebookUsername
      youtubeUsername
      tiktokUsername
      linkedinUrl
      percentToNextLevel
      remainingPointsToNextLevel
      phoneNumber
      phoneNumberVerified
      formattedPhoneNumber
      appDownloadNumInvites
      featuredUrl
      birthdate
      pinnedCauses {
        id
        name
      }
      school {
        id
        name
      }
      referredBy {
        id
        username
        isPublicProfile
      }
      causes {
        id
        name
      }
    }
  }
`;
