import { gql } from '@apollo/client';

export const DELETE_COMMENT = gql`
  mutation ($conversationCommentId: String!) {
    deleteConversationComment(
      input: { conversationCommentId: $conversationCommentId }
    ) {
      success
      error
    }
  }
`;
