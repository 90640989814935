import React from 'react';

import {
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
  ActivityIndicator,
  Platform,
} from 'react-native';

import FlexContainer from '@/components/FlexContainer';
import Text, { TextProps } from '@/components/Text';
import { maxWidth, COLORS } from '@/utils';

type ButtonProps = {
  onPress: () => void;
  title: string | React.ReactChild;
  disabled?: boolean;
  small?: boolean;
  block?: boolean;
  secondary?: boolean;
  transparent?: boolean;
  wide?: boolean;
  squared?: boolean;
  textProps?: Omit<TextProps, 'children'>;
  backgroundStyle?: ViewStyle;
  backgroundColor?: string;
  loading?: boolean;
};

const isWeb = Platform.OS === 'web';

export default function Button({
  onPress,
  title,
  disabled,
  small,
  block,
  secondary,
  transparent,
  wide,
  squared,
  textProps = {},
  backgroundColor,
  backgroundStyle,
  loading,
}: ButtonProps) {
  if (loading) {
    return (
      <FlexContainer row>
        <TouchableOpacity
          onPress={onPress}
          style={[
            styles.container,
            loading && Platform.OS === 'web'
              ? styles.loadingWeb
              : styles.loading,
            secondary && styles.container_secondary,
            { backgroundColor: backgroundColor || COLORS.red },
            small && styles.container_small,
            wide && styles.container_wide,
            block && styles.container_block,
            disabled && styles.container_disabled,
            squared && styles.squared,
            transparent && styles.container_transparent,
            backgroundStyle,
          ]}
          disabled={disabled}
        >
          <Text centered bold inverse {...textProps}>
            <ActivityIndicator
              size={Platform.OS === 'web' ? 'small' : 'large'}
              color={COLORS.white}
            />
          </Text>
        </TouchableOpacity>
      </FlexContainer>
    );
  }
  return (
    <FlexContainer row>
      <TouchableOpacity
        onPress={onPress}
        style={[
          styles.container,
          secondary && styles.container_secondary,
          {
            backgroundColor: backgroundColor || COLORS.red,
          },
          small && styles.container_small,
          wide && styles.container_wide,
          block && styles.container_block,
          disabled && styles.container_disabled,
          squared && styles.squared,
          transparent && styles.container_transparent,
          backgroundStyle,
        ]}
        disabled={disabled}
      >
        <Text centered bold inverse {...textProps}>
          {title}
        </Text>
      </TouchableOpacity>
    </FlexContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    elevation: 8,
    borderRadius: 10,
    paddingVertical: 12,
    paddingHorizontal: 12,
    backgroundColor: COLORS.red,
  },
  loading: {
    paddingBottom: Platform.OS === 'ios' ? 6 : 12,
    paddingTop: Platform.OS === 'ios' ? 12 : 22,
  },
  loadingWeb: {
    paddingTop: 16,
    paddingBottom: 8,
  },
  container_small: {
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 14,
  },
  container_wide: {
    paddingVertical: 6,
    paddingHorizontal: 14,
  },
  container_block: {
    width: isWeb ? maxWidth(500) - 64 : '100%',
    alignItems: 'center',
    borderRadius: 10,
  },
  container_disabled: {
    opacity: 0.5,
  },
  container_secondary: {
    backgroundColor: '#F1F1F1',
    paddingVertical: 12,
    paddingHorizontal: 12,
  },
  container_transparent: {
    elevation: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.35)',
  },
  squared: {
    borderRadius: 0,
  },
});
