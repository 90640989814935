import { useLazyQuery } from '@apollo/client';

import {
  FETCH_CAMPAIGNS,
  FETCH_ENDING_SOON_CAMPAIGNS,
  FETCH_CAMPAIGNS_BY_CHARITY,
  FETCH_RECOMMENDED_CAMPAIGNS,
} from '@/queries';
import { Campaign } from '@/types';

export const useCampaigns = () => {
  const [fetchCampaigns, { data: campaignsData, loading: campaignsLoading }] =
    useLazyQuery<{
      campaignsSearch: Campaign[];
    }>(FETCH_CAMPAIGNS, {
      fetchPolicy: 'network-only',
    });

  const [
    fetchEndingSoonCampaigns,
    { data: endingSoonCampaignsData, loading: endingSoonCampaignsLoading },
  ] = useLazyQuery<{
    endingSoonCampaigns: Campaign[];
  }>(FETCH_ENDING_SOON_CAMPAIGNS, {
    fetchPolicy: 'network-only',
  });

  const [
    fetchCampaignsFromCharity,
    {
      data: campaignsFromCharityData,
      loading: campaignsFromCharityDataLoading,
    },
  ] = useLazyQuery<{ campaignListByCharity: Campaign[] }>(
    FETCH_CAMPAIGNS_BY_CHARITY,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [
    fetchRecommendedCampaigns,
    {
      data: recommendedCampaignsData,
      loading: recommendedCampaignsDataLoading,
    },
  ] = useLazyQuery<{ recommendedCampaigns: Campaign[] }>(
    FETCH_RECOMMENDED_CAMPAIGNS,
    {
      fetchPolicy: 'network-only',
    },
  );

  return {
    fetchCampaigns,
    fetchEndingSoonCampaigns,
    campaignsData,
    endingSoonCampaignsData,
    campaignsLoading,
    endingSoonCampaignsLoading,
    fetchCampaignsFromCharity,
    campaignsFromCharityData,
    campaignsFromCharityDataLoading,
    fetchRecommendedCampaigns,
    recommendedCampaignsData,
    recommendedCampaignsDataLoading,
  };
};
