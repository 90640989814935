import React from 'react';

import { View } from 'react-native';

import Spacing from '@/components/Spacing';
import StashrunCoinIcon from '@/components/StashrunCoinIcon';
import Text from '@/components/Text';
import { ImpactItem } from '@/types';
import { numberShortened, numberWithCommas } from '@/utils/format';

import { styles } from './styles';

type CauseImpactCardProps = {
  item: ImpactItem;
};

const CauseImpactCard = ({ item }: CauseImpactCardProps) => {
  const fontSize = 19;
  if (item.type !== 'Cause') return null;
  return (
    <View style={styles.impactContainer}>
      <Spacing>
        <View style={styles.impact}>
          <View>
            <Text medium inverse override={{ fontSize }}>
              <Text bold underlined>
                {`${
                  (item.coinsReceived &&
                    numberWithCommas(item.coinsReceived)) ||
                  0
                }`}
              </Text>{' '}
              <StashrunCoinIcon size={23} />
              {' total received across '}
              <Text medium inverse bold underlined override={{ fontSize }}>
                {item.numCampaigns}{' '}
                {item.numCampaigns === 1 ? 'campaign' : 'campaigns'}
              </Text>
              {' and '}
              <Text medium inverse bold underlined override={{ fontSize }}>
                {`${numberShortened(item.numSupporters)} ${
                  item.numSupporters === 1 ? 'supporter' : 'supporters'
                }`}
              </Text>
            </Text>
          </View>
          <Spacing top={2}>
            <View>
              <Text italicized small inverse right>
                {`Swipe to learn more 👉`}
              </Text>
            </View>
          </Spacing>
        </View>
      </Spacing>
    </View>
  );
};
export default CauseImpactCard;
