import React from 'react';

import { View } from 'react-native';

import FlexContainer from '@/components/FlexContainer';
import { HeroPreview } from '@/components/HeroPreview';
import InstagramFeed from '@/components/InstagramFeed';
import ShopButton from '@/components/ShopButton';
import Spacing from '@/components/Spacing';
import Text from '@/components/Text';
import WereHiringButton from '@/components/WereHiringButton';
import { InstaImages } from '@/types';
import { COLORS } from '@/utils/constants';
import tw from '@tw';

type EntityHeaderProps = {
  location?: string;
  heroImageUrl?: string;
  heroTagline?: string;
  videoUrl?: string;
  instagramData?: InstaImages[];
  shopUrl?: string;
  hiringUrl?: string;
  studentOrgs?: string;
  // entityId: string;
  // entityType: string;
  screenshot?: boolean;
  showlocation?: boolean;
  stopPlayVideo?: boolean;
};

const EntityHeader = ({
  location,
  heroImageUrl,
  videoUrl,
  shopUrl,
  hiringUrl,
  heroTagline,
  instagramData = [],
  studentOrgs,
  screenshot,
  showlocation = true,
  stopPlayVideo,
}: EntityHeaderProps) => {
  const HeroPreviewRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (stopPlayVideo) {
      HeroPreviewRef?.current?.stopVideo?.();
    }
  }, [stopPlayVideo]);

  return (
    <>
      <Spacing horizontal={2} top={0.5}>
        {showlocation && (
          <FlexContainer row alignItems="center" justifyContent="center">
            <FlexContainer row alignItems="center">
              <Text centered mediumSmall muted>
                {' '}
                {location || ''}
              </Text>
            </FlexContainer>
          </FlexContainer>
        )}
        {(!!hiringUrl || !!shopUrl) && (
          <Spacing top={1} bottom={1}>
            <FlexContainer
              row
              alignItems="center"
              justifyContent="space-between"
            >
              <View>
                {!!hiringUrl && (
                  <WereHiringButton color={COLORS.black} link={hiringUrl} />
                )}
              </View>
              <View>{!!shopUrl && <ShopButton link={shopUrl} />}</View>
            </FlexContainer>
          </Spacing>
        )}
        {/* <FollowButton entityId={entityId} entityType={entityType} /> */}
      </Spacing>
      <View style={tw`my-2`}>
        {!!studentOrgs?.length && (
          <View
            style={{ padding: 10, backgroundColor: COLORS.transparentPurple }}
          >
            <Text small primary bold italicized>
              Supported by {studentOrgs}
            </Text>
          </View>
        )}
        <HeroPreview
          ref={HeroPreviewRef}
          heroImageUrl={heroImageUrl}
          videoUrl={videoUrl}
          screenshot={screenshot}
        />
        {instagramData.length > 0 && (
          <InstagramFeed instagramPosts={instagramData} />
        )}
        {!!heroTagline && (
          <Spacing horizontal={2} bottom={2} top={2}>
            <Text mediumSmall semiboldMinus lightBlack>
              {heroTagline}
            </Text>
          </Spacing>
        )}
      </View>
    </>
  );
};

export default EntityHeader;
