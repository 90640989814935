import { gql } from '@apollo/client';

export const AUTH_CHECK = gql`
  query Authentication {
    auth {
      success
      error
    }
  }
`;
