import React, { useEffect, useMemo, useRef } from 'react';

import { useNavigation } from '@react-navigation/native';
import { StyleSheet, View, Dimensions, Platform, Image } from 'react-native';
import Carousel from 'react-native-snap-carousel';

import Button from '@/components/Button';
import FlexContainer from '@/components/FlexContainer';
import {
  UserImpactCard,
  CauseImpactCard,
  BrandImpactCard,
  CauseCard,
} from '@/components/ImpactCards';
import Spacing from '@/components/Spacing';
import Text from '@/components/Text';
import { useImpact } from '@/hooks/useImpact';
import { ImpactItem } from '@/types';
import { trackEvent, COLORS } from '@/utils';
import { addCloudinaryTransformation } from '@/utils/format';

type ImpactCarouselProps = {
  maxWidth?: number;
  type: string;
  entityId: string;
  logoUrl?: string;
  color?: string;
  coinsPerDrop?: number;
  dollarsEarned?: number;
  invitesSent?: number;
  impactScore?: number;
  isCurrentUser?: boolean;
  firstName?: string;
  haloProperties?: {
    haloPercentage: number;
    impactTextColor: string;
    impactBackground: string;
    haloColor: string;
    haloShadow: string;
  };
  brandDetails?: ImpactItem;
  setLeaderboardExplainerModalOpen?: (...args: any[]) => any;
};

const isWeb = Platform.OS === 'web';

const ImpactCarousel = ({
  maxWidth,
  type,
  entityId,
  logoUrl,
  color,
  coinsPerDrop,
  dollarsEarned,
  invitesSent,
  impactScore,
  isCurrentUser,
  firstName,
  haloProperties,
  brandDetails,
  setLeaderboardExplainerModalOpen,
}: ImpactCarouselProps) => {
  const carouselRef = useRef<any>(null);
  const { impactCarouselData } = useImpact({ entityType: type, entityId });

  const filteredCarouselData = useMemo(() => {
    if (
      impactCarouselData.length === 1 &&
      impactCarouselData[0].type === 'User'
    ) {
      return impactCarouselData.concat({ type: 'empty' });
    }
    if (brandDetails) {
      impactCarouselData.unshift(brandDetails);
      return impactCarouselData;
    }
    return impactCarouselData;
  }, [impactCarouselData]);

  useEffect(() => {
    if (carouselRef?.current?.currentIndex) {
      trackEvent('impactCardSwiped', {
        type,
        entityId,
        index: carouselRef?.current?.currentIndex,
      });
    }
  }, [carouselRef?.current?.currentIndex]);

  const navigation = isWeb ? null : useNavigation();
  const renderItem = ({
    item,
  }: {
    item?: ImpactItem & { logoUrl?: string; name?: string };
  }) => {
    if (item?.type) {
      switch (item.type) {
        case 'User':
          return (
            <UserImpactCard
              item={item}
              coinsPerDrop={coinsPerDrop}
              dollarsEarned={dollarsEarned}
              invitesSent={invitesSent}
              impactScore={impactScore}
              haloProperties={haloProperties!}
              setLeaderboardExplainerModalOpen={
                setLeaderboardExplainerModalOpen!
              }
            />
          );
        case 'Brand Modal':
          return (
            <View style={styles.brandDescriptionContainer}>
              {/* <View style={styles.brandUsername}>
                <Spacing>
                  <Text centered bold small override={{ color: COLORS.purple }}>
                    @{item.username}
                  </Text>
                </Spacing>
              </View> */}
              <View style={{ flexDirection: 'row' }}>
                <View
                  style={{
                    width: '40%',
                    alignItems: 'center',
                    paddingHorizontal: 10,
                  }}
                >
                  <Spacing top={1.5}>
                    <View style={{ alignItems: 'center' }}>
                      <Image
                        style={styles.brandImage}
                        source={{
                          uri: addCloudinaryTransformation(
                            item.imageUrl,
                            'c_scale,w_150',
                          ),
                        }}
                      />
                    </View>
                    <Spacing top={1}>
                      <Text
                        centered
                        bold
                        xsmall
                        override={{ color: COLORS.darkGray, lineHeight: 10 }}
                      >
                        {!!item.location && item.location}
                      </Text>
                    </Spacing>
                  </Spacing>
                </View>
                <View style={{ width: '60%' }}>
                  {!!item.heroTagline && (
                    <Spacing top={1.5} right={1}>
                      <Text
                        xsmallPlus
                        numberOfLines={8}
                        override={{ fontSize: 12, lineHeight: 16 }}
                      >
                        {item.heroTagline}
                      </Text>
                    </Spacing>
                  )}
                </View>
              </View>
            </View>
          );
        case 'Cause':
          return <CauseImpactCard item={item} />;
        case 'Brand':
        case 'Charity':
          return (
            <BrandImpactCard
              color={color}
              logoUrl={logoUrl}
              item={item}
              numItems={
                brandDetails
                  ? filteredCarouselData && filteredCarouselData.length - 1
                  : filteredCarouselData?.length
              }
              brandDetails={!!brandDetails}
            />
          );
        case 'Cause card':
          return item?.campaignCauseName ? (
            <View>
              <CauseCard item={item} brandDetails={!!brandDetails} />
              {!!item?.complete && !brandDetails && (
                <Text mediumSmall primary centered>
                  Completed
                </Text>
              )}
            </View>
          ) : null;
        case 'empty':
          return (
            <View style={styles.emptyContainer}>
              <Spacing right={1}>
                <FlexContainer row justifyContent="center" alignItems="center">
                  <Text
                    bold
                    centered
                    override={{
                      fontSize: 12,
                      lineHeight: 16,
                      color: COLORS.purple,
                    }}
                  >
                    {isCurrentUser ? (
                      <FlexContainer justifyContent="center">
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <Text
                            centered
                            override={{
                              fontSize: 12,
                              lineHeight: 16,
                              color: COLORS.purple,
                            }}
                          >
                            {'None of your campaigns have\nbeen completed yet!'}
                          </Text>
                          <Spacing top={2}>
                            <Button
                              title="Discover more causes"
                              backgroundStyle={{
                                backgroundColor: COLORS.purple,
                                borderRadius: 0,
                                position: 'relative',
                                paddingHorizontal: 10,
                                paddingVertical: 2,
                              }}
                              textProps={{
                                override: {
                                  fontSize: 12,
                                },
                              }}
                              onPress={() =>
                                Platform.OS === 'web'
                                  ? null
                                  : navigation &&
                                    navigation.navigate('Discover')
                              }
                            />
                          </Spacing>
                        </View>
                      </FlexContainer>
                    ) : (
                      <>
                        <Text override={{ fontSize: 48, lineHeight: 52 }}>
                          🤯{'\n'}
                        </Text>
                        {`${firstName} hasn’t completed a\ncampaign yet!`}
                      </>
                    )}
                  </Text>
                </FlexContainer>
              </Spacing>
            </View>
          );
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <View>
      <Carousel
        layout="default"
        ref={carouselRef}
        data={filteredCarouselData}
        renderItem={renderItem}
        sliderWidth={maxWidth || Dimensions.get('window').width}
        itemWidth={brandDetails ? 285 : 300}
        containerCustomStyle={{
          marginLeft: brandDetails ? -94 : -88,
        }}
        inactiveSlideOpacity={brandDetails ? 1 : 0.7}
        inactiveSlideScale={brandDetails ? 1 : 0.9}
        swipeThreshold={isWeb ? 1000 : 20}
        useScrollView={!!isWeb}
      />
    </View>
  );
};

export default ImpactCarousel;

const styles = StyleSheet.create({
  navigationContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    ...(isWeb && { cursor: 'pointer' }),
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.transparentPurple,
    height: 145,
    borderRadius: 7,
    shadowColor: 'black',
    shadowOffset: {
      width: 8,
      height: 8,
    },
    margin: 5,
    shadowRadius: 5,
    shadowOpacity: 0.1,
  },
  brandImage: {
    height: 85,
    width: 85,
    borderRadius: 100,
    backgroundColor: COLORS.white,
  },
  brandDescriptionContainer: {
    backgroundColor: COLORS.tertiaryPurple,
    maxWidth: 270,
    marginVertical: 10,
    borderRadius: 25,
    height: 157,
  },
  brandUsername: {
    backgroundColor: COLORS.tertiaryPurple,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 34,
  },
});
