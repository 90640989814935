import { COLORS } from '@/utils';

const levels = [
  {
    level: 0,
    colorName: 'impactLightPurple',
    color: COLORS.impactLightPurple,
  },
  {
    level: 1,
    colorName: 'impactPurple',
    color: COLORS.impactPurple,
  },
  {
    level: 2,
    colorName: 'impactDarkPurple',
    color: COLORS.impactDarkPurple,
  },
  {
    level: 3,
    colorName: 'impactSkyBlue',
    color: COLORS.impactSkyBlue,
  },
  {
    level: 4,
    colorName: 'impactGreen',
    color: COLORS.impactGreen,
  },
  {
    level: 5,
    colorName: 'impactYellow',
    color: COLORS.impactYellow,
  },
  {
    level: 6,
    colorName: 'impactOrange',
    color: COLORS.impactOrange,
  },
  {
    level: 7,
    colorName: 'impactRed',
    color: COLORS.impactRed,
  },
  {
    level: 8,
    colorName: 'impactPink',
    color: COLORS.impactPink,
  },
  {
    level: 9,
    colorName: 'impactBlack',
    color: COLORS.impactBlack,
  },
];

export default levels;
