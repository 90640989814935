import { gql } from '@apollo/client';

export const SIGN_UP_WITH_EMAIL = gql`
  mutation SignUpWithEmail($input: SignUpWithEmailInput!) {
    signUpWithEmail(input: $input) {
      success
      error
      token
      user {
        id
      }
      clientMutationId
    }
  }
`;
