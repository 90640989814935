import React, { useState, useEffect } from 'react';

import { Animated, Dimensions } from 'react-native';

import Text from '@/components/Text';
import { COLORS } from '@/utils/constants';

const URGENCY_TIME_SECONDS = 5;

type CountdownTimerProps = {
  isStarted: boolean;
  totalSeconds: number;
  onFinish?: (...args: any) => void;
  style?: any;
};

export function CountdownTimer({
  isStarted = false,
  totalSeconds,
  onFinish,
  style = {},
}: CountdownTimerProps) {
  const [remainingSeconds, setRemainingSeconds] = useState(totalSeconds);
  const [urgencyAnimatedValue] = useState(new Animated.Value(0));

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isStarted) {
      const timer = setInterval(() => {
        setRemainingSeconds((prevRemainingSeconds) => {
          const newRemainingSeconds = prevRemainingSeconds - 1;
          if (newRemainingSeconds === 0) {
            if (onFinish) {
              onFinish();
            }
          } else if (newRemainingSeconds === URGENCY_TIME_SECONDS) {
            Animated.loop(
              Animated.sequence([
                Animated.timing(urgencyAnimatedValue, {
                  toValue: 1,
                  duration: 0,
                  useNativeDriver: true,
                }),
                Animated.timing(urgencyAnimatedValue, {
                  toValue: 0,
                  duration: 1000,
                  useNativeDriver: true,
                }),
              ]),
            ).start();
          } else if (prevRemainingSeconds === 0) {
            return 0;
          }

          return newRemainingSeconds;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isStarted]);

  const minutesDisplay = String(Math.floor(remainingSeconds / 60)).padStart(
    2,
    '0',
  );
  const secondsDisplay = String(
    remainingSeconds >= 60 ? remainingSeconds % 60 : remainingSeconds,
  ).padStart(2, '0');

  const { height } = Dimensions.get('window');

  // 428, 926 for iPhone 12 Pro Max
  // 375, 812 for iPhone XS

  const RATIO = height > 200 ? height / 926 : 1;

  return (
    <Animated.View
      style={{
        ...style,
        transform: [
          {
            scale: urgencyAnimatedValue.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.2],
            }),
          },
        ],
      }}
    >
      <Text
        mediumSmallPlus2
        bold
        centered
        override={{
          color: remainingSeconds <= 5 ? COLORS.red : COLORS.lightBlack,
          fontSize: 40 * RATIO,
          lineHeight: 55 * RATIO,
        }}
      >
        {minutesDisplay}:{secondsDisplay}
      </Text>
    </Animated.View>
  );
}
