import { ApolloClient, InMemoryCache } from '@apollo/client';
import { API_HOST_URL } from '@env';
import { createUploadLink } from 'apollo-upload-client';
import moment from 'moment';
import { Dimensions, Platform } from 'react-native';
import { getUserAgentSync } from 'react-native-device-info';
import { Reoverlay } from 'reoverlay';

import { eventNames, eventProps } from '@/constants/ANALYTICS_CONST';
import { analytics } from '@/utils/mixpanel';

export {
  getStoreUserIdentifier,
  setStoreUserIdentifier,
  getStoreTimesPlayed,
  setStoreTimesPlayed,
} from '@/utils/webStorage';

export { default as scroll } from '@/utils/scroll';
export { COLORS } from '@/utils/constants';
export { debounce } from '@/utils/debounce';

export * from '@/utils/format';
export { default as profileHaloColorPicker } from '@/utils/profileHaloColorPickerHelper';

export function randomIntFromInterval(
  min: number,
  max: number,
  generator?: any,
): number {
  // min and max included
  if (generator) {
    // Pass in a seedrandom generator if you want a predictable random number
    return Math.floor(generator() * (max - min + 1) + min);
  }
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getApolloClient(sessionToken?: string) {
  const defaultClientOptions = {
    link: createUploadLink({
      uri: `${API_HOST_URL}/graphql`,
      headers: {
        ...(sessionToken && { Authorization: `Bearer ${sessionToken}` }),
      },
    }) as any,
    cache: new InMemoryCache(),
  };

  return new ApolloClient({
    ...defaultClientOptions,
  });
}

export const removeBeginningHttps = (website: string, split: boolean) => {
  const url = website
    .replace('http://www.', '')
    .replace('https://www.', '')
    .replace('http://', '')
    .replace('https://', '');
  if (split) return url.split(/[/?#]/)[0];
  return url;
};

export const removeUnwantedString = (username?: string) => {
  let url =
    username &&
    removeBeginningHttps(username, false)
      .replace('instagram.com/', '')
      .replace('twitter.com/', '')
      .replace('youtube.com/c/', '')
      .replace('facebook.com/', '')
      .replace('tiktok.com/@', '');

  if (url && url[0] === '/') {
    url = url.substring(1);
  }

  if (url?.includes('@')) {
    url = url.replace('@', '');
  }

  return url || '';
};

// eslint-disable-next-line no-promise-executor-return
export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const timeCalculation = (time: string) => {
  if (time) {
    const minutesDiff = moment().diff(moment(time), 'minutes');
    const hoursDiff = moment().diff(moment(time), 'hours');
    const daysDiff = moment().diff(moment(time), 'days');
    const monthsDiff = moment().diff(moment(time), 'months');
    const yearsDiff = moment().diff(moment(time), 'years');
    if (yearsDiff !== 0) {
      return `${yearsDiff}y`;
    }
    if (monthsDiff !== 0) {
      return `${monthsDiff}mth`;
    }
    if (daysDiff !== 0) {
      if (daysDiff < 7) {
        return `${daysDiff}d`;
      }
      const week = Math.trunc(daysDiff / 7);
      return `${week}w`;
    }
    if (hoursDiff !== 0) {
      return `${hoursDiff}h`;
    }
    if (minutesDiff !== 0) {
      return `${minutesDiff}m`;
    }
    return 'now';
  }
  return '';
};

export const randomProperty = (obj: any) => {
  const keys = Object.keys(obj);
  // eslint-disable-next-line no-bitwise
  return obj[keys[(keys.length * Math.random()) << 0]];
};

export const getScreenSize = () => {
  const { width, height } = Dimensions.get('screen');
  return { width, height };
};

export const maxWidth = (max: number) => {
  const { width } = getScreenSize();
  return width >= max ? max : width;
};

export const isMobileDevice = () => {
  const userAgent = getUserAgentSync();
  if (Platform.OS !== 'web' || !userAgent) return true;
  // Check for mobile-specific keywords in the user agent string
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent,
  );
};

type PropsKeyT = keyof typeof eventProps;
export const trackEvent = (
  name: keyof typeof eventNames,
  eProps?: Partial<Record<PropsKeyT, any>>,
) => {
  const props = Object.keys(eProps || {}).reduce((sum, propKey) => {
    const eventKey = eventProps[propKey as PropsKeyT] as string;
    const sumObj = sum;
    sumObj[eventKey] = eProps?.[propKey as PropsKeyT];
    return sumObj;
  }, {} as Record<string, any>);
  analytics.track(eventNames[name], props);
};

export const preloadImages = (arr: any[]) => {
  arr.forEach((picture) => {
    const img = new Image();
    img.src = picture;
  });
};

export { analytics };

export const errorModal = (
  errorMessage?: string,
  errorButton?: string,
  errorFunction?: () => void,
) => {
  Reoverlay.showModal('ErrorModal', {
    errorMessage,
    errorButton,
    errorFunction,
  });
};
