import * as React from 'react';

import { View, StyleSheet, Platform } from 'react-native';

import MakeItRain from '@/components/MakeItRain';
import { COLORS } from '@/utils';

const Confetti = () => {
  const confettiColors = [
    COLORS.purple,
    COLORS.blue,
    COLORS.green,
    COLORS.red,
    COLORS.yellow,
  ];

  const confettiNumber = Platform.OS === 'ios' ? 25 : 15;

  return (
    <View style={styles.confettiContainer}>
      <MakeItRain
        numItems={confettiNumber}
        itemDimensions={styles.confetti}
        continuous={false}
        fallSpeed={100}
        horizSpeed={100}
        itemColors={confettiColors}
      />
      <MakeItRain
        numItems={confettiNumber}
        itemDimensions={styles.confettiCircle}
        continuous={false}
        fallSpeed={100}
        horizSpeed={100}
        itemColors={confettiColors}
      />
      <MakeItRain
        numItems={confettiNumber}
        itemDimensions={styles.confetti}
        continuous={false}
        fallSpeed={90}
        horizSpeed={90}
        itemColors={confettiColors}
      />
      <MakeItRain
        numItems={confettiNumber}
        itemDimensions={styles.confettiCircle}
        continuous={false}
        fallSpeed={90}
        horizSpeed={90}
        itemColors={confettiColors}
      />
      <MakeItRain
        numItems={confettiNumber}
        itemDimensions={styles.confetti}
        continuous={false}
        fallSpeed={80}
        horizSpeed={80}
        itemColors={confettiColors}
      />
      <MakeItRain
        numItems={confettiNumber}
        itemDimensions={styles.confettiCircle}
        continuous={false}
        fallSpeed={80}
        horizSpeed={80}
        itemColors={confettiColors}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  confettiContainer: {
    zIndex: 9999,
    position: 'absolute',
    top: 0,
    backgroundColor: 'transparent',
  },
  confetti: {
    width: 25,
    height: 15,
  },
  confettiCircle: {
    width: 15,
    height: 15,
    borderRadius: 25,
  },
});

export default Confetti;
