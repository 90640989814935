import React, { useState, useEffect } from 'react';

import { Image } from 'expo-image';
import { StyleSheet, View, Animated } from 'react-native';
import { TapGestureHandler } from 'react-native-gesture-handler';

import Images from '@/assets/images';
import { FlexContainer, Text } from '@/components/web';
import { maxWidth, COLORS } from '@/utils';

type CoinBalanceProps = {
  balance: number;
  setCoinDropExplainerModalOpen: (...args: any[]) => any;
};

const deviceWidth = maxWidth(500);

export default function CoinBalance({
  balance,
  setCoinDropExplainerModalOpen,
}: CoinBalanceProps) {
  const [balanceIconAnimatedValue] = useState(new Animated.Value(0));
  const [expandAnimatedValue] = useState(new Animated.Value(0));

  const animateBalanceIncrease = () => {
    Animated.sequence([
      Animated.timing(balanceIconAnimatedValue, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }),
      Animated.delay(100),
      Animated.timing(balanceIconAnimatedValue, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start();
  };

  const animateExpand = () => {
    Animated.sequence([
      Animated.timing(expandAnimatedValue, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }),
    ]).start();
  };

  useEffect(() => {
    if (balance > 0) {
      animateBalanceIncrease();
    }
  }, [balance]);

  useEffect(() => {
    animateExpand();
  }, []);

  return (
    <View style={styles.root}>
      <View style={styles.stashBalanceContainer}>
        <Animated.View style={[styles.animatedStashBalanceContainer]}>
          <Animated.View
            style={{
              top: 7,
              opacity: expandAnimatedValue.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 1],
              }),
            }}
          >
            <View style={styles.coinIcon}>
              <Image
                style={styles.whiteCoinDropBalance}
                source={Images.whiteCoinDropBalance}
              />
            </View>
            <FlexContainer row alignItems="center">
              <Text mediumSmallPlus2 bold inverse>
                Coin Drop!
              </Text>
              {balance === 0 && (
                <TapGestureHandler
                  onHandlerStateChange={() => {
                    setCoinDropExplainerModalOpen(true);
                  }}
                >
                  <View>
                    <Image
                      style={styles.infoIcon}
                      source={Images.infoIconWhite}
                    />
                  </View>
                </TapGestureHandler>
              )}
              <Animated.View
                style={[
                  styles.animatedCoinBalance,
                  {
                    transform: [
                      {
                        scale: balanceIconAnimatedValue.interpolate({
                          inputRange: [0, 0.25, 0.65, 1],
                          outputRange: [1, 1.01, 1.02, 1.03],
                        }),
                      },
                    ],
                  },
                ]}
              >
                <Text medium bold inverse>
                  ${balance.toFixed(2)}
                </Text>
              </Animated.View>
            </FlexContainer>
          </Animated.View>
        </Animated.View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10000,
  },
  coinIcon: {
    position: 'absolute',
    top: -7,
    right: 10,
    height: 40,
    overflow: 'hidden',
  },
  stashBalanceContainer: {
    position: 'absolute',
    backgroundColor: COLORS.purple,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    height: 40,
  },
  animatedStashBalanceContainer: {
    position: 'absolute',
    height: 40,
    paddingLeft: 16,
    backgroundColor: COLORS.purple,
    width: deviceWidth,
  },

  stashCoinIconContainer: {
    position: 'absolute',
    left: '40%',
    zIndex: 1000,
    top: -2,
  },
  bannerText: {
    top: 10,
  },
  topBanner: {
    position: 'absolute',
    height: 40,
    paddingLeft: 10,
    backgroundColor: COLORS.purple,
  },
  infoIcon: {
    marginLeft: 5,
    height: 18,
    width: 18,
  },
  whiteCoinDropBalance: { width: 50, height: 50 },
  animatedCoinBalance: {
    position: 'absolute',
    right: 65,
    zIndex: 1000,
  },
});
