import { gql } from '@apollo/client';

export const LOGIN_WITH_EMAIL = gql`
  mutation LoginWithEmail($emailOrUsername: String!, $password: String!) {
    loginWithEmail(
      input: { emailOrUsername: $emailOrUsername, password: $password }
    ) {
      success
      error
      token
      userId
    }
  }
`;
