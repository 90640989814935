import { gql } from '@apollo/client';

export const CLAIM_REFERRAL_CODE = gql`
  mutation ReferralCodeClaim($inviteCode: String!) {
    referralCodeClaim(input: { inviteCode: $inviteCode }) {
      success
      campaignId
      error
    }
  }
`;
