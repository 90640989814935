import { Platform, StyleSheet } from 'react-native';

import { COLORS } from '@/utils/constants';

export const styles = StyleSheet.create({
  navigationContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    ...(Platform.OS === 'web' && { cursor: 'pointer' }),
  },
  impactContainer: {
    maxWidth: 285,
    shadowColor: 'black',
    shadowOffset: {
      width: 8,
      height: 8,
    },
    marginVertical: 10,
    shadowRadius: 5,
    shadowOpacity: 0.1,
  },
  impactContainerBrandModal: {
    elevation: 6,
    maxWidth: 270,
    shadowColor: 'black',
    shadowOffset: {
      width: 8,
      height: 8,
    },
    shadowRadius: 5,
    shadowOpacity: 0.1,
    height: 157,
  },
  impact: {
    minHeight: 145,
    width: '100%',
    backgroundColor: COLORS.purple,
    borderRadius: 10,
    borderWidth: 4,
    borderColor: COLORS.purple,
    padding: 10,
  },
  brandImpact: {
    height: 145,
    width: '100%',
    backgroundColor: COLORS.purple,
    borderRadius: 7,
    padding: 15,
  },
  brandImpactBrandModal: {
    marginVertical: 10,
    paddingTop: '10%',
    height: 157,
    width: '100%',
    backgroundColor: COLORS.tertiaryPurple,
    borderRadius: 20,
    padding: 15,
  },
  impactCompact: {
    width: '100%',
    backgroundColor: COLORS.purple,
    borderRadius: 1,
    padding: 15,
  },
  impactDark: {
    width: '80%',
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
  },
  impactPurple: {
    backgroundColor: COLORS.purple,
    padding: 5,
    borderRadius: 1,
    position: 'absolute',
    bottom: 15,
  },
  imageStyle: {
    width: '30%',
    borderBottomRightRadius: 7,
    borderTopRightRadius: 7,
  },
  imageStyleBrandModal: {
    width: '30%',
    borderBottomLeftRadius: 20,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  logoCard: {
    flexDirection: 'column',
    height: 145,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoCardImage: {
    width: 85,
    height: 85,
    borderRadius: 42,
    marginBottom: 10,
  },
  infoIconWhite: {
    zIndex: 1,
    position: 'absolute',
    padding: 0,
    right: 10,
    top: 10,
  },
});
