import React from 'react';

import { Routes, Route } from 'react-router-dom';

import CauseCampaigns from './CauseCampaigns';
import Causes from './Causes';
import NotFound from '../NotFound';

const CreateStashlink = () => {
  return (
    <Routes>
      <Route path="/" element={<Causes />} />
      <Route path="/:cause" element={<CauseCampaigns />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default CreateStashlink;
