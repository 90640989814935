import React, { useEffect } from 'react';

import { Image } from 'expo-image';
import { View } from 'react-native';

import images from '@/assets/images';
import { SlideUpModal, Text } from '@/components/web';
import { COLORS, preloadImages } from '@/utils';
import scroll from '@/utils/scroll';
import tw from '@tw';

const StashrunModal = () => {
  useEffect(() => {
    scroll.lock();
    preloadImages([images.stashrun]);
    return () => {
      scroll.enable();
    };
  }, []);

  return (
    <SlideUpModal
      maxHeight={325}
      haloColor={COLORS.purple}
      userImage={images.stashrun}
      keepBackground
    >
      <View style={tw`items-center w-full`}>
        <View style={tw`mb-2 mt-18 px-14`}>
          <Text small bold centered>
            Fund charities by sharing sponsored links
          </Text>
          {/* List Item */}
          <View>
            {[
              `Stashlinks are personalized fundraising URLs, turning attention into $$$ for charity.`,
              `Play sponsored games to raise funds.`,
              `Share your own Stashlinks with friends.`,
            ].map((_item) => (
              <View style={tw`mt-3`} key={_item}>
                <Text small centered>
                  {_item}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <Image
          style={tw.style(`w-32 h-6 self-center mt-4`, {
            contentFit: 'center',
          })}
          source={images.stashrunTitlePurple}
        />
        <View style={tw`mt-2 flex flex-row items-center space-x-4`}>
          <a
            style={tw`no-underline mr-2`}
            href="https://www.stashrun.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            <Text small bold lightBlack>
              Privacy
            </Text>
          </a>
          <Text small lightBlack>
            •
          </Text>
          <a
            style={tw`no-underline ml-2`}
            href="https://www.stashrun.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            <Text small bold lightBlack>
              Terms
            </Text>
          </a>
        </View>
      </View>
    </SlideUpModal>
  );
};

export default StashrunModal;
