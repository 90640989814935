import { gql } from '@apollo/client';

export const CONNECT_TO_FACEBOOK = gql`
  mutation ConnectToFacebook(
    $fbToken: String!
    $fbId: String!
    $fbAppId: String!
    $fbExpirationDate: String!
    $fbRefreshDate: String
    $coinDropId: Int
    $coinsCollected: Int
    $silverCoinsCollected: Int
    $goldCoinsCollected: Int
    $redCoinsCollected: Int
    $purpleCoinsCollected: Int
    $referralCode: String
  ) {
    connectToFacebook(
      input: {
        fbToken: $fbToken
        fbId: $fbId
        fbAppId: $fbAppId
        fbExpirationDate: $fbExpirationDate
        fbRefreshDate: $fbRefreshDate
        coinDropId: $coinDropId
        coinsCollected: $coinsCollected
        silverCoinsCollected: $silverCoinsCollected
        goldCoinsCollected: $goldCoinsCollected
        redCoinsCollected: $redCoinsCollected
        purpleCoinsCollected: $purpleCoinsCollected
        referralCode: $referralCode
      }
    ) {
      success
      error
      token
      newUser
      user {
        id
        email
        fbName
        pendingCoinDrops
      }
      coinDrop {
        id
        coinsCollected
        silverCoinsCollected
        goldCoinsCollected
        redCoinsCollected
        purpleCoinsCollected
        collectedValueUsd
      }
    }
  }
`;
