import React, { useEffect } from 'react';

import { Image } from 'expo-image';
import moment from 'moment';
import { View, ScrollView } from 'react-native';

import {
  PrimaryButton,
  Text,
  EntityDescription,
  EntityHeader,
  DetailsTable,
} from '@/components/web';
import { Brand, Cause } from '@/types';
import {
  maxWidth,
  COLORS,
  trackEvent,
  scroll,
  addCloudinaryTransformation,
} from '@/utils';
import tw from '@tw';
import { WebWrapper } from '@web/components';

type BrandPageDetailProps = {
  brand?: Brand;
  cause?: Cause;
  setGameType: (gameType: 'spinner' | 'coinTap') => void;
  pressedGame: 'spinner' | 'coinTap';
};

const BrandPageDetail = ({
  brand,
  setGameType,
  pressedGame,
}: BrandPageDetailProps) => {
  const HeroPreviewRef = React.useRef<any>(null);
  useEffect(() => {
    scroll.enable();
    if (brand?.name) {
      trackEvent('brandModalViewed', { brand: brand.name });
    }
    return () => {
      scroll.lock();
    };
  }, [brand?.name]);

  const {
    heroTagline,
    location,
    name,
    videoUrl,
    yearFounded,
    brandColor,
    heroImageUrl,
    brandImages,
    description,
    instagramUsername,
    twitterUsername,
    facebookUsername,
    youtubeUsername,
    tiktokUsername,
    linkedinUrl,
    website,
    supportedCauses,
    joinedAt,
    imageUrl,
  } = brand || {};

  const charityDetails = [
    { label: 'Registered name', value: name },
    { label: 'Location', value: location },
    { label: 'Founded in', value: yearFounded || '?' },
  ];
  const closeModal = () => {
    setGameType(pressedGame);
    trackEvent('chooseGameClicked', {
      type: pressedGame,
    });
    HeroPreviewRef?.current?.stopVideo?.();
  };

  return (
    <WebWrapper>
      <ScrollView
        style={tw`bg-white self-center w-full max-w-${maxWidth(
          500,
        )} pb-140px flex flex-1`}
        showsVerticalScrollIndicator={false}
      >
        <View style={tw`pt-10px`}>
          <Text mediumSmallPlus2 centered bold italicized>
            Meet your game sponsor
          </Text>
          <Text small centered>
            <Text small centered bold>
              {brand?.name}{' '}
            </Text>
            will pay for the donation ❤️
          </Text>
        </View>

        <EntityHeader
          heroImageUrl={heroImageUrl}
          videoUrl={videoUrl}
          location={location}
          instagramData={brandImages}
          showlocation={false}
        />

        <View style={tw`pl-4`}>
          <View style={tw`py-2 pr-28`}>
            <Text
              mediumSmallPlus2
              bold
              numberOfLines={1}
              override={tw`text-xl leading-5 pb-5px`}
            >
              {name || ''}
            </Text>
            {!!joinedAt?.length && (
              <Text
                xsmallPlus2
                bold
                numberOfLines={1}
                color={COLORS.secondaryText}
              >
                Joined {moment(joinedAt).format('MMM YYYY')}
              </Text>
            )}
          </View>
          <View style={tw`w-9.2/10 mt-2`}>
            <Text mediumSmall override={tw`pr-2 py-2`}>
              {heroTagline || ''}
            </Text>
          </View>

          <View
            style={tw.style(
              `w-22 h-22 absolute right-0 top-[-10] z-1 mr-16px border-2 items-center justify-center rounded-full bg-white`,
              {
                borderColor: brandColor || 'black',
              },
            )}
          >
            <Image
              style={tw.style(`h-20 w-20 rounded-full`)}
              source={{
                uri: addCloudinaryTransformation(
                  imageUrl,
                  'c_scale,w_90,dpr_3',
                ),
              }}
            />
          </View>
          <View style={tw`w-9.2/10`}>
            <EntityDescription
              description={description}
              website={website}
              color={brandColor}
              facebookUsername={facebookUsername}
              twitterUsername={twitterUsername}
              instagramUsername={instagramUsername}
              youtubeUsername={youtubeUsername}
              tiktokUsername={tiktokUsername}
              linkedinUrl={linkedinUrl}
              entity="Brand"
            />
          </View>

          <DetailsTable
            details={charityDetails}
            supportedCauses={supportedCauses}
          />
        </View>
      </ScrollView>

      <View
        style={tw.style(
          `fixed max-w-${maxWidth(
            500,
          )}px w-full bg-white bottomSheetShadow rounded-tl-[20px] rounded-tr-[20px] self-center items-center bottom-0 pt-3 pb-6`,
        )}
      >
        <PrimaryButton
          style="w-full"
          title={'Let’s Play!'}
          fullRadius
          onPress={() => closeModal()}
        />

        <Text xsmallPlus2 override={tw`pt-8px text-center w-full`}>
          Play a minigame to stash the donation
        </Text>
      </View>
    </WebWrapper>
  );
};

export default BrandPageDetail;
