import React, { useState, useCallback, useEffect } from 'react';

import { useFormspark } from '@formspark/use-formspark';
import { Image } from 'expo-image';
import { View, Dimensions, TextInput } from 'react-native';

import { Text, PrimaryButton } from '@/components/web';
import { Campaign } from '@/types';
import { COLORS, trackEvent, maxWidth } from '@/utils';
import { addCloudinaryTransformation } from '@/utils/format';
import scroll from '@/utils/scroll';
import tw from '@tw';

export const FORMSPARK_FORM_ID = 'v1yS8HDL';
export const FORMSPARK_FORM_ID_PASSCODE_WAITLIST = 'PpNG7PVo';
type BrandMatchProps = {
  campaign?: Campaign;
  isModal?: boolean;
};

const screenWidth = maxWidth(500);
const { height } = Dimensions.get('window');

const WaitlistForm = ({ campaign, isModal }: BrandMatchProps) => {
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });
  const [email, setEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);

  useEffect(() => {
    scroll.enable();
  }, []);

  const validateEmail = useCallback(
    (input: string) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValid = re.test(String(input).toLowerCase());
      setEmailIsValid(isValid);
      return isValid;
    },
    [setEmailIsValid],
  );

  const onChangeEmail = useCallback(
    (email: string) => {
      setEmail(email);
      validateEmail(email);
    },
    [setEmail],
  );

  const onSubmit = async () => {
    if (!emailIsValid) {
      alert('Please enter a valid email');
      return;
    }
    trackEvent('earlyAccessButtonClicked', {
      page: 'Waitlist',
    });
    await submit({ email });
    alert('You did it! We’ll be in touch. ✨');
    setEmail('');
  };

  const { imageUrl } = campaign || {};

  return (
    <View style={tw`flex-1`}>
      {!isModal && (
        <>
          <View
            style={tw`fixed w-full h-full bottom-0 opacity-90 bg-purple z-0`}
          />
          <Image
            style={tw.style('fixed w-full h-full bottom-0 opacity-10 z-0')}
            source={{
              uri: addCloudinaryTransformation(
                imageUrl,
                `h_${Math.ceil(height)},w_${Math.ceil(screenWidth)},c_fill`,
              ),
            }}
          />
        </>
      )}

      <View style={tw`flex-1 px-24px py-12 z-10 justify-center items-center`}>
        <Text
          {...(isModal ? { mediumSmallPlus2: true } : { medium2: true })}
          inverse={!isModal}
          centered
          bold
          override={tw`flex-col flex items-center`}
        >
          Keep funding the causes
          <Text
            {...(isModal ? { mediumSmallPlus2: true } : { medium2: true })}
            inverse={!isModal}
            centered
            bold
          >
            you care about
          </Text>
        </Text>
        {!isModal && (
          <Text mediumSmallPlus inverse centered bold override={tw`pt-7`}>
            Stashrun is the easiest way to fundraise with friends, for free!
          </Text>
        )}
        <Text
          {...(isModal ? { mediumSmall: true } : { mediumSmallPlus: true })}
          inverse={!isModal}
          centered
          bold
          override={tw`pt-5 px-12px`}
        >
          Enter your email and get early access to the app.
        </Text>
        <View style={tw`w-full mt-24px px-16px`}>
          <Text small inverse={!isModal} bold override={tw`mb-8px`}>
            Enter your email
          </Text>
          <TextInput
            autoCorrect={false}
            placeholder="johnsmith123@stanford.edu"
            placeholderTextColor={COLORS.darkLightGray}
            value={email}
            onChangeText={onChangeEmail}
            keyboardType="email-address"
            autoCapitalize="none"
            style={tw`rounded-10px h-50px bg-white border-2 border-black px-12px mb-15px text-base`}
          />
          <PrimaryButton
            style="w-full self-center flex"
            onPress={onSubmit}
            loading={submitting}
            title="Get Early Access"
          />
        </View>
        <Text
          small
          centered
          color={isModal ? COLORS.darkGray : COLORS.white}
          override={tw`mt-12px mx-3`}
        >
          Student? Enter your edu address to skip the line.
        </Text>
      </View>
    </View>
  );
};

export default WaitlistForm;
