import { addCloudinaryTransformation } from "@/utils";

/* eslint-disable global-require */
const images = {
    stashrunTitle: require('./Stashrun-title.png'),
    stashrunTitlePurple: require('./Stashrun-title-purple.png'),
    socialIconGroup: require('./social-icon-group.png'),
    iosDownload: require('./ios-download.png'),
    moderatorShield: require('./moderator-shield.png'),
    stashrunLogo: require('./stashrun-logo.png'),
    stashrunLogoRing: require('./stashrun-logo-ring.png'),
    campaignCardGradient: require('./campaignCardGradient.png'),
    defaultProfilePic: require('./blank_default_profile.png'),
    rectangle: require( './Rectangle.png'),
    infoIconWhite: require('./info_icon_white.png'),
    whiteCoinDropBalance: require('./white-coin-drop-balance.png'),
    campaignPreview: require('./campaign-preview.png'),
    downwardPointingArrow: require("./downward_pointing_arrow.png"),
    upwardRedArrow: require("./upward-red-arrow.png"),
    brokenImage: require('./broken-image.png'),
    boomText: require('./boom-text.png'),
    brandLogoAnimation: require('./brand-logo-animation.gif'),
    tabIcons: {
        coindrop: require('./icons/tab-icons/coindrop.png'),
        coindropActive: require('./icons/tab-icons/coindrop-active.png'),
        convo: require('./icons/tab-icons/convo.png'),
        convoActive: require('./icons/tab-icons/convo-active.png'),
        star: require('./icons/tab-icons/star.png'),
        starActive: require('./icons/tab-icons/star-active.png'),
        stashlink: require('./icons/tab-icons/stashLink.png'),
    },
    icons: {
      rotatePhone: require("./icons/rotate-phone.png"),
      smsIcon: require("./icons/sms-icon.png"),
      checkMark: require("./icons/check.png"),
      padLock: require("./icons/padlock.png"),
      star: require("./icons/impact-star.png"),
      muted: require("./icons/icon-muted.png"),
      notMuted: require("./icons/icon-not-muted.png"),
      congrats: require("./icons/🎉.png"),
      copyLink: require("./icons/copy-link-icon.png"),
      saveImage: require("./icons/save-image-icon.png"),
    },
    coinDrop: {
      howToStep2: require("./how_to_coin_drop_step_2.png"),
      howToStep3: require("./how_to_coin_drop_step_3.png"),
      coinValues: require("./coin_values.png"),
    },
    coins: {
      goldCoin: require("./images/gold_coin.webp"),
      copperCoin: require("./images/copper_coin.webp"),
      purpleCoin: require("./images/purple_coin.webp"),
      redCoin: require("./images/red_coin.webp"),
    },
    wheelGameKnob: require("./knob.png"),
    chooseCoinDrop: require("./choose-coindrop.png"),
    chooseSpin: require("./choose-spin.png"),
    fillBack: require("./fill-back.png"),
    share: require("./share.svg"),
    videoPlay: require("./video-play.svg"),
    blueShare: require("./blue-share.png"),
    stashrun: require("./icon.png"),
    calendar: require("./calendar.svg"),
    link: require("./link.png"),
    loginIntro: require("./loginIntro.png"),
    defaultProfile: require("./defaultProfile.png"),
    email: require("./email.png"),
    meta: require("./meta.png"),
    shareDark: require("./share-dark.png"),
    copyIcon: require("./copy-icon.svg"),
    cameraIcon: require("./camera-icon.svg"),
    donationDone: require("./donation-done.png"),
    videoPlaceholder: require("./video-upload-placeholder.png"),
    videoClose: require("./video-close.png"),
    openTab: require("./open-tab.svg"),
    dotMenu: require("./3dotmenu.png"),
    leaderboardHeader: require("./leaderboard-header.png"),
    waitlist: require("./waitlist.png"),
    profilePhotoUpload: require("./profile-photo-upload.png"),
    logoTiktok: require("./logo-tiktok.png"),
    logoInstagram: require("./logo-instagram.png"),
    logoYoutube: require("./logo-youtube.png"),
    logoTwitter: require("./logo-twitter.png"),
}

// eslint-disable-next-line import/no-dynamic-require
export const avatarImage = (id: number) => {
  return addCloudinaryTransformation(
    `https://res.cloudinary.com/hfpxddtij/image/upload/v1705556112/avatars/Rectangle_${id}.png`,
    'c_thumb,w_50',
  )
}

export default images;
