import { gql } from '@apollo/client';

export const FETCH_RECOMMENDED_CAMPAIGNS = gql`
  query {
    recommendedCampaigns {
      id
      title
      description
      location
      imageUrl
      currentAmount
      goalAmount
      endTime
      studentOrgs
      charity {
        id
        name
        imageUrl
        videoUrl
        heroImageUrl
      }
      cause {
        id
        imageUrl
        location
        description
        name
        numCampaigns
        numCharities
        numSupporters
        tagline
        videoUrl
        website
        wikipediaUrl
      }
    }
  }
`;
