import { useLazyQuery, useMutation } from '@apollo/client';

import {
  CREATE_STASHLINK,
  GET_STASHLINK,
  SHARE_STASHLINK,
  UPDATE_STASHLINK,
  VIEW_STASHLINK,
} from '@/queries';
import { User } from '@/types';

const useStashLinkHooks = () => {
  const [viewStashLink, { data: viewStashLinkData }] = useMutation<{
    viewStashlink: {
      success: boolean;
      error: boolean;
      stashlink: {
        thankYouMessage: string;
        thankYouMessageVideo: string;
        user: User;
      };
    };
  }>(VIEW_STASHLINK, {
    fetchPolicy: 'network-only',
  });
  const [
    getStashLink,
    { data: getStashLinkData, loading: getStashlinkLoading },
  ] = useLazyQuery<{
    stashlinkByUserAndCampaign: {
      thankYouMessage: string;
      thankYouMessageVideo: string;
      user: User;
      id: string;
      url: string;
    };
  }>(GET_STASHLINK, {
    fetchPolicy: 'network-only',
  });

  const [shareStashlink, { data: shareStashlinkData }] = useMutation<{
    shareStashlink: {
      success: boolean;
      error: boolean;
    };
  }>(SHARE_STASHLINK, {
    fetchPolicy: 'network-only',
  });

  const [
    createStashLink,
    { data: createStashLinkData, loading: createStashLinkLoading },
  ] = useMutation<{
    createStashlink: {
      success: boolean;
      error: boolean;
      stashlink: {
        id: string;
        url: string;
        thankYouMessage: string;
      };
    };
  }>(CREATE_STASHLINK, {
    fetchPolicy: 'network-only',
  });

  const [
    updateStashlink,
    { data: updateStashlinkData, loading: updateStashlinkLoading },
  ] = useMutation<{
    updateStashlink: {
      success: boolean;
      error: string;
      stashlink: {
        id: string;
        url: string;
        thankYouMessageVideo: string;
        thankYouMessage: string;
      };
    };
  }>(UPDATE_STASHLINK, {
    fetchPolicy: 'network-only',
  });

  return {
    viewStashLink,
    viewStashLinkData,
    createStashLink,
    createStashLinkData,
    createStashLinkLoading,
    shareStashlink,
    shareStashlinkData,
    updateStashlink,
    updateStashlinkData,
    getStashLink,
    getStashLinkData,
    updateStashlinkLoading,
    getStashlinkLoading,
  };
};

export default useStashLinkHooks;
