export const PROFILE_EXPLAINER_CONST = [
  {
    title: 'Influence 🔥',
    subTitle: 'Share stashlinks and post',
    content: `Score: Number of coin drops completed by friends with your Stashlink and/or upvotes on your posts in conversations.`,
  },
  {
    title: 'Speed ✋',
    subTitle: 'Tap coins really fast',
    content: `Score: Avg number of coins you’ve stashed in coin drops this month.`,
  },
  {
    title: 'Donations 💸',
    subTitle: 'Complete coin drops',
    content: `Score: Total number of $$$ donated in each coin drop you complete.`,
  },
];
