import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { Image, Platform, TouchableWithoutFeedback, View } from 'react-native';

import FlexContainer from '@/components/FlexContainer';
import Spacing from '@/components/Spacing';
import Text, { RATIO } from '@/components/Text';
import { ImpactItem } from '@/types';
import { COLORS } from '@/utils/constants';

import { styles } from './styles';

type CauseCardProps = {
  item: ImpactItem;
  brandDetails?: boolean;
};

const CauseCard = ({ item, brandDetails }: CauseCardProps) => {
  const navigation = Platform.OS === 'web' ? null : useNavigation();
  const onCausePress = () => {
    if (item.id)
      if (navigation) navigation.navigate('CauseProfile', { causeId: item.id });
  };

  if (!item || item.type !== 'Cause card') return null;
  if (brandDetails) {
    return (
      <TouchableWithoutFeedback
        onPress={onCausePress}
        style={styles.impactContainer}
      >
        <View
          style={{
            maxWidth: 270,
            marginVertical: brandDetails ? 10 : 0,
          }}
        >
          <Spacing vertical={brandDetails ? 0 : 1}>
            <FlexContainer
              row
              alignItems="center"
              justifyContent="space-between"
            >
              {!!item.campaignImage && (
                <Image
                  source={{
                    uri: item.campaignImage,
                  }}
                  style={[
                    { ...styles.imageStyle, height: '100%' },
                    styles.imageStyleBrandModal,
                  ]}
                />
              )}
              <View
                style={[
                  styles.impactDark,
                  {
                    backgroundColor: COLORS.tertiaryPurple,
                    height: 157,
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                    borderBottomRightRadius: 20,
                    borderTopRightRadius: 20,
                  },
                  !!item.campaignImage && { width: '70%' },
                ]}
              >
                <Spacing top={1} bottom={2}>
                  <Text
                    mediumSmall
                    inverse
                    bold
                    centered
                    numberOfLines={4}
                    override={{ color: COLORS.purple, lineHeight: 19 * RATIO }}
                  >
                    {item.campaignTitle}
                  </Text>
                </Spacing>
                <View style={styles.impactPurple}>
                  <Text centered mediumSmall inverse bold>
                    #{item.campaignCauseName}
                  </Text>
                </View>
              </View>
            </FlexContainer>
          </Spacing>
        </View>
      </TouchableWithoutFeedback>
    );
  }
  return (
    <TouchableWithoutFeedback
      onPress={onCausePress}
      style={styles.impactContainer}
    >
      <View
        style={{
          maxWidth: 270,
          marginVertical: brandDetails ? 10 : 0,
        }}
      >
        <Spacing vertical={brandDetails ? 0 : 1}>
          <FlexContainer row alignItems="center" justifyContent="space-between">
            <View
              style={[
                styles.impactDark,
                {
                  backgroundColor: COLORS.tertiaryPurple,
                  height: 145,
                  borderRadius: 7,
                },
                !!item.campaignImage && { width: '70%' },
              ]}
            >
              <Spacing top={1} bottom={2}>
                <Text
                  mediumSmall
                  inverse
                  bold
                  centered
                  numberOfLines={4}
                  override={{ color: COLORS.purple, lineHeight: 19 * RATIO }}
                >
                  {item.campaignTitle}
                </Text>
              </Spacing>
              <View style={styles.impactPurple}>
                <Text centered mediumSmall inverse bold>
                  #{item.campaignCauseName}
                </Text>
              </View>
            </View>
            {!!item.campaignImage && (
              <Image
                source={{
                  uri: item.campaignImage,
                }}
                style={[
                  { ...styles.imageStyle, height: '100%' },
                  brandDetails && styles.imageStyleBrandModal,
                ]}
              />
            )}
          </FlexContainer>
        </Spacing>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default CauseCard;
