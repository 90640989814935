import React from 'react';

import { View } from 'react-native';

import FlexContainer from '@/components/FlexContainer';
import SocialMediaButton from '@/components/SocialMediaButton';
import Spacing from '@/components/Spacing';
import Text from '@/components/Text';
import VolunteerButton from '@/components/VolunteerButton';
import WebsiteButton from '@/components/WebsiteButton';
import tw from '@tw';

type EntityDescriptionProps = {
  noTitle?: boolean;
  description?: string;
  website?: string;
  color?: string;
  volunteerUrl?: string;
  facebookUsername?: string;
  twitterUsername?: string;
  instagramUsername?: string;
  youtubeUsername?: string;
  tiktokUsername?: string;
  linkedinUrl?: string;
  entity?: string;
};

const EntityDescription = ({
  noTitle,
  description,
  website,
  color,
  volunteerUrl,
  facebookUsername,
  twitterUsername,
  instagramUsername,
  youtubeUsername,
  tiktokUsername,
  linkedinUrl,
  entity,
}: EntityDescriptionProps) => {
  const socialUsernameMap: { [key: string]: string } = {
    ...(!!facebookUsername?.length && { facebook: facebookUsername }),
    ...(!!twitterUsername?.length && { twitter: twitterUsername }),
    ...(!!instagramUsername?.length && { instagram: instagramUsername }),
    ...(!!youtubeUsername?.length && { youtube: youtubeUsername }),
    ...(!!tiktokUsername?.length && { tiktok: tiktokUsername }),
    ...(!!linkedinUrl?.length && { linkedin: linkedinUrl }),
  };
  const socialNetworks = Object.keys(socialUsernameMap);

  return (
    <Spacing vertical={1}>
      {!noTitle && (
        <View style={tw`mb-1`}>
          <Text mediumSmallPlus2 bold>
            Our Story
          </Text>
        </View>
      )}
      {!!description && (
        <View
          style={{
            borderLeftWidth: 5,
            borderLeftColor: color,
            marginBottom: 20,
          }}
        >
          <Spacing horizontal={1}>
            <Text mediumSmall semiboldMinus lightBlack>
              {description || ''}
            </Text>
          </Spacing>
          {(!!website || !!volunteerUrl) && (
            <Spacing horizontal={1} top={2} bottom={2}>
              <Spacing bottom={1}>
                <Text bold lightBlack mediumSmall>
                  Learn more
                </Text>
              </Spacing>
              <FlexContainer row>
                {!!website && (
                  <View style={{ marginRight: 10 }}>
                    <WebsiteButton link={website} entity={entity} />
                  </View>
                )}
                {!!volunteerUrl && (
                  <VolunteerButton link={volunteerUrl} entity={entity} />
                )}
              </FlexContainer>
            </Spacing>
          )}
          {socialNetworks.length > 0 && (
            <Spacing horizontal={1} bottom={2}>
              <Spacing top={1} bottom={1}>
                <Text bold lightBlack mediumSmall>
                  Check out our...
                </Text>
              </Spacing>
              <FlexContainer row>
                {socialNetworks.map((socialNetwork: any) => {
                  if (socialNetwork === 'linkedin') {
                    return (
                      <View key={socialNetwork} style={{ marginRight: 2 }}>
                        <SocialMediaButton
                          type={socialNetwork}
                          url={socialUsernameMap[socialNetwork]}
                          entity={entity}
                        />
                      </View>
                    );
                  }
                  return (
                    <View key={socialNetwork} style={{ marginRight: 2 }}>
                      <SocialMediaButton
                        type={socialNetwork}
                        username={socialUsernameMap[socialNetwork!]}
                        entity={entity}
                      />
                    </View>
                  );
                })}
              </FlexContainer>
            </Spacing>
          )}
        </View>
      )}
    </Spacing>
  );
};

export default EntityDescription;
