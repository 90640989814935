// eslint-disable-next-line react/jsx-filename-extension
import React, { useState, useEffect, useRef } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { View, Image, Animated, Dimensions, Pressable } from 'react-native';
import { Emitter } from 'react-native-particles';
import Pulse from 'react-native-pulse';
import ReAnimated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { Reoverlay } from 'reoverlay';
// eslint-disable-next-line import/no-extraneous-dependencies
import weighted from 'weighted';

import images from '@/assets/images';
import { DraggableCoin } from '@/components/CoinGame/DraggableCoin';
import OnboardingAnimations from '@/components/CoinGame/OnboardingAnimations';
import {
  LoadingIndicator,
  WheelSpin,
  useSessionTokenContext,
} from '@/components/web';
import { eventProps } from '@/constants/ANALYTICS_CONST';
import useUser from '@/hooks/useUser';
import useWebHooks from '@/hooks/useWebHooks';
import { SAMPLE_ELIGIBLE_BRANDS, START_COIN_DROP } from '@/queries';
import { StartCoinDropData, SampleEligibleBrandsData } from '@/types';
import {
  COLORS,
  addCloudinaryTransformation,
  delay,
  maxWidth,
  preloadImages,
  randomIntFromInterval,
  trackEvent,
  getStoreUserIdentifier,
  scroll,
  isMobileDevice,
  getStoreTimesPlayed,
  errorModal,
} from '@/utils';
import { isFeatureEnabled } from '@/utils/growthbook';
import tw from '@tw';
import { CampaignWall, WebWrapper } from '@web/components';

import ChooseGame from './ChooseGame';
import CoinBalance from './CoinBalance';
import CoinDropExplainerModal from './CoinDropExplainerModal';
import GameContextAndCountdown from './GameContextAndCountdown';
import GameFinished from './GameFinished';

const DEFAULT_COIN_DURATION_MS = 20000;
const PULSE_DELAY_MS = 500;
const PULSE_TIMING_MS = 3000;
const COIN_LIFE_MS = 5000;

export default function CoinGame() {
  const [userIdentifier, setUserIdentifier] = useState('');
  const [balance, setBalance] = useState(0);
  const [coinsObtained, setCoinsObtained] = useState(0);
  const [silverCoinsObtained, setSilverCoinsObtained] = useState(0);
  const [goldCoinsObtained, setGoldCoinsObtained] = useState(0);
  const [redCoinsObtained, setRedCoinsObtained] = useState(0);
  const [purpleCoinsObtained, setPurpleCoinsObtained] = useState(0);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [displayResults, setDisplayResults] = useState(false);
  const [onboardingFinished, setOnboardingFinished] = useState(false);
  const [coinGameFinished, setCoinGameFinished] = useState(false);
  const [loading, setLoading] = useState(true);
  const [speed, setSpeed] = useState(60);
  const [readyToStart, setReadyToStart] = useState(false);
  const [gameType, setGameType] = useState<'spinner' | 'coinTap' | undefined>();
  const [coinDropExplainerModalOpen, setCoinDropExplainerModalOpen] =
    useState(false);
  const [pulseAnimatedValue] = useState(new Animated.Value(0));
  const [timeupAnimatedValue] = useState(new Animated.Value(0));
  const [showWall, setShowWall] = useState(false);

  const pressed = useSharedValue(1);
  const { fetchUser, userData } = useUser();

  const redCoinRef = useRef<{ start: () => void }>(null);
  const purpleCoinRef = useRef<{ start: () => void }>(null);
  const wheelSpinRef = useRef<any>(null);
  const wheelSpinStart = useRef(false);

  const screenHeight = Dimensions.get('screen').height;
  const screenWidth = maxWidth(500);

  const { campaignLink, query, navigate } = useWebHooks();
  const { userId } = useSessionTokenContext();
  const referrerUserId = query.get('referrerUserId') || '';
  const secondaryReferrer = query.get('secondaryReferrer') || '';
  const isCoindrop = gameType === 'coinTap';
  const isOnboarding = false;
  const { data: eligibleBrands, loading: firstLoading } =
    useQuery<SampleEligibleBrandsData>(SAMPLE_ELIGIBLE_BRANDS, {
      variables: {
        campaignId: campaignLink,
        userIdentifier: userId || userIdentifier,
      },
      fetchPolicy: 'network-only',
    });
  const [
    startCoinDrop,
    { data: startCoinDropData, loading: startCoinDropLoading },
  ] = useMutation<StartCoinDropData>(START_COIN_DROP, {
    variables: {
      campaignId: campaignLink,
      userIdentifier,
      gameType,
      brandId: eligibleBrands?.sampleEligibleBrands?.brand.id,
      web: true,
    },
    onCompleted: async (data) => {
      if (!data.startCoinDrop?.success) {
        // Go back to game selection screen
        Reoverlay.showModal('WaitlistModal', {
          onBack: () => {
            setGameType(undefined);
            Reoverlay.hideModal();
          },
        });
        trackEvent('coinDropError', { error: data.startCoinDrop?.error });
        return;
      }
      trackEvent(isCoindrop ? 'coinDropStart' : 'spinnerStart', {
        referrerUserId,
        coinDropId: Number(data.startCoinDrop?.coinDrop?.id || '0'),
        stashrunUserId: userId || 'New user',
        anonymousUserId: userIdentifier,
      });
    },
    onError: (error: ApolloError) => {
      trackEvent(isCoindrop ? 'coinDropError' : 'spinnerError', { error });
    },
  });

  const delayCoinDropModal = async () => {
    await delay(500);
    if (isOnboarding) {
      setCoinDropExplainerModalOpen(true);
    }
  };

  useEffect(() => {
    const preventBack = (e: any) => {
      // is not near edge of view, exit
      if (e.pageX > 20 && e.pageX < window.innerWidth - 20) return;
      // prevent swipe to navigate back gesture
      e.preventDefault();
    };
    const element = document.querySelector('div');
    if (gameType) {
      element?.addEventListener('touchstart', preventBack);
    }
    if (coinGameFinished) {
      element?.removeEventListener('touchstart', preventBack);
    }
    return () => {
      element?.removeEventListener('touchstart', preventBack);
    };
  }, [gameType, coinGameFinished]);

  useEffect(() => {
    const gameQuery = query.get('game');
    if (gameQuery === 'spinner' || gameQuery === 'coinTap') {
      setGameType(gameQuery);
    }
    if (userId) {
      setUserIdentifier(userId);
    } else {
      // this user identifier is anonId
      const userStoreIdentifier = getStoreUserIdentifier();
      setUserIdentifier(userStoreIdentifier);
    }
    if (!isMobileDevice()) {
      setShowWall(true);
    }
  }, []);

  useEffect(() => {
    const timeNow = moment().format();
    return () => {
      trackEvent(isCoindrop ? 'coinDropGameViewed' : 'spinnerGameViewed', {
        secondsSpent: moment().diff(moment(timeNow), 'seconds'),
      });
    };
  }, []);

  useEffect(() => {
    delayCoinDropModal();
  }, [isOnboarding]);

  useEffect(() => {
    async function start() {
      try {
        // Gives a little timeout to let the component mount first
        setTimeout(async () => {
          await startCoinDrop();
          setLoading(false);
        }, 100);
      } catch (e) {
        alert('Unable to start a coin game currently. Please try again later!');
      } finally {
        // Tell the application to render
        if (gameType === 'coinTap') {
          setCoinDropExplainerModalOpen(true);
        }
        animatePulse();
        setTimeout(() => setReadyToStart(true), 3000);
      }
    }
    if (gameType) {
      start();
    }
  }, [gameType]);

  useEffect(() => {
    // prefetch userData
    if (userId && !userData) {
      fetchUser({ variables: { userId } });
    }
  }, [userId]);

  const coinDrop = startCoinDropData?.startCoinDrop?.coinDrop;

  const {
    maxSilverCoins,
    maxGoldCoins,
    maxRedCoins,
    maxPurpleCoins,
    timeMs,
    brand = eligibleBrands?.sampleEligibleBrands?.brand,
    campaign = eligibleBrands?.sampleEligibleBrands?.campaign,
    silverCoinValueUsd,
    goldCoinValueUsd,
    redCoinValueUsd,
    purpleCoinValueUsd,
    secret,
    spinnerOptions = [],
    spinnerOptionWeights = [],
  } = coinDrop || {};

  const { imageUrl, brandColor, heroImageUrl } = brand || {};
  const { charity } = campaign || {};
  const { imageUrl: charityImageUrl } = charity || {};
  const brandImage =
    imageUrl && addCloudinaryTransformation(imageUrl, 'h_340,w_340');
  const charityImage =
    charityImageUrl &&
    addCloudinaryTransformation(charityImageUrl, 'h_340,w_340');
  const heroImage =
    heroImageUrl &&
    addCloudinaryTransformation(
      heroImageUrl,
      `c_scale,w_${maxWidth(500)},dpr_2`,
    );
  const { brandLogoAnimation } = images;
  const coinGameDuration = timeMs || DEFAULT_COIN_DURATION_MS;

  const totalSilverCoins = maxSilverCoins || 0;
  const totalGoldCoins = maxGoldCoins || 0;
  const totalRedCoins = maxRedCoins || 0;
  const totalPurpleCoins = maxPurpleCoins || 0;
  const coinsPerInterval = 1;
  const silverIntervalMs =
    totalSilverCoins !== 0
      ? Math.round((coinGameDuration - 1500) / totalSilverCoins)
      : 1000;
  const goldIntervalMs =
    totalGoldCoins !== 0
      ? Math.round((coinGameDuration - 1500) / totalGoldCoins)
      : 1000;
  const redIntervalMs =
    totalRedCoins !== 0
      ? Math.round(coinGameDuration / 3 / totalRedCoins)
      : 1000;
  const purpleIntervalMs =
    totalPurpleCoins !== 0
      ? Math.round(coinGameDuration / 3 / totalPurpleCoins)
      : 1000;
  const totalDurationMs = coinGameDuration;

  const updateSpeed = () => {
    setSecondsElapsed(secondsElapsed + 1);
    setSpeed(
      totalDurationMs / 1000 - secondsElapsed <= 6
        ? 200
        : randomIntFromInterval(20, 120),
    );
  };

  useEffect(() => {
    if (brand && heroImage) {
      preloadImages([heroImage]);
    }
  }, [brand]);

  useEffect(() => {
    preloadImages([heroImage, brandLogoAnimation]);
    scroll.lock();
    return () => {
      scroll.enable();
    };
  }, []);

  useEffect(() => {
    let speedInterval: any = null;
    if (onboardingFinished && !speedInterval) {
      speedInterval = setInterval(updateSpeed, 1000);
    }

    if (displayResults && speedInterval) {
      clearInterval(speedInterval);
    }

    return () => {
      if (speedInterval) {
        clearInterval(speedInterval);
      }
    };
  }, [onboardingFinished, displayResults, secondsElapsed]);

  useEffect(() => {
    if (onboardingFinished) {
      // Random wait for red/purple coins to start
      if (totalRedCoins) {
        setTimeout(
          () => redCoinRef?.current?.start(),
          randomIntFromInterval(0, Math.round(coinGameDuration / 2)),
        );
      }
      if (totalPurpleCoins) {
        setTimeout(
          () => purpleCoinRef?.current?.start(),
          randomIntFromInterval(0, Math.round(coinGameDuration / 2)),
        );
      }
    }
  }, [onboardingFinished]);

  // redirects the user back to the main page to prevent multiple plays
  useEffect(() => {
    if (getStoreTimesPlayed(campaign?.slug || '') > 3) {
      errorModal(
        'The number of times the campaign has been played has reached the set limit!',
      );
      if (referrerUserId?.length) {
        navigate(`/users/${referrerUserId}/${campaign?.slug}`);
      } else {
        navigate(`/campaign/${campaign?.slug}`);
      }
    }
  });

  const animatePulse = () => {
    Animated.loop(
      Animated.sequence([
        Animated.delay(PULSE_DELAY_MS),
        Animated.timing(pulseAnimatedValue, {
          toValue: 1,
          duration: PULSE_TIMING_MS,
          useNativeDriver: true,
        }),
        Animated.delay(PULSE_DELAY_MS),
        Animated.timing(pulseAnimatedValue, {
          toValue: 0,
          duration: PULSE_TIMING_MS,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  };

  const tapToContinueAnimation = () => {
    Animated.sequence([
      Animated.timing(timeupAnimatedValue, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }),
    ]).start();
  };

  const increaseBalance = (
    coinValue?: string,
    color?: 'silver' | 'gold' | 'red' | 'purple',
  ) => {
    if (!displayResults) {
      setBalance((oldBalance) => oldBalance + Number(coinValue || 0));
      setCoinsObtained((oldCoinsObtained) => oldCoinsObtained + 1);

      switch (color) {
        case 'silver':
          setSilverCoinsObtained(
            (oldSilverCoinsObtained) => oldSilverCoinsObtained + 1,
          );
          break;
        case 'gold':
          setGoldCoinsObtained(
            (oldGoldCoinsObtained) => oldGoldCoinsObtained + 1,
          );
          break;
        case 'red':
          setRedCoinsObtained((oldRedCoinsObtained) => oldRedCoinsObtained + 1);
          break;
        case 'purple':
          setPurpleCoinsObtained(
            (oldPurpleCoinsObtained) => oldPurpleCoinsObtained + 1,
          );
          break;
        default:
          break;
      }
    }
  };

  const spinAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: withTiming(pressed.value, {
            duration: 300,
            easing: Easing.elastic(1.5),
          }),
        },
      ],
    } as any;
  });

  const onWheelStop = (winner: number) => {
    setTimeout(() => {
      setDisplayResults(true);
      setCoinGameFinished(true);
      setBalance(spinnerOptions[winner]);
    }, 600);
  };

  if (showWall && isFeatureEnabled('campaign-wall'))
    return <CampaignWall campaign={campaign} userId={referrerUserId} />;

  if (firstLoading) {
    return <LoadingIndicator />;
  }

  if (!gameType) {
    return (
      <>
        <ChooseGame
          brand={brand}
          brandImage={brandImage}
          campaign={campaign}
          setGameType={(type) => setGameType(type)}
          campaignLink={campaignLink}
          animate={true}
          brandLogoAnimation={brandLogoAnimation}
        />
      </>
    );
  }

  if (startCoinDropLoading || loading) {
    return <LoadingIndicator />;
  }

  if (coinGameFinished && balance) {
    return (
      <GameFinished
        coinDrop={coinDrop!}
        coinsObtained={coinsObtained}
        silverCoinsObtained={silverCoinsObtained}
        goldCoinsObtained={goldCoinsObtained}
        redCoinsObtained={redCoinsObtained}
        purpleCoinsObtained={purpleCoinsObtained}
        secret={secret}
        referrerUserId={referrerUserId}
        timeupAnimatedValue={timeupAnimatedValue}
        displayResults={displayResults}
        balance={balance}
        isOnboarding={isOnboarding}
        isCoindrop={isCoindrop}
        tapToContinueAnimation={tapToContinueAnimation}
        profilePhoto={charityImage}
        secondaryReferrerUserId={secondaryReferrer}
      />
    );
  }

  return (
    <WebWrapper
      analytics={{
        [eventProps.page]:
          gameType === 'coinTap' ? 'Coin game' : 'Spinner game',
      }}
    >
      <CoinDropExplainerModal
        brand={brand}
        brandImage={brandImage}
        isCoinDropExplainerModalOpen={coinDropExplainerModalOpen}
        setCoinDropExplainerModalOpen={setCoinDropExplainerModalOpen}
      />
      <GameContextAndCountdown
        onboardingFinished={onboardingFinished}
        displayResults={displayResults}
        totalDurationMs={totalDurationMs}
        causeName={campaign?.cause?.name}
        isCoindrop={isCoindrop}
        username={brand?.name}
        setCoinGameFinished={(finished: boolean) => {
          setDisplayResults(true);
          setCoinGameFinished(finished);
        }}
      />
      {isCoindrop && (
        <CoinBalance
          setCoinDropExplainerModalOpen={(val: boolean) => {
            if (!onboardingFinished) {
              setCoinDropExplainerModalOpen(val);
            }
          }}
          balance={balance}
        />
      )}
      {isCoindrop && !coinDropExplainerModalOpen && readyToStart && (
        <OnboardingAnimations
          onboardingFinished={onboardingFinished}
          onStart={() => {
            if (!coinDropExplainerModalOpen) {
              increaseBalance(goldCoinValueUsd, 'gold');
              setOnboardingFinished(true);
            }
          }}
        />
      )}
      <Image
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: screenWidth,
          position: 'absolute',
          zIndex: -100,
          opacity: 0.1,
        }}
        source={{
          uri: addCloudinaryTransformation(
            campaign?.imageUrl,
            `h_${Math.ceil(screenHeight)},w_${Math.ceil(screenWidth)},c_fill`,
          ),
        }}
      />
      {!isCoindrop && (
        <>
          <Pressable
            style={tw`top-0 left-0 w-full h-full fixed z-10`}
            onPressOut={() => {
              wheelSpinRef.current?.spin();
              pressed.value = withTiming(1, {
                duration: 300,
                easing: Easing.elastic(1.5),
              });
            }}
            onPressIn={(e) => {
              e.preventDefault();
              if (wheelSpinStart.current) return;
              pressed.value = withTiming(1.05, {
                duration: 300,
                easing: Easing.elastic(1.5),
              });
              wheelSpinRef.current?.holdSpin();
              wheelSpinStart.current = true;
            }}
          />
          <ReAnimated.View
            style={[
              spinAnimatedStyle,
              tw`flex absolute top-[30%] w-full items-center justify-center`,
            ]}
          >
            <WheelSpin
              ref={wheelSpinRef}
              onWheelStop={onWheelStop}
              rewards={spinnerOptions.map((i: number) => `$${i}`)}
              winner={
                spinnerOptions.indexOf(
                  weighted.select(spinnerOptions, spinnerOptionWeights),
                ) || 0
              }
              textColor={COLORS.black}
              colors={[COLORS.white]}
            />
            <Animated.View
              style={[
                {
                  transform: [
                    {
                      scale: pulseAnimatedValue.interpolate({
                        inputRange: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                        outputRange: [1, 0.97, 0.95, 0.97, 1, 1.03, 1.05, 1],
                      }),
                    },
                  ],
                },
                tw`absolute z-0 items-center justify-center`,
              ]}
            >
              <View
                style={tw`border-[6px] border-[${
                  brandColor || '#0597E7'
                }] p-1.5 rounded-full absolute bg-white w-[110px] h-[110px] -z-10`}
              >
                <Image
                  style={tw`w-full h-full rounded-full`}
                  source={{
                    uri: brandImage,
                  }}
                />
              </View>
            </Animated.View>
          </ReAnimated.View>
        </>
      )}
      {isCoindrop && !coinGameFinished && (
        <>
          {/* Pulsing background circles */}
          <View
            style={{
              position: 'absolute',
              left: '50%',
              top: '55%',
              zIndex: -20,
            }}
          >
            <Pulse
              color={brandColor}
              numPulses={3}
              diameter={360}
              initialDiameter={180}
              speed={20}
              duration={2000}
              style={{
                zIndex: -100,
                top: -180,
                position: 'absolute',
              }}
            />
          </View>

          {/* Particle Emitter */}

          <View
            style={{
              backgroundColor: '#ffffff',
              position: 'absolute',
              left: '50%',
              top: '55%',
              zIndex: -10,
            }}
          >
            {onboardingFinished && (
              <>
                <Emitter
                  autoStart
                  numberOfParticles={totalSilverCoins} // From the onboarding coin
                  emissionRate={coinsPerInterval}
                  interval={silverIntervalMs}
                  particleLife={COIN_LIFE_MS}
                  speed={speed}
                  direction={-90}
                  spread={360}
                  fromPosition={{ x: 0, y: 0 }}
                  style={{
                    backgroundColor: '#ffffff',
                    position: 'relative',
                    zIndex: 1000,
                  }}
                  width={500}
                  height={500}
                >
                  <DraggableCoin
                    color="silver"
                    onStart={() => {
                      increaseBalance(silverCoinValueUsd, 'silver');
                    }}
                    boxStyle={{ top: -25, left: -25, zIndex: -15 }}
                  />
                </Emitter>

                <Emitter
                  autoStart
                  numberOfParticles={totalGoldCoins - 1} // From the onboarding coin
                  emissionRate={coinsPerInterval}
                  interval={goldIntervalMs}
                  particleLife={COIN_LIFE_MS}
                  speed={speed}
                  direction={-90}
                  spread={360}
                  fromPosition={{ x: 0, y: 0 }}
                  style={{
                    backgroundColor: '#ffffff',
                    position: 'relative',
                    zIndex: 1000,
                  }}
                  width={500}
                  height={500}
                >
                  <DraggableCoin
                    color="gold"
                    onStart={() => {
                      increaseBalance(goldCoinValueUsd, 'gold');
                    }}
                    boxStyle={{ top: -25, left: -25, zIndex: -15 }}
                  />
                </Emitter>

                <Emitter
                  autoStart={false}
                  numberOfParticles={totalRedCoins}
                  emissionRate={coinsPerInterval}
                  interval={redIntervalMs}
                  particleLife={COIN_LIFE_MS}
                  speed={speed * 1.2}
                  direction={-90}
                  spread={360}
                  fromPosition={{ x: 0, y: 0 }}
                  style={{
                    backgroundColor: '#ffffff',
                    position: 'relative',
                    zIndex: 1000,
                  }}
                  ref={redCoinRef}
                  width={500}
                  height={500}
                >
                  <DraggableCoin
                    color="red"
                    onStart={() => {
                      increaseBalance(redCoinValueUsd, 'red');
                    }}
                    boxStyle={{ top: -25, left: -25, zIndex: -15 }}
                  />
                </Emitter>

                <Emitter
                  autoStart={false}
                  numberOfParticles={totalPurpleCoins}
                  emissionRate={coinsPerInterval}
                  interval={purpleIntervalMs}
                  particleLife={COIN_LIFE_MS}
                  speed={speed * 1.5}
                  direction={-90}
                  spread={360}
                  fromPosition={{ x: 0, y: 0 }}
                  style={{
                    backgroundColor: '#ffffff',
                    position: 'relative',
                    zIndex: 1000,
                  }}
                  width={500}
                  height={500}
                  ref={purpleCoinRef}
                >
                  <DraggableCoin
                    color="purple"
                    onStart={() => {
                      increaseBalance(purpleCoinValueUsd, 'purple');
                    }}
                    boxStyle={{ top: -25, left: -25, zIndex: -15 }}
                  />
                </Emitter>
              </>
            )}
          </View>

          {/* Pulsing Brand Logo */}
          <View
            style={{
              position: 'absolute',
              left: '50%',
              top: '55%',
            }}
          >
            <Animated.View
              style={{
                transform: [
                  {
                    scale: pulseAnimatedValue.interpolate({
                      inputRange: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                      outputRange: [1, 0.97, 0.95, 0.97, 1, 1.03, 1.05, 1],
                    }),
                  },
                ],
              }}
            >
              <Image
                style={{
                  opacity: 1,
                  height: 140,
                  width: 140,
                  top: -70,
                  left: -70,
                  borderWidth: 6,
                  borderColor: COLORS.white,
                  position: 'absolute',
                  borderRadius: 200,
                  padding: 50,
                  zIndex: -10,
                  backgroundColor: COLORS.white,
                }}
                source={{
                  uri: brandImage,
                }}
              />
            </Animated.View>
          </View>
        </>
      )}
    </WebWrapper>
  );
}
