import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { useSessionTokenContext } from '@/components/web';

import AuthIntro from './AuthIntro';
import AuthLogin from './AuthLogin';
import AuthSignup from './AuthSignup';
import EditProfile from './EditProfile';
import ForgotPasswordPage from './ForgotPasswordPage';
import NotFound from '../NotFound';

const Auth = () => {
  const { userId } = useSessionTokenContext();

  return (
    <Routes>
      {userId ? (
        <>
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="*" element={<Navigate to="/home" replace={true} />} />
        </>
      ) : (
        <>
          <Route path="/" element={<AuthIntro />} />
          <Route path="/login" element={<AuthLogin />} />
          <Route path="/signup" element={<AuthSignup />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="*" element={<NotFound />} />
        </>
      )}
    </Routes>
  );
};

export default Auth;
