import { gql } from '@apollo/client';

export const FETCH_BRAND = gql`
  query FetchBrand($id: ID!) {
    brand(id: $id) {
      id
      name
      username
      description
      imageUrl
      videoUrl
      heroImageUrl
      headerLogoUrl
      brandColor
      heroTagline
      supportedCauses {
        id
        name
        description
      }
      registeredName
      location
      registeredNonProfit
      yearFounded
      numEmployees
      website
      coinsGiven
      totalCoinDrops
      numSupporters
      numCauses
      numCampaigns
      coinBalance
      instagramUsername
      twitterUsername
      facebookUsername
      youtubeUsername
      tiktokUsername
      linkedinUrl
      instagramData
      hiringUrl
      shopUrl
      brandImages {
        id
        imageUrl
      }
    }
  }
`;
