import React, { useState } from 'react';

import { Image, Pressable, ScrollView, View } from 'react-native';

import { InstaImages } from '@/types';
import { addCloudinaryTransformation, maxWidth } from '@/utils';

// import InstagramEmbed from 'react-native-embed-instagram';

type InstagramFeedProps = {
  instagramPosts: InstaImages[];
};

const imageSize = maxWidth(500);

const InstagramFeed = ({ instagramPosts }: InstagramFeedProps) => {
  const [imageErrors, setImageErrors] = useState<{ [id: string]: boolean }>({});

  return (
    <ScrollView horizontal>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
        }}
      >
        {instagramPosts.slice(0, 10).map((instagramPost: InstaImages) => {
          if (imageErrors[instagramPost.imageUrl]) {
            return null;
          }

          return (
            <View key={instagramPost.id}>
              <Pressable
              // onPress={async () => {
              //   if (instagramPost.url) {
              //     await Linking.openURL(instagramPost.url);
              //   }
              // }}
              >
                <Image
                  style={{
                    height: imageSize / 4.5,
                    width: imageSize / 4.5,
                    margin: 2.5,
                  }}
                  source={{
                    uri:
                      addCloudinaryTransformation(
                        instagramPost.imageUrl,
                        'c_scale,w_240',
                      ) || '',
                  }}
                  onError={() => {
                    setImageErrors({
                      ...imageErrors,
                      [instagramPost.imageUrl]: true,
                    });
                  }}
                />
                {/* {postId && (
                <InstagramEmbed
                  id={postId}
                  style={{ width: 240, height: 240 }}
                  avatarStyle={{}}
                  nameStyle={{}}
                  thumbnailStyle={{}}
                  showCaption={false}
                  showStats={false}
                  showAvatar={false}
                  renderCaption={() => 'test'}
                />
              )} */}
              </Pressable>
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};

export default InstagramFeed;
