import { gql } from '@apollo/client';

export const START_COIN_DROP = gql`
  mutation StartCoinDrop(
    $campaignId: String!
    $userIdentifier: String
    $gameType: String
    $brandId: String
    $web: Boolean
  ) {
    startCoinDrop(
      input: {
        brandId: $brandId
        campaignId: $campaignId
        userIdentifier: $userIdentifier
        gameType: $gameType
        web: $web
      }
    ) {
      success
      error
      coinDrop {
        id
        secret
        spinnerOptions
        spinnerOptionWeights
        campaign {
          id
          title
          description
          location
          imageUrl
          currentAmount
          goalAmount
          endTime
          slug
          cause {
            id
            name
          }
          charity {
            id
            name
            imageUrl
            charityColor
          }
          associatedReferral {
            id
            referrer {
              id
              fullName
              username
              profilePhoto
            }
          }
        }
        brand {
          id
          name
          imageUrl
          videoUrl
          heroImageUrl
          username
          description
          brandColor
          registeredName
          location
          registeredNonProfit
          yearFounded
          numEmployees
          coinsGiven
          numSupporters
          numCampaigns
          numCauses
          website
          description
          heroTagline
          supportedCauses {
            id
            name
            description
            imageUrl
            coinsReceived
            numCampaigns
            numSupporters
            numCharities
          }
        }
        user {
          id
          email
        }
        maxCoins
        timeMs
        maxSilverCoins
        maxGoldCoins
        maxRedCoins
        maxPurpleCoins
        silverCoinValueUsd
        goldCoinValueUsd
        redCoinValueUsd
        purpleCoinValueUsd
      }
    }
  }
`;
