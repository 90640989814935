import React from 'react';

import { View, Image } from 'react-native';

import FlexContainer from '@/components/FlexContainer';
import Spacing from '@/components/Spacing';
import StashrunCoinIcon from '@/components/StashrunCoinIcon';
import Text from '@/components/Text';
import { ImpactItem } from '@/types';
import { COLORS } from '@/utils/constants';
import {
  addCloudinaryTransformation,
  numberShortened,
  numberWithCommas,
} from '@/utils/format';

import { styles } from './styles';

type BrandImpactCardProps = {
  item: ImpactItem;
  logoUrl?: string;
  color?: string;
  numItems: number;
  brandDetails?: boolean;
};

const BrandImpactCard = ({
  item,
  logoUrl,
  color,
  brandDetails,
}: BrandImpactCardProps) => {
  const fontSize = brandDetails ? 24 : 14;
  const colorString = brandDetails ? COLORS.purple : COLORS.white;
  if (item.type !== 'Brand' && item.type !== 'Charity') return null;
  if (brandDetails) {
    return (
      <View style={styles.impactContainerBrandModal}>
        <Spacing>
          <View style={styles.brandImpactBrandModal}>
            <FlexContainer row>
              <Text medium override={{ color: colorString }}>
                <Text medium bold underlined override={{ color: colorString }}>
                  {numberShortened(item.numSupporters || 0)}
                </Text>
                {` ${item.numSupporters === 1 ? 'supporter' : 'supporters'}\n`}
                <Text medium bold underlined override={{ color: colorString }}>
                  {item.numCampaigns || 0}
                </Text>
                {` ${item.numCampaigns === 1 ? 'campaign' : 'campaigns'}\n`}
                <Text medium bold underlined override={{ color: colorString }}>
                  {item.numCauses || 0}
                </Text>
                {` ${item.numCauses === 1 ? 'cause' : 'causes'}\n`}
              </Text>
            </FlexContainer>
          </View>
        </Spacing>
      </View>
    );
  }
  return (
    <View style={styles.impactContainer}>
      <Spacing>
        <View style={styles.brandImpact}>
          <FlexContainer row>
            {logoUrl && (
              <Spacing right={2}>
                <Image
                  style={{
                    ...styles.logoCardImage,
                    ...{ borderColor: color || COLORS.black, borderWidth: 5 },
                  }}
                  source={{
                    uri: addCloudinaryTransformation(logoUrl, 'c_scale,w_170'),
                  }}
                />
              </Spacing>
            )}
            <Text mediumSmall override={{ fontSize, color: colorString }}>
              <Text
                mediumSmall
                bold
                underlined
                override={{ fontSize, color: colorString }}
              >
                {`${
                  (item.coinsGiven && numberWithCommas(item.coinsGiven)) || 0
                }`}
              </Text>{' '}
              <StashrunCoinIcon purple={brandDetails} size={18} />
              {' donated\n'}
              <Text
                mediumSmall
                bold
                underlined
                override={{ fontSize, color: colorString }}
              >
                {numberShortened(item.numSupporters || 0)}
              </Text>
              {` ${item.numSupporters === 1 ? 'supporter' : 'supporters'}\n`}
              <Text
                mediumSmall
                bold
                underlined
                override={{ fontSize, color: colorString }}
              >
                {item.numCampaigns || 0}
              </Text>
              {` ${item.numCampaigns === 1 ? 'campaign' : 'campaigns'}\n`}
              <Text
                mediumSmall
                bold
                underlined
                override={{ fontSize, color: colorString }}
              >
                {item.numCauses || 0}
              </Text>
              {` ${item.numCauses === 1 ? 'cause' : 'causes'}\n`}
            </Text>
          </FlexContainer>
          {!brandDetails && (
            <Text
              centered
              small
              bold
              override={{ fontSize: 12, color: colorString }}
            >
              Swipe to see how they’re giving back 👉
            </Text>
          )}
        </View>
      </Spacing>
    </View>
  );
};
export default BrandImpactCard;
