import React, { useEffect, useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Octicons, Ionicons } from '@expo/vector-icons';
import { DateTime } from 'luxon';
import {
  StyleSheet,
  Image,
  Linking,
  View,
  ScrollView,
  TouchableOpacity,
  Pressable,
} from 'react-native';
import { getUserAgentSync } from 'react-native-device-info';
import ProgressBarAnimated from 'react-native-progress-bar-animated';
import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import Carousel from 'react-native-snap-carousel';
import { Reoverlay } from 'reoverlay';

import Images from '@/assets/images';
import {
  Spacing,
  Text,
  LoadingIndicator,
  SocialMediaButton,
  FlexContainer,
} from '@/components/web';
import useWebHooks from '@/hooks/useWebHooks';
import {
  FETCH_CAMPAIGN_PROFILE,
  FETCH_CONVERSATIONS_LIST_WEB,
  FETCH_USER,
  FETCH_USER_STATUS,
} from '@/queries';
import { User, UserStatus } from '@/types';
import {
  maxWidth,
  removeBeginningHttps,
  removeUnwantedString,
  timeCalculation,
  COLORS,
  profileHaloColorPicker,
  addCloudinaryTransformation,
  numberWithCommas,
  trackEvent,
} from '@/utils';
import { isFeatureEnabled } from '@/utils/growthbook';
import tw from '@tw';
import { WebWrapper, CampaignWall } from '@web/components';

const screenWidth = maxWidth(500);

const ProfilePage = () => {
  const { navigate, campaignLink, users } = useWebHooks();
  const [showWall, setShowWall] = useState(false);

  const userAgent = getUserAgentSync();

  const isMobileDevice = () => {
    // Check for mobile-specific keywords in the user agent string
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    );
  };

  const carouselRef = useRef<any>(null);
  const animation = useSharedValue(0);

  useEffect(() => {
    trackEvent('userProfileViewed');
    animation.value = withRepeat(
      withTiming(1, {
        duration: 3000,
        easing: Easing.linear,
      }),
      -1,
      false,
    );
  }, []);

  const cardHeartBeat = useAnimatedStyle(() => {
    const scale = interpolate(
      animation.value,
      [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
      [1, 0.97, 0.95, 0.97, 1, 1.03, 1.05, 1],
      Extrapolate.CLAMP,
    );
    return {
      transform: [{ scale }],
    } as any;
  });

  const { loading: userLoading, data: userData } = useQuery<{
    user: User;
  }>(FETCH_USER, {
    variables: { userId: users },
  });

  const { loading: campaignLoading, data: campaignData } = useQuery(
    FETCH_CAMPAIGN_PROFILE,
    {
      variables: { campaignId: campaignLink },
    },
  );

  const { data: userStatusData, loading: userStatusLoading } = useQuery<{
    userStatuses: UserStatus;
  }>(FETCH_USER_STATUS, {
    variables: { userId: users },
    fetchPolicy: 'network-only',
  });

  const { data: conversationPost, loading: conversationLoading } = useQuery(
    FETCH_CONVERSATIONS_LIST_WEB,
    {
      variables: {
        cursor: undefined,
        pageSize: 10,
        causeId: campaignData?.campaign?.cause?.id,
        userId: users,
        sortType: 'recent',
      },
      fetchPolicy: 'no-cache',
    },
  );

  const { title, imageUrl, charity, currentAmount, goalAmount } =
    campaignData?.campaign || {};

  const remainingPercentage =
    (Number(currentAmount || 0) / Number(goalAmount)) * 100;

  const haloProperties = profileHaloColorPicker(
    userData?.user?.impactLevel || 0,
    userData?.user?.percentToNextLevel || 0,
    true,
  );

  const websiteLink =
    userData?.user?.website &&
    removeBeginningHttps(userData?.user?.website, true);

  const conversationItems =
    conversationPost?.conversationsList?.entries.length < 4
      ? conversationPost?.conversationsList?.entries
      : conversationPost?.conversationsList?.entries.slice(0, 3);

  const onWebsitePress = async () => {
    const link = userData?.user?.website;
    if (!link) return;

    const url = link.indexOf('://') === -1 ? `https://${link}` : link;
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        if (url.indexOf('http') !== -1) {
          Linking.openURL(url);
        }
        Linking.openURL(`https://${url}`);
      }
    });
  };

  const renderItem = ({ item, index }: { item: any; index: number }) => {
    if (conversationItems && conversationItems.length > 0) {
      return (
        <View style={styles.statusContainer} key={index}>
          <Spacing all={2}>
            <FlexContainer row>
              <Image
                style={{ width: 40, height: 40, borderRadius: 50 }}
                source={{ uri: userData?.user?.profilePhoto }}
              />
              <View style={{ flex: 1, marginLeft: 10 }}>
                <Spacing>
                  <Text small numberOfLines={1}>
                    {userData?.user?.fullName} #{item?.cause.name}{' '}
                    {timeCalculation(item.createdAt || '')}
                  </Text>
                </Spacing>
                <Text small numberOfLines={2}>
                  {item.title}
                </Text>
                <Text small numberOfLines={8}>
                  {item.message}
                </Text>
                {item?.heroImageUrl ? (
                  <Spacing vertical={1}>
                    <Image
                      style={{
                        width: '100%',
                        height: 130,
                      }}
                      source={{ uri: item?.heroImageUrl }}
                    />
                  </Spacing>
                ) : null}
              </View>
            </FlexContainer>
          </Spacing>
        </View>
      );
    }
    if (
      userStatusData?.userStatuses &&
      userStatusData?.userStatuses.length > 0
    ) {
      return (
        <View style={styles.statusContainer} key={index}>
          <Spacing all={2}>
            <FlexContainer row>
              <Image
                style={{ width: 40, height: 40, borderRadius: 50 }}
                source={{ uri: userData?.user?.profilePhoto }}
              />
              <View style={{ flex: 1, marginLeft: 10 }}>
                <Spacing>
                  <Text small numberOfLines={1}>
                    {userData?.user?.fullName} ·{' '}
                    {timeCalculation(item.createdAt || '')}
                  </Text>
                </Spacing>
                <Text small numberOfLines={10}>
                  {item.statusText}
                </Text>
              </View>
            </FlexContainer>
          </Spacing>
        </View>
      );
    }
    return null;
  };

  const highlight = (string: string) =>
    string.split(' ').map((word: string, i: number) => (
      <Text key={i}>
        <Text mediumSmallPlus bold inverse primaryBg override={tw`ml-[-1px]`}>
          {word}
        </Text>
        {'​'}
      </Text>
    ));

  const campaignCard = () => (
    <>
      <Spacing all={2}>
        <Text mediumSmallPlus override={{ textAlign: 'center' }}>
          Help{' '}
          <Text mediumSmallPlus bold>
            me{' '}
          </Text>
          fundraise for
        </Text>
      </Spacing>
      <Text
        mediumSmallPlus
        inverse
        override={{
          borderWidth: 1,
          borderColor: COLORS.white,
          paddingVertical: 4,
          paddingHorizontal: 10,
          backgroundColor: COLORS.black,
        }}
      >
        #{campaignData?.campaign?.cause?.name}
      </Text>
      <Spacing horizontal={2} top={2} bottom={1}>
        <Text
          mediumSmallPlus
          bold
          italicized
          override={{ textAlign: 'center' }}
          color={COLORS.purple}
        >
          Tap to fund this cause for FREE
        </Text>
        <Text
          mediumSmallPlus
          bold
          italicized
          override={{ textAlign: 'center' }}
          color={COLORS.purple}
        >
          using donations from sponsors 👇
        </Text>
      </Spacing>
      <Spacing vertical={2}>
        <Animated.View
          style={[
            tw`bg-white border-primary border-2 campaignCardShadow rounded-[24px]`,
            cardHeartBeat,
          ]}
        >
          <Pressable
            onPress={() => {
              trackEvent('campaignCardClicked', {
                referrerUserId: users,
                campaignName: title,
              });
              if (isMobileDevice()) {
                navigate(`/campaign/${campaignLink}`, {
                  state: { referrerUserId: users },
                });
              } else {
                setShowWall(true);
              }
            }}
          >
            <View style={{ width: 280 }}>
              <Spacing top={1} bottom={1} horizontal={1.5}>
                <View
                  style={{ backgroundColor: 'white', alignItems: 'center' }}
                >
                  <Text mediumSmallPlus numberOfLines={1}>
                    {campaignData?.campaign?.charity?.name || ''}
                  </Text>
                </View>
              </Spacing>
              <View style={tw`w-[280px] h-[320px] overflow-hidden relative`}>
                <Image
                  style={tw`w-full h-full`}
                  source={
                    imageUrl
                      ? {
                          uri: imageUrl,
                        }
                      : Images.stashrunLogo
                  }
                />
                <Image
                  style={tw`w-full h-full absolute top-0 left-0 opacity-80`}
                  source={Images.campaignCardGradient}
                />
              </View>
              <View style={styles.titleContainer}>
                <Spacing all={1.5}>
                  <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {highlight(title)}
                  </View>
                </Spacing>
              </View>
            </View>
            <View>
              <Spacing top={2} bottom={1} horizontal={1.5}>
                <Spacing bottom={1}>
                  <FlexContainer row alignItems="center">
                    <Spacing right={1}>
                      <FlexContainer row alignItems="center">
                        <Image
                          style={[
                            styles.charityImage,
                            { borderColor: charity?.charityColor },
                          ]}
                          source={{
                            uri: addCloudinaryTransformation(
                              charity?.imageUrl,
                              'c_scale,w_120',
                            ),
                          }}
                        />
                      </FlexContainer>
                    </Spacing>
                    <Spacing top={1}>
                      <FlexContainer alignItems="center">
                        <ProgressBarAnimated
                          backgroundColor={COLORS.green}
                          underlyingColor={COLORS.gray}
                          width={200}
                          height={9}
                          borderWidth={0}
                          borderRadius={10}
                          value={Math.min(100, remainingPercentage)}
                        />
                        <Spacing top={0.5}>
                          {campaignData?.campaign?.canSupport ||
                          remainingPercentage < 100 ? (
                            <Text small>
                              {100 - Math.trunc(remainingPercentage)}% to go
                              until $
                              {(goalAmount
                                ? goalAmount / 100
                                : 0
                              ).toLocaleString() || ''}{' '}
                              goal
                            </Text>
                          ) : (
                            <Text small>Completed!</Text>
                          )}
                        </Spacing>
                      </FlexContainer>
                    </Spacing>
                  </FlexContainer>
                </Spacing>
              </Spacing>
            </View>
          </Pressable>
        </Animated.View>
      </Spacing>
    </>
  );

  const carouselContent = () => {
    if (
      (conversationItems && conversationItems.length > 0) ||
      (userStatusData?.userStatuses && userStatusData?.userStatuses.length > 0)
    ) {
      return (
        <>
          <Spacing all={2.5}>
            <Text mediumSmallPlus override={{ textAlign: 'center' }}>
              See what
              <Text mediumSmallPlus bold>
                {' '}
                {userData?.user?.fullName}
                {'\n'}
              </Text>
              is saying on Stashrun
            </Text>
          </Spacing>
          <Carousel
            layout="default"
            ref={carouselRef}
            data={
              conversationItems?.length > 0
                ? conversationItems
                : userStatusData?.userStatuses
            }
            renderItem={renderItem}
            sliderWidth={screenWidth}
            itemWidth={280}
            swipeThreshold={1000}
            useScrollView
          />
        </>
      );
    }
    return null;
  };

  if (
    userLoading ||
    campaignLoading ||
    userStatusLoading ||
    conversationLoading
  ) {
    return <LoadingIndicator />;
  }

  if (!userData?.user) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Text>Invalid user</Text>
      </View>
    );
  }

  if (!userData?.user?.isPublicProfile) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Text>User is private</Text>
      </View>
    );
  }

  if (showWall && isFeatureEnabled('campaign-wall'))
    return <CampaignWall campaign={campaignData?.campaign} />;

  return (
    <WebWrapper>
      <ScrollView
        style={styles.scrollViewBody}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.profileContainer}>
          <Spacing all={2}>
            <Pressable
              style={[
                styles.profilePhotoContainer,
                { borderColor: haloProperties.haloColor },
              ]}
              onPress={() =>
                Reoverlay.showModal('ProfileExplainerModal', {
                  user: userData?.user,
                })
              }
            >
              {userData?.user?.profilePhoto ? (
                <Image
                  style={styles.profilePicture}
                  source={{
                    uri: addCloudinaryTransformation(
                      userData?.user?.profilePhoto,
                      'c_scale,h_600',
                    ),
                  }}
                />
              ) : (
                <Image
                  style={styles.profilePicture}
                  source={Images.defaultProfilePic}
                />
              )}
            </Pressable>
          </Spacing>
          <View style={{ flex: 1 }}>
            <Spacing top={2}>
              <Text mediumSmall>{userData?.user?.fullName}</Text>
              <Spacing vertical={0.5}>
                <Text>
                  <Text
                    mediumSmall
                    override={{
                      paddingHorizontal: 8,
                      borderRadius: 25,
                      backgroundColor: haloProperties.haloColor,
                      color: COLORS.white,
                      marginRight: 6,
                    }}
                  >
                    {numberWithCommas(
                      Math.round(Number(userData?.user?.impactScore || 0)),
                    )}{' '}
                  </Text>

                  <Text mediumSmall italicized color={COLORS.darkGray}>
                    Impact Score
                  </Text>
                </Text>
              </Spacing>
              <Spacing right={2} bottom={1}>
                <Text small>{userData?.user?.description}</Text>
              </Spacing>
            </Spacing>
          </View>
        </View>
        <Spacing bottom={2}>
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ marginLeft: 16 }}
          >
            {!!userData?.user?.website && (
              <TouchableOpacity onPress={onWebsitePress}>
                <View style={styles.userPillWebsite}>
                  <Octicons
                    name="link-external"
                    size={10}
                    color={COLORS.purple}
                  />
                  <Text bold xsmallPlus color={COLORS.purple}>
                    {' '}
                    {websiteLink}
                  </Text>
                </View>
              </TouchableOpacity>
            )}
            {!!userData?.user?.tiktokUsername?.length && (
              <SocialMediaButton
                type="tiktok"
                username={removeUnwantedString(userData?.user?.tiktokUsername)}
              />
            )}
            {!!userData?.user?.instagramUsername?.length && (
              <SocialMediaButton
                type="instagram"
                username={removeUnwantedString(
                  userData?.user?.instagramUsername,
                )}
              />
            )}
            {!!userData?.user?.twitterUsername?.length && (
              <SocialMediaButton
                type="twitter"
                username={removeUnwantedString(userData?.user?.twitterUsername)}
              />
            )}
            {!!userData?.user?.youtubeUsername?.length && (
              <SocialMediaButton
                type="youtube"
                username={removeUnwantedString(userData?.user?.youtubeUsername)}
              />
            )}
            {!!userData?.user?.linkedinUrl?.length && (
              <SocialMediaButton
                type="linkedin"
                url={userData?.user?.linkedinUrl}
              />
            )}
            {!!userData?.user?.facebookUsername?.length && (
              <SocialMediaButton
                type="facebook"
                username={removeUnwantedString(
                  userData?.user?.facebookUsername,
                )}
              />
            )}
            <View style={styles.pillContainer}>
              {!!userData?.user?.causes &&
                userData?.user?.causes?.map((cause) => (
                  <View
                    key={`pill-cause-${cause.id}`}
                    style={styles.moderatorPills}
                  >
                    <Image
                      style={styles.moderatorShieldIcon}
                      source={Images.moderatorShield}
                    />
                    <Text
                      bold
                      xsmallPlus
                      color={COLORS.moderatorBlue}
                      override={styles.pillTextPadding}
                    >
                      #{cause.name}
                    </Text>
                  </View>
                ))}
              {!!userData?.user?.school?.name && (
                <View style={styles.userPills}>
                  <Ionicons name="school" size={16} />
                  <Text bold xsmallPlus override={styles.pillTextPadding}>
                    {' '}
                    {userData?.user?.school?.name}
                  </Text>
                </View>
              )}
              <View style={styles.userPills}>
                <Text bold xsmallPlus override={styles.pillTextPadding}>
                  <Text bold xsmallPlus>
                    Joined{' '}
                  </Text>
                  {userData?.user?.joinedAt
                    ? DateTime.fromISO(userData.user.joinedAt).toFormat(
                        'LLL, yyyy',
                      )
                    : 'recently'}
                </Text>
              </View>
              {!!userData?.user?.referredBy && (
                <View style={styles.userPills}>
                  <Text bold xsmallPlus override={styles.pillTextPadding}>
                    <Text bold xsmallPlus>
                      Invited by{' '}
                    </Text>
                    <Text xsmallPlus override={styles.pillTextPadding}>
                      @{userData?.user?.referredBy?.username}
                    </Text>
                  </Text>
                </View>
              )}
            </View>
          </ScrollView>
        </Spacing>
        <View style={styles.footerContainer}>
          {campaignData?.campaign ? campaignCard() : null}
          {carouselContent()}
        </View>
      </ScrollView>
    </WebWrapper>
  );
};

const styles = StyleSheet.create({
  scrollViewBody: {
    backgroundColor: COLORS.white,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 500,
    maxHeight: '100vh',
    zIndex: -1,
  },
  moderatorShieldIcon: {
    width: 16,
    height: 16,
    alignSelf: 'center',
  },
  profilePicture: {
    width: 120,
    height: 120,
    borderRadius: 275,
  },
  pillContainer: {
    flexDirection: 'row',
  },
  userPills: {
    marginRight: 4,
    paddingHorizontal: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 25,
    borderWidth: 1,
    borderColor: COLORS.secondaryBlack,
  },
  moderatorPills: {
    marginRight: 4,
    paddingHorizontal: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 25,
    borderWidth: 1,
    borderColor: COLORS.purple,
    backgroundColor: COLORS.tertiaryPurple,
  },
  userPillWebsite: {
    height: 30,
    marginRight: 4,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 25,
    backgroundColor: COLORS.tertiaryPurple,
  },
  pillTextPadding: {
    paddingRight: 2,
  },
  pillTextWebsite: {
    fontFamily: 'AvenirNext-Regular',
    fontSize: 9,
    lineHeight: 25,
    fontWeight: 'bold',
    color: COLORS.purple,
  },
  cardImage: {
    width: 400,
    resizeMode: 'cover',
  },
  cardImageOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    opacity: 0.8,
  },
  container: {
    backgroundColor: '#fff',
    borderColor: COLORS.purple,
    borderRadius: 25,
    borderWidth: 2,
  },
  titleContainer: {
    position: 'absolute',
    bottom: 0,
    marginRight: 20,
  },
  charityImage: {
    height: 40,
    width: 40,
    borderRadius: 40,
    borderWidth: 2,
    backgroundColor: COLORS.white,
  },
  modalView: {
    width: screenWidth * 0.81,
    height: 600,
    backgroundColor: 'white',
    borderRadius: 30,
    alignItems: 'center',
    alignSelf: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  statusContainer: {
    backgroundColor: COLORS.white,
    borderRadius: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxHeight: 400,
    width: 280,
    shadowColor: 'black',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowRadius: 6,
    shadowOpacity: 0.25,
    elevation: 6,
    marginBottom: 12,
  },
  contentContainer: {
    fontSize: 14 * 8,
    lineHeight: 19 * 8,
    padding: 10,
    marginTop: 4,
    fontWeight: 'bold',
    maxHeight: 98,
  },
  contentContainerStatuses: {
    paddingRight: 34,
    maxHeight: 120,
  },
  footerContainer: {
    flex: 1,
    borderTopWidth: 2,
    borderColor: COLORS.tertiaryText,
    backgroundColor: COLORS.tertiaryPurple,
    alignItems: 'center',
    paddingBottom: 100,
  },
  profileContainer: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: COLORS.white,
  },
  modalTopContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBackground: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.purple,
    opacity: 0.9,
  },
  socialImageGroup: { width: '80%', height: 70 },
  campaignCardGradient: {
    height: 320,
    width: 280,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    opacity: 0.8,
  },
  profilePhotoContainer: {
    width: 140,
    height: 140,
    borderRadius: 200,
    borderWidth: 5,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ProfilePage;
