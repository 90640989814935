import React from 'react';

import { View, StyleSheet } from 'react-native';

import FlexContainer from '@/components/FlexContainer';
import Spacing from '@/components/Spacing';
import Text from '@/components/Text';
import { Cause, LabelData } from '@/types';
import { COLORS } from '@/utils';

type DetailsTableProps = {
  details: LabelData[];
  supportedCauses?: Cause[];
  header?: boolean;
  headerCustomStyle?: any;
  labelCustomStyle?: any;
  valueCustomStyle?: any;
  causeTitle?: string;
  causeTagCustomStyle?: any;
  causeTagTextCustomStyle?: any;
};

export function DetailsTable({
  details,
  supportedCauses,
  header = true,
  headerCustomStyle,
  labelCustomStyle,
  valueCustomStyle,
  causeTitle,
  causeTagCustomStyle,
  causeTagTextCustomStyle,
}: DetailsTableProps) {
  return (
    <View>
      {header && (
        <Spacing horizontal={1}>
          <Text mediumSmallPlus2 bold lightBlack override={headerCustomStyle}>
            Details
          </Text>
        </Spacing>
      )}
      <Spacing top={1.5} horizontal={2}>
        {details.map(({ label, value }, i) => {
          if (!value) {
            return null;
          }

          return (
            <View
              key={label}
              style={
                !header && i === 0 ? styles.firstDetailsRow : styles.detailsRow
              }
            >
              <FlexContainer row justifyContent="space-between">
                <Text small override={labelCustomStyle}>
                  {label}
                </Text>
                <Text small override={valueCustomStyle}>
                  {value}
                </Text>
              </FlexContainer>
            </View>
          );
        })}
        {supportedCauses && supportedCauses.length > 0 && (
          <View style={styles.detailsRow}>
            <Text small override={labelCustomStyle}>
              {causeTitle ?? 'Causes supported:'}{' '}
            </Text>
            <FlexContainer row>
              <View style={styles.causeTagContainer}>
                {supportedCauses.slice(0, 10)?.map((supportedCause: Cause) => {
                  return (
                    <View
                      key={supportedCause.id}
                      style={{ ...styles.causeTag, ...causeTagCustomStyle }}
                    >
                      <Text
                        bold
                        xsmallPlus
                        inverse
                        override={causeTagTextCustomStyle}
                      >
                        #{supportedCause.name}
                      </Text>
                    </View>
                  );
                })}
                {supportedCauses.length > 10 && (
                  <Text bold small>
                    ...
                  </Text>
                )}
              </View>
              {/* TODO create badge component, it should actually be similar to a small "causeContainer" in this file */}
            </FlexContainer>
          </View>
        )}
      </Spacing>
    </View>
  );
}

const styles = StyleSheet.create({
  firstDetailsRow: {
    paddingBottom: 2,
  },
  detailsRow: {
    borderTopWidth: 1,
    borderTopColor: '#DDDDDD',
    paddingTop: 10,
    paddingBottom: 2,
  },
  causeTagContainer: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    marginLeft: -5,
    flexDirection: 'row',
  },
  causeTag: {
    alignSelf: 'flex-start',
    backgroundColor: COLORS.purple,
    paddingVertical: 2,
    paddingHorizontal: 5,
    marginHorizontal: 2,
    marginVertical: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 1,
    borderColor: COLORS.white,
  },
});
