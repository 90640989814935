import React, { useEffect } from 'react';

import { PrimaryButton } from '@/components/web';
import useTiktok from '@/hooks/useTiktok';

import { WebWrapper } from './components';

const TiktokTestLink = () => {
  const { fetchTiktokLink, tiktokUrlData } = useTiktok();

  useEffect(() => {
    if (tiktokUrlData) {
      window.location.href = tiktokUrlData.authTiktok.redirectTo;
    }
  }, [tiktokUrlData]);

  return (
    <WebWrapper>
      <PrimaryButton
        title="Continue with tiktok"
        type="withBorder"
        onPress={async () => {
          await fetchTiktokLink();
        }}
        reverse
      />
    </WebWrapper>
  );
};

export default TiktokTestLink;
