import { gql } from '@apollo/client';

export const SHORTEN_DYNAMIC_LINK = gql`
  mutation ShortenDynamicLink($dynamicLink: String!) {
    shortenDynamicLink(input: { dynamicLink: $dynamicLink }) {
      success
      shortenedLink
      error
    }
  }
`;
