export const getStoreUserIdentifier = () => {
  const userIdentifier = localStorage?.getItem('userIdentifier') as string;
  return userIdentifier;
};

export const setStoreUserIdentifier = (identifier: string) => {
  localStorage?.setItem('userIdentifier', identifier);
};

export const deleteStoreUserIdentifier = () => {
  localStorage?.removeItem('userIdentifier');
};

export const getStoreTimesPlayed = (campaignLink: string) => {
  const timesPlayed = Number(
    localStorage?.getItem(`numTimesPlayed-${campaignLink}`),
  ) as number;
  return timesPlayed;
};

export const setStoreTimesPlayed = (
  campaignLink: string,
  timesPlayed: number,
) => {
  localStorage?.setItem(`numTimesPlayed-${campaignLink}`, String(timesPlayed));
};

export const setPasscodeDone = () => {
  localStorage?.setItem('passcode', 'true');
};

export const getPasscodeDone = () => {
  return localStorage?.getItem(`passcode`);
};

export const getUserIdContext = () => {
  const userContextId = localStorage?.getItem('USER_ID') as string;
  return userContextId;
};

export const setVideoPreview = (obj: {
  user: string;
  campaignCurrentAmount: number;
  campaignGoalAmount: number;
  campaignCauseName: string;
  campaignCanSupport: boolean;
  video: string;
  haloColor: string;
  profilePhoto: string;
}) => {
  localStorage.setItem('videoPreview', JSON.stringify(obj));
};

export const getVideoPreview = () => {
  const data = localStorage?.getItem(`videoPreview`);
  return data && JSON.parse(data);
};

export const deleteVideoPreview = () => {
  localStorage?.removeItem('videoPreview');
};

export const getFirstTimeCause = () => {
  return localStorage?.getItem('firstTimeCause');
};

export const setFirstTimeCause = (cause: string) => {
  localStorage?.setItem('firstTimeCause', cause);
};

export const deleteFirstTimeCause = () => {
  localStorage?.removeItem('firstTimeCause');
};

export const getSourceTrack = (stashlinkId: string) => {
  return localStorage?.getItem(`${stashlinkId}-source-tracked`);
};

export const setSourceTrack = (stashlinkId: string, source: string) => {
  localStorage?.setItem(`${stashlinkId}-source-tracked`, source);
};
