import { gql } from '@apollo/client';

export const FETCH_CONVERSATION_COMMENTS_LIST = gql`
  query FetchConversationCommentsList(
    $cursor: String
    $pageSize: Int
    $conversationCommentId: String
    $conversationId: String
  ) {
    conversationCommentsList(
      cursor: $cursor
      pageSize: $pageSize
      conversationCommentId: $conversationCommentId
      conversationId: $conversationId
    ) {
      id
      nextCursor
      entries {
        id
        imageUrl
        numVotes
        message
        isAnonymous
        createdAt
        deletedAt
        repliedToMessage {
          id
          message
          deletedAt
        }
        user {
          id
          username
          profilePhoto
          school {
            id
            name
          }
        }
        userVotes {
          id
          voteType
        }
        replies {
          id
          message
          createdAt
          numVotes
          isAnonymous
          deletedAt
          user {
            id
            username
            profilePhoto
            school {
              id
              name
            }
          }
          userVotes {
            id
            voteType
          }
          replies {
            id
            message
            createdAt
            numVotes
            isAnonymous
            deletedAt
            user {
              id
              username
              profilePhoto
              school {
                id
                name
              }
            }
            userVotes {
              id
              voteType
            }
          }
        }
      }
    }
  }
`;
