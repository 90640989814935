/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback } from 'react';

import { View } from 'react-native';
import { interpolate } from 'react-native-reanimated';
import Carousel from 'react-native-reanimated-carousel';

import tw from '@tw';

type PropsT<T> = {
  width: number;
  height: number;
  renderItem: (item: T) => React.ReactElement;
  items?: T[];
};

const HorizontalCarousel = <T extends unknown>({
  width,
  height,
  renderItem,
  items = [],
}: PropsT<T>) => {
  const animationStyle = useCallback((value: number) => {
    'worklet';

    const translateX = interpolate(value, [-1, 0, 1], [-width, 0, width]);
    const translateY = interpolate(value, [-1, 0, 1], [-10, 0, -10]);
    const scale = interpolate(value, [-1, 0, 1], [0.85, 1, 0.85]);
    const opacity = interpolate(value, [-1, 0, 1], [0.8, 1, 0.8]);

    return {
      transform: [
        {
          translateX,
        },
        {
          translateY,
        },
        { scale },
      ],
      opacity,
    } as any;
  }, []);

  return (
    <View style={tw`flex flex-1 justify-center`}>
      <Carousel
        style={tw`w-full items-center justify-center`}
        loop={false}
        width={width}
        height={height}
        data={items}
        customAnimation={animationStyle}
        scrollAnimationDuration={800}
        renderItem={({ item }) => renderItem(item)}
      />
    </View>
  );
};

export { HorizontalCarousel };
