import React, { useMemo } from 'react';

import { Image } from 'expo-image';
import { View } from 'react-native';
import { Reoverlay } from 'reoverlay';

import Images from '@/assets/images';
import { PrimaryButton, Text } from '@/components/web';
import { User } from '@/types';
import {
  getStoreTimesPlayed,
  maxWidth,
  profileHaloColorPicker,
  trackEvent,
} from '@/utils';
import tw from '@tw';

import AvatarList from './AvatarList';
import ProgressBar from './ProgressBar';
import RoundedBorderedImage from './RoundedBorderedImage';

type CampaignProfileFooterProps = {
  userData: { user: User } | undefined;
  secondaryUserData?: { user: User };
  campaignData: any;
  createStashStep: boolean;
  stashLinkExist?: boolean;
  onFooterButtonPress: () => void;
};

const CampaignProfileFooter: React.FC<CampaignProfileFooterProps> = ({
  userData,
  secondaryUserData,
  campaignData,
  createStashStep,
  stashLinkExist,
  onFooterButtonPress,
}) => {
  const haloProperties = useMemo(() => {
    const circleProperties = profileHaloColorPicker(
      userData?.user?.impactLevel || 0,
      userData?.user?.percentToNextLevel || 0,
      true,
    );
    return { ...circleProperties };
  }, [userData?.user?.impactLevel]);
  const secondaryHaloProperties = useMemo(() => {
    const circleProperties = profileHaloColorPicker(
      secondaryUserData?.user?.impactLevel || 0,
      secondaryUserData?.user?.percentToNextLevel || 0,
      true,
    );
    return { ...circleProperties };
  }, [secondaryUserData?.user?.impactLevel]);

  const timesPlayed =
    getStoreTimesPlayed(campaignData?.campaign?.slug || '') || 0;

  const checkPlayAgainText = (description?: boolean) => {
    if (timesPlayed === 0) {
      return !description
        ? 'Play To Fund'
        : 'Play a minigame and a sponsor will pay for the donation';
    }
    if (timesPlayed > 2) {
      return !description
        ? 'Support Another Charity'
        : 'Raise money for another charity for this cause';
    }
    return !description ? 'Play Again' : 'Raise more money for this charity';
  };

  const campaign = campaignData?.campaign || {};
  const { currentAmount, goalAmount, charity } = campaign || {};
  const remainingPercentage =
    (Number(currentAmount || 0) / Number(goalAmount)) * 100;
  const isCompleted = remainingPercentage >= 100;
  const footerButtonTitle = {
    stash: 'Create Link to Share',
    true: 'Find More Causes',
    false: checkPlayAgainText(),
  }[createStashStep ? 'stash' : String(isCompleted)];

  const footerDesc = {
    stash: 'Share your Stashlink to fund this charity for free',
    true: 'Tap to find more causes to support for FREE',
    false: checkPlayAgainText(true),
  }[createStashStep ? 'stash' : String(isCompleted)];

  if (createStashStep) {
    return (
      <View
        style={tw.style(
          `fixed max-w-${maxWidth(
            500,
          )}px w-full bg-white bottomSheetShadow self-center items-center bottom-0 pt-3 pb-6 rounded-tl-[20px] rounded-tr-[20px]`,
        )}
      >
        <View style={tw`mt-8`}>
          <View style={tw`absolute top-[-19] self-center`}>
            <AvatarList size={55} count={8} noShadow />
          </View>
        </View>
        {stashLinkExist ? (
          <Text small>You’ve already created a Stashlink for this cause!</Text>
        ) : (
          <Text small centered>
            Help raise funds for{'\n'}
            <Text small bold>
              {charity.name}
            </Text>
          </Text>
        )}

        <PrimaryButton
          style="mt-8px w-full"
          onPress={onFooterButtonPress}
          title={stashLinkExist ? 'Share to Keep Funding' : footerButtonTitle}
          fullRadius
          customRender={
            stashLinkExist ? (
              <Image source={Images.share} style={tw`w-6 h-6 ml-2.5`} />
            ) : null
          }
        />

        <Text xsmallPlus2 override={tw`pt-8px text-center w-full`}>
          {stashLinkExist
            ? 'Share your link to raise more for this charity'
            : footerDesc || ''}
        </Text>
      </View>
    );
  }

  return (
    <View
      style={tw.style(
        `fixed max-w-${maxWidth(
          500,
        )}px w-full bg-white bottomSheetShadow self-center items-center bottom-0 pt-3 pb-6 rounded-tl-[20px] rounded-tr-[20px]`,
      )}
    >
      <View
        style={{
          position: 'absolute',
          ...(secondaryUserData
            ? {
                left: 'calc(50% - 75px)',
              }
            : {
                marginLeft: 'auto',
                marginRight: 'auto',
                left: 0,
                right: 0,
              }),
          top: -45,
        }}
      >
        <RoundedBorderedImage
          color={haloProperties.haloColor}
          type="outer"
          size={75}
          image={userData?.user?.profilePhoto}
          onPress={() => {
            trackEvent('profileModalClicked', {
              userProfile: userData?.user?.id,
            });
            Reoverlay.showModal('ProfileModal', {
              user: userData?.user,
              haloProperties,
            });
          }}
        />
      </View>
      {secondaryUserData ? (
        <View
          style={{
            position: 'absolute',
            right: 'calc(50% - 75px)',
            top: -45,
          }}
        >
          <RoundedBorderedImage
            color={secondaryHaloProperties.haloColor}
            type="outer"
            size={75}
            image={secondaryUserData?.user?.profilePhoto}
            onPress={() => {
              trackEvent('profileModalClicked', {
                userProfile: secondaryUserData?.user?.id,
              });
              Reoverlay.showModal('ProfileModal', {
                user: secondaryUserData?.user,
                haloProperties: secondaryHaloProperties,
              });
            }}
          />
        </View>
      ) : null}
      <View style={tw`justify-center mt-3 mb-4 relative`}>
        <View style={tw`px-10px text-center relative`}>
          {userData?.user && (
            <View style={tw`relative`}>
              <View style={tw`w-17/20 mx-auto mb-2 mt-7 relative`}>
                <View style={tw`flex items-center flex-col justify-center`}>
                  <View style={tw`max-w-[95%]`}>
                    <Text small>
                      Help{' '}
                      <Text small bold>
                        {userData?.user?.fullName || ''}
                        {secondaryUserData ? ' ' : '\n'}
                      </Text>
                      {secondaryUserData?.user?.fullName?.length ? (
                        <Text small>
                          and{' '}
                          <Text small bold>
                            {secondaryUserData?.user?.fullName || ''}{' '}
                          </Text>
                        </Text>
                      ) : null}
                      raise funds for{'\n'}
                      <Text small bold>
                        {charity.name}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}
        </View>
        <ProgressBar
          campaign={campaignData.campaign}
          width={280}
          borderRadius={10}
        />
      </View>

      <PrimaryButton
        style="w-full"
        onPress={onFooterButtonPress}
        title={footerButtonTitle}
        fullRadius
        customRender={
          createStashStep ? (
            <Image source={Images.share} style={tw`w-6 h-6 ml-2.5`} />
          ) : null
        }
      />

      <Text xsmallPlus2 override={tw`pt-8px text-center w-full`}>
        {footerDesc || ''}
      </Text>
    </View>
  );
};

export default CampaignProfileFooter;
