import React from 'react';

import { AVPlaybackStatusSuccess, ResizeMode, Video } from 'expo-av';
import { Image } from 'expo-image';
import { TouchableOpacity, useWindowDimensions, Platform } from 'react-native';

import images from '@/assets/images';
import { addCloudinaryTransformation, maxWidth, trackEvent } from '@/utils';
import tw from '@tw';

interface Props {
  videoUrl?: string;
  heroImageUrl?: string;
  heroImage?: string;
  height?: number;
  screenshot?: boolean;
}

const HeroPreview = (
  { videoUrl, heroImageUrl, height, screenshot, heroImage }: Props,
  ref: React.Ref<any> | undefined,
) => {
  const [status, setStatus] = React.useState({} as AVPlaybackStatusSuccess);
  const [muted, setMuted] = React.useState(true);
  const video = React.useRef<Video>(null);

  React.useImperativeHandle(ref, () => ({
    stopVideo: () => {
      video.current
        ?.pauseAsync()
        .then(() => trackEvent('videoStopped', { videoUrl }));
    },
  }));
  React.useEffect(() => {
    video.current
      ?.playAsync()
      .then(() => trackEvent('videoPlayed', { videoUrl }));
    return () => {
      video.current?.pauseAsync();
    };
  }, []);

  const onPressVideo = () => {
    if (!status.isPlaying) {
      video.current
        ?.playAsync()
        .then(() => trackEvent('videoPlayed', { videoUrl }));
      setMuted(false);
    } else if (muted) {
      setMuted(false);
    } else {
      video.current?.pauseAsync();
      setMuted(true);
    }
  };

  const { width: windowWidth } = useWindowDimensions();
  const imageSize = Platform.OS === 'web' ? maxWidth(500) : windowWidth;

  const previewHeight = height || imageSize / 1.778;

  const brandImage = (isPlaybuttonImage = false) => (
    <Image
      source={{
        uri:
          heroImage ||
          addCloudinaryTransformation(
            heroImageUrl,
            `c_scale,w_${maxWidth(500)},dpr_2`,
          ),
      }}
      style={tw.style(`bg-black w-full self-center`, {
        absolute: isPlaybuttonImage,
        resizeMode: 'cover',
        height: previewHeight,
      })}
    />
  );

  const brandVideo = () => (
    <TouchableOpacity
      style={tw.style(`w-full`, {
        height: previewHeight,
      })}
      onPress={onPressVideo}
    >
      <Video
        ref={video}
        style={tw`h-full w-full z-[-10] absolute`}
        resizeMode={ResizeMode.COVER}
        source={{ uri: videoUrl || '' }}
        usePoster
        posterStyle={{ resizeMode: 'cover' }}
        posterSource={{ uri: heroImageUrl }}
        isMuted={muted}
        videoStyle={tw`h-full w-full`}
        onPlaybackStatusUpdate={(status) =>
          setStatus(() => status as AVPlaybackStatusSuccess)
        }
      />
      {!status.isPlaying && (
        <Image
          source={images.videoPlay}
          style={tw`absolute m-auto self-center h-50px w-50px z-50`}
        />
      )}
      {status.isPlaying && muted && (
        <Image
          style={tw`h-8 w-8 absolute top-2 left-2 z-50`}
          source={images.icons.muted}
        />
      )}
    </TouchableOpacity>
  );

  if (screenshot) {
    return brandImage();
  }
  if (videoUrl?.length) {
    return brandVideo();
  }

  return brandImage();
};

export default React.forwardRef(HeroPreview);
