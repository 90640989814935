import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { FETCH_IMPACT_DATA } from '@/queries/fetch-impact-data';
import { ImpactData, ImpactItem } from '@/types';

export const useImpact = ({
  entityId,
  entityType,
}: {
  entityId: string;
  entityType: string;
}) => {
  const { data, loading: impactDataLoading } = useQuery<{
    impactData: ImpactData;
  }>(FETCH_IMPACT_DATA, {
    variables: { entityId, entityType },
    fetchPolicy: 'network-only',
  });

  const blueCardValues = useMemo(() => {
    if (!data?.impactData) {
      return {
        numSupporters: 0,
        numCauses: 0,
        numCampaigns: 0,
        numBrands: 0,
        coinsGiven: 0,
        dollarsGiven: '0',
        coinsReceived: 0,
        type: entityType,
      };
    }
    const {
      numSupporters,
      numCauses,
      numCampaigns,
      numBrands,
      coinsGiven,
      dollarsGiven,
      coinsReceived,
    } = data.impactData;
    return {
      numSupporters,
      numCauses,
      numCampaigns,
      numBrands,
      coinsGiven,
      dollarsGiven,
      coinsReceived,
      type: entityType,
    };
  }, [data]);

  const impactCards = useMemo(() => {
    if (!data?.impactData || !data?.impactData?.impactCards?.length) {
      return [];
    }
    return data?.impactData?.impactCards;
  }, [data]);

  const impactCarouselData = useMemo(
    () => [blueCardValues, ...impactCards] as ImpactItem[],
    [blueCardValues],
  );

  return { impactDataLoading, impactCarouselData };
};
