import React, { useEffect, useRef, useState } from 'react';

import { Image } from 'expo-image';
import {
  View,
  Dimensions,
  Pressable,
  ImageSourcePropType,
  Animated as RNAnimated,
} from 'react-native';
import Animated, {
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
  useAnimatedStyle,
  Easing,
  withDelay,
} from 'react-native-reanimated';
import { Reoverlay } from 'reoverlay';

import Images from '@/assets/images';
import { Text } from '@/components/web';
import { eventProps } from '@/constants/ANALYTICS_CONST';
import { Brand, Campaign } from '@/types';
import { maxWidth, trackEvent } from '@/utils';
import { addCloudinaryTransformation } from '@/utils/format';
import tw from '@tw';
import { WebWrapper } from '@web/components';
import RoundedBorderedImage from '@web/components/RoundedBorderedImage';

import BrandPageDetail from './BrandPageDetail';

type PropsT = {
  brand?: Brand;
  brandImage?: string;
  brandLogoAnimation?: string;
  campaign?: Campaign;
  campaignLink?: string;
  animate?: boolean;
  setGameType: (gameType: 'spinner' | 'coinTap') => void;
  setShowBrandMatch?: () => void;
};
type ChooseButtonPropsT = {
  img: ImageSourcePropType;
  onPress: () => void;
  animate?: boolean;
};

const screenWidth = maxWidth(500);
const { height } = Dimensions.get('window');

const ChooseButton = ({ img, onPress, animate }: ChooseButtonPropsT) => {
  const rotate = useSharedValue(0);
  const scale = useSharedValue(0.95);

  useEffect(() => {
    if (animate) {
      rotate.value = withDelay(
        100,
        withSequence(
          withTiming(-10, { duration: 100, easing: Easing.out(Easing.cubic) }),
          withRepeat(withTiming(10, { duration: 100 }), 4, true),
          withTiming(0, { duration: 200, easing: Easing.elastic(2) }),
        ),
      );
      scale.value = withDelay(
        100,
        withSequence(
          withTiming(1.08, { duration: 120, easing: Easing.out(Easing.cubic) }),
          withDelay(
            500,
            withTiming(1, { duration: 300, easing: Easing.elastic(2.5) }),
          ),
        ),
      );
    }
  }, [animate]);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotate: `${rotate.value}deg`,
          scale: scale.value,
        },
      ],
    } as any;
  });

  return (
    <Pressable onPress={onPress} style={tw`w-[52%] h-58 `}>
      <Animated.Image
        source={img}
        style={[animatedStyles, tw`w-full h-full`]}
        resizeMode="contain"
      />
    </Pressable>
  );
};

const ChooseGame = ({
  brand,
  brandImage,
  campaign,
  setGameType,
  campaignLink,
  brandLogoAnimation,
}: PropsT) => {
  const [nextScreen, setNextScreen] = useState(false);
  const [pressedGame, setPressedGame] = useState<'spinner' | 'coinTap'>(
    'coinTap',
  );
  const [logo, setLogo] = useState(true);
  const [animated, setAnimated] = useState(false);

  const fadeAnim = useRef(new RNAnimated.Value(0)).current;

  const { imageUrl } = campaign || {};
  useEffect(() => {
    // prevent thankyou modal from staying open when user forwards the browsing history
    Reoverlay.hideAll();
    trackEvent('chooseGameViewed', {
      campaignName: campaign?.title,
    });
    const animationLogo = setTimeout(() => {
      setLogo(false);
      RNAnimated.timing(fadeAnim, {
        toValue: 1,
        duration: 400,
        useNativeDriver: true,
      }).start();
      setAnimated(true);
    }, 2100);
    return () => {
      clearTimeout(animationLogo);
    };
  }, []);

  if (nextScreen) {
    return (
      <BrandPageDetail
        brand={brand}
        cause={campaign?.cause}
        setGameType={setGameType}
        pressedGame={pressedGame}
      />
    );
  }

  return (
    <WebWrapper
      analytics={{
        [eventProps.page]: 'Choose Game',
        [eventProps.campaignId]: campaignLink,
      }}
      style={tw`flex-1`}
    >
      <Image
        style={tw`fixed w-full h-full bottom-0 opacity-10 z-0`}
        source={{
          uri: addCloudinaryTransformation(
            imageUrl,
            `h_${Math.ceil(height)},w_${Math.ceil(screenWidth)},c_fill`,
          ),
        }}
      />
      <View style={tw`flex-1 px-6 py-4 z-10 items-center absolute`}>
        <Text mediumSmallPlus2 centered override={tw`mb-3 font-black`}>
          Matching you with a sponsor...
        </Text>
        <View style={tw`border-b-1 border-zinc-300 w-9/10 mb-6`} />
        <View>
          {logo && (
            <RoundedBorderedImage
              color={'transparent'}
              className={'mb-4 absolute z-1'}
              type="outer"
              size={80}
              image={brandLogoAnimation}
              noDefault
            />
          )}
          <RoundedBorderedImage
            color={brand?.brandColor || 'black'}
            className={'mb-4'}
            type="outer"
            size={80}
            image={brandImage}
            noDefault
          />
        </View>
        <RNAnimated.View style={[tw`items-center`, { opacity: fadeAnim }]}>
          <Text mediumSmall centered override={tw`px-4 mb-6`}>
            100% of the money you stash while playing will be donated by{' '}
            <Text mediumSmall centered bold>
              {brand?.name}{' '}
            </Text>
            to charity.
          </Text>

          <Text mediumSmall centered bold>
            Choose your game
          </Text>
          <View style={tw`flex flex-row justify-center w-full mt-2`}>
            <ChooseButton
              img={Images.chooseCoinDrop}
              onPress={() => {
                if (brand && animated) {
                  setNextScreen(true);
                  setPressedGame('coinTap');
                }
              }}
              animate={animated}
            />
            <ChooseButton
              img={Images.chooseSpin}
              onPress={() => {
                if (brand && animated) {
                  setNextScreen(true);
                  setPressedGame('spinner');
                }
              }}
              animate={animated}
            />
          </View>
        </RNAnimated.View>
      </View>
    </WebWrapper>
  );
};

export default ChooseGame;
