import { gql } from '@apollo/client';

export const CREATE_REFERRAL = gql`
  mutation CreateReferral(
    $invitedPhoneNumber: String!
    $invitedUserName: String!
    $campaignId: String!
    $brandId: String!
  ) {
    createReferral(
      input: {
        invitedPhoneNumber: $invitedPhoneNumber
        invitedUserName: $invitedUserName
        campaignId: $campaignId
        brandId: $brandId
      }
    ) {
      success
      error
    }
  }
`;
