import { gql } from '@apollo/client';

export const FETCH_CAUSES = gql`
  query FetchCauses($stashlinkEnabled: Boolean) {
    causes(stashlinkEnabled: $stashlinkEnabled) {
      id
      name
      description
      imageUrl
      coinsReceived
      dollarsReceived
      numCampaigns
      numSupporters
      numCharities
      numComments
      moderators {
        id
        username
        fullName
        profilePhoto
      }
    }
  }
`;
