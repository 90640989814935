import { gql } from '@apollo/client';

export const FETCH_USER_STASHLINKS = gql`
  query FetchUser($userId: ID!) {
    user(id: $userId) {
      id
      fullName
      impactLevel
      percentToNextLevel
      profilePhoto
      haloColor
      impactScore
      stashlinks {
        id
        url
        numViews
        numShares
        dollarsDonated
        thankYouMessage
        thankYouMessageVideo
        campaign {
          id
          title
          currentAmount
          imageUrl
          currentAmount
          goalAmount
          cause {
            id
            name
          }
          charity {
            id
            name
            charityColor
            imageUrl
          }
        }
      }
    }
  }
`;
