import React, { useEffect, useRef } from 'react';

import { Animated, ViewProps } from 'react-native';

interface FadeInViewProps extends ViewProps {
  children: NonNullable<React.ReactNode>;
}

export default function FadeInView(props: FadeInViewProps) {
  const { children, style, ...restOfProps } = props;

  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  }, []);

  return (
    <Animated.View
      style={[
        style,
        {
          opacity: fadeAnim,
        },
      ]}
      {...restOfProps}
    >
      {children}
    </Animated.View>
  );
}
