import React, { createRef, useEffect } from 'react';

import Clipboard from '@react-native-clipboard/clipboard';
import { Image } from 'expo-image';
import { saveAs } from 'file-saver';
import { Pressable, View, Linking } from 'react-native';
import ProgressBarAnimated from 'react-native-progress-bar-animated';
import QRCode from 'react-native-qrcode-svg';
import { Reoverlay } from 'reoverlay';
import { CountUp } from 'use-count-up';
import { useScreenshot } from 'use-react-screenshot';

import Images from '@/assets/images';
import { SlideUpModal, Text } from '@/components/web';
import useStashLinkHooks from '@/hooks/useStashLink';
import { Campaign } from '@/types';
import { COLORS, maxWidth, scroll, trackEvent } from '@/utils';
import tw from '@tw';

type Props = {
  url: string;
  campaign: Campaign;
  referrerUserId?: string;
  secondaryReferrerUserId?: string;
  stashlinkId?: string;
  stashlinkMessage?: string;
  profilePhoto?: string;
  haloColor?: string;
  refetch?: () => void;
  thankYou?: boolean;
  hub?: boolean;
  redirect?: () => void;
};

const WebShareModal = ({
  url,
  campaign,
  referrerUserId,
  secondaryReferrerUserId,
  haloColor,
  profilePhoto,
  thankYou,
  redirect,
}: Props) => {
  const { shareStashlink } = useStashLinkHooks();
  const ref = createRef<View>();
  const [image, takeScreenshot] = useScreenshot();
  const { currentAmount, goalAmount } = campaign || {};
  const remainingPercentage =
    (Number(currentAmount || 0) / Number(goalAmount)) * 100;
  const countUpAmount = Number(
    (currentAmount ? currentAmount / 100 : 0).toFixed(0),
  );

  const getImage = async () => {
    await takeScreenshot(ref.current);
  };

  useEffect(() => {
    scroll.lock();
    getImage();
    return () => {
      scroll.enable();
    };
  }, []);

  const onPressShare = async () => {
    await shareStashlink({
      variables: {
        userId: referrerUserId,
        campaignId: campaign.id,
        secondaryReferrerUserId,
      },
    });
  };
  const takeScreenshotSave = async () => {
    await saveAs(image, 'share');
  };

  return (
    <SlideUpModal
      maxHeight={480}
      haloColor={haloColor}
      userImage={profilePhoto}
      keepBackground
      customClose={redirect}
      backbuttonFunction={() => {
        Reoverlay.hideModal();
        if (redirect) {
          redirect();
        }
      }}
      removeButton
    >
      <View style={tw.style(`items-center w-full pt-15`)}>
        <Text bold>Share to raise more 💰</Text>
        <Text centered xsmallPlus2>
          Post to your socials or DMs
        </Text>
        <View
          ref={ref}
          style={tw.style(`m-8`, { transform: [{ rotate: '45deg' }] })}
        >
          <QRCode value={url} size={160} quietZone={10} />
        </View>
        <View style={tw`justify-center mb-2`}>
          <View style={tw`px-10px pb-1 text-center`}>
            {campaign?.canSupport || remainingPercentage < 100 ? (
              <Text mediumSmall>
                <Text mediumSmall bold>
                  $
                  <CountUp isCounting end={countUpAmount} duration={2} />{' '}
                </Text>
                raised of $
                {(goalAmount ? goalAmount / 100 : 0).toLocaleString() || ''}{' '}
                goal
              </Text>
            ) : (
              <Text mediumSmall>Completed!</Text>
            )}
          </View>
          <View style={tw`mx-auto`}>
            <ProgressBarAnimated
              backgroundColor={COLORS.progressBar}
              underlyingColor={COLORS.gray}
              barEasing="ease"
              barAnimationDuration={1000 + remainingPercentage * 10}
              width={maxWidth(240)}
              height={10}
              borderWidth={0}
              borderRadius={10}
              value={Math.min(100, remainingPercentage)}
            />
          </View>
        </View>
        <View
          style={tw`border-b-1 border-dividerGray w-7/10 self-center my-2`}
        />
        <View
          style={tw.style(`flex-row justify-around mt-8px px-20`, {
            width: maxWidth(500),
          })}
        >
          <Pressable
            style={tw`w-75px items-center`}
            onPress={() => {
              trackEvent('contentCopied');
              Clipboard.setString(url);
              onPressShare();
              Reoverlay.showModal('AlertModal', {
                alert: 'Link copied to clipboard',
              });
            }}
          >
            <Image
              style={tw`h-50px w-50px rounded-full`}
              source={Images.icons.copyLink}
            />
            <Text xsmallPlus override={tw`mt-6px`} centered>
              Copy Link
            </Text>
          </Pressable>
          <Pressable
            style={tw`w-75px items-center`}
            onPress={() =>
              Linking.openURL(`sms:&body=${url}`).then(() => {
                trackEvent(thankYou ? 'contentShareSms' : 'contentShareSms');
                onPressShare();
              })
            }
          >
            <Image
              style={tw`h-50px w-50px rounded-full`}
              source={Images.icons.smsIcon}
            />
            <Text xsmallPlus override={tw`mt-6px`} centered>
              SMS
            </Text>
          </Pressable>

          <Pressable
            style={tw`w-75px items-center`}
            onPress={() => {
              takeScreenshotSave();
            }}
          >
            <Image
              style={tw`h-50px w-50px rounded-full`}
              source={Images.icons.saveImage}
            />
            <Text xsmallPlus override={tw`mt-6px`} centered>
              Save Img
            </Text>
          </Pressable>
        </View>
      </View>
    </SlideUpModal>
  );
};

export default WebShareModal;
