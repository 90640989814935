import { gql } from '@apollo/client';

export const FETCH_CHARITIES = gql`
  query FetchCharities {
    charityList {
      id
      imageUrl
      heroImageUrl
      name
    }
  }
`;
