import { gql } from '@apollo/client';

export const FETCH_LOGIN_TIKTOK_USER = gql`
  mutation connectToTiktok($code: String!) {
    connectToTiktok(input: { code: $code }) {
      token
      success
      error
      user {
        id
      }
    }
  }
`;
