import { gql } from '@apollo/client';

export const FETCH_CONVERSATION_COMMENT = gql`
  query FetchConversationComment($commentId: ID!) {
    conversationComment(id: $commentId) {
      id
      imageUrl
      numVotes
      message
      isAnonymous
      createdAt
      deletedAt
      repliedToMessage {
        id
        message
        deletedAt
      }
      user {
        id
        username
        profilePhoto
        school {
          id
          name
        }
      }
      userVotes {
        id
        voteType
      }
      replies {
        id
        message
        createdAt
        numVotes
        isAnonymous
        deletedAt
        user {
          id
          username
          profilePhoto
          school {
            id
            name
          }
        }
        userVotes {
          id
          voteType
        }
        replies {
          id
          message
          createdAt
          numVotes
          isAnonymous
          deletedAt
          user {
            id
            username
            profilePhoto
            school {
              id
              name
            }
          }
          userVotes {
            id
            voteType
          }
        }
      }
    }
  }
`;
