import { gql } from '@apollo/client';

export const FETCH_APP_SETTINGS = gql`
  query FetchAppSettings {
    appSetting {
      beta
      domain
      minSupportedAndroidVersion
      minSupportedIosVersion
    }
  }
`;
