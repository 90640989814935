import { gql } from '@apollo/client';

export const CREATE_FIREBASE_DEVICE_REGISTRATION_ID = gql`
  mutation CreateFirebaseDeviceRegistrationId($registrationId: String!) {
    createFirebaseDeviceRegistrationId(
      input: { registrationId: $registrationId }
    ) {
      success
      error
    }
  }
`;
