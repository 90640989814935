export const COLORS = {
  // stashrun palette
  purple: '#5551FF',
  secondaryPurple: '#AAA8FF',
  tertiaryPurple: '#EEEEFF',
  red: '#FF1930',
  secondaryRed: '#FF8C98',
  tetiaryRed: '#FFE8EA',
  primaryText: ' #999999',
  alternativeText: '#4F4161',
  secondaryText: '#91889C',
  tertiaryText: '#E9E7EB',
  white: '#FFFFFF',
  trueGray: '#404040',
  borderGray: '#808080',
  dividerGray: '#D9D9D9',
  // end of stashrun palette
  primaryDark: '#231239',
  secondaryWhite: '#E9E7EB',
  lightPurple: '#8885FF',
  extraLightPurple: '#C3C2FF',
  transparentPurpleMore: 'rgba(85, 81, 255, 0.94)',
  transparentPurpleBitMore: 'rgba(85, 81, 255, 0.5)',
  transparentPurple: 'rgba(85, 81, 255, 0.2)',
  transparentBlue: 'rgba(5, 149, 231, 0.3)',
  transparentDarkBlue: 'rgba(5, 149, 231, 0.3)',
  progressBar: 'rgba(0, 228, 209, 1)',
  lightRed: '#FFCCCB',
  lightGreen: '#90EE90',
  green: '#00E4D1',
  darkGreen: '#008800',
  gray: '#EEEEEE',
  lightGray: '#F1F1F1',
  darkLightGray: '#959595',
  darkGray: '#8C8C8C',
  muted: '#464646',
  lightBlack: '#262626',
  black: '#000000',
  secondaryBlack: '#4F4161',
  blue: '#1DA1F3',
  facebookBlue: '#4267B2',
  moderatorBlue: '#3897F0',
  gold: '#E4BA46',
  brightGold: '#DC9F00',
  silver: '#B5B7BB',
  bronze: '#B06304',
  navy: '#00479B',
  brightRed: '#FF3131',
  violet: '#AE00A7',
  olive: '#899F00',
  orange: '#FF550D',
  magenta: '#FB28FF',
  coral: '#FF7777',
  brightGreen: '#04C100',
  yellow: '#EA9A00',
  cyan: '#00CBD8',
  darkPurple: '#4D00AE',
  transparentborder50: 'rgba(34, 34, 34, 0.50)',
  // New halo impact colors 01-2024
  impactLightPurple: '#C1C0EB',
  impactPurple: '#9D9BF2',
  impactDarkPurple: '#7976F9',
  impactSkyBlue: '#2DB9FF',
  impactGreen: '#19B519',
  impactYellow: '#FFD700',
  impactOrange: '#F69F1C',
  impactRed: '#FB2E2E',
  impactPink: '#E73ABC',
  impactBlack: '#FF3131',
};

export const CONFETTI_COLORS = [
  COLORS.purple,
  COLORS.blue,
  COLORS.green,
  COLORS.red,
  COLORS.yellow,
];
export const DEFAULT_WINDOW_HEIGHT = 650;

export const EDU_ACCESS_EMAIL_KEY = 'eduAccessEmail';

export const REQUESTS_URL = 'https://www.stashrun.com/requests';
export const TERMS_OF_SERVICE_URL = 'https://www.stashrun.com/terms';
export const PRIVACY_POLICY_URL = 'https://www.stashrun.com/privacy';
