import React from 'react';

import { View } from 'react-native';

import Spacing from '@/components/Spacing';
import StashrunCoinIcon from '@/components/StashrunCoinIcon';
import Text from '@/components/Text';
import { numberShortened, numberWithCommas } from '@/utils/format';

import { styles } from './styles';

type BrandImpactCardProps = {
  item: any;
};

const BrandImpactCompactCard = ({ item }: BrandImpactCardProps) => {
  if (item.type !== 'Brand compact') return null;
  return (
    <View style={styles.impactContainer}>
      <Spacing>
        <View style={styles.impactCompact}>
          <Text small inverse>
            <Text small bold underlined>
              {`${(item.coinsGiven && numberWithCommas(item.coinsGiven)) || 0}`}
            </Text>{' '}
            <StashrunCoinIcon size={16} />
            {' total donated by '}
            <Text small inverse bold underlined>
              {`${
                item.numCampaigns === 1
                  ? `${numberShortened(item.numSupporters)} supporter`
                  : `${numberShortened(item.numSupporters)} supporters`
              }`}
            </Text>
            {' across '}
            <Text small inverse bold underlined>
              {`${item.numCauses} causes`}
            </Text>
            <Text small>{' & '}</Text>
            <Text small inverse bold underlined>
              {`${
                item.numCampaigns === 1
                  ? `${item.numCampaigns} campaign`
                  : `${item.numCampaigns} campaigns`
              }`}
            </Text>
          </Text>
        </View>
      </Spacing>
    </View>
  );
};
export default BrandImpactCompactCard;
