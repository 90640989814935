import { gql } from '@apollo/client';

export const FETCH_PENDING_COIN_DROPS = gql`
  query FetchPendingCoinDrops($userId: ID!) {
    user(id: $userId) {
      id
      pendingCoinDrops
    }
  }
`;
