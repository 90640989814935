import React, { useCallback, useState } from 'react';

import { useMutation, ApolloError } from '@apollo/client';
import { View, Image, Pressable } from 'react-native';

import images from '@/assets/images';
import {
  Input,
  PrimaryButton,
  Text,
  useSessionTokenContext,
} from '@/components/web';
import { eventProps } from '@/constants/ANALYTICS_CONST';
import useWebHooks from '@/hooks/useWebHooks';
import { LOGIN_WITH_EMAIL } from '@/queries';
import { LoginWithEmailData } from '@/types';
import { trackEvent } from '@/utils';
import tw from '@tw';
import { WebWrapper } from '@web/components';

const AuthLogin = () => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const { setSessionToken, setUserId } = useSessionTokenContext();
  const { navigate } = useWebHooks();

  const [loginWithEmail, { loading: loginWithEmailLoading }] =
    useMutation<LoginWithEmailData>(LOGIN_WITH_EMAIL, {
      variables: { emailOrUsername, password },
      onCompleted: async (data: LoginWithEmailData) => {
        if (data.loginWithEmail?.error) {
          setErrorMessage(data.loginWithEmail?.error);
          trackEvent('emailLoginError', {
            error: data.loginWithEmail?.error,
          });
        } else if (data.loginWithEmail?.success && data.loginWithEmail?.token) {
          await setSessionToken(data.loginWithEmail?.token);
          await setUserId(data.loginWithEmail?.userId || null);
          trackEvent('emailLoginSuccess');
        }
      },
      onError: (error: ApolloError) => {
        trackEvent('emailLoginError', {
          error,
        });
        setErrorMessage(error.message);
      },
    });

  const onChangeEmailOrUsername = useCallback(
    (email: string) => {
      setEmailOrUsername(email);
    },
    [setEmailOrUsername],
  );

  const onChangePassword = useCallback(
    (password: string) => {
      setPassword(password);
    },
    [setPassword],
  );

  const handleLogin = async () => {
    if (emailOrUsername?.length && password?.length) {
      await loginWithEmail({
        variables: {
          emailOrUsername: emailOrUsername?.trim(),
          password,
        },
      });
    } else {
      alert('Please enter your login credentials');
    }
  };

  return (
    <WebWrapper withBack analytics={{ [eventProps.page]: 'Login' }}>
      <View style={tw`flex flex-1 flex-col items-center p-6 pb-32`}>
        <Text bold mediumSmallPlus2 centered>
          Login
        </Text>
        <View
          style={tw`w-28 h-28 rounded-full border-4 border-primary p-1 mt-5`}
        >
          <Image
            source={images.stashrun}
            style={tw`w-full h-full rounded-full`}
          />
        </View>

        <Input
          style={tw`mb-2 w-full mt-9`}
          inputStyle={tw`border rounded px-2 mt-1`}
          autoCorrect={false}
          label="Email (or Username)"
          labelSize="mediumSmall"
          autocapitalize="none"
          returnKeyType="next"
          value={emailOrUsername}
          onChange={onChangeEmailOrUsername}
        />
        <Input
          style={tw`mb-2 w-full`}
          inputStyle={tw`border rounded px-2 mt-1`}
          secureTextEntry
          autoCorrect={false}
          label="Password"
          placeholder="opensesabob"
          labelSize="mediumSmall"
          autocapitalize="none"
          errorMessage={errorMessage}
          value={password}
          onChange={onChangePassword}
        />
        <Pressable
          onPress={async () => {
            navigate('/auth/forgot-password');
          }}
        >
          <Text mediumSmall>Forgot Password</Text>
        </Pressable>
      </View>
      <View
        style={tw.style(
          `fixed w-full bg-white bottomSheetShadow self-center items-center bottom-0 pt-4 pb-7 rounded-tl-[20px] rounded-tr-[20px]`,
        )}
      >
        <PrimaryButton
          fullRadius
          style="w-full"
          title="Login"
          loading={loginWithEmailLoading}
          onPress={handleLogin}
        />
      </View>
    </WebWrapper>
  );
};

export default AuthLogin;
