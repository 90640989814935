import { gql } from '@apollo/client';

export const FETCH_CONVERSATIONS_LIST = gql`
  query FetchConversationsList(
    $cursor: String
    $pageSize: Int
    $causeId: String!
    $sortType: String
  ) {
    conversationsList(
      cursor: $cursor
      pageSize: $pageSize
      causeId: $causeId
      sortType: $sortType
    ) {
      id
      nextCursor
      entries {
        id
        title
        heroImageUrl
        numVotes
        createdAt
        message
        numComments
        deletedAt
        isAnonymous
        user {
          id
          username
          profilePhoto
          school {
            id
            name
          }
        }
        userVotes {
          id
          voteType
        }
        cause {
          id
          name
          moderators {
            id
            username
            fullName
            profilePhoto
          }
        }
      }
    }
  }
`;
