import { Platform, StyleSheet } from 'react-native';

import { COLORS } from '@/utils/constants';

import BrandImpactCard from './BrandImpactCard';
import BrandImpactCompactCard from './BrandImpactCompactCard';
import CauseCard from './CauseCard';
import CauseImpactCard from './CauseImpactCard';
import UserImpactCard from './UserImpactCard';

export {
  UserImpactCard,
  CauseImpactCard,
  BrandImpactCard,
  CauseCard,
  BrandImpactCompactCard,
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberShortened = (x: number) => {
  const moreThan10000 = x > 10000 ? `${(x / 1000).toFixed(1)}K` : x;
  return x > 1000000 ? `${(x / 1000000).toFixed(1)}M` : moreThan10000;
};

export const styles = StyleSheet.create({
  navigationContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    ...(Platform.OS === 'web' && { cursor: 'pointer' }),
  },
  impactContainer: { maxWidth: 300 },
  impact: {
    height: 145,
    width: '100%',
    backgroundColor: COLORS.purple,
    borderRadius: 7,
    padding: 15,
    justifyContent: 'space-between',
  },
  impactCompact: {
    width: '100%',
    backgroundColor: COLORS.purple,
    borderRadius: 7,
    padding: 15,
  },
  impactDark: {
    height: 145,
    width: '80%',
    borderRadius: 7,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
  },
  impactPurple: {
    backgroundColor: COLORS.purple,
    padding: 4,
    position: 'absolute',
    bottom: 15,
  },
  imageStyle: {
    width: 120,
    borderBottomRightRadius: 10,
    borderTopRightRadius: 8,
  },
});
