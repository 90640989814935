import React, { useEffect, useRef } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { BlurView } from 'expo-blur';
import { Image } from 'expo-image';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Animated,
  ScrollView,
  Platform,
  Pressable,
} from 'react-native';
import { Reoverlay } from 'reoverlay';

import images from '@/assets/images';
import { COLORS, addCloudinaryTransformation, maxWidth } from '@/utils';
import tw from '@tw';
import AvatarList from '@web/components/AvatarList';

type Props = {
  children: React.ReactNode;
  maxHeight?: number | string;
  removeButton?: boolean;
  customFooter?: React.ReactNode;
  preventClose?: boolean;
  haloColor?: string;
  userImage?: string;
  slideDown?: boolean;
  keepBackground?: boolean;
  noPurpleBackground?: boolean;
  showRandomAvatar?: boolean;
  bigHead?: boolean;
  customClose?: () => void;
  backbuttonFunction?: (() => void | null) | undefined;
};

const SlideUpModal = ({
  children,
  maxHeight = '90%',
  removeButton,
  customFooter = null,
  preventClose = false,
  haloColor,
  keepBackground,
  userImage,
  slideDown,
  noPurpleBackground,
  showRandomAvatar,
  bigHead,
  customClose,
  backbuttonFunction,
}: Props) => {
  const slide = useRef(new Animated.Value(0)).current;

  const closeModal = () => {
    Animated.timing(slide, {
      toValue: 0,
      duration: 200,
      useNativeDriver: false,
    }).start(() => {
      Reoverlay.hideModal();
      if (customClose) {
        customClose();
      }
    });
  };

  useEffect(() => {
    Animated.timing(slide, {
      toValue: 1,
      duration: 200,
      useNativeDriver: false,
    }).start();

    if (slideDown) {
      closeModal();
    }
  }, [slideDown]);

  return (
    <View
      style={tw.style(
        'left-0 w-full items-center flex z-999',
        noPurpleBackground ? `h-[${maxHeight}px] bottom-0` : 'top-0 h-full',
        {
          fixed: Platform.OS === 'web',
          absolute: Platform.OS !== 'web',
        },
      )}
    >
      <BlurView intensity={10} style={tw.style(`w-full h-full`)}>
        <Pressable
          onPress={() => {
            if (!preventClose) closeModal();
          }}
          style={tw.style(
            `w-full h-full absolute z-2`,
            haloColor && !keepBackground
              ? 'bg-transparent'
              : 'bg-trueGray opacity-80',
            noPurpleBackground && 'bg-transparent',
          )}
        />
      </BlurView>

      <Animated.View
        style={[
          styles.contentWrapper,
          {
            transform: [
              {
                translateY: slide.interpolate({
                  inputRange: [0, 1],
                  outputRange: [600, 0],
                }),
              },
            ],
            maxHeight,
            overflow: haloColor || showRandomAvatar ? 'visible' : 'scroll',
          },
        ]}
      >
        {haloColor && (
          <View
            style={tw.style(
              `h-20 w-20 rounded-full border-2 items-center justify-center z-200 self-center absolute -top-10 bg-white`,
              bigHead && 'h-30 w-30 -top-15',
              { borderColor: haloColor },
            )}
          >
            <Image
              style={tw.style(`h-18 w-18 rounded-full`, bigHead && 'h-28 w-28')}
              source={{
                uri: addCloudinaryTransformation(
                  userImage,
                  'c_scale,w_100,dpr_2',
                ),
              }}
              defaultSource={images.defaultProfilePic}
            />
          </View>
        )}
        {showRandomAvatar && (
          <View style={tw`mt-8`}>
            <View style={tw`z-200 self-center absolute -top-15`}>
              <AvatarList size={55} count={8} noShadow />
            </View>
          </View>
        )}

        <ScrollView
          showsVerticalScrollIndicator={false}
          style={tw.style(`z-100`, { maxWidth: maxWidth(500) })}
        >
          {children}
        </ScrollView>
        {customFooter}
        {removeButton ? null : (
          <TouchableOpacity
            style={tw`z-110 h-50px w-50px flex justify-center items-center absolute top-5px right-5px`}
            onPress={() => {
              closeModal();
            }}
          >
            <Ionicons name="close-outline" size={40} color={'#808080'} />
          </TouchableOpacity>
        )}
        {backbuttonFunction ? (
          <TouchableOpacity
            style={tw`z-110 h-50px w-50px flex justify-center items-center absolute top-5px left-5px`}
            onPress={() => {
              if (backbuttonFunction) {
                backbuttonFunction();
              }
            }}
          >
            <Image source={images.fillBack} style={tw`w-7 h-7`} />
          </TouchableOpacity>
        ) : null}
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  contentWrapper: {
    height: '100%',
    width: maxWidth(500),
    backgroundColor: COLORS.white,
    borderTopRightRadius: 35,
    borderTopLeftRadius: 35,
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    zIndex: 10,
    shadowColor: 'black',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 16,
    shadowOpacity: 0.6,
  },
});

export default SlideUpModal;
