import React, { useEffect } from 'react';

import { View } from 'react-native';
import { NavigateFunction } from 'react-router-dom';
import { Reoverlay } from 'reoverlay';

import { PrimaryButton, SlideUpModal, Text } from '@/components/web';
import { useLogout } from '@/hooks/useLogout';
import scroll from '@/utils/scroll';
import { deleteStoreUserIdentifier } from '@/utils/webStorage';
import tw from '@tw';

type LogoutProps = {
  navigate?: NavigateFunction;
};

const LogoutModal = ({ navigate }: LogoutProps) => {
  const [logoutMutation] = useLogout(() => {
    Reoverlay.hideAll();
    deleteStoreUserIdentifier();
    navigate?.('/auth');
    window.location.reload();
  });
  useEffect(() => {
    scroll.lock();
    return () => {
      scroll.enable();
    };
  }, []);

  return (
    <SlideUpModal maxHeight={350}>
      <View style={tw`px-22 pt-10 w-full`}>
        <Text bold centered>
          Are you sure you want to log out?
        </Text>

        <PrimaryButton
          title="Ok"
          type="withBorder"
          fullRadius
          style={tw`self-center mt-8 mb-4 w-full`}
          onPress={() => logoutMutation()}
        />
        <PrimaryButton
          title="Cancel"
          fullRadius
          style={tw`self-center w-full`}
          type="getApp"
          onPress={() => {
            Reoverlay.hideModal();
          }}
        />
      </View>
    </SlideUpModal>
  );
};

export default LogoutModal;
