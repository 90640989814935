import { gql } from '@apollo/client';

export const MARK_READ_NOTIFICATION = gql`
  mutation MarkNotificationAsRead($notificationId: String!) {
    markNotificationAsRead(input: { notificationId: $notificationId }) {
      success
      error
    }
  }
`;
