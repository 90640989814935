import { gql } from '@apollo/client';

export const FETCH_BRANDS_BY_CAMPAIGN_ID = gql`
  query ($campaignId: ID!) {
    brandListByCampaign(campaignId: $campaignId) {
      id
      name
      description
      heroTagline
      imageUrl
      heroImageUrl
      headerLogoUrl
      username
      registeredName
      location
      registeredNonProfit
      yearFounded
      numEmployees
      website
      instagramUsername
      twitterUsername
      facebookUsername
      youtubeUsername
      tiktokUsername
      linkedinUrl
    }
  }
`;
