import { gql } from '@apollo/client';

export const FETCH_FLAGGED_NOTIFICATIONS = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      notifications(type: "moderator_flag") {
        claimedAt
        createdAt
        id
        imageUrl
        message
        numCoinDrops
        readAt
        title
        type
        url
        metadata
      }
      causes {
        id
        moderators {
          id
          username
          fullName
          profilePhoto
        }
      }
    }
  }
`;
