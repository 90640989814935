import { gql } from '@apollo/client';

export const DELETE_FLAG = gql`
  mutation DeleteFlag($flagId: String!) {
    deleteFlag(input: { flagId: $flagId }) {
      success
      error
    }
  }
`;
