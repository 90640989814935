import React, { useEffect, useRef } from 'react';

import { Animated, Platform, View } from 'react-native';
import { Reoverlay } from 'reoverlay';

import { Text } from '@/components/web';
import scroll from '@/utils/scroll';
import tw from '@tw';

type AlertProps = {
  alert?: string;
  afterClose?: () => void;
};

const AlertModal = ({ alert = '', afterClose }: AlertProps) => {
  const opacity = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    scroll.lock();
    const timeout = setTimeout(() => {
      Animated.timing(opacity, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }).start(() => {
        Reoverlay.hideModal();
        if (afterClose) afterClose();
      });
    }, 800);

    return () => {
      scroll.enable();
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Animated.View
      style={[
        tw.style(
          'left-0 w-full items-center flex z-999 top-0 h-full justify-center items-center',
          {
            fixed: Platform.OS === 'web',
            absolute: Platform.OS !== 'web',
          },
        ),
        { opacity },
      ]}
    >
      <View
        style={tw`max-h-44 w-80 p-4 bottomSheetShadow z-10 bg-white absolute rounded-lg`}
      >
        <Text mediumSmall>{alert}</Text>
      </View>
    </Animated.View>
  );
};

export default AlertModal;
