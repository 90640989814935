import { gql } from '@apollo/client';

export const VIEW_STASHLINK = gql`
  mutation viewStashlink(
    $userId: String!
    $campaignId: String!
    $source: String
  ) {
    viewStashlink(
      input: { userId: $userId, campaignId: $campaignId, source: $source }
    ) {
      success
      error
      stashlink {
        thankYouMessage
        thankYouMessageVideo
        user {
          fullName
          profilePhoto
          haloColor
        }
      }
    }
  }
`;
