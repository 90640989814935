import React from 'react';

import { Image } from 'expo-image';
import { View, Pressable } from 'react-native';

import { Text } from '@/components/web';
import useWebHooks from '@/hooks/useWebHooks';
import { Campaign } from '@/types';
import { maxWidth } from '@/utils';
import { addCloudinaryTransformation } from '@/utils/format';
import tw from '@tw';

import ProgressBar from './ProgressBar';

type CampaignCardProps = {
  campaign: Campaign;
  shadow?: boolean;
};

const width = maxWidth(500);

const CampaignCard = ({ campaign, shadow }: CampaignCardProps) => {
  const { navigate } = useWebHooks();
  const { imageUrl, charity, title = '', id } = campaign || {};

  return (
    <Pressable
      style={tw.style(
        `flex bg-white rounded-2xl border-2 border-borderGray`,
        shadow && `campaignCardShadow`,
      )}
      onPress={() => {
        navigate(`/campaign/${id}?type=create-stash`);
      }}
    >
      <View style={tw`p-2.5 pb-0 relative z-20`}>
        <Text bold small numberOfLines={2} centered>
          {title}
        </Text>
      </View>

      <View style={tw`justify-center self-center`}>
        <ProgressBar
          campaign={campaign}
          divider
          small
          width={width * 0.69}
          borderRadius={0}
        />
        <Image
          style={tw.style(`w-full aspect-square rounded-b-2xl`)}
          source={{
            uri: addCloudinaryTransformation(
              imageUrl,
              `c_scale,w_${width},dpr_2`,
            ),
          }}
        />
      </View>

      <View
        style={tw.style(
          `absolute h-13 w-13 rounded-full border-2 left-3 bottom-3`,
          {
            borderColor: charity?.charityColor || 'black',
          },
        )}
      >
        <Image
          style={tw`h-12 w-12 rounded-full`}
          source={{
            uri: addCloudinaryTransformation(
              charity?.imageUrl,
              `c_scale,w_120`,
            ),
          }}
        />
      </View>
      <Text
        bold
        small
        numberOfLines={1}
        override={tw`absolute z-full -bottom-6 self-center`}
      >
        {charity?.name || ''}
      </Text>
    </Pressable>
  );
};

export { CampaignCard };
